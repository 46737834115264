import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { COLORS } from 'styles/constants';
import { truncate } from 'lodash';
import { Checkbox, Dialog, IconButton } from '@mui/material';
import { Icons } from 'components';
import { OutPlanWeekly, OutWork, UpdateIssueDetail, UpdateWork } from 'queries/model';
import { useState } from 'react';
import IssueDetailView from 'pages/Task/components/IssueDetailView';
import WeeklyPlanMenuPopover from './Components/WeeklyPlanMenuPopover';
import { PlanSections } from 'pages/Task/SidePanel/tabs/Plan';
import InboxContextMenuPopover, { InboxContextMenuType } from 'components/InboxContextMenuPopover';
import PriorityIssueCheckbox from 'components/PriorityIssueCheckbox';
import FirstPriorityIssueCheckbox from 'components/\bFirstPriorityIssueCheckbox';
import dayjs from 'dayjs';
interface PlanIssueProps {
  sectionIssue?: OutWork[];
  section?: PlanSections[];
  weeklyPlan?: OutPlanWeekly[];
  sectionId?: string;
  sectionPriority?: number;
  clickedMenu?: { id: string; type: string };
  onClickSaveIssue?: (id: string, value: UpdateIssueDetail) => void;
  onClickDeleteIssue?: (id: string) => void;
  onDragStart?: (sectionId: string, issueId: string) => void;
  onDragEnd?: () => void;
  onDrag?: (e: React.DragEvent<HTMLDivElement>, id: string) => void;
  onDragOver?: (e: React.DragEvent<HTMLDivElement>, id: string) => void;
  onDrop?: (sectionId: string) => void;
  onClickMenu?: (id: string, type: string, menu: InboxContextMenuType) => void;
  onClickCheckbox?: (issueId: string, issue: UpdateWork) => void;
}

const PlanIssueItemWrapper = styled.div<{ done?: boolean }>`
  width: 100%;
  min-height: 72px;
  display: flex;
  flex-direction: column;
  padding: 8px 8px;
  margin-bottom: 8px;
  border-radius: 8px;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.gray200};
  cursor: grab;

  ${(props) =>
    props.done &&
    css`
      opacity: 50%;
    `}

  :hover .issue-more-btn {
    opacity: 1;
  }
`;

const PlanIssueTitle = styled.div<{ done?: boolean }>`
  ${(props) =>
    props.done &&
    css`
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 11px;
        left: -1px;
        width: 105%;
        height: 1px;
        background-color: black;
      }
    `}
`;

const IssueMoreButtonWrapper = styled.div`
  display: flex;
  opacity: 0;
  transition: opacity 0.3s ease-out;
`;

const IssueTaskWrapper = styled.div<{ selected?: boolean; ghosting?: boolean }>`
  height: 28px;
  display: ${(props) => (props.ghosting ? 'none' : 'flex')};
  align-items: center;
  border-radius: 4px;
  padding: 0px 8px;
  background: ${(props) => (props.selected ? COLORS.sub3 : COLORS.white)};
  transition: transform 0.1s !important;
`;

const TextTruncate = styled.div<{ done?: boolean }>`
  ${truncate('100%')};
  ${(props) =>
    props.done &&
    css`
      text-decoration: line-through;
    `}
`;

const IssueTaskDuedate = styled.div`
  padding: 0px 8px;
  margin-top: 12px;
  color: ${COLORS.gray400};
  font-size: 13px;
`;

export const PlanIssue = ({
  sectionIssue = [],
  section = [],
  weeklyPlan = [],
  sectionId,
  clickedMenu,
  sectionPriority,
  onClickSaveIssue = () => {},
  onClickDeleteIssue = () => {},
  onDragStart = () => {},
  onDragEnd = () => {},
  onDrag = () => {},
  onDragOver = () => {},
  onDrop = () => {},
  onClickMenu = () => {},
  onClickCheckbox = () => {},
}: PlanIssueProps) => {
  const [clickedIssue, setClickedIssue] = useState<string>();
  const [sectionTitle, setSectionTitle] = useState<string>();
  const [priority, setPriority] = useState<number>();
  const [issueMenuPopover, setIssueMenuPopover] = useState<HTMLElement | null>(null);

  const handleClickCancelIssue = () => {
    setClickedIssue(undefined);
  };

  const handleClickSaveIssue = async (id: string, value: UpdateIssueDetail) => {
    if (!value) return;
    onClickSaveIssue(id, value);
    setClickedIssue(undefined);
  };

  const handleDragStart = (sectionId: string, issueId: string) => {
    onDragStart(sectionId, issueId);
  };

  const handleDragEnd = () => {
    onDragEnd();
  };

  const handleClickIssueDetail = (issueId: string, sectionTitle: string, priority: number) => {
    setClickedIssue(issueId);
    setSectionTitle(sectionTitle);
    setPriority(priority);
  };

  const handleClickMenu = (id: string, type: string) => {
    onClickMenu(id, type, 'DELETE');
    setIssueMenuPopover(null);
    setClickedIssue(undefined);
  };

  const handleClickCheckbox = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, issueId: string, issue: OutWork) => {
    e.stopPropagation();
    const updateIssue: UpdateWork = {
      done: issue.doneAt ? false : true,
    };
    onClickCheckbox(issueId, updateIssue);
  };

  return (
    <>
      {sectionIssue.map((v) => {
        const priority = weeklyPlan.find((item) => item.issue.id === v.id)?.priority;
        const sectionId = weeklyPlan.find((item) => item.issue.id === v.id)?.sectionId
          ? weeklyPlan.find((item) => item.issue.id === v.id)?.sectionId
          : 'default';
        const sectionTitle = section.find((item) => item.id === sectionId)?.name ? section.find((item) => item.id === sectionId)?.name : '섹션 없음';

        return (
          <PlanIssueItemWrapper
            key={v.id}
            done={v.doneAt ? true : false}
            draggable={true}
            onDragStart={() => handleDragStart(sectionId!, v.id)}
            onDragEnd={handleDragEnd}
            onDrag={(e) => onDrag(e, v.id)}
            onDragOver={(e) => onDragOver(e, v.id)}
            onClick={() => {
              handleClickIssueDetail(v.id, sectionTitle!, priority!);
            }}
            style={
              priority !== 0 && priority !== null
                ? {
                    border: '1px solid transparent',
                    background: `linear-gradient(#fff 0 0) padding-box, linear-gradient(90deg, #c471ed 0%, #f64f59 88.02%) border-box`,
                  }
                : { border: `1px solid ${COLORS.gray200}` }
            }
          >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0 8px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {priority === 1 ? (
                  <FirstPriorityIssueCheckbox
                    checked={v.doneAt ? true : false}
                    onClick={(e) => {
                      handleClickCheckbox(e, v.id, v);
                    }}
                  ></FirstPriorityIssueCheckbox>
                ) : (
                  <PriorityIssueCheckbox
                    checked={v.doneAt ? true : false}
                    onClick={(e) => {
                      handleClickCheckbox(e, v.id, v);
                    }}
                  ></PriorityIssueCheckbox>
                )}
                <PlanIssueTitle done={v.doneAt ? true : false}>
                  <span style={{ fontSize: 16, fontWeight: 'bold' }}>{v.content || '제목 없음'}</span>
                  <span style={{ fontSize: 16, fontWeight: 'bold', color: `${COLORS.gray400}`, marginLeft: '4px' }}>{`(${v.tasks?.length})`}</span>
                </PlanIssueTitle>
              </div>
              <IssueMoreButtonWrapper className="issue-more-btn">
                <IconButton
                  aria-label="more"
                  sx={{ background: 'white', padding: 0, marginLeft: '4px' }}
                  size="small"
                  onClick={(e) => {
                    setIssueMenuPopover(e.currentTarget);
                    setClickedIssue(v.id);
                    e.stopPropagation();
                  }}
                >
                  <Icons.ColorMore />
                </IconButton>
              </IssueMoreButtonWrapper>
            </div>
            {v?.tasks?.map((task) => (
              <IssueTaskWrapper key={task.id}>
                <Checkbox disabled={true} checked={task.doneAt ? true : false} style={{ marginRight: 8 }} sx={{ padding: 0 }} />
                <TextTruncate done={v.doneAt ? true : false} style={{ fontSize: 13 }}>
                  {task.content}
                </TextTruncate>
              </IssueTaskWrapper>
            ))}
            {v.dueDate && <IssueTaskDuedate>{dayjs(v.dueDate).format('MM월 DD일 ddd요일')}</IssueTaskDuedate>}
          </PlanIssueItemWrapper>
        );
      })}

      {!!clickedIssue && !issueMenuPopover && (
        <Dialog
          open={true}
          sx={{ borderRadius: 8 }}
          PaperProps={{ style: { maxWidth: '100%' } }}
          onBackdropClick={handleClickCancelIssue}
          onClose={handleClickCancelIssue}
        >
          <IssueDetailView
            issueId={clickedIssue}
            priority={priority}
            sectionTitle={sectionTitle}
            onClickCancel={handleClickCancelIssue}
            onClickSave={handleClickSaveIssue}
          ></IssueDetailView>
        </Dialog>
      )}
      {clickedIssue && issueMenuPopover && (
        <InboxContextMenuPopover
          id={clickedIssue}
          type={'issue'}
          open={Boolean(issueMenuPopover)}
          anchorEl={issueMenuPopover}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          sx={{ marginTop: 0.5, marginLeft: 2 }}
          hiddenMenuItems={['MOVE_TO_THIS_WEEK', 'MOVE_TO_NEXT_WEEK', 'MOVE_TO_AFTER', 'SWITCH_TO_ISSUE', 'SWITCH_TO_TASK']}
          onClose={() => {
            setIssueMenuPopover(null);
            setClickedIssue(undefined);
          }}
          onClickMenu={handleClickMenu}
        ></InboxContextMenuPopover>
      )}
    </>
  );
};
