import { SVGProps } from 'react';

export const DoubleQuotesL = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="Edit / Double_Quotes_L">
        <g id="Vector">
          <path
            d="M9.33301 8.0013V10.268C9.33301 10.6413 9.33301 10.8279 9.40567 10.9705C9.46959 11.0959 9.5715 11.1981 9.69694 11.262C9.83941 11.3346 10.026 11.3346 10.3986 11.3346H11.6007C11.9733 11.3346 12.1597 11.3346 12.3021 11.262C12.4276 11.1981 12.5298 11.0959 12.5937 10.9704C12.6663 10.828 12.6663 10.6416 12.6663 10.269V9.06694C12.6663 8.6943 12.6663 8.5077 12.5937 8.36523C12.5298 8.23979 12.4276 8.13788 12.3022 8.07396C12.1596 8.0013 11.973 8.0013 11.5997 8.0013H9.33301Z"
            fill="#0039A7"
          />
          <path
            d="M3.33301 8.0013V10.268C3.33301 10.6413 3.33301 10.8279 3.40567 10.9705C3.46959 11.0959 3.5715 11.1981 3.69694 11.262C3.83941 11.3346 4.02601 11.3346 4.39864 11.3346H5.6007C5.97334 11.3346 6.15967 11.3346 6.30214 11.262C6.42758 11.1981 6.52983 11.0959 6.59375 10.9704C6.66634 10.828 6.66634 10.6416 6.66634 10.269V9.06694C6.66634 8.6943 6.66634 8.5077 6.59375 8.36523C6.52983 8.23979 6.42765 8.13788 6.3022 8.07396C6.1596 8.0013 5.97304 8.0013 5.59967 8.0013H3.33301Z"
            fill="#0039A7"
          />
          <path
            d="M9.33301 8.0013V10.268C9.33301 10.6413 9.33301 10.8279 9.40567 10.9705C9.46959 11.0959 9.5715 11.1981 9.69694 11.262C9.83941 11.3346 10.026 11.3346 10.3986 11.3346H11.6007C11.9733 11.3346 12.1597 11.3346 12.3021 11.262C12.4276 11.1981 12.5298 11.0959 12.5937 10.9704C12.6663 10.828 12.6663 10.6416 12.6663 10.269V9.06694C12.6663 8.6943 12.6663 8.5077 12.5937 8.36523C12.5298 8.23979 12.4276 8.13788 12.3022 8.07396C12.1596 8.0013 11.973 8.0013 11.5997 8.0013H9.33301ZM9.33301 8.0013V6.66797C9.33301 5.5634 10.2284 4.66797 11.333 4.66797M3.33301 8.0013V10.268C3.33301 10.6413 3.33301 10.8279 3.40567 10.9705C3.46959 11.0959 3.5715 11.1981 3.69694 11.262C3.83941 11.3346 4.02601 11.3346 4.39864 11.3346H5.6007C5.97334 11.3346 6.15967 11.3346 6.30214 11.262C6.42758 11.1981 6.52983 11.0959 6.59375 10.9704C6.66634 10.828 6.66634 10.6416 6.66634 10.269V9.06694C6.66634 8.6943 6.66634 8.5077 6.59375 8.36523C6.52983 8.23979 6.42765 8.13788 6.3022 8.07396C6.1596 8.0013 5.97304 8.0013 5.59967 8.0013H3.33301ZM3.33301 8.0013V6.66797C3.33301 5.5634 4.22844 4.66797 5.33301 4.66797"
            stroke="#0039A7"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};
