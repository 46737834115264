import styled from '@emotion/styled';
import { Button, IconButton, TextField, Typography } from '@mui/material';
import { Icons } from 'components';
import IssueCheckbox from 'components/IssueCheckbox';
import { OutWork } from 'queries/model';
import { PropsWithChildren, ReactNode, useRef, useState } from 'react';
import { COLORS } from 'styles/constants';
import { hideScroll } from 'styles/utils';
import { useAtom } from 'jotai';
import { dragContextAtom, WorkDragContext, WorkDragView } from 'atoms/works';
import IssueItem from './IssueItem';
import { InboxContextMenuPopoverProps, InboxContextMenuType } from 'components/InboxContextMenuPopover';
import { IssueDetailViewProps } from 'pages/Task/components/IssueDetailView';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const IssueListViewDroppableArea = styled.div<{ dragOver?: boolean }>`
  position: absolute;
  min-height: 240px;
  top: 32px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${(props) => (props.dragOver ? COLORS.sub5 : COLORS.sub3)};
  border: 1px dashed ${(props) => (props.dragOver ? COLORS.sub4 : COLORS.brand1)};
  border-radius: 8px;
  z-index: 100;

  :hover {
    border: 1px dashed red;
  }
`;

const IssueListTitle = styled.h3`
  font-size: 16px;
  font-weight: bold;
`;

const IssueListIssueCount = styled.h3`
  font-weight: bold;
  color: ${COLORS.gray400};
`;

const IssueListHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  padding: 0 4px;
`;

const NewIssueInputWrapper = styled.div`
  width: 100%;
  min-height: 42px;
  display: flex;
  align-items: center;
  background: ${COLORS.white};
  border-radius: 8px;
  border: 1px solid ${COLORS.gray200};
  padding: 0px 16px;
  margin-bottom: 8px;
`;

const IssueListViewItemWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  ${hideScroll()}
`;

const EmptyListWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 12px;
  border-radius: 8px;
  background: linear-gradient(180deg, #e7eaf4 0%, #f2f5fc 100%);
`;

const DroppableIssueArea = styled.div`
  display: flex;
  width: 100%;
  min-height: 88px;
  background-color: ${COLORS.sub5};
  align-items: center;
  justify-content: center;
  border: 1px dashed ${COLORS.sub4};
  border-radius: 8px;
  margin-bottom: 8px;
  filter: brightness(100%);

  :hover {
    filter: brightness(95%);
  }
`;

const DroppableIssueGuide = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: ${COLORS.gray400};
`;

export interface IssueListViewProps extends PropsWithChildren {
  title?: ReactNode;
  issues?: { issue: OutWork; priority?: number }[];
  droppable?: boolean;
  droppableInItem?: boolean;
  draggable?: boolean;
  dragView?: WorkDragView;
  droppableViews?: string[];
  hiddenContextMenus?: InboxContextMenuType[];
  onCreate?: (value: string) => void;
  onChangeIssue?: (id: string, checked: boolean) => void;
  onChangeTask?: (id: string, checked: boolean) => void;
  onClickContextMenu?: InboxContextMenuPopoverProps['onClickMenu'];
  onCloseIssueDetailPopup?: () => void;
  onApplyIssueDetailPopup?: IssueDetailViewProps['onClickSave'];
  onDropInItem?: (id: string, context?: WorkDragContext) => void;
  onDropOutside?: (context?: WorkDragContext) => void;
}

const IssueListView = (props: IssueListViewProps) => {
  const {
    title,
    issues = [],
    droppable,
    droppableInItem,
    draggable,
    dragView,
    hiddenContextMenus = ['SWITCH_TO_ISSUE'],
    droppableViews = [],
    onCreate,
    onDropInItem,
    onDropOutside,
    onClickContextMenu,
    onChangeIssue,
    onChangeTask,
    onCloseIssueDetailPopup,
    onApplyIssueDetailPopup,
  } = props;
  const refInput = useRef<HTMLInputElement>(null);
  const [isVisibleInput, setIsVisibleInput] = useState(false);
  const [dragOver, setDragOver] = useState(false);
  const [dragContext] = useAtom(dragContextAtom);
  const isDragging =
    droppable &&
    dragContext &&
    (dragContext?.type === 'task' || dragContext?.type === 'issue') &&
    (!droppableViews.length || droppableViews.includes(dragContext?.view || '')) &&
    !issues.some((item) => item.issue.id === dragContext?.id);

  const isDroppableInItem =
    droppableInItem &&
    (dragContext?.type === 'task' || dragContext?.type === 'issue') &&
    (!droppableViews.length || droppableViews.includes(dragContext?.view || ''));

  const handleKeydownInput = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!refInput.current) return;

    const value = refInput.current?.value;
    if (e.key === 'Escape') {
      if (value) return;

      e.preventDefault();
      setIsVisibleInput(false);
    }

    if (e.key === 'Enter') {
      if (e.nativeEvent.isComposing) return;
      if (e.repeat) {
        e.preventDefault();
        return;
      }

      refInput.current.value = '';
      onCreate && onCreate(value);
    }
  };

  const handleClickCreate = () => {
    setIsVisibleInput(true);
    setTimeout(() => refInput.current?.focus(), 100);
  };

  const handleDrop = () => {
    setDragOver(false);
    if (dragContext) onDropOutside && onDropOutside(dragContext);
  };

  return (
    <Container>
      {isDragging && !isDroppableInItem && (
        <IssueListViewDroppableArea
          onDrop={handleDrop}
          onDragOver={(e) => {
            e.preventDefault();
            setDragOver(true);
          }}
          onDragLeave={() => setDragOver(false)}
          dragOver={dragOver}
        />
      )}
      <IssueListHeaderWrapper>
        {title ? (
          <>{title}</>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IssueListTitle>이슈</IssueListTitle>
            <IssueListIssueCount style={{ marginLeft: 4 }}>({issues.length})</IssueListIssueCount>
          </div>
        )}
        <IconButton size={'small'} color="inherit" style={{ padding: 0 }} onClick={handleClickCreate}>
          <Icons.Plus width={16} height={16} strokeWidth={2} />
        </IconButton>
      </IssueListHeaderWrapper>
      {isDragging && isDroppableInItem && (
        <DroppableIssueArea onDrop={handleDrop} onDragOver={(e) => e.preventDefault()}>
          <Icons.AddTask />
          <DroppableIssueGuide style={{ marginLeft: 4 }}>태스크를 Drag&Drop 해서 이슈를 만들어 주세요.</DroppableIssueGuide>
        </DroppableIssueArea>
      )}
      {isVisibleInput && (
        <NewIssueInputWrapper>
          <IssueCheckbox style={{ marginRight: 8 }} disabled />
          <TextField
            inputRef={refInput}
            autoComplete="off"
            fullWidth
            variant="standard"
            placeholder="이슈 제목을 입력해주세요."
            onKeyDown={handleKeydownInput}
            onBlur={() => !refInput?.current?.value && setIsVisibleInput(false)}
            InputProps={{ disableUnderline: true, style: { fontSize: 13, color: COLORS.gray800 } }}
          />
        </NewIssueInputWrapper>
      )}
      {(!isDragging || isDroppableInItem) && issues.length > 0 && (
        <IssueListViewItemWrapper>
          {issues.map((v, idx) => (
            <IssueItem
              key={idx}
              issue={v.issue}
              priority={v?.priority}
              draggable={draggable}
              droppable={isDroppableInItem}
              dragView={dragView}
              hiddenContextMenus={hiddenContextMenus}
              onDrop={onDropInItem}
              onChangeIssue={onChangeIssue}
              onChangeTask={onChangeTask}
              onClickContextMenu={onClickContextMenu}
              onCloseIssueDetailPopup={onCloseIssueDetailPopup}
              onApplyIssueDetailPopup={onApplyIssueDetailPopup}
            />
          ))}
        </IssueListViewItemWrapper>
      )}
      {!isVisibleInput && issues.length === 0 && (
        <EmptyListWrapper>
          <Button size={'small'} color="inherit" style={{ paddingLeft: 0, paddingRight: 0 }} onClick={handleClickCreate} fullWidth>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icons.Plus width={16} height={16} strokeWidth={2} />
              <Typography variant="button" fontSize={13} color={COLORS.gray700} style={{ marginLeft: 4 }}>
                이슈 추가
              </Typography>
            </div>
          </Button>
        </EmptyListWrapper>
      )}
    </Container>
  );
};

export default IssueListView;
