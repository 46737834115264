import styled from '@emotion/styled';
import { Popover, PopoverProps } from '@mui/material';
import { Icons } from 'components';
import { COLORS } from 'styles/constants';

const Container = styled.div`
  width: 200px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 12px;
`;

const MenuList = styled.ul``;

const MenuItem = styled.li`
  display: flex;
  align-items: center;
  padding: 4px 4px;
  cursor: pointer;

  &:hover {
    border-radius: 4px;
    background-color: ${COLORS.gray100};
  }
`;

const MenuItemText = styled.span`
  font-size: 12px;
  color: ${COLORS.gray900};
`;

const HotkeyText = styled.span`
  font-size: 12px;
  color: ${COLORS.gray600};
`;

import { Button, Dialog, DialogActions, DialogContent, DialogProps } from '@mui/material';
import React, { useImperativeHandle, useState } from 'react';

export const ConfirmDialog = React.forwardRef<DialogElement, ConfirmDialogProps>(function ConfirmDialog(props, ref): JSX.Element {
  const { onClose, onConfirm, ...other } = props;
  const [state, setState] = useState<State>({ open: false });
  const handleClose = useHandleClose(setState, onClose);
  const handleConfirm = useHandleConfirm(setState, onConfirm);

  useImperativeHandle(ref, () => ({
    open() {
      setState({ open: true });
    },
  }));

  return (
    <Dialog open={state.open} onClose={handleClose} {...other}>
      {/* <DialogTitle></DialogTitle> */}
      <DialogContent style={{ color: `${COLORS.negative1}`, width: 300 }}>이슈를 삭제하시겠습니까?</DialogContent>
      <DialogActions>
        <Button onClick={(e) => handleClose(e, 'escapeKeyDown')}>취소</Button>
        <Button onClick={handleConfirm}>삭제</Button>
      </DialogActions>
    </Dialog>
  );
});

function useHandleClose(setState: SetState, handleClose?: CloseHandler) {
  return React.useCallback<CloseHandler>(function (event, reason) {
    setState({ open: false });
    handleClose?.(event, reason ?? 'backdropClick');
  }, []);
}

function useHandleConfirm(setState: SetState, handleConfirm?: ConfirmHandler) {
  return React.useCallback(async function () {
    await handleConfirm?.();
    setState({ open: false });
  }, []);
}

type State = { open: boolean; error?: Error };
type SetState = React.Dispatch<React.SetStateAction<State>>;
type CloseHandler = NonNullable<DialogProps['onClose']>;
type ConfirmHandler = () => Promise<void> | void;

export type DialogElement = { open: () => void };

export type ConfirmDialogProps = Omit<DialogProps, 'open'> & {
  onConfirm?: ConfirmHandler;
  onClickDeleteIssue?: (id: string) => void;
};

export const InboxContextMenuName = {
  SWITCH_TO_TASK: 'SWITCH_TO_TASK',
  SWITCH_TO_ISSUE: 'SWITCH_TO_ISSUE',
  MOVE_TO_THIS_WEEK: 'MOVE_TO_THIS_WEEK',
  MOVE_TO_NEXT_WEEK: 'MOVE_TO_NEXT_WEEK',
  MOVE_TO_AFTER: 'MOVE_TO_AFTER',
  DELETE: 'DELETE',
} as const;

export type InboxContextMenuType = typeof InboxContextMenuName[keyof typeof InboxContextMenuName];
export type MenuType = {
  id: string;
  type: string;
};

export interface WeeklyPlanMenuPopoverProps extends PopoverProps {
  clickedMenu: MenuType;
  type?: 'issue' | 'task';
  hiddenMenuItems?: InboxContextMenuType[];
  onClickMenu?: (id: string, type: string, menu: InboxContextMenuType) => void;
  onClickDelete?: (id: string) => void;
}

export const WeeklyPlanMenuPopover = ({
  clickedMenu,
  open,
  anchorEl,
  type,
  hiddenMenuItems = [],
  onClickMenu,
  onClose,
  onClickDelete = () => {},

  ...props
}: WeeklyPlanMenuPopoverProps) => {
  const [menuDelete, setMenuDelete] = useState<boolean>(false);
  // const confirmRef = React.useRef<DialogElement>(null);

  // const handleClickMenuSwitchTo = async (workType: 'task' | 'issue') => {
  //   onClickMenu && onClickMenu(id!, type!, workType === 'task' ? 'SWITCH_TO_TASK' : 'SWITCH_TO_ISSUE');
  // };

  const handleClickMenu = () => {
    clickedMenu.type === 'issue' ? setMenuDelete(true) : onClickDelete(clickedMenu.id);
  };

  const handleClickCancel = () => {
    setMenuDelete(false);
  };

  const handleClickDelete = (id: string) => {
    onClickDelete(id);
    setMenuDelete(false);
  };
  // const handleDialogAction = () => {
  //   onClickMenu && onClickMenu(id!, type!, 'DELETE');
  // };

  return (
    <Popover open={open} anchorEl={anchorEl} onClose={onClose} {...props}>
      <Container>
        <MenuList>
          {/* {!hiddenMenuItems.includes('SWITCH_TO_ISSUE') && (
            <MenuItem onClick={() => handleClickMenuSwitchTo('issue')}>
              <span style={{ marginRight: 8 }}>
                <Icons.AddTask fill="#1C1B1F" />
              </span>
              <MenuItemText>이슈로 전환하기</MenuItemText>
            </MenuItem>
          )}
          {!hiddenMenuItems.includes('SWITCH_TO_TASK') && (
            <MenuItem onClick={() => handleClickMenuSwitchTo('task')}>
              <span style={{ marginRight: 8 }}>
                <Icons.SplitScreen fill="#1C1B1F" />
              </span>
              <MenuItemText>태스크로 전환하기</MenuItemText>
            </MenuItem>
          )}
          {(!hiddenMenuItems.includes('SWITCH_TO_TASK') || !hiddenMenuItems.includes('SWITCH_TO_TASK')) && <Divider style={{ margin: '4px 0px' }} />}
          {!hiddenMenuItems.includes('MOVE_TO_THIS_WEEK') && (
            <MenuItem style={{ justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: 8 }}>
                  <Icons.CalendarWithoutClock fill="#1C1B1F" width={14} height={14} />
                </span>
                <MenuItemText>플랜 - 이번주</MenuItemText>
              </div>
              <HotkeyText>Ctrl(⌘C)+I</HotkeyText>
            </MenuItem>
          )}
          {!hiddenMenuItems.includes('MOVE_TO_NEXT_WEEK') && (
            <MenuItem style={{ justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: 8 }}>
                  <Icons.NextWeek fill="#1C1B1F" />
                </span>
                <MenuItemText>플랜 - 다음주</MenuItemText>
              </div>
              <HotkeyText>Ctrl(⌘C)+K</HotkeyText>
            </MenuItem>
          )}
          {(!hiddenMenuItems.includes('MOVE_TO_THIS_WEEK') || !hiddenMenuItems.includes('MOVE_TO_NEXT_WEEK')) && <Divider style={{ margin: '4px 0px' }} />}
          {!hiddenMenuItems.includes('MOVE_TO_AFTER') && (
            <MenuItem>
              <span style={{ marginRight: 8 }}>
                <Icons.NextPlan fill="#1C1B1F" />
              </span>
              <MenuItemText>나중에</MenuItemText>
            </MenuItem>
          )} */}
          {/* {!hiddenMenuItems.includes('DELETE') && (
            <MenuItem onClick={handleClickMenuDelete}>
              <span style={{ marginRight: 8 }}>
                <Icons.Delete fill={COLORS.negative1} width={14} height={14} />
              </span>
              <MenuItemText style={{ color: COLORS.negative1 }}>삭제</MenuItemText>
            </MenuItem>
          )} */}
          <MenuItem onClick={handleClickMenu}>
            <span style={{ marginRight: 8 }}>
              <Icons.Delete fill={COLORS.negative1} width={14} height={14} />
            </span>
            <MenuItemText style={{ color: COLORS.negative1 }}>삭제</MenuItemText>
          </MenuItem>
        </MenuList>

        <Dialog open={menuDelete}>
          {/* <DialogTitle></DialogTitle> */}
          <DialogContent style={{ color: `${COLORS.negative1}`, width: 300 }}>이슈를 삭제하시겠습니까?</DialogContent>
          <DialogActions>
            <Button onClick={handleClickCancel}>취소</Button>
            <Button onClick={() => handleClickDelete(clickedMenu.id)}>삭제</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Popover>
  );
};

export default WeeklyPlanMenuPopover;
