import { SVGProps } from 'react';

export const Bookmark = ({ stroke = '#858994', ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="Interface / Bookmark">
        <path
          id="Vector"
          d="M4 4.79753V11.121C4 12.0283 4 12.4819 4.13608 12.7596C4.3883 13.2742 4.94105 13.5699 5.50911 13.4943C5.8156 13.4535 6.19309 13.2019 6.94807 12.6986L6.94988 12.6974C7.24912 12.4979 7.39877 12.3981 7.5553 12.3428C7.84281 12.2412 8.15651 12.2412 8.44401 12.3428C8.60086 12.3982 8.75109 12.4984 9.05155 12.6987C9.80652 13.202 10.1844 13.4534 10.4909 13.4942C11.059 13.5698 11.6117 13.2742 11.8639 12.7596C12 12.482 12 12.0282 12 11.121V4.79534C12 4.05006 12 3.67686 11.8548 3.39193C11.727 3.14104 11.5225 2.93722 11.2716 2.80939C10.9864 2.66406 10.6135 2.66406 9.8668 2.66406H6.13346C5.38673 2.66406 5.01308 2.66406 4.72786 2.80939C4.47698 2.93722 4.27316 3.14104 4.14532 3.39193C4 3.67714 4 4.05079 4 4.79753Z"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
