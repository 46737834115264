type State = { open: boolean; error?: Error };
type SetState = React.Dispatch<React.SetStateAction<State>>;
type CloseHandler = NonNullable<DialogProps['onClose']>;
type ConfirmHandler = () => Promise<void> | void;

export type DialogElement = { open: () => void };
export type ConfirmDialogProps = Omit<DialogProps, 'open'> & {
  onConfirm?: ConfirmHandler;
};

import { Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogProps } from '@mui/material';
import React, { useImperativeHandle, useState } from 'react';

export const ConfirmDialog = React.forwardRef<DialogElement, ConfirmDialogProps>(function ConfirmDialog(props, ref): JSX.Element {
  const { onClose, onConfirm, children, title, ...other } = props;
  const [state, setState] = useState<State>({ open: false });
  const handleClose = useHandleClose(setState, onClose);
  const handleConfirm = useHandleConfirm(setState, onConfirm);

  useImperativeHandle(ref, () => ({
    open() {
      setState({ open: true });
    },
  }));

  return (
    <Dialog open={state.open} onClose={handleClose} {...other}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent style={{ minWidth: 300 }}>{children}</DialogContent>
      <DialogActions>
        <Button onClick={(e) => handleClose(e, 'escapeKeyDown')}>취소</Button>
        <Button onClick={handleConfirm}>삭제</Button>
      </DialogActions>
    </Dialog>
  );
});

function useHandleClose(setState: SetState, handleClose?: CloseHandler) {
  return React.useCallback<CloseHandler>(function (event, reason) {
    setState({ open: false });
    handleClose?.(event, reason ?? 'backdropClick');
  }, []);
}

function useHandleConfirm(setState: SetState, handleConfirm?: ConfirmHandler) {
  return React.useCallback(async function () {
    await handleConfirm?.();
    setState({ open: false });
  }, []);
}

export default ConfirmDialog;
