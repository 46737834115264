import styled from '@emotion/styled';
import { Divider, Popover, PopoverProps } from '@mui/material';
import { Icons } from 'components';
import { useRef } from 'react';
import { COLORS } from 'styles/constants';
import ConfirmDialog, { DialogElement } from './ConfirmDialog';

const Container = styled.div`
  width: 200px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 12px;
`;

const MenuList = styled.ul``;

const MenuItem = styled.li`
  display: flex;
  align-items: center;
  padding: 4px 4px;
  cursor: pointer;

  &:hover {
    border-radius: 4px;
    background-color: ${COLORS.gray100};
  }
`;

const MenuItemText = styled.span`
  font-size: 12px;
  color: ${COLORS.gray900};
`;

const HotkeyText = styled.span`
  font-size: 12px;
  color: ${COLORS.gray600};
`;

export const InboxContextMenuName = {
  SWITCH_TO_TASK: 'SWITCH_TO_TASK',
  SWITCH_TO_ISSUE: 'SWITCH_TO_ISSUE',
  MOVE_TO_THIS_WEEK: 'MOVE_TO_THIS_WEEK',
  MOVE_TO_NEXT_WEEK: 'MOVE_TO_NEXT_WEEK',
  MOVE_TO_AFTER: 'MOVE_TO_AFTER',
  DELETE: 'DELETE',
} as const;

export type InboxContextMenuType = typeof InboxContextMenuName[keyof typeof InboxContextMenuName];
export interface InboxContextMenuPopoverProps extends PopoverProps {
  id: string;
  type: 'issue' | 'task';
  hiddenMenuItems?: InboxContextMenuType[];
  onClickMenu?: (id: string, type: string, menu: InboxContextMenuType) => void;
}

export const InboxContextMenuPopover = ({ id, open, anchorEl, type, hiddenMenuItems = [], onClickMenu, onClose, ...props }: InboxContextMenuPopoverProps) => {
  const refConfirm = useRef<DialogElement>(null);

  const handleClickMenuItem = async (menu: InboxContextMenuType) => {
    onClickMenu && onClickMenu(id, type, menu);
  };

  const handleClickMenuDelete = async () => refConfirm.current?.open();

  const handleConfirm = () => {
    onClickMenu && onClickMenu(id, type, 'DELETE');
  };

  return (
    <Popover open={open} anchorEl={anchorEl} onClose={onClose} {...props}>
      <Container>
        <MenuList>
          {!hiddenMenuItems.includes('SWITCH_TO_ISSUE') && (
            <MenuItem onClick={() => handleClickMenuItem('SWITCH_TO_ISSUE')}>
              <span style={{ marginRight: 8 }}>
                <Icons.AddTask fill="#1C1B1F" />
              </span>
              <MenuItemText>이슈로 전환하기</MenuItemText>
            </MenuItem>
          )}
          {!hiddenMenuItems.includes('SWITCH_TO_TASK') && (
            <MenuItem onClick={() => handleClickMenuItem('SWITCH_TO_TASK')}>
              <span style={{ marginRight: 8 }}>
                <Icons.SplitScreen fill="#1C1B1F" />
              </span>
              <MenuItemText>태스크로 전환하기</MenuItemText>
            </MenuItem>
          )}
          {(!hiddenMenuItems.includes('SWITCH_TO_ISSUE') || !hiddenMenuItems.includes('SWITCH_TO_TASK')) && <Divider style={{ margin: '4px 0px' }} />}
          {!hiddenMenuItems.includes('MOVE_TO_THIS_WEEK') && (
            <MenuItem style={{ justifyContent: 'space-between' }} onClick={() => handleClickMenuItem('MOVE_TO_THIS_WEEK')}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: 8 }}>
                  <Icons.CalendarWithoutClock fill="#1C1B1F" width={14} height={14} />
                </span>
                <MenuItemText>플랜 - 이번주</MenuItemText>
              </div>
              <HotkeyText>Ctrl(⌘C)+I</HotkeyText>
            </MenuItem>
          )}
          {!hiddenMenuItems.includes('MOVE_TO_NEXT_WEEK') && (
            <MenuItem style={{ justifyContent: 'space-between' }} onClick={() => handleClickMenuItem('MOVE_TO_NEXT_WEEK')}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: 8 }}>
                  <Icons.NextWeek fill="#1C1B1F" />
                </span>
                <MenuItemText>플랜 - 다음주</MenuItemText>
              </div>
              <HotkeyText>Ctrl(⌘C)+K</HotkeyText>
            </MenuItem>
          )}
          {(!hiddenMenuItems.includes('MOVE_TO_THIS_WEEK') || !hiddenMenuItems.includes('MOVE_TO_NEXT_WEEK')) && <Divider style={{ margin: '4px 0px' }} />}
          {!hiddenMenuItems.includes('MOVE_TO_AFTER') && (
            <MenuItem onClick={() => handleClickMenuItem('MOVE_TO_AFTER')}>
              <span style={{ marginRight: 8 }}>
                <Icons.Later width={16} height={16} fill="#1C1B1F" />
              </span>
              <MenuItemText>나중에</MenuItemText>
            </MenuItem>
          )}
          {!hiddenMenuItems.includes('MOVE_TO_AFTER') && <Divider style={{ margin: '4px 0px' }} />}
          {!hiddenMenuItems.includes('DELETE') && (
            <MenuItem onClick={handleClickMenuDelete}>
              <span style={{ marginRight: 8 }}>
                <Icons.Delete fill={COLORS.negative1} width={14} height={14} />
              </span>
              <MenuItemText style={{ color: COLORS.negative1 }}>삭제하기</MenuItemText>
            </MenuItem>
          )}
        </MenuList>
        <ConfirmDialog ref={refConfirm} onConfirm={handleConfirm}>
          <div style={{ color: `${COLORS.negative1}` }}>{`${type === 'task' ? '태스크' : '이슈'}를 삭제 하시겠습니까?`}</div>
        </ConfirmDialog>
      </Container>
    </Popover>
  );
};

export default InboxContextMenuPopover;
