import styled from '@emotion/styled';
import { COLORS } from 'styles/constants';
import { Popover, Divider, TextField } from '@mui/material';
import { OutTaskboxDetailResponse } from 'queries/model';
import { Icons } from 'components';
import { StaticDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

interface Props {
  event?: OutTaskboxDetailResponse;
  events?: OutTaskboxDetailResponse[];
  incomplete?: HTMLElement | null;
  onCloseIncomplete?: (taskboxes: OutTaskboxDetailResponse[]) => void;
  onClickTomorrow?: (taskboxes: OutTaskboxDetailResponse[]) => void;
  onClickNextWeek?: (taskboxes: OutTaskboxDetailResponse[]) => void;
  onClickLater?: (taskboxes: OutTaskboxDetailResponse[]) => void;
  onClickCalendar?: (value: dayjs.Dayjs, taskboxes: OutTaskboxDetailResponse[]) => void;
}

export const TaskboxIncompleteDetail = ({
  event,
  events,
  incomplete,
  onCloseIncomplete = () => {},
  onClickTomorrow = () => {},
  onClickNextWeek = () => {},
  onClickLater = () => {},
  onClickCalendar = () => {},
}: Props) => {
  return (
    <>
      <Popover
        open={Boolean(incomplete)}
        anchorEl={incomplete}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={onCloseIncomplete}
        sx={{ marginTop: '4px' }}
      >
        <Container>
          <IncompleteTitleWrapper>
            <IncompleteTitle>완료되지 않은 업무</IncompleteTitle>
            <IncompleteCount>{events?.filter((event) => !event.done).length}</IncompleteCount>
          </IncompleteTitleWrapper>
          <Divider style={{ margin: '8px 0px' }}></Divider>
          <MenuList>
            <MenuItem
              className="item-text"
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', cursor: 'default', backgroundColor: COLORS.white }}
            >
              <span style={{ color: COLORS.gray600, marginBottom: '2px' }}>이동하기</span>
              <span style={{ color: COLORS.gray400, fontSize: '10px' }}>진행 중 업무는 이어서 하는 업무로 생성됩니다.</span>
            </MenuItem>
            <MenuItem
              className="item-text"
              onClick={() => {
                onClickTomorrow(events!.filter((event) => !event.done));
              }}
            >
              <span style={{ marginRight: 8 }}>
                <Icons.Tomorrow />
              </span>
              <span>다음 날</span>
            </MenuItem>
            <MenuItem
              className="item-text"
              onClick={() => {
                onClickNextWeek(events!.filter((event) => !event.done));
              }}
            >
              <span style={{ marginRight: 8 }}>
                <Icons.NextMonday stroke="#1C1B1F" />
              </span>
              <span>다음주 월요일</span>
            </MenuItem>
            <MenuItem
              className="item-text"
              onClick={() => {
                onClickLater(events!.filter((event) => !event.done));
              }}
            >
              <span style={{ marginRight: 8 }}>
                <Icons.Later width={16} height={16} fill="#1C1B1F" />
              </span>
              <span>나중에</span>
            </MenuItem>
          </MenuList>
          <Divider style={{ margin: '8px 0px' }}></Divider>
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            value={event?.start?.date ? dayjs(event?.start?.date) : dayjs(event?.start?.datetime)}
            onChange={(newValue) =>
              onClickCalendar(
                newValue!,
                events!.filter((event) => !event.done),
              )
            }
            renderInput={(params) => <TextField {...params} />}
          />
        </Container>
      </Popover>
    </>
  );
};

const Container = styled.div`
  width: fit-content;
  height: fit-content;
  min-width: 138px;
  background-color: ${COLORS.white};
  box-shadow: 0px 8px 16px 0px rgba(26, 30, 39, 0.16);
  border-radius: 8px;
  padding: 16px 16px 0px 16px;
  z-index: 10;

  .item-text {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: ${COLORS.gray100};
    }
  }

  .MuiTypography-root {
    width: 20px;
    height: 20px;
  }

  .MuiPickersDay-root {
    width: 20px;
    height: 20px;
  }

  .MuiPickerStaticWrapper-content {
    min-width: 0px;
    height: 190px;
  }

  .MuiPickersCalendarHeader-root {
    margin-top: 0px;
  }

  .MuiCalendarPicker-root {
    width: 200px;
  }

  .css-epd502 {
    width: 200px;
    height: 190px;
  }

  .MuiButtonBase-root {
    padding: 0px;
  }

  .MuiPickersArrowSwitcher-spacer {
    width: 8px;
  }

  .MuiCalendarPicker-viewTransitionContainer {
    > div {
      height: 150px;
    }
  }

  .PrivatePickersSlideTransition-root {
    min-height: 130px;
  }
`;

const IncompleteTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const IncompleteTitle = styled.div`
  font-size: 12px;
  font-weight: 700;
`;

const IncompleteCount = styled.div`
  width: 16px;
  height: 16px;
  background-color: ${COLORS.negative1};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  color: ${COLORS.white};
  font-weight: 700;
`;

const MenuList = styled.ul``;

const MenuItem = styled.li`
  font-size: 12px;
  box-sizing: border-box;
`;
