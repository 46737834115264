import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { COLORS, FontSize } from 'styles/constants';
import { motion } from 'framer-motion';
import { Popover, PopoverProps } from 'react-tiny-popover';
import { StaticDatePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import dayjs from 'lib/dayjs';

const Container = styled(motion.div)`
  box-shadow: 0px 8px 16px ${COLORS.shadow100};
  border-radius: 8px;
  background: ${COLORS.white};
  border: 1px solid ${COLORS.gray200};
  z-index: 10;

  .MuiPickersDay-root.Mui-selected {
    background-color: ${COLORS.sub3};
    color: ${COLORS.gray900};
  }

  .MuiPickersDay-root.MuiPickersDay-today {
    background-color: ${COLORS.brand1};
    color: ${COLORS.white};
  }
`;

const ContentButton = styled.button`
  margin-top: -1px;
  width: 100%;
  height: 48px;
  font-size: ${FontSize.h5};
  color: ${COLORS.gray500};

  :hover {
    color: ${COLORS.gray900};
  }
`;

export interface CalendarPopoverProps extends Omit<PopoverProps, 'content'> {
  onClickItem?: (value: Date | null) => void;
  defaultValue?: Date | null;
  nullable?: boolean;
}

export const CalendarPopover = ({ isOpen, onClickItem, nullable, defaultValue, children, ...props }: CalendarPopoverProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  useEffect(() => {
    setIsPopoverOpen(isOpen);
  }, [isOpen]);

  const handleClickPopoverOutside = () => {
    setIsPopoverOpen(false);
  };

  return (
    <Popover
      {...props}
      isOpen={isPopoverOpen}
      align={'start'}
      positions={['bottom']}
      containerStyle={{ zIndex: '10' }}
      content={
        <Container initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            value={defaultValue}
            onChange={(value) => onClickItem && onClickItem(dayjs.isDayjs(value) ? value.toDate() : value || null)}
            renderInput={(params) => <TextField {...params} />}
          />
          {nullable && <ContentButton onClick={() => onClickItem && onClickItem(null)}>지정 안함</ContentButton>}
        </Container>
      }
      onClickOutside={props.onClickOutside ? props.onClickOutside : handleClickPopoverOutside}
    >
      {children}
    </Popover>
  );
};

export default CalendarPopover;
