import { SVGProps } from 'react';

export const DoubleQuotesR = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="Edit / Double_Quotes_R">
        <g id="Vector">
          <path
            d="M9.33301 5.7347V6.9347C9.33301 7.30807 9.33301 7.49449 9.40567 7.6371C9.46958 7.76254 9.5715 7.8648 9.69694 7.92871C9.83941 8.0013 10.026 8.0013 10.3986 8.0013L12.6663 8.00137V5.73361C12.6663 5.36097 12.6663 5.17437 12.5937 5.0319C12.5298 4.90646 12.4276 4.80455 12.3021 4.74063C12.1595 4.66797 11.9731 4.66797 11.5997 4.66797H10.3997C10.0264 4.66797 9.83955 4.66797 9.69694 4.74063C9.5715 4.80455 9.46959 4.90646 9.40567 5.0319C9.33301 5.17451 9.33301 5.36133 9.33301 5.7347Z"
            fill="#0039A7"
          />
          <path
            d="M3.33301 5.7347V6.9347C3.33301 7.30807 3.33301 7.49449 3.40567 7.6371C3.46959 7.76254 3.5715 7.8648 3.69694 7.92871C3.83941 8.0013 4.02601 8.0013 4.39864 8.0013L6.66634 8.00137V5.73361C6.66634 5.36097 6.66634 5.17437 6.59375 5.0319C6.52983 4.90646 6.42758 4.80455 6.30214 4.74063C6.15953 4.66797 5.97311 4.66797 5.59974 4.66797H4.39974C4.02637 4.66797 3.83955 4.66797 3.69694 4.74063C3.5715 4.80455 3.46959 4.90646 3.40567 5.0319C3.33301 5.17451 3.33301 5.36133 3.33301 5.7347Z"
            fill="#0039A7"
          />
          <path
            d="M10.6663 11.3346C11.7709 11.3346 12.6663 10.4392 12.6663 9.33464V8.00137M12.6663 8.00137V5.73361C12.6663 5.36097 12.6663 5.17437 12.5937 5.0319C12.5298 4.90646 12.4276 4.80455 12.3021 4.74063C12.1595 4.66797 11.9731 4.66797 11.5997 4.66797H10.3997C10.0264 4.66797 9.83955 4.66797 9.69694 4.74063C9.5715 4.80455 9.46959 4.90646 9.40567 5.0319C9.33301 5.17451 9.33301 5.36133 9.33301 5.7347V6.9347C9.33301 7.30807 9.33301 7.49449 9.40567 7.6371C9.46959 7.76254 9.5715 7.8648 9.69694 7.92871C9.83941 8.0013 10.026 8.0013 10.3986 8.0013L12.6663 8.00137ZM4.66634 11.3346C5.77091 11.3346 6.66634 10.4392 6.66634 9.33464L6.66634 8.00137M6.66634 8.00137V5.73361C6.66634 5.36097 6.66634 5.17437 6.59375 5.0319C6.52983 4.90646 6.42758 4.80455 6.30214 4.74063C6.15953 4.66797 5.97311 4.66797 5.59974 4.66797H4.39974C4.02637 4.66797 3.83955 4.66797 3.69694 4.74063C3.5715 4.80455 3.46959 4.90646 3.40567 5.0319C3.33301 5.17451 3.33301 5.36133 3.33301 5.7347V6.9347C3.33301 7.30807 3.33301 7.49449 3.40567 7.6371C3.46959 7.76254 3.5715 7.8648 3.69694 7.92871C3.83941 8.0013 4.02601 8.0013 4.39864 8.0013L6.66634 8.00137Z"
            stroke="#0039A7"
            strokeWidth="1.33333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};
