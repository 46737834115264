import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { Icons } from 'components';
import Fonts from 'components/Fonts';
import { COLORS } from 'styles/constants';

export const Lounge = () => {
  return (
    <Container>
      <LoungeWrapper>
        <LoungeMain>
          <LoungeIcon>
            <Icons.LoungeBeta width={170} height={170} />
          </LoungeIcon>

          <LoungeMainContent>
            <MainContentHeader>
              <Fonts.H4>준비중</Fonts.H4>
            </MainContentHeader>
            <MainContentMain>
              <Icons.DoubleQuotesL width={22} height={22} style={{ marginRight: '6px' }} />
              <Fonts.H1>
                탁월한 팀은 커뮤니케이션으로 시작하고
                <br />
                커뮤니케이션으로 끝난다.
              </Fonts.H1>
              <Icons.DoubleQuotesR width={22} height={22} style={{ marginLeft: '6px' }} />
            </MainContentMain>
            <MainContentFooter>
              <Fonts.Body1>
                라운지는 팀이 소통하는 새로운 방법입니다.
                <br />
                팀원끼리 일지와 논의사항, 기분을 공유할 수 있습니다.
              </Fonts.Body1>
            </MainContentFooter>
          </LoungeMainContent>
        </LoungeMain>
        <LoungeFooter>
          <Fonts.Body1>현재 겪는 불편이 있거나 제안하고 싶은 기능이 있다면 제안해주세요.</Fonts.Body1>
          <Button
            variant="contained"
            href="https://bit.ly/slash_survey_lounge"
            target="_blank"
            sx={{
              backgroundColor: `${COLORS.brand1}`,
              borderRadius: '8px',
              width: '81px',
              marginTop: '12px',
            }}
          >
            제안하기
          </Button>
        </LoungeFooter>
      </LoungeWrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  background-color: ${COLORS.gray100};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LoungeWrapper = styled.div`
  height: 460px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const LoungeMain = styled.div`
  width: 700px;
  height: 200px;
  display: flex;
  justify-content: center;
  position: relative;
`;

const LoungeIcon = styled.div`
  position: absolute;
  left: 60px;
`;

const LoungeMainContent = styled.div`
  position: absolute;
  left: 265px;
`;

const MainContentHeader = styled.div`
  color: ${COLORS.brand1};
  width: fit-content;
  background-color: ${COLORS.sub3};
  padding: 4px 12px;
  border-radius: 100px;
  margin-left: 30px;
`;

const MainContentMain = styled.div`
  display: flex;
  margin-top: 17px;
  margin-bottom: 12px;
`;

const MainContentFooter = styled.div`
  margin-left: 30px;
`;

const LoungeFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${COLORS.gray600};
`;

export default Lounge;
