import styled from '@emotion/styled';
import Fonts from 'components/Fonts';
import { COLORS } from 'styles/constants';
import { Button, Popover, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useState } from 'react';
import { OutTaskboxDetailResponse } from 'queries/model';
import { Icons } from 'components';

interface Props {
  event?: OutTaskboxDetailResponse;
  eventId?: string | null;
  detail?: HTMLElement | null;
  onCloseDetail?: () => void;
  onRemoveTimeline?: (event: OutTaskboxDetailResponse) => void;
}

export const TimelineTaskboxDetail = ({ event, eventId, detail, onCloseDetail = () => {}, onRemoveTimeline = () => {} }: Props) => {
  const [removeTimeline, setRemoveTimeline] = useState(false);

  return (
    <>
      <Popover
        open={Boolean(detail)}
        anchorEl={detail}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={onCloseDetail}
        sx={{ marginTop: '4px' }}
      >
        <DetailModal>
          <Fonts.Blockquote
            className="detail-text"
            onClick={() => {
              setRemoveTimeline(true);
            }}
            style={{ color: `${COLORS.negative1}` }}
          >
            <Icons.Delete fill={COLORS.negative1}></Icons.Delete>
            <span style={{ marginLeft: '8px' }}>삭제</span>
          </Fonts.Blockquote>
        </DetailModal>
      </Popover>

      <Dialog
        open={removeTimeline}
        onClose={() => {
          setRemoveTimeline(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ color: `${COLORS.negative1}` }}>{`"${
          event?.title !== '' ? event?.title : 'Title'
        }" 태스크박스를 삭제하시겠습니까?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ textAlign: 'center' }}>
            관련 데이터를 영구적으로 삭제하려고 합니다.<br></br> 확신할 수 없다면 취소를 눌러 삭제창을 닫아주세요.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setRemoveTimeline(false);
            }}
          >
            취소
          </Button>
          <Button
            onClick={() => {
              setRemoveTimeline(false);
              onRemoveTimeline(event!);
            }}
          >
            삭제
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const DetailModal = styled.div`
  width: fit-content;
  height: fit-content;
  min-width: 138px;
  background-color: ${COLORS.white};
  box-shadow: 0px 8px 16px 0px rgba(26, 30, 39, 0.16);
  border-radius: 8px;
  padding: 12px;
  z-index: 10;

  .detail-text {
    display: flex;
    align-items: center;
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: ${COLORS.gray100};
    }
  }
`;
