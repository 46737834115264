import { SVGProps } from 'react';

export const NextMonday = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M9 7L11 9L9 11" stroke="#1F2023" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M11.9993 3.32812H3.99935C3.26297 3.32812 2.66602 3.92508 2.66602 4.66146V12.6615C2.66602 13.3978 3.26297 13.9948 3.99935 13.9948H11.9993C12.7357 13.9948 13.3327 13.3978 13.3327 12.6615V4.66146C13.3327 3.92508 12.7357 3.32812 11.9993 3.32812Z"
        stroke="#1F2023"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10.666 2V4.66667" stroke="#1F2023" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.33398 2V4.66667" stroke="#1F2023" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.5 7L7.5 9L5.5 11" stroke="#1F2023" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
