import styled from '@emotion/styled';
import {
  createTaskboxV1TaskboxesPost,
  getEventsV1EventsGet,
  readTaskboxesV1TaskboxesGet,
  removeTaskV1WorksWorkIdDelete,
  updateTaskboxesV1TaskboxesTaskboxIdPut,
} from 'queries';
import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { COLORS } from 'styles/constants';
import CalendarView, { CustomEvent } from './CalendarView';
import dayjs from 'lib/dayjs';
import { DATE_FORMAT_1, DATE_FORMAT_4 } from 'utils/datetimeFormat';
import { CreateTaskbox, DateTime, OutEvent, OutSubTask, OutTaskboxDetailResponse } from 'queries/model';
import { useClickOutside, useEventListener, useKeyboardEvent, useUpdateEffect } from '@react-hookz/web';
import { v4 as uuidv4 } from 'uuid';
import toast from 'react-hot-toast';
import { Icons } from 'components';
import { hideScroll } from 'styles/utils';
import { Button, CircularProgress, Dialog, Fab, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { TimelineView } from '../components/TimelineView';
import { useAtom } from 'jotai';
import { dragContextAtom, currentDateAtom, fetchTasksAtom } from 'atoms/works';
import TaskSelectionView, { TaskSelectionViewTask } from '../components/TaskSelectionView';
import { useInView } from 'react-intersection-observer';
import { debounce, groupBy, pick } from 'lodash';
import { isElementInViewPort } from 'utils/viewport';
import SidePanel from '../SidePanel';

const TaskViewWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  min-width: 702px;
  padding: 0px 0px;
  justify-content: center;
  background-color: ${COLORS.gray200};
  overflow-y: auto;
  ${hideScroll()};
`;

const TaskViewFixedCenter = styled.div`
  width: 630px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TaskBoardWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const TaskBoardTopButtonWrapper = styled.div`
  margin-top: 12px;
  height: 40px;
  text-align: center;
`;
const TaskBoardBottomButtonWrapper = styled.div`
  margin-top: 36px;
  height: 60px;
  text-align: center;
`;

const TaskBoardButtonTextWrapper = styled.span`
  margin-right: 8px;
  color: ${COLORS.gray600};
  font-weight: bold;
  font-size: 13px;
`;

const TaskBoardContainer = styled.div<{ focused?: boolean }>`
  margin-bottom: 36px;
  border-radius: 8px;
  box-shadow: ${(props) => (props.focused ? `0px 8px 16px ${COLORS.shadow100}` : 'none')};
`;

const KeyboardButtonRect = styled.span<{ small?: boolean }>`
  width: 16px;
  height: 16px;
  background: #ffffff;
  border: 1px solid ${COLORS.gray400};
  border-radius: 2px;
  font-size: 10px;
  color: ${COLORS.gray500};
  padding: ${(props) => `${props.small ? '1px' : '4px'}`};
`;

export interface TaskBoardProps {
  id: string;
  date: Date;
  taskboxes?: OutTaskboxDetailResponse[];
  editing?: boolean;
  expanded?: boolean;
  parentRef?: RefObject<HTMLDivElement>;
  selectedEventId?: string;
  stickyHeader?: boolean;
  focused?: boolean;
  onCreateTaskbox?: (date: Date, value: string) => void;
  onClickEditTaskBoard?: (date: Date) => void;
  onClickExpand?: (date: Date) => void;
  onChange?: (date: Date) => void;
  onViewportOnView?: (date: Date) => void;
  onClickTaskbox?: (date: Date, id: string) => void;
  onFocused?: (id: string) => void;
  onDropTask?: (date: Date, tasks: { id: string; content: string }[]) => void;
}

const TaskBoard = (props: TaskBoardProps) => {
  const {
    id,
    date,
    taskboxes = [],
    expanded,
    editing,
    parentRef,
    selectedEventId,
    stickyHeader,
    focused,
    onCreateTaskbox,
    onClickEditTaskBoard,
    onClickExpand,
    onChange,
    onViewportOnView,
    onClickTaskbox,
    onFocused,
    onDropTask,
  } = props;
  const { ref: refInView, inView } = useInView({ initialInView: true, rootMargin: '-10% 0px -90% 0px' });

  useUpdateEffect(() => {
    if (inView) onViewportOnView?.(date);
  }, [inView]);

  const handleCreateTaskbox = (value: string) => {
    onCreateTaskbox?.(date, value);
  };

  const handleChange = (date: Date) => {
    onChange?.(date);
  };

  const handleClickExpand = (date: Date) => {
    onClickExpand?.(date);
  };

  const handleClickTimelineTaskbox = (taskbox: OutTaskboxDetailResponse) => {
    onClickTaskbox?.(date, taskbox.id!);
  };

  const handleClickFocusInput = () => {
    const taskView = document.getElementById('task-view');
    const taskBoard = document.querySelector(`[data-board-id="${id}"]`) as HTMLDivElement;
    taskView?.scrollTo({ top: taskBoard?.offsetTop - 12 });
  };

  const handleFocus = () => {
    onFocused?.(id);
  };

  return (
    <TaskBoardContainer data-board-id={id} ref={refInView} onFocus={handleFocus} focused={focused}>
      <TimelineView
        ref={parentRef}
        date={date}
        expanded={expanded}
        events={taskboxes as any}
        onChange={handleChange}
        selectedEvent={taskboxes.find((v) => v.id === selectedEventId) as any}
        onClickTaskbox={handleClickTimelineTaskbox}
        stickyHeader={stickyHeader}
        onCreateTaskbox={handleCreateTaskbox}
        onDropTask={onDropTask}
        onClickExpand={handleClickExpand}
        onClickFocusInput={handleClickFocusInput}
        onClickEditTaskBoard={onClickEditTaskBoard}
        focusedTaskBoard={focused}
        editing={editing}
      />
    </TaskBoardContainer>
  );
};

export const TaskToday = () => {
  const navigate = useNavigate();
  const [meetings, setMeetings] = useState<OutEvent[]>([]);
  const [taskBoards, setTaskBoards] = useState<{ id: string; date: Date; taskboxes: OutTaskboxDetailResponse[] }[]>([]);
  const [taskboxes, setTaskboxes] = useState<OutTaskboxDetailResponse[]>([]);
  const [selectedEventId, setSelectedEventId] = useState<string | undefined>();
  const [newTaskbox, setNewTaskbox] = useState<CreateTaskbox & { allDay: boolean }>();
  const refTaskView = useRef<HTMLDivElement>(null);
  const refCalendarView = useRef<HTMLDivElement>(null);
  const [taskViewDragContext] = useAtom(dragContextAtom);
  const [currentDate, setCurrentDate] = useAtom(currentDateAtom);
  const [taskSelectionModal, setTaskSelectionModal] =
    useState<{ open: boolean; id?: string; issueTitle?: string; start?: DateTime; end?: DateTime; tasks?: OutSubTask[] } | null>();
  const [isLoading, setLoading] = useState(false);
  const [suppressScrollEvent, setSuppressScrollEvent] = useState(false);
  const [boardWritingStatus, setBoardWritingStatus] = useState<Map<string, boolean>>(new Map());
  const [boardExpandStatus, setBoardExpandStatus] = useState<Map<string, boolean>>(new Map());
  const { ref: refInBottomView, inView: inBottomView } = useInView({ initialInView: true, delay: 100, trackVisibility: true, threshold: 0.1 });
  const [isStickyHeader, setStickyHeader] = useState(false);
  const [tasksAtom, fetchTasks] = useAtom(fetchTasksAtom);

  useEffect(() => {
    init();
    localStorage.setItem('task-view', 'today');
    localStorage.getItem('task-board-expand-status') && setBoardExpandStatus(new Map(JSON.parse(localStorage.getItem('task-board-expand-status')!)));
  }, []);

  useClickOutside(refCalendarView, () => {
    setSelectedEventId(undefined);
  });

  useEventListener(
    refTaskView,
    'scroll',
    () => {
      if (!refTaskView) return;
      const el = refTaskView.current?.querySelector(`[data-board-id="${dayjs(currentDate).format(DATE_FORMAT_4)}"] > div`) as HTMLDivElement;
      if (isElementInViewPort(el, refTaskView.current!)) {
        setStickyHeader(false);
      } else {
        setStickyHeader(true);
      }
    },
    { passive: true },
  );

  useKeyboardEvent(true, (ev) => ev.shiftKey && ev.key === 'T' && handleChangeCurrentDate(new Date()), [], { eventOptions: { passive: true } });

  useUpdateEffect(() => {
    if (inBottomView && !isLoading && !suppressScrollEvent) {
      (async () => {
        const date = taskBoards?.length > 0 ? taskBoards[taskBoards.length - 1].date : currentDate;
        const from = dayjs(date).add(1, 'day').toDate();
        const to = dayjs(date).add(7, 'day').toDate();

        setLoading(true);
        const nextTaskBoards = await fetchTaskBoard(from, to);
        setTaskBoards([...taskBoards, ...nextTaskBoards]);
        setLoading(false);
      })();
    }
  }, [inBottomView]);

  useUpdateEffect(() => {
    if (taskBoards.length) updateTaskboxes(currentDate);
  }, [JSON.stringify(tasksAtom)]);

  const refresh = async (date: Date) => {
    const meetings = await fetchMeetings(date);
    const taskboxes = await fetchTaskboxes(date);
    const taskBoard = await fetchTaskBoard(dayjs(date).subtract(1, 'day').toDate(), dayjs(date).add(7, 'day').toDate());

    setStickyHeader(false);
    setMeetings(meetings);
    setTaskboxes(taskboxes);
    setTaskBoards(taskBoard);
    setBoardWritingStatus(
      new Map(taskBoard.filter((item) => item.taskboxes.length || dayjs(item.date).isToday() || dayjs(item.date).isTomorrow()).map((item) => [item.id, true])),
    );
    setCurrentDate(date);
  };

  const updateTodayView = useCallback(async (date: Date) => {
    const [meetings, taskboxes] = await Promise.all([fetchMeetings(date), fetchTaskboxes(date)]);
    setMeetings(meetings);
    setTaskboxes(taskboxes);
    setTaskBoards((state) => state.map((item) => (item.id === dayjs(date).format(DATE_FORMAT_4) ? { ...item, taskboxes: taskboxes } : item)));
  }, []);

  const debounceFetch = useMemo(() => debounce(updateTodayView, 300), [updateTodayView]);

  const calendarEvents = useMemo(() => {
    const meetingEvents: CustomEvent[] = meetings.map((item: OutEvent) => ({
      id: item.eventId || '',
      title: item.title || '',
      start: dayjs(item.startTime).toDate(),
      end: dayjs(item.endTime).toDate(),
      data: item,
      type: 'meeting',
      allDay: item.allDay,
    }));

    const taskboxEvents: CustomEvent[] = taskboxes.map((item: OutTaskboxDetailResponse) => ({
      id: item.id || '',
      title: item.title || '',
      start: item.allDay ? dayjs(item.start?.date).toDate() : dayjs(item.start?.datetime).toDate(),
      end: item.allDay ? dayjs(item.end?.date).toDate() : dayjs(item.end?.datetime).toDate(),
      data: item.tasks,
      type: 'task',
      done: item.done,
      allDay: item.allDay,
      lockedIn: item.lockedIn,
    }));

    const newTaskboxEvent: CustomEvent[] =
      newTaskbox && newTaskbox.id
        ? [
            {
              id: newTaskbox.id,
              title: newTaskbox.title || '',
              start: newTaskbox.start?.date ? dayjs(newTaskbox.start?.date).toDate() : dayjs(newTaskbox.start?.datetime).toDate(),
              end: newTaskbox.end?.date ? dayjs(newTaskbox.end?.date).toDate() : dayjs(newTaskbox.end?.datetime).toDate(),
              type: 'task',
              allDay: Boolean(newTaskbox.allDay),
            },
          ]
        : [];

    return [...meetingEvents, ...taskboxEvents, ...newTaskboxEvent];
  }, [meetings, taskboxes, newTaskbox]);

  const fetchMeetings = async (date: Date) => {
    const meetings = await getEventsV1EventsGet({
      startTime: dayjs(date).startOf('day').format(DATE_FORMAT_1),
      endTime: dayjs(date).endOf('day').format(DATE_FORMAT_1),
    });

    return meetings.data || [];
  };

  const fetchTaskBoard = async (from: Date, to: Date) => {
    const diffDay = dayjs(to).diff(from, 'day');
    if (diffDay < 0) return [];

    const data = await readTaskboxesV1TaskboxesGet({ start_date: dayjs(from).format(DATE_FORMAT_4), end_date: dayjs(to).format(DATE_FORMAT_4) });
    const taskboxes = data.map((item) => ({ ...item, date: dayjs(item.start?.date || item.start?.datetime).format(DATE_FORMAT_4) }));
    const groups = groupBy(taskboxes, 'date');
    const days = [...Array(diffDay)].map((_, idx) => dayjs(from).add(idx, 'day').toDate());
    return [...days.map((item) => ({ id: dayjs(item).format(DATE_FORMAT_4), date: item, taskboxes: groups[dayjs(item).format(DATE_FORMAT_4)] || [] }))].sort(
      (a, b) => +a.date - +b.date,
    );
  };

  const fetchTaskboxes = async (date: Date) => {
    const taskboxes = await readTaskboxesV1TaskboxesGet({ start_date: dayjs(date).format(DATE_FORMAT_4), end_date: dayjs(date).format(DATE_FORMAT_4) });
    return taskboxes;
  };

  const init = async () => {
    setLoading(true);
    await refresh(currentDate);
    scrollToDateLine(currentDate);
    setLoading(false);
  };

  const updateMeetings = async (date: Date) => {
    const meetings = await fetchMeetings(date);
    setMeetings(meetings);
  };

  const updateTaskboxes = async (date: Date) => {
    const taskboxes = await readTaskboxesV1TaskboxesGet({ start_date: dayjs(date).format(DATE_FORMAT_4), end_date: dayjs(date).format(DATE_FORMAT_4) });
    setTaskboxes(taskboxes);
    setTaskBoards(taskBoards.map((item) => (item.id === dayjs(date).format(DATE_FORMAT_4) ? { ...item, taskboxes: taskboxes } : item)));
  };

  const handleRefresh = async () => {
    if (isLoading) return;

    await refresh(currentDate);
    scrollToDateLine(currentDate);
  };

  const handleSelectEvent = async (eventId: string) => {
    const event = calendarEvents.find((item) => item.id === eventId);
    setSelectedEventId(event?.id || undefined);
  };

  const handleUpdateEvent = async ({ eventId, startTime, endTime, isAllDay }: { eventId: string; startTime: string; endTime: string; isAllDay: boolean }) => {
    if (startTime && endTime && Math.abs(dayjs(startTime).diff(endTime, 'minute')) < 15) return; // 15분 미만 변경 불가

    const start = isAllDay ? { date: dayjs(startTime).format(DATE_FORMAT_4) } : { datetime: startTime };
    const end = isAllDay ? { date: dayjs(endTime).format(DATE_FORMAT_4) } : { datetime: endTime };

    if (newTaskbox && newTaskbox.id === eventId) {
      setNewTaskbox(undefined);
      return;
    }

    const taskbox = taskboxes.find((item) => item.id === eventId);
    if (taskbox) {
      setTaskboxes(taskboxes.map((item) => (item.id === taskbox.id ? { ...taskbox, start, end, allDay: isAllDay } : item)));
      await updateTaskboxesV1TaskboxesTaskboxIdPut(taskbox.id!, { ...pick(taskbox, ['title', 'lockedIn', 'tasks']), start, end });
      toast.success('태스크박스를 수정하였습니다.');
      updateTaskboxes(currentDate);
    }
  };

  const handleUpdateEventTitle = async ({ eventId, title }: { eventId: string; title: string; isAllDay: boolean }) => {
    if (newTaskbox && newTaskbox.id === eventId) {
      if (title) {
        const result = await createTaskboxV1TaskboxesPost({ ...newTaskbox, id: newTaskbox.id || uuidv4(), title: title });
        if (result) {
          updateTaskboxes(currentDate);
          setNewTaskbox(undefined);
          toast.success('새로운 태스크박스를 생성하였습니다.');
        }
      } else {
        setNewTaskbox(undefined);
      }
    } else {
      const taskbox = taskboxes.find((item) => item.id === eventId);
      if (!taskbox) return;
      if (title === taskbox.title) return;

      await updateTaskboxesV1TaskboxesTaskboxIdPut(taskbox.id!, { ...pick(taskbox, ['lockedIn', 'tasks', 'start', 'end']), title });
      updateTaskboxes(currentDate);
      toast.success('태스크박스를 수정하였습니다.');
    }
  };

  const handleClickTimeSlot = async ({ startTime, endTime, isAllDay }: { startTime: string; endTime: string; isAllDay: boolean }) => {
    const id = uuidv4();
    setNewTaskbox((prevTaskbox) => {
      if (prevTaskbox) return { ...prevTaskbox, id: id, start: { datetime: startTime }, end: { datetime: endTime }, allDay: isAllDay };
      else return { id: id, title: '', tasks: [], start: { datetime: startTime }, end: { datetime: endTime }, allDay: isAllDay };
    });
    setTimeout(() => setSelectedEventId(id), 100);
  };

  const handleDropFromOutside = async ({ startTime, endTime, isAllDay }: { startTime: string; endTime: string; isAllDay: boolean }) => {
    const offsetMin = +dayjs(endTime) >= +dayjs(currentDate).endOf('day') ? 0 : 15;

    if (!taskViewDragContext) return;

    const { id, type, title, data } = taskViewDragContext;
    if (type === 'issue' && Array.isArray(data) && data.length > 0) {
      setTaskSelectionModal({
        open: true,
        id: id,
        issueTitle: title,
        start: { datetime: startTime },
        end: { datetime: dayjs(endTime).add(offsetMin, 'minute').format(DATE_FORMAT_1) },
        tasks: data || [],
      });
    } else {
      const taskbox = taskboxes.find((item) => item.id === id);
      if (taskbox) {
        const diffDay = dayjs(startTime).diff(endTime, 'day');
        if (Math.abs(diffDay) > 0) return;

        const start = isAllDay ? { date: dayjs(startTime).format(DATE_FORMAT_4) } : { datetime: startTime };
        const end = isAllDay ? { date: dayjs(endTime).format(DATE_FORMAT_4) } : { datetime: dayjs(startTime).add(60, 'minute').format(DATE_FORMAT_1) };
        const result = await updateTaskboxesV1TaskboxesTaskboxIdPut(id!, { ...taskbox, start: start, end: end });
        if (result) {
          toast.success('태스크박스를 수정하였습니다.');
          updateTaskboxes(currentDate);
        }
      } else {
        const result = await createTaskboxV1TaskboxesPost({
          id: uuidv4(),
          title: title || '',
          start: { datetime: startTime },
          end: { datetime: dayjs(startTime).add(60, 'minute').format(DATE_FORMAT_1) },
          issueId: type === 'issue' ? id : undefined,
        });

        if (result && result.id) {
          if (Array.isArray(data)) {
            const tasks = data.map((item) => ({ ...item, id: uuidv4(), type: 'task' }));
            await updateTaskboxesV1TaskboxesTaskboxIdPut(result.id, { tasks: tasks });
            for (const item of data) {
              if (item.id) await removeTaskV1WorksWorkIdDelete(item.id);
            }
          } else {
            await removeTaskV1WorksWorkIdDelete(id!);
          }
          toast.success('새로운 태스크박스를 생성하였습니다.');
          updateTaskboxes(currentDate);
          fetchTasks();
        }
      }
    }
  };

  const handleClickTaskSelection = async (tasks: TaskSelectionViewTask[]) => {
    if (!taskSelectionModal) return;
    const { id, issueTitle, start, end } = taskSelectionModal;

    setTaskSelectionModal(null);
    const result = await createTaskboxV1TaskboxesPost({
      id: uuidv4(),
      title: issueTitle,
      start: start,
      end: end,
      tasks: tasks.map((item) => ({ id: item.id!, content: item.content || '' })),
      issueId: id,
    });

    if (result) {
      toast.success('새로운 태스크박스를 생성하였습니다.');
      updateTaskboxes(currentDate);

      await removeTaskV1WorksWorkIdDelete(id!);
      fetchTasks();
    }
  };

  const handleClickCreateTaskBoard = (date: Date) => {
    setBoardWritingStatus(new Map(boardWritingStatus).set(dayjs(date).format(DATE_FORMAT_4), true));
    scrollToDateLine(date);
  };

  const handleAddTaskbox = async (date: Date, value: string) => {
    if (!value) return;

    const start = { date: dayjs(date).format(DATE_FORMAT_4) };
    const end = { date: dayjs(date).format(DATE_FORMAT_4) };
    const result = await createTaskboxV1TaskboxesPost({ id: uuidv4(), title: value, start: start, end: end });
    if (result) {
      toast.success('새로운 태스크박스를 생성하였습니다.');
      updateTaskboxes(date);
    }
  };

  const handleChangeCurrentDate = async (date: Date) => {
    setSuppressScrollEvent(true);

    const dateValue = dayjs(date).format(DATE_FORMAT_4);
    if (taskBoards.map((item) => dayjs(item.date).format(DATE_FORMAT_4)).includes(dateValue)) {
      updateMeetings(date);
      updateTaskboxes(date);
      scrollToDateLine(date, 'auto');
      setCurrentDate(date);
    } else {
      await refresh(date);
      scrollToDateLine(date);
    }

    setTimeout(() => setSuppressScrollEvent(false), 1000);
  };

  const handleViewportOnView = async (date: Date) => {
    if (isLoading) return;
    if (suppressScrollEvent) return;

    setStickyHeader(false);
    setCurrentDate(date);
    debounceFetch(date);
  };

  const handleChangeTaskBoard = async (date: Date) => {
    setSuppressScrollEvent(true);
    const [meetings, taskboxes] = await Promise.all([fetchMeetings(date), fetchTaskboxes(date)]);
    if (dayjs(date).isSame(currentDate, 'day')) {
      setMeetings(meetings);
      setTaskboxes(taskboxes);
    }
    setTaskBoards((state) => state.map((item) => (item.id === dayjs(date).format(DATE_FORMAT_4) ? { ...item, taskboxes: taskboxes } : item)));
    setSuppressScrollEvent(false);
  };

  const handleClickBeforeDate = async () => {
    if (isLoading) return;

    const date = taskBoards?.length > 0 ? taskBoards[0].date : currentDate;
    const from = dayjs(date).subtract(1, 'day').toDate();
    const to = dayjs(date).subtract(0, 'day').toDate();

    setLoading(true);
    setSuppressScrollEvent(true);
    setStickyHeader(false);

    const prevTaskBoards = await fetchTaskBoard(from, to);
    setTaskBoards([...prevTaskBoards, ...taskBoards]);
    if (dayjs(from).isYesterday()) setBoardWritingStatus(new Map(boardWritingStatus).set(dayjs(from).format(DATE_FORMAT_4), true));
    setCurrentDate(from);
    setLoading(false);
    setSuppressScrollEvent(false);
  };

  const handleClickExpand = (date: Date) => {
    const expanded = boardExpandStatus.get(dayjs(date).format(DATE_FORMAT_4));
    const newBoardExpandStatus = new Map(boardExpandStatus).set(dayjs(date).format(DATE_FORMAT_4), !expanded);
    setBoardExpandStatus(newBoardExpandStatus);
    localStorage.setItem('task-board-expand-status', JSON.stringify([...newBoardExpandStatus]));
  };

  const scrollToDateLine = async (date: Date, behavior: ScrollBehavior = 'smooth') => {
    setTimeout(() => {
      if (refTaskView && refTaskView?.current) {
        const el = refTaskView?.current.querySelector(`[data-board-id="${dayjs(date).format(DATE_FORMAT_4)}"]`) as HTMLDivElement;
        if (el) refTaskView?.current.scrollTo({ top: el.offsetTop - 52, behavior: behavior });
      }
    }, 30);
  };

  const handleClickTaskbox = async (date: Date, taskboxId: string) => {
    if (isLoading) return;
    if (suppressScrollEvent) return;

    setSuppressScrollEvent(true);
    setCurrentDate(date);
    if (dayjs(date).get('date') !== dayjs(currentDate).get('date')) {
      const [meetings, taskboxes] = await Promise.all([fetchMeetings(date), fetchTaskboxes(date)]);
      setMeetings(meetings);
      setTaskboxes(taskboxes);
      setTaskBoards((state) => state.map((item) => (item.id === dayjs(date).format(DATE_FORMAT_4) ? { ...item, taskboxes: taskboxes } : item)));
    }
    setSelectedEventId(taskboxId);
    setSuppressScrollEvent(false);
  };

  const handleDropTaskOnTaskBoard = async (date: Date, tasks: { id: string; content: string }[]) => {
    const start = { date: dayjs(date).format(DATE_FORMAT_4) };
    const end = { date: dayjs(date).format(DATE_FORMAT_4) };

    await createTaskboxV1TaskboxesPost({
      id: uuidv4(),
      title: tasks.length > 1 ? '' : tasks[0].content,
      start: start,
      end: end,
      tasks: tasks.length > 1 ? tasks.map((item) => ({ id: uuidv4(), content: item.content || '' })) : [],
    });

    toast.success('새로운 태스크박스를 생성하였습니다.');
    updateTaskboxes(date);

    for (const item of tasks) {
      if (item.id) await removeTaskV1WorksWorkIdDelete(item.id);
    }
    fetchTasks();
  };

  return (
    <>
      <div ref={refCalendarView}>
        {isLoading && (
          <div style={{ position: 'absolute', top: `calc(100vh - 50%)`, left: '220px' }}>
            <CircularProgress size={32} sx={{ color: COLORS.brand1 }} />
          </div>
        )}
        <CalendarView
          newEventId={newTaskbox?.id}
          events={calendarEvents}
          selectedEventId={selectedEventId}
          currentDate={currentDate}
          onClickRefresh={handleRefresh}
          onClickToggleView={() => navigate('/task/week')}
          onSelectEvent={handleSelectEvent}
          onUpdateEvent={handleUpdateEvent}
          onUpdateEventTitle={handleUpdateEventTitle}
          onClickTimeSlot={handleClickTimeSlot}
          onChangeCurrentDate={handleChangeCurrentDate}
          onDropFromOutside={handleDropFromOutside}
        />
      </div>
      <TaskViewWrapper ref={refTaskView} id="task-view">
        <TaskViewFixedCenter>
          <TaskBoardWrapper>
            {taskBoards.length ? (
              <TaskBoardTopButtonWrapper>
                <Button disableElevation color="inherit" variant="text" sx={{ margin: 0, minWidth: 160 }} onClick={handleClickBeforeDate}>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} style={{ color: COLORS.gray500 }} />
                  ) : (
                    <>
                      <TaskBoardButtonTextWrapper>지난날 업무 일지 보기</TaskBoardButtonTextWrapper>
                      <Icons.ArrowUpSmall fill={COLORS.gray600} />
                    </>
                  )}
                </Button>
              </TaskBoardTopButtonWrapper>
            ) : (
              <></>
            )}
            {taskBoards?.map((v) => (
              <TaskBoard
                key={v.id}
                id={v.id}
                date={v.date}
                taskboxes={v.taskboxes}
                focused={dayjs(v.date).isSame(currentDate, 'day')}
                editing={boardWritingStatus.get(dayjs(v.date).format(DATE_FORMAT_4))}
                expanded={boardExpandStatus.get(dayjs(v.date).format(DATE_FORMAT_4))}
                parentRef={refTaskView}
                selectedEventId={selectedEventId}
                stickyHeader={!isLoading && dayjs(v.date).isSame(currentDate, 'day') && isStickyHeader}
                onChange={handleChangeTaskBoard}
                onCreateTaskbox={handleAddTaskbox}
                onClickEditTaskBoard={handleClickCreateTaskBoard}
                onViewportOnView={handleViewportOnView}
                onClickExpand={handleClickExpand}
                onClickTaskbox={handleClickTaskbox}
                onDropTask={handleDropTaskOnTaskBoard}
              />
            ))}
            {taskBoards.length ? (
              <TaskBoardBottomButtonWrapper ref={refInBottomView}>
                {isLoading ? <CircularProgress color="inherit" size={20} style={{ color: COLORS.gray500 }} /> : <></>}
              </TaskBoardBottomButtonWrapper>
            ) : (
              <></>
            )}
          </TaskBoardWrapper>
        </TaskViewFixedCenter>
      </TaskViewWrapper>
      {taskSelectionModal && taskSelectionModal.open && (
        <Dialog open={taskSelectionModal.open} sx={{ borderRadius: 8 }}>
          <TaskSelectionView
            issueTitle={taskSelectionModal.issueTitle}
            tasks={taskSelectionModal.tasks}
            closeIcon
            onClickCancel={() => setTaskSelectionModal(null)}
            onClickComplete={handleClickTaskSelection}
          />
        </Dialog>
      )}
      <div style={{ position: 'relative' }}>
        {!dayjs(currentDate).isToday() && (
          <Tooltip
            title={
              <div style={{ margin: '2px 4px' }}>
                <span>오늘로 이동</span>
                <KeyboardButtonRect small style={{ marginLeft: 4 }}>
                  ⇧
                </KeyboardButtonRect>{' '}
                + <KeyboardButtonRect small>T</KeyboardButtonRect>
              </div>
            }
          >
            <Fab color="primary" style={{ position: 'absolute', bottom: 20, left: -88, zIndex: 10 }} onClick={() => handleChangeCurrentDate(new Date())}>
              오늘
            </Fab>
          </Tooltip>
        )}
        <SidePanel />
      </div>
    </>
  );
};

export default TaskToday;
