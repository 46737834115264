import styled from '@emotion/styled';
import { IconButton, TextField, Checkbox, Popover, Tooltip } from '@mui/material';
import { Icons } from 'components';
import { OutWork, UpdateWork } from 'queries/model';
import React, { useRef, useState } from 'react';
import { COLORS } from 'styles/constants';
import { truncate } from 'styles/utils';
import { useAtom } from 'jotai';
import { dragContextAtom, WorkDragView } from 'atoms/works';
import InboxContextMenuPopover, { InboxContextMenuPopoverProps, InboxContextMenuType } from 'components/InboxContextMenuPopover';
import TaskTag from 'components/TaskTag';
import dayjs from 'dayjs';
import { StaticDatePicker } from '@mui/x-date-pickers';
import { omit } from 'lodash';

const Container = styled.div<{ selected?: boolean; hidden?: boolean }>`
  width: 100%;
  min-height: 36px;
  align-items: flex-start;
  padding: 8px 16px;
  margin-bottom: 8px;
  background-color: ${(props) => (props.selected ? COLORS.sub3 : COLORS.white)};
  border-radius: 8px;
  border: 1px solid ${(props) => (props.selected ? COLORS.gray300 : COLORS.gray200)};
  display: ${(props) => (props.hidden ? 'none' : 'flex')};

  :hover .task-more-btn {
    opacity: 1;
  }
`;

const TaskItemCheckboxWrapper = styled.div`
  flex: 0 0 24px;
  display: flex;
  margin-top: 2px;
`;

const TaskItemContentWrapper = styled.div`
  flex: 1;
  overflow: hidden;
`;

const TaskMoreButtonWrapper = styled.div`
  flex: 0 0 24px;
  display: flex;
  justify-content: flex-end;
  margin-top: 3px;
  opacity: 0;
  transition: opacity 0.3s ease-out;
`;

const TextTruncate = styled.div`
  ${truncate('100%')};
`;

const EditableTextField = styled.div`
  width: 100%;
  display: inline-block;
  white-space: pre-wrap;
  word-break: break-all;
`;

const CalendarInitButton = styled.button`
  margin-top: -1px;
  width: 100%;
  height: 48px;
  font-size: 12px;
  color: ${COLORS.gray500};

  :hover {
    color: ${COLORS.gray900};
  }
`;

const TruncateTaskTag = styled(TaskTag)`
  max-width: 120px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export interface TaskItemProps {
  task: OutWork;
  selected?: boolean;
  draggable?: boolean;
  dragView?: WorkDragView;
  editable?: boolean;
  hidden?: boolean;
  multipleDrag?: boolean;
  hiddenContextMenus?: InboxContextMenuType[];
  checkboxTooltip?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onChangeTask?: (id: string, params: UpdateWork) => void;
  onDragStart?: (e: React.DragEvent<HTMLDivElement>, id: string) => void;
  onDrag?: (e: React.DragEvent<HTMLDivElement>) => void;
  onClickContextMenu?: InboxContextMenuPopoverProps['onClickMenu'];
  onCloseCalendar?: () => void;
}

const TaskItem = (props: TaskItemProps) => {
  const {
    task,
    selected,
    draggable,
    dragView,
    editable,
    hidden,
    multipleDrag,
    hiddenContextMenus,
    checkboxTooltip,
    onClick,
    onDragStart,
    onDrag,
    onChangeTask,
    onClickContextMenu,
    onCloseCalendar,
  } = props;
  const refInput = useRef<HTMLInputElement | null>(null);
  const refContent = useRef<HTMLDivElement | null>(null);
  const refContainer = useRef<HTMLDivElement | null>(null);
  const [calendarPopover, setCalendarPopover] = useState<HTMLElement | null>();
  const [contextMenuPopover, setContextMenuPopover] = useState<HTMLElement | null>();
  const [editing, setEditing] = useState(false);
  const [dueDateEditing, setDueDateEditing] = useState(false);
  const [, setDragContext] = useAtom(dragContextAtom);

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    onClick?.(e);
  };

  const handleCheckTask = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    onChangeTask?.(task.id, { ...omit(task, 'id'), done: e.currentTarget.checked });
  };

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    if (multipleDrag) {
      onDragStart?.(e, task.id);
    } else {
      setDragContext({ id: task.id, view: dragView, title: task.content, type: 'task' });
    }
  };

  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    onDrag?.(e);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDragEnd = () => {
    setDragContext(undefined);
  };

  const handleClickTextField = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.ctrlKey || e.metaKey) return;
    if (!editable) return;

    setEditing(true);
    setTimeout(() => refInput.current?.focus(), 50);
  };

  const handleKeydownTextField = async (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Escape') {
      e.preventDefault();
    }

    if (e.key === 'Enter') {
      if (e.nativeEvent.isComposing) return;
      if (e.repeat) {
        e.preventDefault();
        return;
      }

      e.preventDefault();
      const content = e.currentTarget.textContent || '';
      if (task.content !== content) onChangeTask?.(task.id, { content: content });

      setEditing(false);
    }
  };

  const handleBlurTextField = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!editable) return;

    const content = e.currentTarget.textContent;
    if (task.content !== content) onChangeTask?.(task.id, { content: content || '' });
    setTimeout(() => {
      setEditing(false);
    }, 100);
  };

  const handleClickMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setContextMenuPopover(e.currentTarget);
  };

  const handleClickContextMenu = (id: string, type: string, menu: InboxContextMenuType) => {
    onClickContextMenu?.(id, type, menu);
    setContextMenuPopover(null);
  };

  const handleClickDueDate = () => {
    setDueDateEditing(true);
    setCalendarPopover(refContent.current);
  };

  const handleChangeTaskCalendar = async (value: Date | null) => {
    const dueDate = value ? (dayjs.isDayjs(value) ? value.format('YYYY-MM-DD') : dayjs(value).format('YYYY-MM-DD')) : null;
    if (task.dueDate !== dueDate) onChangeTask?.(task.id, { dueDate: dueDate as any });

    setCalendarPopover(null);
    setDueDateEditing(false);
  };

  const handleCloseCalendarPopover = () => {
    onCloseCalendar?.();
    setDueDateEditing(false);
    setCalendarPopover(null);
  };

  return (
    <div>
      <Container
        ref={refContainer}
        draggable={draggable}
        selected={selected}
        hidden={hidden}
        onClick={handleClick}
        onDragStart={handleDragStart}
        onDrag={handleDrag}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
      >
        <TaskItemCheckboxWrapper>
          {checkboxTooltip ? (
            <Tooltip title={checkboxTooltip} placement="bottom-start">
              <Checkbox sx={{ padding: 0 }} onChange={handleCheckTask} checked={!!task.doneAt} />
            </Tooltip>
          ) : (
            <Checkbox sx={{ padding: 0 }} onChange={handleCheckTask} checked={!!task.doneAt} />
          )}
        </TaskItemCheckboxWrapper>
        <TaskItemContentWrapper ref={refContent}>
          <div style={{ fontSize: 13, minHeight: 20 }} onClick={handleClickTextField}>
            {editing ? (
              <EditableTextField
                ref={refInput}
                contentEditable={true}
                suppressContentEditableWarning={true}
                onKeyDown={handleKeydownTextField}
                onBlur={handleBlurTextField}
                onClick={(e) => e.stopPropagation()}
                dangerouslySetInnerHTML={{ __html: task.content || '' }}
              />
            ) : (
              <TextTruncate>{task.content || ''}</TextTruncate>
            )}
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
            {task.meetingNoteTitle && <TruncateTaskTag style={{ marginRight: 8 }}>{task.meetingNoteTitle}</TruncateTaskTag>}
            {task.dueDate ? (
              <TaskTag onClick={handleClickDueDate}>
                <Icons.Flag />
                <span>{dayjs(task.dueDate).format('MM월 DD일')}</span>
              </TaskTag>
            ) : (
              <>
                {(editing || dueDateEditing) && (
                  <TaskTag onClick={handleClickDueDate}>
                    <Icons.Flag />
                    <span>기한 설정하기</span>
                  </TaskTag>
                )}
              </>
            )}
          </div>
        </TaskItemContentWrapper>
        <TaskMoreButtonWrapper className="task-more-btn" style={contextMenuPopover ? { opacity: 1 } : {}}>
          <IconButton aria-label="more" sx={{ background: 'white', padding: 0 }} size="small" onClick={handleClickMenu}>
            <Icons.ColorMore />
          </IconButton>
        </TaskMoreButtonWrapper>
      </Container>
      {contextMenuPopover && (
        <InboxContextMenuPopover
          id={task.id}
          type={'task'}
          open={Boolean(contextMenuPopover)}
          anchorEl={contextMenuPopover}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          sx={{ marginTop: 0.5, marginLeft: 2 }}
          hiddenMenuItems={hiddenContextMenus}
          onClose={() => setContextMenuPopover(null)}
          onClickMenu={handleClickContextMenu}
        />
      )}
      {calendarPopover && (
        <Popover
          open={Boolean(calendarPopover)}
          anchorEl={calendarPopover}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          sx={{ marginTop: 0.5 }}
          onClose={handleCloseCalendarPopover}
        >
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            value={new Date(task?.dueDate || '')}
            onChange={handleChangeTaskCalendar}
            renderInput={(params) => <TextField {...params} />}
          />
          <CalendarInitButton onClick={() => handleChangeTaskCalendar(null)}>지정 안함</CalendarInitButton>
        </Popover>
      )}
    </div>
  );
};

export default TaskItem;
