import styled from '@emotion/styled';
import { Box, Tab, Tabs } from '@mui/material';
import { Icons } from 'components';
import { useState } from 'react';
import Inbox from './tabs/Inbox';

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px 20px 0px 20px;
  background: #f2f5fc;
`;

const TabPanel = styled.div`
  height: 100%;
  width: 100%;
`;

const SidePanel = () => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <Container>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabIndex} onChange={(_, v) => setTabIndex(v)} centered={false}>
          <Tab icon={<Icons.Inbox />} iconPosition="start" label="인박스" />
          <Tab icon={<Icons.Later />} iconPosition="start" label="나중에" />
        </Tabs>
      </Box>
      <div style={{ marginTop: 12, height: '100%', width: '100%' }}>
        <TabPanel role="tabpanel" hidden={!(tabIndex === 0)}>
          <Inbox />
        </TabPanel>
        <TabPanel role="tabpanel" hidden={!(tabIndex === 1)}>
          나중에
        </TabPanel>
      </div>
    </Container>
  );
};

export default SidePanel;
