import styled from '@emotion/styled';
import {
  assignTaskV1WorksIssuesIssueIdTasksPut,
  createPlanWeeklyV1PlansWeeklyPost,
  createTaskV1WorksPost,
  listWorksV1WorksGet,
  removeTaskV1WorksWorkIdDelete,
  updateIssueV1WorksIssuesIssueIdPut,
  updateTaskV1WorksWorkIdPut,
} from 'queries';
import { OutWork, UpdateIssueDetail, UpdateWork } from 'queries/model';
import React, { useEffect, useState } from 'react';
import { COLORS } from 'styles/constants';
import { v4 as uuidv4 } from 'uuid';
import toast from 'react-hot-toast';
import { useAtom } from 'jotai';
import { dragContextAtom, WorkDragContext } from 'atoms/works';
import { InboxContextMenuType } from 'components/InboxContextMenuPopover';
import dayjs from 'dayjs';
import TaskListView from 'components/Task/TaskListView';
import IssueListView from 'components/Issue/IssueListView';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const InboxListWrapper = styled.div`
  width: 100%;
  height: calc(100% - 72px);
  overflow: hidden;
  display: flex;
`;

const TaskListViewWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: ${COLORS.gray200};
  border-radius: 8px;
  padding: 12px 16px 12px 16px;
`;

const IssueListViewWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 16px 12px 16px;
`;

const Inbox = () => {
  const [tasks, setTasks] = useState<OutWork[]>([]);
  const [issues, setIssues] = useState<OutWork[]>([]);
  const [, setTaskViewDragContext] = useAtom(dragContextAtom);

  useEffect(() => {
    fetchInbox();
  }, []);

  const fetchInbox = async () => {
    const data = await listWorksV1WorksGet({ section_type: 'inbox' });

    setTasks(data.tasks || []);
    setIssues(data.issues || []);
  };

  const handleDragEnd = () => {
    setTaskViewDragContext(null);
    fetchInbox();
  };

  const handleClickContextMenu = async (id: string, type: string, menu: InboxContextMenuType) => {
    try {
      switch (menu) {
        case 'SWITCH_TO_TASK':
          await updateTaskV1WorksWorkIdPut(id, { type: 'task' });
          toast.success('이슈를 태스크로 전환하였습니다.');
          break;
        case 'SWITCH_TO_ISSUE':
          await updateTaskV1WorksWorkIdPut(id, { type: 'issue' });
          toast.success('태스크를 이슈로 전환하였습니다.');
          break;
        case 'MOVE_TO_THIS_WEEK':
          await createPlanWeeklyV1PlansWeeklyPost({
            year: dayjs().year(),
            week: dayjs().week(),
            issueId: id,
          });
          toast.success('이슈를 이동하였습니다.');
          break;
        case 'MOVE_TO_NEXT_WEEK':
          await createPlanWeeklyV1PlansWeeklyPost({
            year: dayjs().add(1, 'week').year(),
            week: dayjs().add(1, 'week').week(),
            issueId: id,
          });
          toast.success('이슈를 이동하였습니다.');
          break;
        case 'MOVE_TO_AFTER':
          await updateTaskV1WorksWorkIdPut(id, { workSectionType: 'someday' });
          toast.success('나중에 목록으로 이동하였습니다.');
          break;
        case 'DELETE':
          await removeTaskV1WorksWorkIdDelete(id);
          toast.success(`${type === 'task' ? '태스크' : '이슈'}를 삭제하였습니다.`);
          break;
      }
    } catch (e) {
      toast.error('작업을 수행할 수 없습니다.');
    }

    fetchInbox();
  };

  const handleClickSaveIssue = async (id: string, value: UpdateIssueDetail) => {
    if (!value) return;

    await updateIssueV1WorksIssuesIssueIdPut(id, value);
    fetchInbox();
    toast.success('이슈를 수정하였습니다.');
  };

  const handleClickCancelIssue = () => {
    fetchInbox();
  };

  const handleCreateTask = async (value: string) => {
    await createTaskV1WorksPost({ id: uuidv4(), type: 'task', content: value });
    toast.success('새로운 태스크를 생성하였습니다.');
    fetchInbox();
  };

  const handleChangeTask = async (id: string, params: UpdateWork) => {
    await updateTaskV1WorksWorkIdPut(id, params);
    fetchInbox();
  };

  const handleCreateIssue = async (value: string) => {
    await createTaskV1WorksPost({ id: uuidv4(), type: 'issue', content: value });
    toast.success('새로운 이슈를 생성하였습니다.');
    fetchInbox();
  };

  const handleCheckIssue = async (id: string, checked: boolean) => {
    await updateIssueV1WorksIssuesIssueIdPut(id, { done: checked });
    fetchInbox();
  };

  const handleCheckIssueTask = async (id: string, checked: boolean) => {
    await updateTaskV1WorksWorkIdPut(id, { done: checked });
    fetchInbox();
  };

  const handleDropTask = async (context: WorkDragContext | undefined) => {
    if (!context || !context.id) return;
    const { id, data } = context;

    if (Array.isArray(data) && data.length) {
      toast.error('이슈에 태스크가 포함되어 있습니다.');
      return;
    }

    const res = await updateTaskV1WorksWorkIdPut(id, { type: 'task' });
    if (res) {
      toast.success('이슈를 태스크로 전환하였습니다.');
      fetchInbox();
    }
  };

  const handleDropIssue = async (context: WorkDragContext | undefined) => {
    if (!context || !context.id) return;
    const { id, title, data } = context;

    if (Array.isArray(data) && data.length) {
      await createTaskV1WorksPost({ id: uuidv4(), type: 'issue', content: '', tasks: data });
    } else {
      await updateTaskV1WorksWorkIdPut(id, { type: 'issue', content: title });
    }

    toast.success('태스크를 이슈로 전환하였습니다.');
    fetchInbox();
  };

  const handleDropInIssueItem = async (id: string, context: WorkDragContext | undefined) => {
    if (!context || !context.id) return;
    const { data } = context;
    const subTasks = Array.isArray(data) ? data.map((item) => ({ taskId: item.id })) : [{ taskId: context.id }];

    await assignTaskV1WorksIssuesIssueIdTasksPut(id, subTasks);
    toast.success('이슈에 태스크를 할당하였습니다.');
    fetchInbox();
  };

  return (
    <Container>
      <InboxListWrapper onDragEnd={handleDragEnd}>
        <TaskListViewWrapper>
          <TaskListView
            tasks={tasks}
            draggable={true}
            droppable={true}
            editable={true}
            multipleDrag={true}
            dragView="plan-inbox-task"
            droppableViews={['plan-inbox-issue']}
            hiddenContextMenus={['SWITCH_TO_TASK', 'MOVE_TO_NEXT_WEEK', 'MOVE_TO_THIS_WEEK']}
            onCreate={handleCreateTask}
            onChangeTask={handleChangeTask}
            onClickContextMenu={handleClickContextMenu}
            onDropOutside={handleDropTask}
          />
        </TaskListViewWrapper>
        <IssueListViewWrapper>
          <IssueListView
            issues={issues.map((item) => ({ issue: item }))}
            draggable={true}
            droppable={true}
            droppableInItem={true}
            dragView="plan-inbox-issue"
            droppableViews={['plan-inbox-task']}
            onCreate={handleCreateIssue}
            onChangeIssue={handleCheckIssue}
            onChangeTask={handleCheckIssueTask}
            onClickContextMenu={handleClickContextMenu}
            onCloseIssueDetailPopup={handleClickCancelIssue}
            onApplyIssueDetailPopup={handleClickSaveIssue}
            onDropOutside={handleDropIssue}
            onDropInItem={handleDropInIssueItem}
          />
        </IssueListViewWrapper>
      </InboxListWrapper>
    </Container>
  );
};

export default Inbox;
