import { Checkbox, CheckboxProps } from '@mui/material';
import { Icons } from 'components';

export interface IssueCheckboxProps extends CheckboxProps {}

const FirstPriorityIssueCheckbox = (props: IssueCheckboxProps) => {
  const UncheckedIcon = <Icons.FirstPriorityIssueUncheck />;
  const CheckedIcon = <Icons.FirstPriorityIssueCheck />;
  return <Checkbox {...props} icon={UncheckedIcon} checkedIcon={CheckedIcon} sx={{ padding: 0, margin: 0, ...props.sx }} style={{ marginRight: 8 }} />;
};
export default FirstPriorityIssueCheckbox;
