import { MouseEvent, useEffect, useRef, useState } from 'react';
import { Popover, PopoverProps } from 'react-tiny-popover';
import styled from '@emotion/styled';
import { RoundTag } from '../styles';
import { Icons } from 'components';
import { motion } from 'framer-motion';
import { COLORS, FontSize } from 'styles/constants';
import { AppModelsSchemasUserOutUser, OutUserDetail } from 'queries/model';
import { getMembersV1UsersMembersGet, getUserMeV1UsersMeGet } from 'queries';

const MemberSearchPopoverWrapper = styled(motion.div)`
  min-width: 205px;
  height: 100%;
  background: var(--white);
  border: 1px solid var(--gray-200);
  box-shadow: 0px 8px 16px var(--shadow-100);
  border-radius: 8px;
  padding: 24px 16px;
`;

const SearchInputWrapper = styled.div`
  display: flex;
  position: relative;
`;

const SearchInput = styled.input`
  width: 100%;
  height: 48px;
  padding: 0px 40px 0px 12px;
  font-size: 13px;
  border: 1px solid var(--gray-200);
  border-radius: 8px;
  color: var(--gray-900);

  &:focus-within {
    border: 1px solid var(--brand1);
    color: var(--gray-900);
  }
`;

const SearchButton = styled.button`
  position: absolute;
  right: 0;
  width: 48px;
  height: 48px;
  padding: 0px 40px 0px 12px;
`;

const ContentItemWrapper = styled.ul`
  width: 100%;
  max-height: 195px;
  overflow-y: auto;
  margin-top: 14px;

  li {
    margin-bottom: 16px;
  }
`;

const ContentItem = styled.li`
  display: flex;
  align-items: center;
  font-size: ${FontSize.h6};
  color: ${COLORS.gray500};
  cursor: pointer;
`;

const EmptyContentItem = styled.div`
  width: 100%;
  display: flex;
  height: 32px;
  justify-content: center;
  font-size: 13px;
  color: ${COLORS.gray600};
`;

const ContentFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonNotSelect = styled.button`
  font-size: ${FontSize.h5};
  color: ${COLORS.gray500};
  &:hover {
    color: ${COLORS.gray900};
  }
`;

const ButtonAssignToMe = styled.button`
  color: ${COLORS.brand1};

  :hover {
    color: ${COLORS.sub1};
    text-decoration: underline;
  }
`;

export interface MemberSearchPopoverProps extends Omit<PopoverProps, 'content'> {
  onClickItem?: (member: AppModelsSchemasUserOutUser | undefined) => void;
  content?: JSX.Element;
}

const MemberSearchPopover = ({ isOpen = false, onClickItem, children, ...props }: MemberSearchPopoverProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const textInput = useRef<HTMLInputElement>(null);
  const [members, setMembers] = useState<AppModelsSchemasUserOutUser[]>([]);
  const [user, setUser] = useState<OutUserDetail | null>(null);

  useEffect(() => {
    setIsPopoverOpen(isOpen);
    if (isOpen) {
      fetchMembers();
      setInputFocus();
    }
  }, [isOpen]);

  const fetchMembers = async () => {
    const [userData, membersData] = await Promise.all([getUserMeV1UsersMeGet(), getMembersV1UsersMembersGet()]);
    const membersWithoutMe = (membersData || []).filter((item) => item.id !== userData?.id);
    const keyword = textInput?.current?.value || '';
    const filteredMembers = keyword ? (membersWithoutMe || []).filter((item) => item.name?.includes(keyword)) : membersWithoutMe;

    setUser(userData);
    setMembers(filteredMembers);
  };

  const setInputFocus = () => {
    setTimeout(() => textInput.current && textInput.current.focus(), 100);
  };

  const handleClickContent = (value: AppModelsSchemasUserOutUser | undefined) => {
    setIsPopoverOpen(false);
    onClickItem && onClickItem(value);
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (e.nativeEvent.isComposing) return;
      fetchMembers();
      e.preventDefault();
      return;
    }

    if (e.key === 'Backspace') {
      setTimeout(() => {
        if (textInput?.current?.value.length !== 0) return;
        if (e.nativeEvent.isComposing) return;
        if (e.repeat) return;

        fetchMembers();
      });
    }
  };

  const handleClickSearch = (e: MouseEvent<HTMLButtonElement>) => {
    fetchMembers();
    e.preventDefault();
  };

  const handleClickPopoverOutside = () => {
    setIsPopoverOpen(false);
  };

  const handleClickUnselectButton = (e: MouseEvent<HTMLButtonElement>) => {
    onClickItem && onClickItem(undefined);
    e.preventDefault();
  };

  return (
    <Popover
      {...props}
      isOpen={isPopoverOpen}
      align={'start'}
      positions={['bottom']}
      content={
        <MemberSearchPopoverWrapper initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <SearchInputWrapper>
            <SearchInput ref={textInput} placeholder="팀원을 검색해주세요." onKeyDown={handleInputKeyDown} autoFocus />
            <SearchButton onClick={handleClickSearch}>
              <Icons.Search />
            </SearchButton>
          </SearchInputWrapper>
          <ContentItemWrapper>
            {!textInput?.current?.value && (
              <ContentItem onClick={() => handleClickContent(user as AppModelsSchemasUserOutUser)}>
                <RoundTag>{user?.name}</RoundTag>
                <ButtonAssignToMe style={{ marginLeft: 8 }}>나에게 할당하기</ButtonAssignToMe>
              </ContentItem>
            )}
            {members.map((v) => (
              <ContentItem key={v.id} onClick={() => handleClickContent(v)}>
                <RoundTag>{v.name}</RoundTag>
                <span style={{ marginLeft: 8 }}>{v.departments?.join(', ')}</span>
              </ContentItem>
            ))}
            {members.length === 0 && <EmptyContentItem>해당하는 팀원이 없습니다.</EmptyContentItem>}
          </ContentItemWrapper>
          <ContentFooter>
            <ButtonNotSelect onClick={handleClickUnselectButton}>지정 안함</ButtonNotSelect>
          </ContentFooter>
        </MemberSearchPopoverWrapper>
      }
      onClickOutside={props.onClickOutside ? props.onClickOutside : handleClickPopoverOutside}
      clickOutsideCapture={true}
    >
      {children}
    </Popover>
  );
};

export default MemberSearchPopover;
