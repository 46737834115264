import { Icons } from 'components';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { COLORS } from 'styles/constants';
import Fonts from 'components/Fonts';

export interface MemoType {
  id?: string | null;
  content: string;
  prevId?: string;
  section?: SectionType;
  attribute?: string;
}

export type SectionType = 'Objective' | 'Agendas' | 'Shareables' | 'Discussables' | 'Tasks' | 'Keycontents';

interface Props {
  onClose: () => void;
  meetingNoteId: string;
}
export const MemoBar = ({ onClose, meetingNoteId }: Props) => {
  const [expanded, setExpanded] = useState(false);
  const [targetMemo, setTargetMemo] = useState<MemoType>();
  const [savedMemo, setSavedMemo] = useState<MemoType>();
  // const memoStore = useContext(MemoStore);

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    // await memoStore.loadMemos(meetingNoteId);
    // if (memoStore.currentMemo) {
    //   const memo = toJS(memoStore.currentMemo);
    //   setTargetMemo(memo);
    //   setSavedMemo(memo);
    // }
  };
  /** input에서 focus out되면 메모 생성 또는 저장 */
  const onHandleFocusOut = async (event: any) => {
    const { textContent } = event.target;
    const requestMemo = { ...targetMemo, content: textContent };
    // 변경된 내용이 없으면 업데이트하지 않음
    if (JSON.stringify(requestMemo) === JSON.stringify(savedMemo)) {
      console.log('같아서 리턴함');
      return;
    }
    console.log(requestMemo);

    let response;
    // if (requestMemo?.id) {
    //   response = await memoStore.saveMemo(requestMemo!);
    // } else {
    //   response = await memoStore.createMemo(meetingNoteId, requestMemo!);
    // }
    setTargetMemo(response);
    setSavedMemo(response);
  };
  return (
    <Wrapper expanded={expanded}>
      <header className="flex justify-between items-center">
        <div className="flex gap-2 items-center">
          <Fonts.H1>개인 메모</Fonts.H1>
          <div className="cursor-pointer">
            <Icons.Info />
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <div className="cursor-pointer" onClick={() => setExpanded(!expanded)}>
            {expanded ? <Icons.Minimum /> : <Icons.Maximum />}
          </div>
          <div className="cursor-pointer" onClick={onClose}>
            <Icons.Close />
          </div>
        </div>
      </header>
      <div className="py-4">
        <article>
          <Fonts.Blockquote
            contentEditable={true}
            suppressContentEditableWarning={true}
            placeholder={'개인 메모를 작성하세요. 작성 후에 공유할만한 내용이라면 메모 일부를 회의 노트에 게시하거나, 메모 전체를 첨부할 수 있습니다.'}
            className="memo-content"
            data-type="agendas"
            onBlur={onHandleFocusOut}
          />
          {/* {memoStore.currentMemo?.content} */}
        </article>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ expanded: boolean }>`
  padding: 24px 32px;
  overflow-y: auto;
  background: ${COLORS.white};

  ${(props) =>
    props.expanded
      ? css`
          position: absolute;
          width: calc(100% - 440px);
          right: 0px;
        `
      : css`
          min-width: 372px; ;
        `}

  article {
    height: 100%;

    &:focus-within {
      outline: none;
    }
  }
  .memo-content {
    height: 100%;
  }
`;
