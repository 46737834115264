import { SVGProps } from 'react';

export const Reload = ({ width = 16, height = 16, fill = '#1F2023', strokeWidth = 1.5 }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3332 7.33322C13.1701 6.16003 12.6259 5.07299 11.7843 4.23955C10.9426 3.40611 9.85034 2.8725 8.67562 2.72092C7.50089 2.56934 6.30892 2.80821 5.2833 3.40072C4.25769 3.99323 3.45534 4.90651 2.99984 5.99989M2.6665 3.33322V5.99989H5.33317"
        stroke={fill}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.6665 8.66675C2.82954 9.83994 3.37379 10.927 4.21541 11.7604C5.05703 12.5939 6.14933 13.1275 7.32406 13.279C8.49878 13.4306 9.69076 13.1918 10.7164 12.5993C11.742 12.0067 12.5443 11.0935 12.9998 10.0001M13.3332 12.6667V10.0001H10.6665"
        stroke={fill}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
