import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthAction } from 'hooks/useAuthAction';
import { useEffect } from 'react';

const Auth = () => {
  const navigate = useNavigate();
  const { login } = useAuthAction();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const searchParams = Object.fromEntries(params);
    const token = searchParams?.access_token;

    if (!token) {
      navigate('/');
    } else {
      login(token);
    }
  }, []);

  return <></>;
};

export default Auth;
