import { SVGProps } from 'react';

export const TaskboxUncheck = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="12" cy="12" r="11.25" stroke="#0039A7" strokeWidth="1.5" />
      <path d="M6 12.75L10 16.5L18 9" stroke="#c0d6ff" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
