const detectOS = (): string => {
  const userAgent: string = navigator.userAgent;
  if (/Win/i.test(userAgent)) {
    return 'Windows';
  } else if (/Mac/i.test(userAgent)) {
    return 'macOS';
  } else if (/Linux/i.test(userAgent)) {
    return 'Linux';
  } else if (/Android/i.test(userAgent)) {
    return 'Android';
  } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return 'iOS';
  } else {
    return 'Unknown';
  }
};

export { detectOS };
