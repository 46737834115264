import { SVGProps } from 'react';

export const LockIn = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.666 2V4.66667M5.3327 2V4.66667M3.99935 3.33203H11.9993C12.7357 3.33203 13.3327 3.92898 13.3327 4.66536V12.6654C13.3327 13.4017 12.7357 13.9987 11.9993 13.9987H3.99935C3.26297 13.9987 2.66602 13.4017 2.66602 12.6654V4.66536C2.66602 3.92898 3.26297 3.33203 3.99935 3.33203Z"
        stroke="#C8CDDB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.04919 8.33297C4.95356 8.24455 5.00551 8.08469 5.13486 8.06936L6.9677 7.852C7.02042 7.84575 7.06621 7.81265 7.08845 7.76445L7.86152 6.08871C7.91607 5.97045 8.08423 5.97042 8.13878 6.08869L8.91185 7.76441C8.93409 7.81261 8.97958 7.8458 9.0323 7.85205L10.8652 8.06936C10.9946 8.08469 11.0464 8.2446 10.9508 8.33302L9.59585 9.58605C9.55687 9.62209 9.53952 9.67573 9.54987 9.72779L9.90946 11.5378C9.93484 11.6655 9.79887 11.7645 9.68521 11.7009L8.07469 10.7993C8.02837 10.7734 7.97209 10.7735 7.92577 10.7994L6.31508 11.7007C6.20142 11.7643 6.0652 11.6655 6.09058 11.5378L6.45023 9.72791C6.46058 9.67584 6.44327 9.62208 6.40429 9.58604L5.04919 8.33297Z"
        fill="#C8CDDB"
      />
    </svg>
  );
};
