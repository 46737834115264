import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CheckboxRound, Icons } from 'components';
import {
  AppApplicationMeetingNotesSchemasOutTask,
  AppModelsSchemasUserOutUser,
  OperationType,
  OutAnswer,
  OutBlockAgendaType,
  OutBlockAnswer,
  OutBlockAnswerType,
  OutBlockExtraAnswerType,
  OutBlockKeyContentType,
  OutBlockObjective,
  OutBlockObjectiveType,
  OutBlockQuestionType,
  OutBlockShareableType,
  OutBlockTaskType,
  AppApplicationMeetingNotesSchemasOutMeetingNote,
  AppApplicationMeetingNotesSchemasOutMeetingNoteBlocksItem,
  OutQuestion,
  SaveTransactionV1MeetingNotesMeetingNoteIdSavePostBodyItem,
  ShareableAttributeType,
} from 'queries/model';
import { saveTransactionV1MeetingNotesMeetingNoteIdSavePost } from 'queries';
import { v4 as uuidv4 } from 'uuid';
import { setCaretToEnd } from 'utils';
import styled from '@emotion/styled';
import { cloneDeep, get, groupBy, has, set, throttle, unset } from 'lodash';
import { BlockAddArea, EditableTextField, EditableTextFieldWrapper, HorizontalDivider, IconRadio, RoundTag, DragHandleIcon } from './styles';
import PropertySelect, { PropertySelectValue } from './components/PropertySelect';
import { SectionTitle, SectionTitleButtons } from './components/SectionHeader';
import BlockAddComment from './components/BlockAddComment';
import MemberSearchPopover from './components/MemberSearchPopover';
import ArrowToggleButton from 'components/ArrowToggleButton';
import CalendarPopover from 'components/CalendarPopover';
import dayjs from 'lib/dayjs';
import { COLORS } from 'styles/constants';
import AttachmentsSection from './components/AttachmentsSection';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

const Container = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 150px;
`;

const DisabledWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100% - 150px);
  opacity: 0.8;
  border-radius: 8px;
  background: #f2f5fc;
  pointer-events: 'none';
  z-index: 1;
`;

const MainForm = styled.form`
  padding: 24px 0px;
  background: var(--white);
  border-radius: 8px;

  & > div:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const InputBlockWrapper = styled.div`
  padding: 0px 24px;
`;

const SectionTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const AssigneeAndDueDateWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const VerticalDivider = styled.span`
  display: inline-flex;
  height: 12px;
  width: 1px;
  margin: 2px 8px 0px 6px;
  background: ${COLORS.gray300};
`;

const shareablePropertySelectOptions = [
  {
    key: 'Update',
    title: '업데이트',
    value: '업무적으로 업데이트된 내용을 작성합니다.',
    placeholder: '업무적으로 업데이트된 내용을 작성해주세요.',
  },
  {
    key: 'Forwarding',
    title: '참고사항',
    value: '팀이 참고할만한 정보를 작성합니다.',
    placeholder: '팀이 참고할만한 사항을 작성해주세요.',
  },
  { key: 'Notice', title: '공유사항', value: '팀에 공유할 사항을 작성합니다.', placeholder: '팀에 공유할 사항을 작성해주세요.' },
];

const discussablePropertySelectOptions = [
  {
    key: OutBlockQuestionType.Question,
    title: '논의사항',
    value: '논의할 내용을 작성합니다.',
    placeholder: '팀과 논의할 사항을 작성해주세요.',
  },
  {
    key: OutBlockAnswerType.Answer,
    title: '결정사항',
    value: '회의를 통해 결정된 사항을 작성합니다.',
    placeholder: '회의를 통해 결정된 사항을 작성해주세요.',
  },
];

const ShareableAttributeText: Record<string, string> = {
  Update: '업데이트',
  Notice: '공유사항',
  Forwarding: '참고사항',
};

const MeetingNoteSection = {
  Objective: OutBlockObjectiveType.Objective,
  Agenda: OutBlockAgendaType.Agenda,
  Question: OutBlockQuestionType.Question,
  Answer: OutBlockAnswerType.Answer,
  ExtraAnswer: OutBlockExtraAnswerType.ExtraAnswer,
  KeyContent: OutBlockKeyContentType.KeyContent,
  Shareable: OutBlockShareableType.Shareable,
  Task: OutBlockTaskType.Task,
} as const;

type MeetingNoteSection = typeof MeetingNoteSection[keyof typeof MeetingNoteSection];

type TransactionPayload = {
  id: string;
  operation: OperationType;
  type: MeetingNoteSection;
  value?: any;
};

interface MeetingNoteFormProps {
  meetingNote?: AppApplicationMeetingNotesSchemasOutMeetingNote;
  disabled?: boolean;
  onBlur?: () => void;
}

const transactionQueue = new Map<string, SaveTransactionV1MeetingNotesMeetingNoteIdSavePostBodyItem>();

const MeetingNoteForm = ({ meetingNote, disabled = false, onBlur }: MeetingNoteFormProps) => {
  const objective = useRef('');
  const [blocks, setBlocks] = useState<AppApplicationMeetingNotesSchemasOutMeetingNoteBlocksItem[]>([]);
  const [isVisibleShareablePropertySelect, setIsVisibleShareablePropertySelect] = useState(false);
  const [isVisibleDiscussablePropertySelect, setIsVisibleDiscussablePropertySelect] = useState(false);
  const [selectedDueDateId, setSelectedDueDateId] = useState('');
  const [selectedAssigneeId, setSelectedAssigneeId] = useState('');
  const [hoverBlockId, setHoverBlockId] = useState('');

  useEffect(() => {
    objective.current = (meetingNote?.blocks || []).find((item) => item.type === MeetingNoteSection.Objective)?.value?.content || '';
    setBlocks(meetingNote?.blocks || []);
    transactionQueue.clear();
  }, [meetingNote]);

  const sections = useMemo(() => {
    const newSections = {
      [MeetingNoteSection.Objective]: blocks.filter(({ type }) => type === MeetingNoteSection.Objective),
      [MeetingNoteSection.Agenda]: blocks.filter(({ type }) => type === MeetingNoteSection.Agenda),
      [MeetingNoteSection.Shareable]: blocks.filter(({ type }) => type === MeetingNoteSection.Shareable),
      [MeetingNoteSection.Question]: blocks.filter(({ type }) => type === MeetingNoteSection.Question),
      [MeetingNoteSection.Answer]: blocks.filter(({ type }) => type === MeetingNoteSection.Answer),
      [MeetingNoteSection.ExtraAnswer]: blocks.filter(({ type }) => type === MeetingNoteSection.ExtraAnswer),
      [MeetingNoteSection.Task]: blocks.filter(({ type }) => type === MeetingNoteSection.Task),
      [MeetingNoteSection.KeyContent]: blocks.filter(({ type }) => type === MeetingNoteSection.KeyContent),
    };

    // 논의사항 순서대로 결정사항 정렬
    if (newSections.Answer) {
      const sortKeys = newSections.Question.map((item) => item.id);
      newSections.Answer = newSections.Answer.sort((a: any, b: any) => sortKeys.indexOf(a.value?.question?.id) - sortKeys.indexOf(b.value?.question?.id));
    }
    return newSections;
  }, [blocks]);

  const shareableGroup = useMemo(
    () =>
      groupBy(
        blocks.filter(({ type }) => type === MeetingNoteSection.Shareable),
        'value.shareableAttribute',
      ),
    [blocks],
  );

  const request = (meetingNoteId: string, queue: Map<string, SaveTransactionV1MeetingNotesMeetingNoteIdSavePostBodyItem>) => {
    if (!meetingNoteId) return;
    if (!queue || !queue.size) return;

    const transactions = Array.from(queue.values());
    saveTransactionV1MeetingNotesMeetingNoteIdSavePost(meetingNoteId, transactions);
    queue.clear();
  };

  const throttleRequest = useCallback(throttle(request, 800), []);

  const sendTransaction = (payloads: TransactionPayload[]) => {
    if (!meetingNote?.id) return;
    if (!Array.isArray(payloads) || payloads.length === 0) return;

    payloads.forEach((payload) => transactionQueue.set(`${payload.id}-${payload.operation}`, convertToRequestFormat(payload)));
    throttleRequest(meetingNote.id, transactionQueue);
  };

  const convertToRequestFormat = (payload: TransactionPayload): TransactionPayload => {
    if (!payload) return payload;

    if (has(payload, 'value.question')) {
      const obj = cloneDeep(payload);
      set(obj, 'value.questionId', get(payload, 'value.question.id'));
      unset(obj, 'value.question');
      return obj;
    }

    if (has(payload, 'value.assignee')) {
      const obj = cloneDeep(payload);
      set(obj, 'value.assigneeId', get(payload, 'value.assignee.id'));
      unset(obj, 'value.assignee');
      return obj;
    }

    return payload;
  };

  const createBlock = ({ type, value }: { type: MeetingNoteSection; value?: any }): AppApplicationMeetingNotesSchemasOutMeetingNoteBlocksItem => ({
    id: uuidv4(),
    type: type,
    value: { content: '', ...value },
  });

  const insertBlock = (block: AppApplicationMeetingNotesSchemasOutMeetingNoteBlocksItem, index = -1) => {
    const beforeId = index === -1 ? (blocks.length === 0 ? null : blocks[blocks.length - 1].id) : blocks[index - 1].id;
    if (index === -1) {
      setBlocks([...blocks, block]);
    } else {
      const newBlocks = blocks.slice(0);
      newBlocks.splice(index, 0, block);
      setBlocks(newBlocks);
    }
    sendTransaction([{ id: block.id, operation: OperationType.create, type: block.type, value: { ...block.value, beforeId } }]);
  };

  const deleteBlock = (block: AppApplicationMeetingNotesSchemasOutMeetingNoteBlocksItem) => {
    setBlocks(blocks.filter(({ id }) => id !== block.id));
    sendTransaction([{ id: block.id, operation: OperationType.delete, type: block.type, value: block.value }]);
  };

  const deleteBlocks = (blockList: AppApplicationMeetingNotesSchemasOutMeetingNoteBlocksItem[]) => {
    const blockListIds = blockList.map(({ id }) => id);
    const newBlocks = blocks.filter(({ id }) => !blockListIds.includes(id));

    setBlocks(newBlocks);
    sendTransaction(blockList.map((item) => ({ id: item.id, operation: OperationType.delete, type: item.type, value: item.value })));
  };

  const updateBlock = (block: AppApplicationMeetingNotesSchemasOutMeetingNoteBlocksItem) => {
    const blockIndex = blocks.map(({ id }) => id).indexOf(block.id);
    if (blockIndex === -1) return;

    blocks[blockIndex] = { ...blocks[blockIndex], value: block.value } as AppApplicationMeetingNotesSchemasOutMeetingNoteBlocksItem;
    setBlocks(blocks);
    sendTransaction([{ id: block.id, operation: OperationType.update, type: block.type, value: block.value }]);
  };

  const refreshBlock = (id: string, value: object) => {
    const block = blocks.find((item) => item.id === id);
    if (!block) return;

    const newBlocks = blocks.map((item) =>
      item.id === id ? ({ ...item, value: { ...item.value, ...value } } as AppApplicationMeetingNotesSchemasOutMeetingNoteBlocksItem) : item,
    );
    setBlocks(newBlocks);
    sendTransaction([{ id: block.id, operation: OperationType.update, type: block.type, value: { ...block.value, ...value } }]);
  };

  const swapBlock = (
    srcBlock: AppApplicationMeetingNotesSchemasOutMeetingNoteBlocksItem,
    dstBlock: AppApplicationMeetingNotesSchemasOutMeetingNoteBlocksItem,
  ) => {
    if (!srcBlock) return;
    if (!dstBlock) return;

    const dstBlockIndex = blocks.findIndex((item) => item.id === dstBlock.id);
    if (dstBlockIndex === -1) return;

    const newBlocks = blocks.filter((item) => item.id !== srcBlock.id);
    const beforeId = dstBlockIndex === 0 ? null : newBlocks[dstBlockIndex - 1].id;

    newBlocks.splice(dstBlockIndex, 0, srcBlock);
    setBlocks([...newBlocks]);
    sendTransaction([{ id: srcBlock.id, operation: OperationType.update, type: srcBlock.type, value: { ...srcBlock.value, beforeId } }]);
  };

  const setFocusElement = (blockId: string) => {
    setTimeout(() => {
      const el = document.querySelector(`[data-field-id="${blockId}"]`) as HTMLDivElement;
      el && setCaretToEnd(el);
    }, 50);
  };

  const handleClickAdd = (type: MeetingNoteSection, value?: any) => {
    const block = createBlock({ type, value });
    insertBlock(block);
    setFocusElement(block.id);
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLDivElement>,
    blockId: string,
    index: number,
    type: MeetingNoteSection,
    filteredBlocks: AppApplicationMeetingNotesSchemasOutMeetingNoteBlocksItem[] = [],
    value?: any,
  ) => {
    const text = e.currentTarget.textContent || '';
    if (e.key === 'Backspace') {
      if (text.length !== 0) return;
      if (e.repeat) {
        e.preventDefault();
        return;
      }

      const nextBlock = filteredBlocks[index === 0 ? index + 1 : index - 1];
      if (nextBlock) setFocusElement(nextBlock.id);

      const dstBlock = filteredBlocks.find(({ id }) => id === blockId);
      if (dstBlock?.type) {
        const answerBlocks = blocks.filter(({ type, value }) => type === MeetingNoteSection.Answer && value?.question?.id === blockId);
        deleteBlocks([dstBlock, ...answerBlocks]);
      }

      e.preventDefault();
      return;
    }

    if (e.key === 'Enter') {
      if (e.nativeEvent.isComposing) return;
      if (e.repeat) {
        e.preventDefault();
        return;
      }

      const block = createBlock({ type, value });
      const realIndex = blocks.map(({ id }) => id).indexOf(blockId);
      e.currentTarget.blur();
      insertBlock(block, realIndex + 1);
      setFocusElement(block.id);
      e.preventDefault();
      return;
    }

    if (e.key === 'ArrowDown') {
      const blockIndex = index >= filteredBlocks.length - 1 ? filteredBlocks.length - 1 : index + 1;
      setFocusElement(filteredBlocks[blockIndex].id);
      e.preventDefault();
      return;
    }

    if (e.key === 'ArrowUp') {
      const blockIndex = index <= 0 ? 0 : index - 1;
      setFocusElement(filteredBlocks[blockIndex].id);
      e.preventDefault();
      return;
    }
  };

  const handleChangeBlock = (e: React.FormEvent<HTMLDivElement>, id: string, type: MeetingNoteSection, value?: any) => {
    const block = {
      id,
      type,
      value: { ...value, content: `${e.currentTarget.textContent || ''}` },
    } as AppApplicationMeetingNotesSchemasOutMeetingNoteBlocksItem;
    updateBlock(block);
  };

  const handleKeydownAddButton = (e: React.KeyboardEvent<HTMLDivElement>, type: MeetingNoteSection, value?: any) => {
    if (e.key !== 'Enter') return;
    if (e.nativeEvent.isComposing) return;
    if (e.repeat) {
      e.preventDefault();
      return;
    }

    // if (blocks.filter((item) => item.type === type).length) return;
    const block = createBlock({ type, value });
    insertBlock(block);
    setFocusElement(block.id);
    e.preventDefault();
  };

  const handleInputPropertySelect = (type: MeetingNoteSection, propertyValue: PropertySelectValue, value?: any) => {
    const { key, text } = propertyValue;
    if (!key || text === '') return;

    const block = createBlock({ type, value: { ...value, content: text } });
    insertBlock(block);
  };

  const handleClickDiscussionItem = (id: string, value: OutQuestion) => {
    const block = blocks.find((item) => item.type === MeetingNoteSection.Answer && item.value?.question?.id === id);
    if (block) {
      if (block.value?.content === '') {
        deleteBlock(block);
      }
    } else {
      const newBlock = createBlock({ type: MeetingNoteSection.Answer, value: { question: { id, content: value.content || '' } } });
      insertBlock(newBlock);
      setFocusElement(newBlock.id);
    }
  };

  const handleChangeObjective = (e: React.FormEvent<HTMLDivElement>) => {
    const content = e.currentTarget.textContent || '';
    objective.current = content;

    const objectiveBlock = blocks.find((item) => item.type === MeetingNoteSection.Objective);
    if (objectiveBlock) {
      updateBlock({ ...objectiveBlock, value: { content: `${e.currentTarget.textContent || ''}` } } as OutBlockObjective);
    } else {
      insertBlock(createBlock({ type: MeetingNoteSection.Objective, value: { content } }));
    }
  };

  const handleClickAssigneeItem = (member: AppModelsSchemasUserOutUser | undefined) => {
    const blockId = selectedAssigneeId;
    setSelectedAssigneeId('');

    if (blockId) {
      refreshBlock(blockId, { assignee: member });
    }
  };

  const handleClickCalendarItem = (value: Date | null) => {
    const blockId = selectedDueDateId;
    setSelectedDueDateId('');

    const dueDate = value ? dayjs(value).startOf('day').format('YYYY-MM-DDTHH:mm:ss') : null;
    if (blockId) {
      refreshBlock(blockId, { dueDate });
    }
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    if (result.source.index === result.destination.index) return;

    const srcBlock = blocks.find((item) => item.id === result.draggableId);
    if (!srcBlock) return;

    const sectionBlocks = blocks.filter(
      (item) => item.type === srcBlock.type && get(item.value, 'shareableAttribute') === get(srcBlock.value, 'shareableAttribute'),
    );
    const dstBlock = sectionBlocks[result.destination.index];
    if (!dstBlock) return;

    swapBlock(srcBlock, dstBlock);
  };

  return (
    <Container>
      {disabled && <DisabledWrapper />}
      <MainForm onBlur={onBlur}>
        <InputBlockWrapper>
          <SectionTitleWrapper>
            <SectionTitle>회의 목표</SectionTitle>
          </SectionTitleWrapper>
          <EditableTextField
            tabIndex={0}
            style={{ lineHeight: '26px' }}
            contentEditable={true}
            suppressContentEditableWarning={true}
            placeholder={'이번 회의의 목표를 여기에 적어주세요. 명확한 회의 목표로 명확한 회의 결과를 얻어보세요.'}
            onInput={handleChangeObjective}
            dangerouslySetInnerHTML={{ __html: objective.current }}
          />
        </InputBlockWrapper>
        <InputBlockWrapper>
          <SectionTitleWrapper>
            <SectionTitle>아젠다</SectionTitle>
          </SectionTitleWrapper>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId={MeetingNoteSection.Agenda}>
              {(provided) => (
                <div className={MeetingNoteSection.Agenda} {...provided.droppableProps} ref={provided.innerRef}>
                  {sections.Agenda.map(({ id, value }, idx) => (
                    <Draggable draggableId={id} index={idx} key={id}>
                      {(provided) => (
                        <EditableTextFieldWrapper
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                          style={{ ...provided.draggableProps.style, marginBottom: 8 }}
                          onMouseEnter={() => setHoverBlockId(id)}
                          onMouseLeave={() => setHoverBlockId('')}
                        >
                          <DragHandleIcon {...provided.dragHandleProps} isHover={id === hoverBlockId} />
                          <span style={{ fontSize: 10, marginRight: 8 }} onMouseEnter={(e) => e.stopPropagation()}>
                            &#8211;
                          </span>
                          <EditableTextField
                            tabIndex={0}
                            contentEditable={true}
                            suppressContentEditableWarning={true}
                            data-field-id={`${id}`}
                            onKeyDown={(e) => handleKeyDown(e, id, idx, MeetingNoteSection.Agenda, sections.Agenda)}
                            onInput={(e) => handleChangeBlock(e, id, MeetingNoteSection.Agenda)}
                            onMouseEnter={(e) => e.stopPropagation()}
                            dangerouslySetInnerHTML={{ __html: value?.content || '' }}
                          />
                        </EditableTextFieldWrapper>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {sections.Agenda.length === 0 && (
            <BlockAddArea
              tabIndex={0}
              onKeyDown={(e) => handleKeydownAddButton(e, MeetingNoteSection.Agenda)}
              onClick={() => handleClickAdd(MeetingNoteSection.Agenda)}
            >
              <BlockAddComment>세부 주제를 여기에 적어주세요. 어떤 순서가 효율적인지 고민하여 정리해보세요.</BlockAddComment>
            </BlockAddArea>
          )}
        </InputBlockWrapper>
        <HorizontalDivider />
        <InputBlockWrapper>
          <SectionTitleWrapper>
            <SectionTitle icon={<Icons.Speaker />}>공유사항</SectionTitle>
            <SectionTitleButtons onClickAdd={() => setIsVisibleShareablePropertySelect(true)} />
          </SectionTitleWrapper>
          {isVisibleShareablePropertySelect ? (
            <div style={{ marginBottom: 12 }}>
              <PropertySelect
                onInputEnter={(v) => handleInputPropertySelect(MeetingNoteSection.Shareable, v, { shareableAttribute: v.key })}
                onBlur={(v) => v && v.text === '' && setIsVisibleShareablePropertySelect(false)}
                options={shareablePropertySelectOptions}
              />
            </div>
          ) : (
            sections.Shareable.length === 0 && (
              <BlockAddArea
                tabIndex={0}
                onKeyDown={(e) => handleKeydownAddButton(e, MeetingNoteSection.Shareable, { shareableAttribute: ShareableAttributeType.Update })}
                onClick={() => setIsVisibleShareablePropertySelect(true)}
              >
                <BlockAddComment>팀에 공유할 사항을 작성해주세요.</BlockAddComment>
              </BlockAddArea>
            )
          )}
          {Object.keys(shareableGroup).map((key) => (
            <div key={key} style={{ marginBottom: 24 }}>
              <div style={{ marginBottom: 12 }}>
                <RoundTag>{ShareableAttributeText[key]}</RoundTag>
              </div>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId={MeetingNoteSection.Shareable}>
                  {(provided) => (
                    <div className={MeetingNoteSection.Shareable} {...provided.droppableProps} ref={provided.innerRef}>
                      {shareableGroup[key].map(({ id, value }, idx) => (
                        <Draggable draggableId={id} index={idx} key={id}>
                          {(provided) => (
                            <EditableTextFieldWrapper
                              {...provided.draggableProps}
                              ref={provided.innerRef}
                              style={{ ...provided.draggableProps.style, marginBottom: 12 }}
                              onMouseEnter={() => setHoverBlockId(id)}
                              onMouseLeave={() => setHoverBlockId('')}
                            >
                              <DragHandleIcon {...provided.dragHandleProps} isHover={id === hoverBlockId} />
                              <IconRadio style={{ marginRight: 8 }} onMouseEnter={(e) => e.stopPropagation()} />
                              <EditableTextField
                                tabIndex={0}
                                data-field-id={`${id}`}
                                contentEditable={true}
                                suppressContentEditableWarning={true}
                                onKeyDown={(e) => handleKeyDown(e, id, idx, MeetingNoteSection.Shareable, shareableGroup[key], { shareableAttribute: key })}
                                onInput={(e) => handleChangeBlock(e, id, MeetingNoteSection.Shareable, value)}
                                onMouseEnter={(e) => e.stopPropagation()}
                                dangerouslySetInnerHTML={{ __html: value?.content || '' }}
                              />
                            </EditableTextFieldWrapper>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              {shareableGroup[key].length !== 0 && (
                <BlockAddArea
                  tabIndex={0}
                  onKeyDown={(e) => handleKeydownAddButton(e, MeetingNoteSection.Shareable, { shareableAttribute: key })}
                  onClick={() => handleClickAdd(MeetingNoteSection.Shareable, { shareableAttribute: key })}
                  style={{ marginTop: 8 }}
                >
                  <BlockAddComment icon={<Icons.Add />}>{`새로운 ${ShareableAttributeText[key]} 추가하기`}</BlockAddComment>
                </BlockAddArea>
              )}
            </div>
          ))}
          <HorizontalDivider className="mt-6" />
        </InputBlockWrapper>
        <InputBlockWrapper>
          <SectionTitleWrapper>
            <SectionTitle icon={<Icons.Hand />}>논의사항</SectionTitle>
            <SectionTitleButtons onClickAdd={() => setIsVisibleDiscussablePropertySelect(true)} />
          </SectionTitleWrapper>
          {isVisibleDiscussablePropertySelect ? (
            <div style={{ marginBottom: 12 }}>
              <PropertySelect
                onInputEnter={(v) => handleInputPropertySelect(v?.key as MeetingNoteSection, v)}
                onBlur={(v) => v && v.text === '' && setIsVisibleDiscussablePropertySelect(false)}
                options={discussablePropertySelectOptions}
              />
            </div>
          ) : (
            sections.Question.length === 0 && (
              <BlockAddArea
                tabIndex={0}
                onKeyDown={(e) => handleKeydownAddButton(e, MeetingNoteSection.Question)}
                onClick={() => setIsVisibleDiscussablePropertySelect(true)}
              >
                <BlockAddComment>팀과 논의할 사항을 작성해주세요.</BlockAddComment>
              </BlockAddArea>
            )
          )}
          {sections.Question.length !== 0 && (
            <div>
              <div style={{ marginBottom: 12 }}>
                <RoundTag>논의사항</RoundTag>
              </div>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId={MeetingNoteSection.Question}>
                  {(provided) => (
                    <div className={MeetingNoteSection.Question} {...provided.droppableProps} ref={provided.innerRef}>
                      {sections.Question.map(({ id, value }, idx) => (
                        <Draggable draggableId={id} index={idx} key={id}>
                          {(provided) => (
                            <EditableTextFieldWrapper
                              {...provided.draggableProps}
                              ref={provided.innerRef}
                              style={{ ...provided.draggableProps.style, marginBottom: 12 }}
                              onMouseEnter={() => setHoverBlockId(id)}
                              onMouseLeave={() => setHoverBlockId('')}
                            >
                              <DragHandleIcon {...provided.dragHandleProps} isHover={id === hoverBlockId} />
                              <CheckboxRound
                                onChange={() => handleClickDiscussionItem(id, value as OutQuestion)}
                                disabled={false}
                                checked={!!sections.Answer.find((v) => (v as OutBlockAnswer)?.value?.question?.id === id)}
                              />
                              <EditableTextField
                                style={{ fontWeight: 'normal', marginLeft: 8 }}
                                tabIndex={0}
                                data-field-id={`${id}`}
                                contentEditable={true}
                                suppressContentEditableWarning={true}
                                onKeyDown={(e) => handleKeyDown(e, id, idx, MeetingNoteSection.Question, sections.Question)}
                                onInput={(e) => handleChangeBlock(e, id, MeetingNoteSection.Question)}
                                onMouseEnter={(e) => e.stopPropagation()}
                                dangerouslySetInnerHTML={{ __html: value?.content || '' }}
                              />
                            </EditableTextFieldWrapper>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              {sections.Question.length !== 0 && (
                <BlockAddArea
                  tabIndex={0}
                  onKeyDown={(e) => handleKeydownAddButton(e, MeetingNoteSection.Question)}
                  onClick={() => handleClickAdd(MeetingNoteSection.Question)}
                  style={{ marginTop: 8 }}
                >
                  <BlockAddComment icon={<Icons.Add />}>{`새로운 논의사항 추가하기`}</BlockAddComment>
                </BlockAddArea>
              )}
            </div>
          )}
          {sections.Answer.length || sections.ExtraAnswer.length ? (
            <div style={{ marginTop: 24, marginBottom: 12 }}>
              <RoundTag>결정사항</RoundTag>
            </div>
          ) : (
            <></>
          )}
          {sections.Answer.length !== 0 && (
            <div style={{ marginBottom: 12 }}>
              {sections.Answer.map(({ id, value }, idx) => (
                <div key={id}>
                  <div style={{ fontSize: '12px', color: 'var(--gray-500)', marginBottom: 4, wordBreak: 'break-all' }}>
                    {(value as OutAnswer).question?.content}
                  </div>
                  <EditableTextFieldWrapper style={{ marginBottom: 8 }}>
                    <span style={{ marginRight: 8 }}>
                      <Icons.Enter />
                    </span>
                    <EditableTextField
                      style={{ fontWeight: 'bold' }}
                      tabIndex={0}
                      data-field-id={`${id}`}
                      contentEditable={true}
                      suppressContentEditableWarning={true}
                      onKeyDown={(e) => (e.key == 'Enter' ? e.preventDefault() : handleKeyDown(e, id, idx, MeetingNoteSection.Answer, sections.Answer))}
                      onInput={(e) => handleChangeBlock(e, id, MeetingNoteSection.Answer, value)}
                      dangerouslySetInnerHTML={{ __html: value?.content || '' }}
                    />
                  </EditableTextFieldWrapper>
                </div>
              ))}
            </div>
          )}
          {sections.ExtraAnswer.length !== 0 && (
            <div style={{ marginBottom: 12 }}>
              <div style={{ color: 'var(--sub4)', fontSize: 'var(--font-size-5)' }}>추가된 결정사항</div>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId={MeetingNoteSection.ExtraAnswer}>
                  {(provided) => (
                    <div className={MeetingNoteSection.ExtraAnswer} {...provided.droppableProps} ref={provided.innerRef}>
                      {sections.ExtraAnswer.map(({ id, value }, idx) => (
                        <Draggable draggableId={id} index={idx} key={id}>
                          {(provided) => (
                            <EditableTextFieldWrapper
                              {...provided.draggableProps}
                              ref={provided.innerRef}
                              style={{ ...provided.draggableProps.style, marginBottom: 8 }}
                              onMouseEnter={() => setHoverBlockId(id)}
                              onMouseLeave={() => setHoverBlockId('')}
                            >
                              <DragHandleIcon {...provided.dragHandleProps} isHover={id === hoverBlockId} />
                              <span style={{ marginRight: 8 }} onMouseEnter={(e) => e.stopPropagation()}>
                                <Icons.Enter />
                              </span>
                              <EditableTextField
                                style={{ fontWeight: 'bold' }}
                                tabIndex={0}
                                data-field-id={`${id}`}
                                contentEditable={true}
                                suppressContentEditableWarning={true}
                                onKeyDown={(e) => handleKeyDown(e, id, idx, MeetingNoteSection.ExtraAnswer, sections.ExtraAnswer)}
                                onInput={(e) => handleChangeBlock(e, id, MeetingNoteSection.ExtraAnswer, value)}
                                onMouseEnter={(e) => e.stopPropagation()}
                                dangerouslySetInnerHTML={{ __html: value?.content || '' }}
                              />
                            </EditableTextFieldWrapper>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          )}
          {sections.Answer.length || sections.ExtraAnswer.length ? (
            <BlockAddArea
              tabIndex={0}
              onKeyDown={(e) => handleKeydownAddButton(e, MeetingNoteSection.ExtraAnswer)}
              onClick={() => handleClickAdd(MeetingNoteSection.ExtraAnswer)}
              style={{ marginTop: 8 }}
            >
              <BlockAddComment icon={<Icons.Add />}>{`새로운 결정사항 추가하기`}</BlockAddComment>
            </BlockAddArea>
          ) : (
            <></>
          )}
          <HorizontalDivider className="mt-6" />
        </InputBlockWrapper>
        <InputBlockWrapper>
          <SectionTitleWrapper>
            <SectionTitle icon={<Icons.ColorList />}>업무</SectionTitle>
            <SectionTitleButtons onClickAdd={() => handleClickAdd(MeetingNoteSection.Task)} />
          </SectionTitleWrapper>

          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId={MeetingNoteSection.Task}>
              {(provided) => (
                <div className={MeetingNoteSection.Task} {...provided.droppableProps} ref={provided.innerRef}>
                  {sections.Task.map(({ id, value }, idx) => (
                    <Draggable draggableId={id} index={idx} key={id}>
                      {(provided) => (
                        <div
                          key={id}
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                          style={{ ...provided.draggableProps.style, marginBottom: 8 }}
                          onMouseEnter={() => setHoverBlockId(id)}
                          onMouseLeave={() => setHoverBlockId('')}
                        >
                          <EditableTextFieldWrapper>
                            <DragHandleIcon {...provided.dragHandleProps} isHover={id === hoverBlockId} />
                            <IconRadio style={{ marginRight: 8 }} onMouseEnter={(e) => e.stopPropagation()} />
                            <EditableTextField
                              tabIndex={0}
                              data-field-id={`${id}`}
                              contentEditable={true}
                              suppressContentEditableWarning={true}
                              onKeyDown={(e) => handleKeyDown(e, id, idx, MeetingNoteSection.Task, sections.Task)}
                              onInput={(e) => handleChangeBlock(e, id, MeetingNoteSection.Task)}
                              onMouseEnter={(e) => e.stopPropagation()}
                              dangerouslySetInnerHTML={{ __html: value?.content || '' }}
                            />
                          </EditableTextFieldWrapper>
                          <AssigneeAndDueDateWrapper style={{ marginLeft: 24 }} onMouseEnter={(e) => e.stopPropagation()}>
                            <MemberSearchPopover
                              isOpen={selectedAssigneeId === id}
                              onClickOutside={() => setSelectedAssigneeId('')}
                              onClickItem={handleClickAssigneeItem}
                            >
                              <span onClick={() => setSelectedAssigneeId(selectedAssigneeId ? '' : id)}>
                                <ArrowToggleButton toggle={false} active={selectedAssigneeId === id}>
                                  {(value as AppApplicationMeetingNotesSchemasOutTask)?.assignee?.name || '담당자'}
                                </ArrowToggleButton>
                              </span>
                            </MemberSearchPopover>
                            <VerticalDivider />
                            <CalendarPopover
                              isOpen={selectedDueDateId === id}
                              onClickOutside={() => setSelectedDueDateId('')}
                              onClickItem={handleClickCalendarItem}
                              nullable
                            >
                              <span onClick={() => setSelectedDueDateId(selectedDueDateId ? '' : id)}>
                                <ArrowToggleButton toggle={false} active={selectedDueDateId === id}>
                                  {(value as AppApplicationMeetingNotesSchemasOutTask)?.dueDate?.split('T')[0] || '날짜 선택'}
                                </ArrowToggleButton>
                              </span>
                            </CalendarPopover>
                          </AssigneeAndDueDateWrapper>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <BlockAddArea
            tabIndex={0}
            onKeyDown={(e) => handleKeydownAddButton(e, MeetingNoteSection.Task)}
            onClick={() => handleClickAdd(MeetingNoteSection.Task)}
          >
            <BlockAddComment icon={<Icons.Add />}>
              {sections.Task.length === 0 ? '회의를 통해 결정된 업무를 작성해주세요.' : '새로운 업무 추가하기'}
            </BlockAddComment>
          </BlockAddArea>
          <HorizontalDivider className="mt-6" />
        </InputBlockWrapper>
        <InputBlockWrapper>
          <SectionTitleWrapper>
            <SectionTitle icon={<Icons.Focus />}>주요 내용</SectionTitle>
            <SectionTitleButtons onClickAdd={() => handleClickAdd(MeetingNoteSection.KeyContent)} />
          </SectionTitleWrapper>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId={MeetingNoteSection.KeyContent}>
              {(provided) => (
                <div className={MeetingNoteSection.KeyContent} {...provided.droppableProps} ref={provided.innerRef}>
                  {sections.KeyContent.map(({ id, value }, idx) => (
                    <Draggable draggableId={id} index={idx} key={id}>
                      {(provided) => (
                        <EditableTextFieldWrapper
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                          style={{ ...provided.draggableProps.style, marginBottom: 8 }}
                          onMouseEnter={() => setHoverBlockId(id)}
                          onMouseLeave={() => setHoverBlockId('')}
                        >
                          <DragHandleIcon {...provided.dragHandleProps} isHover={id === hoverBlockId} />
                          <span style={{ fontSize: 10, marginRight: 8 }} onMouseEnter={(e) => e.stopPropagation()}>
                            &#10033;
                          </span>
                          <EditableTextField
                            tabIndex={0}
                            data-field-id={`${id}`}
                            contentEditable={true}
                            suppressContentEditableWarning={true}
                            onKeyDown={(e) => handleKeyDown(e, id, idx, MeetingNoteSection.KeyContent, sections.KeyContent)}
                            onInput={(e) => handleChangeBlock(e, id, MeetingNoteSection.KeyContent)}
                            onMouseEnter={(e) => e.stopPropagation()}
                            dangerouslySetInnerHTML={{ __html: value?.content || '' }}
                          />
                        </EditableTextFieldWrapper>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {sections.KeyContent.length === 0 && (
            <BlockAddArea
              tabIndex={0}
              onKeyDown={(e) => handleKeydownAddButton(e, MeetingNoteSection.KeyContent)}
              onClick={() => handleClickAdd(MeetingNoteSection.KeyContent)}
            >
              <BlockAddComment>가장 강력한 기억력보다 즉시 작성하는 몇 글자의 기록이 낫다고 합니다. 주요 내용을 기록해보세요.</BlockAddComment>
            </BlockAddArea>
          )}
          <HorizontalDivider className="mt-6" />
        </InputBlockWrapper>
        <InputBlockWrapper>
          <AttachmentsSection meetingNoteId={meetingNote?.id || ''} attachedFiles={meetingNote?.attachments} />
        </InputBlockWrapper>
      </MainForm>
    </Container>
  );
};

export default MeetingNoteForm;
