import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { DATE_FORMAT_2, TIME_FORMAT_1, TIME_FORMAT_2, convertDatetimeFormat } from 'utils/datetimeFormat';
import Fonts from 'components/Fonts';
import { COLORS } from 'styles/constants';
import { TimeboxComponent } from './TimeboxComponent';
import { OutTimeboxWithTasks } from 'queries/model';
interface TimeboxProps {
  focus?: boolean;
  disabled?: boolean;
  onClick: () => void;
  handleTimeboxes: (timebox: Timeboxes[]) => void;
  timebox: Timeboxes;
  loadTimeboxes: () => void;
}

interface Timeboxes extends OutTimeboxWithTasks {
  event?: boolean;
}

export const Timebox = ({ focus = false, disabled = false, onClick, timebox, handleTimeboxes, loadTimeboxes }: TimeboxProps) => {
  return (
    <Time>
      {/* <TimeboxComponent disabled={disabled} focus={focus} onClick={onClick} loadTimeboxes={loadTimeboxes} handleTimeboxes={handleTimeboxes} timebox={timebox} /> */}
    </Time>
  );

  /*
    <Banner disabled={disabled}>
      <Fonts.H6>+3</Fonts.H6>
    </Banner>
  */
};

const Time = styled.div``;

const Banner = styled.div<{ disabled: boolean }>`
  width: 24px;
  height: 24px;
  background-color: ${COLORS.brand1};
  color: ${COLORS.white};
  border-radius: 50%;
  border: 1px solid ${COLORS.white};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -8px;
  left: 149px;
  ${(props) =>
    props.disabled &&
    css`
      background-color: ${COLORS.gray500};
    `}
`;
