import styled from '@emotion/styled';
import { Button, IconButton, Typography } from '@mui/material';
import { Icons } from 'components';
import {
  createPlanWeeklyV1PlansWeeklyPost,
  createTaskV1WorksPost,
  deletePlanWeeklyV1PlansWeeklyPlanWeeklyIdDelete,
  listPlanWeeklyV1PlansWeeklyGet,
  removeTaskV1WorksWorkIdDelete,
  updateIssueV1WorksIssuesIssueIdPut,
  updatePlanWeeklyV1PlansWeeklyPlanWeeklyIdPut,
  updateTaskV1WorksWorkIdPut,
} from 'queries';
import { OutPlanWeekly, UpdateIssueDetail } from 'queries/model';
import { useEffect, useMemo, useState } from 'react';
import { COLORS } from 'styles/constants';
import dayjs from 'lib/dayjs';
import SidePanel from '../components/SidePanel';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import toast from 'react-hot-toast';
import { useUpdateEffect } from '@react-hookz/web';
import { WorkDragContext } from 'atoms/works';
import { InboxContextMenuType } from 'components/InboxContextMenuPopover';
import IssueListView from 'components/Issue/IssueListView';

const Container = styled.div`
  width: 100%;
  min-width: 1440px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.gray100};
`;

const PlanningHeaderWrapper = styled.div`
  display: flex;
  min-height: 84px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${COLORS.gray200};
  padding: 0px 32px;
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  background-color: ${COLORS.gray100};
`;

const SidePanelWrapper = styled.div`
  max-width: 724px;
  min-width: 724px;
  border-right: 1px solid ${COLORS.gray100};
  box-shadow: 0px 8px 16px rgba(26, 30, 39, 0.16);
`;

const WeeklyPlanWrapper = styled.div`
  width: calc(100% - 496px);
  min-width: 600px;
  display: flex;
  flex-direction: column;
  padding: 0px 28px;
`;

const WeeklyPlanHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 65px;
  border-bottom: 1px solid ${COLORS.gray300};
`;

const IssueListViewWrapper = styled.div`
  width: 100%;
  min-width: 300px;
  height: calc(100vh - 168px);
  display: flex;
  padding-top: 28px;
  gap: 20px;
`;

const PlanningPeriod = ({ week, period }: { week: string; period: string }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <span>
      <Typography variant="subtitle2" fontWeight={'bold'} fontSize={13} color={COLORS.gray800}>
        {week}
      </Typography>
    </span>
    <span style={{ margin: '0 4px', color: COLORS.gray400, fontSize: 12 }}>|</span>
    <span>
      <Typography variant="subtitle2" fontSize={13} color={COLORS.gray600}>
        {period}
      </Typography>
    </span>
  </div>
);

export const Planning = () => {
  const navigate = useNavigate();
  const [weekIssues, setWeekIssues] = useState<OutPlanWeekly[]>([]);
  const [nextWeekIssues, setNextWeekIssues] = useState<OutPlanWeekly[]>([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const { weekDate, nextWeekDate, planningYear, planningWeek, nextPlanningYear, nextPlanningWeek, periodOfWeek, periodOfNextWeek } = useMemo(
    () => ({
      weekDate: dayjs(currentDate),
      nextWeekDate: dayjs(currentDate).add(7, 'day'),
      planningYear: dayjs(currentDate).weekday(0).year(),
      planningWeek: dayjs(currentDate).weekday(0).week(),
      nextPlanningYear: dayjs(currentDate).add(7, 'day').weekday(0).year(),
      nextPlanningWeek: dayjs(currentDate).add(7, 'day').weekday(0).week(),
      periodOfWeek: `${dayjs(currentDate).weekday(0).startOf('day').format('DD(ddd)')}~${dayjs(currentDate)
        .weekday(6)
        .startOf('day')
        .format('DD(ddd)')}, ${dayjs(currentDate).weekday(0).startOf('day').format('YYYY년 MM월')}`,
      periodOfNextWeek: `${dayjs(currentDate).add(7, 'day').weekday(0).startOf('day').format('DD(ddd)')}~${dayjs(currentDate)
        .add(7, 'day')
        .weekday(6)
        .startOf('day')
        .format('DD(ddd)')}, ${dayjs(currentDate).add(7, 'day').weekday(0).startOf('day').format('YYYY년 MM월')}`,
    }),
    [currentDate],
  );

  useEffect(() => {
    fetchIssues();
    localStorage.setItem('planning-view-entered', 'true');
  }, []);

  useUpdateEffect(() => {
    fetchIssues();
  }, [currentDate]);

  const fetchIssues = async () => {
    const [weekPlans, nextWeekPlans] = await Promise.all([
      listPlanWeeklyV1PlansWeeklyGet({ year: planningYear, week: planningWeek }),
      listPlanWeeklyV1PlansWeeklyGet({ year: nextPlanningYear, week: nextPlanningWeek }),
    ]);

    setWeekIssues((weekPlans || []).sort((a, b) => +Date.parse(b.createdAt!) - +Date.parse(a.createdAt!)));
    setNextWeekIssues((nextWeekPlans || []).sort((a, b) => +Date.parse(b.createdAt!) - +Date.parse(a.createdAt!)));
  };

  const handleChangeCurrentDate = (date: Date) => {
    setCurrentDate(date);
  };

  const handleCreateIssue = async ({ year, week, content }: { year: number; week: number; content: string }) => {
    const issue = await createTaskV1WorksPost({ id: uuidv4(), type: 'issue', content: content });
    if (issue) {
      await createPlanWeeklyV1PlansWeeklyPost({ year, week, issueId: issue.id });
      toast.success('새로운 이슈를 생성하였습니다.');
    }

    fetchIssues();
  };

  const handleClickSaveIssue = async (id: string, value: UpdateIssueDetail) => {
    if (!value) return;

    await updateIssueV1WorksIssuesIssueIdPut(id, value);
    fetchIssues();
    toast.success('이슈를 수정하였습니다.');
  };

  const handleClickCancelIssue = () => {
    fetchIssues();
  };

  const handleDropIssue = async ({ year, week, context }: { year: number; week: number; context?: WorkDragContext }) => {
    if (context?.type !== 'issue' || !context.id) return;
    const { id, view } = context;

    if (view === 'plan-planning') {
      const plan = [...weekIssues, ...nextWeekIssues].find((item) => item.issue.id === id);
      if (!plan) return;
      await updatePlanWeeklyV1PlansWeeklyPlanWeeklyIdPut(plan.id, { year, week, issueId: id });
    }

    if (view === 'plan-inbox-issue') {
      await createPlanWeeklyV1PlansWeeklyPost({ year, week, issueId: id });
    }

    toast.success('이슈를 이동하였습니다.');
    fetchIssues();
  };

  const handleClickContextMenu = async (id: string, type: string, menu: InboxContextMenuType) => {
    try {
      switch (menu) {
        case 'MOVE_TO_NEXT_WEEK': {
          const weekPlan = weekIssues.find((item) => item.issue.id === id);
          const nextWeekPlan = nextWeekIssues.find((item) => item.issue.id === id);

          if (weekPlan || nextWeekPlan) {
            const planId = weekPlan ? weekPlan.id : nextWeekPlan?.id;
            const date = weekPlan ? weekDate : nextWeekDate;

            await updatePlanWeeklyV1PlansWeeklyPlanWeeklyIdPut(planId!, {
              year: dayjs(date).add(1, 'week').year(),
              week: dayjs(date).add(1, 'week').week(),
              issueId: id,
            });
            toast.success('이슈를 이동하였습니다.');
            fetchIssues();
          }
          break;
        }
        case 'MOVE_TO_AFTER': {
          const weekPlan = weekIssues.find((item) => item.issue.id === id);
          const nextWeekPlan = nextWeekIssues.find((item) => item.issue.id === id);
          if (weekPlan || nextWeekPlan) {
            const planId = weekPlan ? weekPlan.id : nextWeekPlan?.id;
            await updateTaskV1WorksWorkIdPut(id, { workSectionType: 'someday' });
            await deletePlanWeeklyV1PlansWeeklyPlanWeeklyIdDelete(planId!);
            fetchIssues();
            toast.success('나중에 목록으로 이동하였습니다.');
          }
          break;
        }
        case 'DELETE':
          await removeTaskV1WorksWorkIdDelete(id);
          toast.success('이슈를 삭제하였습니다.');
          fetchIssues();
          break;
      }
    } catch (e) {
      toast.error('작업을 수행할 수 없습니다.');
    }
  };

  const handleCheckIssue = async (id: string, checked: boolean) => {
    await updateIssueV1WorksIssuesIssueIdPut(id, { done: checked });
    fetchIssues();
  };

  const handleCheckTask = async (id: string, checked: boolean) => {
    await updateTaskV1WorksWorkIdPut(id, { done: checked });
    fetchIssues();
  };

  return (
    <Container>
      <PlanningHeaderWrapper>
        <div style={{ display: 'flex' }}>
          <img src={require('assets/images/thumbs-up.png')} alt="thumbs up" width={24} height={24} style={{ width: 24, height: 24, marginRight: 12 }} />
          <Typography variant="h1" fontWeight={'bold'} fontSize={24} color={COLORS.gray900}>
            한 주를 계획해보세요
          </Typography>
        </div>
        <Button disableElevation color="primary" variant="contained" style={{ marginLeft: 8 }} onClick={() => navigate('/plan/weekly')}>
          <Icons.CheckDouble />
          <span style={{ marginLeft: 4 }}>완료하기</span>
        </Button>
      </PlanningHeaderWrapper>
      <ContentWrapper>
        <SidePanelWrapper>
          <SidePanel />
        </SidePanelWrapper>
        <WeeklyPlanWrapper>
          <WeeklyPlanHeaderWrapper>
            <div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icons.DoubleQuotesL style={{ marginTop: -8 }} />
                <Typography variant="h4" fontSize={16} fontWeight={'bold'} color={COLORS.gray900} style={{ margin: '0 4px' }}>
                  언제나, 언제나 계획을 세워라.
                </Typography>
                <Icons.DoubleQuotesR style={{ marginTop: -8 }} />
                <Typography variant="h4" fontSize={13} fontWeight={'bold'} color={COLORS.brand1} style={{ marginLeft: 4 }}>
                  릭 리어던
                </Typography>
              </div>
            </div>
            <div>
              <Button
                sx={{ borderRadius: 2, background: 'white', color: 'black', border: '1px solid #E7EAF4' }}
                size="small"
                style={{ minWidth: '48px', marginLeft: '8px' }}
                onClick={() => handleChangeCurrentDate(new Date())}
              >
                <b>오늘</b>
              </Button>
              <IconButton
                aria-label="previous day"
                sx={{ background: 'white' }}
                style={{ marginLeft: 8 }}
                onClick={() => handleChangeCurrentDate(dayjs(currentDate).subtract(7, 'day').toDate())}
              >
                <Icons.ArrowLeftSmall />
              </IconButton>
              <IconButton
                aria-label="next day"
                sx={{ background: 'white' }}
                style={{ marginLeft: 8 }}
                onClick={() => handleChangeCurrentDate(dayjs(currentDate).add(7, 'day').toDate())}
              >
                <Icons.ArrowRightSmall />
              </IconButton>
            </div>
          </WeeklyPlanHeaderWrapper>
          <IssueListViewWrapper>
            <div style={{ width: '100%', minWidth: '300px' }}>
              <IssueListView
                title={<PlanningPeriod week={`W${planningWeek}`} period={periodOfWeek} />}
                issues={weekIssues}
                draggable={true}
                droppable={true}
                dragView={'plan-planning'}
                droppableViews={['plan-planning', 'plan-inbox-issue']}
                hiddenContextMenus={['MOVE_TO_THIS_WEEK', 'SWITCH_TO_ISSUE', 'SWITCH_TO_TASK']}
                onCreate={(value) => handleCreateIssue({ year: planningYear, week: planningWeek, content: value })}
                onChangeIssue={handleCheckIssue}
                onChangeTask={handleCheckTask}
                onClickContextMenu={handleClickContextMenu}
                onDropOutside={(context) => handleDropIssue({ year: planningYear, week: planningWeek, context })}
                onCloseIssueDetailPopup={handleClickCancelIssue}
                onApplyIssueDetailPopup={handleClickSaveIssue}
              />
            </div>
            <div style={{ width: '100%', minWidth: '300px' }}>
              <IssueListView
                title={<PlanningPeriod week={`W${nextPlanningWeek}`} period={periodOfNextWeek} />}
                issues={nextWeekIssues}
                draggable={true}
                droppable={true}
                dragView={'plan-planning'}
                droppableViews={['plan-planning', 'plan-inbox-issue']}
                hiddenContextMenus={['MOVE_TO_THIS_WEEK', 'SWITCH_TO_ISSUE', 'SWITCH_TO_TASK']}
                onCreate={(value) => handleCreateIssue({ year: nextPlanningYear, week: nextPlanningWeek, content: value })}
                onChangeIssue={handleCheckIssue}
                onChangeTask={handleCheckTask}
                onClickContextMenu={handleClickContextMenu}
                onDropOutside={(context) => handleDropIssue({ year: nextPlanningYear, week: nextPlanningWeek, context })}
                onCloseIssueDetailPopup={handleClickCancelIssue}
                onApplyIssueDetailPopup={handleClickSaveIssue}
              />
            </div>
          </IssueListViewWrapper>
        </WeeklyPlanWrapper>
      </ContentWrapper>
    </Container>
  );
};

export default Planning;
