import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { TIME_FORMAT_2, convertDatetimeFormat, DATE_FORMAT_2 } from 'utils/datetimeFormat';
import Fonts from 'components/Fonts';
import { COLORS } from 'styles/constants';
import toast from 'react-hot-toast';
import { updateEventsV1EventsEventIdPut } from 'queries';
import { UpdateEvent } from 'queries/model';
import { CalendarWithoutClock } from 'components/Icons/CalendarWithoutClock';

interface ListProps {
  event: any;
  focus: boolean;
  disable: boolean;
  onClick: () => void;
  eventList: any[];
  onChangeEventList: (eventList: any[]) => void;
  handleModal?: () => void;
}

export const CalendarList = ({ focus = false, disable = false, event, onClick, eventList, onChangeEventList, handleModal = () => {} }: ListProps) => {
  // const [startTime, setStartTime] = useState(event.startTime ? convertDatetimeFormat(event.startTime, TIME_FORMAT_2) : '00시');
  // const [endTime, setEndTime] = useState(event.endTime ? convertDatetimeFormat(event.endTime, TIME_FORMAT_2) : '24시');
  const created = convertDatetimeFormat(event.createdAt, DATE_FORMAT_2);
  const startTime = event.startTime ? convertDatetimeFormat(event.startTime, TIME_FORMAT_2) : '00시';
  const endTime = event.endTime ? convertDatetimeFormat(event.endTime, TIME_FORMAT_2) : '00시';

  /** 잔상 남는 박스 */
  const [shadowBox, setShadowBox] = useState(false);

  /** 마우스오버 판별 */
  const [mouseover, setMouseover] = useState(false);

  // 회의록 보여지는 위치 설정해주기
  const timecap = computeTimeCap(startTime!, endTime!);
  const positionTime = convertTimeToMinute(startTime!);
  const share = Math.floor(positionTime / 60);
  const remainder = positionTime % 60;
  const fixedPosition = share * 96 + 16.7 + remainder * 1.6;

  const [position, setPosition] = useState(0);
  const [finalPosition, setFinalPosition] = useState(0);

  /** 캘린더에서 이동하면 변하는 시간 */
  const [movingStartTime, setMovingStartTime] = useState(startTime);
  const [movingEndTime, setMovingEndTime] = useState(endTime);
  useEffect(() => {
    setMovingStartTime(startTime);
    setMovingEndTime(endTime);
  }, [startTime, endTime]);

  /** 캘린더에서 이동하여 이벤트 수정에 필요한 시간 */
  const [fixStartTime, setFixStartTime] = useState('');
  const [fixEndTime, setFixEndTime] = useState('');

  /** 이벤트 박스 높이 변경 */
  const [changeHeight, setChangeHeight] = useState(0);
  const [finalChangeHeight, setFinalChangeHeight] = useState(0);

  /** resize 여부 판단 */
  const [resize, setResize] = useState(false);

  function convertTimeToMinute(time: string) {
    const splitTime = time.split(' ');
    let hour = Number(splitTime[1].split(':')[0]);
    const minute = Number(splitTime[1].split(':')[1]);
    if (splitTime[0] === '오전' && splitTime[1].split(':')[0] === '12') {
      hour = 0;
    } else if (splitTime[0] === '오후' && splitTime[1].split(':')[0] !== '12') {
      hour += 12;
    }

    return hour * 60 + minute;
  }

  function computeTimeCap(startTime: string, endTime: string) {
    let timeCap = convertTimeToMinute(endTime) - convertTimeToMinute(startTime);
    if (timeCap < 30) {
      timeCap = 30;
    }
    return timeCap;
  }

  async function handleOnMouseUp() {
    if (startTime !== movingStartTime || endTime !== movingEndTime) {
      const fixEvent: UpdateEvent = {
        ...event,
        start: fixStartTime,
        end: fixEndTime,
      };
      const fakeEvent = {
        ...event,
        startTime: fixStartTime,
        endTime: fixEndTime,
      };
      const realIndex = eventList.map(({ eventId }) => eventId).indexOf(event.eventId);
      eventList[realIndex] = fakeEvent;
      onChangeEventList(eventList);

      setChangeHeight(0);
      setFinalChangeHeight(0);
      setPosition(0);
      setFinalPosition(0);
      const success = await updateEventsV1EventsEventIdPut(event.eventId, fixEvent);
      if (success) toast.success('저장 되었습니다.');
      else toast.error('저장 실패하였습니다.');
    }
  }

  /** 이벤트 카드 이동 상한선 하한선 구하기 */
  useEffect(() => {
    if (position < 0) {
      const limitTop = fixedPosition - 16.7;
      if (Math.abs(position) >= limitTop) {
        setPosition((limitTop + 24) * -1);
      }
    } else {
      const limitBottom = 2224.7 - fixedPosition;
      if (position > limitBottom) {
        setPosition(limitBottom);
      }
    }
    const share = Math.floor(position / 24);
    if (position !== 0) setFinalPosition((share + 1) * 24);

    const startTimeToMinute = convertTimeToMinute(startTime!);
    const endTimeToMinute = convertTimeToMinute(endTime!);
    const addTime = (finalPosition / 24) * 15;
    const startShare = Math.abs(Math.floor((startTimeToMinute + addTime) / 60));
    const startRemainder = (startTimeToMinute + addTime) % 60;

    setFixStartTime(`${event.startTime?.split('T')[0]}T${String(startShare).padStart(2, '0')}:${String(startRemainder).padEnd(2, '0')}:00+09:00`);
    if (fixStartTime) {
      setMovingStartTime(convertDatetimeFormat(fixStartTime, TIME_FORMAT_2));
    }

    const endShare = Math.abs(Math.floor((endTimeToMinute + addTime) / 60));
    const endRemainder = (endTimeToMinute + addTime) % 60;

    setFixEndTime(`${event.endTime?.split('T')[0]}T${String(endShare).padStart(2, '0')}:${String(endRemainder).padEnd(2, '0')}:00+09:00`);

    if (fixEndTime) {
      setMovingEndTime(convertDatetimeFormat(fixEndTime, TIME_FORMAT_2));
    }
  }, [position]);

  useEffect(() => {
    const share = Math.floor(changeHeight / 24);
    if (changeHeight !== 0 && changeHeight >= 24 - timecap * 1.6) {
      setFinalChangeHeight((share + 1) * 24);
    }

    const endTimeToMinute = convertTimeToMinute(endTime!);
    const addTime = (finalChangeHeight / 24) * 15;
    const endShare = Math.abs(Math.floor((endTimeToMinute + addTime) / 60));
    const endRemainder = (endTimeToMinute + addTime) % 60;

    setFixEndTime(`${event.endTime?.split('T')[0]}T${String(endShare).padStart(2, '0')}:${String(endRemainder).padEnd(2, '0')}:00+09:00`);
    if (fixEndTime) {
      setMovingEndTime(convertDatetimeFormat(fixEndTime, TIME_FORMAT_2));
    }
  }, [changeHeight]);

  // useEffect(() => {
  //   eventOverlap();
  // }, []);

  const [overlapCount, setOverlapCount] = useState(0);
  const eventOverlap = () => {
    const overlapStartTime = fixStartTime ? fixStartTime : event.startTime;
    const overlapEndTime = movingEndTime ? movingEndTime : endTime;
    let count = 0;
    for (let i = 0; i < eventList.length; i++) {
      if (eventList[i].startTime === overlapStartTime) {
        count++;
        setOverlapCount(count);
        eventList[i].count = count;
      }
    }
  };

  return (
    <div
      onMouseOver={() => {
        setMouseover(true);
      }}
      onMouseLeave={() => setMouseover(false)}
    >
      {shadowBox ? (
        <Wrapper
          className="event-list"
          style={{ opacity: '0.5' }}
          onClick={onClick}
          focus={focus}
          disable={disable}
          timecap={timecap}
          overlapCount={overlapCount}
          resize={false}
          finalChangeHeight={finalChangeHeight}
          fixedPosition={fixedPosition}
        >
          <div style={{ padding: '0px 16px' }}>
            <FontBadge disable={disable}>
              <Fonts.Badge>
                {startTime}~{endTime}
              </Fonts.Badge>
            </FontBadge>
            <FontH4 disable={disable}>
              <Fonts.H4>{event.title} </Fonts.H4>
            </FontH4>
            {timecap * 1.6 - Math.abs(finalChangeHeight) === 48 ? null : (
              <FontsBlock disable={disable}>
                <Fonts.Badge>생성됨: {created}</Fonts.Badge>
              </FontsBlock>
            )}
          </div>
        </Wrapper>
      ) : null}

      <Wrapper
        className={`event-list overlapcount-${event.count}`}
        data-eventid={`${event.eventId}`}
        style={{ transform: `translateY(${finalPosition}px)` }}
        focus={focus}
        disable={disable}
        timecap={timecap}
        overlapCount={overlapCount}
        resize={resize}
        finalChangeHeight={finalChangeHeight}
        fixedPosition={fixedPosition}
        onClick={onClick}
        onMouseDown={(clickEvent: any) => {
          if (disable) {
            toast.error('변경할 수 없습니다.');
          } else {
            const mouseMoveHandler = (moveEvent: any) => {
              const deltaY = moveEvent.pageY - clickEvent.pageY;
              if (deltaY !== 0) {
                if (!event.canModify) {
                  toast.error('변경 권한이 없습니다.');
                  mouseUpHandler();
                } else {
                  setPosition(position + deltaY);
                  setShadowBox(true);
                  setResize(true);
                }
              }
            };
            const mouseUpHandler = () => {
              document.removeEventListener('mousemove', mouseMoveHandler);
              setResize(false);
            };

            document.addEventListener('mousemove', mouseMoveHandler);
            document.addEventListener('mouseup', mouseUpHandler, {
              once: true,
            });
          }
        }}
        onMouseUp={() => {
          setShadowBox(false);
          handleOnMouseUp();
          eventOverlap();
        }}
      >
        <div style={{ padding: '0px 16px' }}>
          <FontBadge disable={disable}>
            <Fonts.Badge>{`${movingStartTime}~${movingEndTime}`}</Fonts.Badge>
          </FontBadge>
          <FontH4 disable={disable}>
            <Fonts.H4>{event.title}</Fonts.H4>
          </FontH4>
          {finalChangeHeight <= 0 && timecap * 1.6 - Math.abs(finalChangeHeight) === 48 ? null : (
            <FontsBlock disable={disable}>
              <Fonts.Badge>생성됨: {created}</Fonts.Badge>
            </FontsBlock>
          )}
        </div>
      </Wrapper>
      {event.canModify && !disable ? (
        <>
          <Resize
            style={{ transform: `translateY(${finalPosition}px)` }}
            className="resize"
            fixedPosition={fixedPosition}
            timecap={timecap}
            finalChangeHeight={finalChangeHeight}
            overlapCount={overlapCount}
            onMouseDown={(clickEvent: any) => {
              const mouseMoveHandler = (moveEvent: any) => {
                const deltaY = moveEvent.pageY - clickEvent.pageY;
                if (deltaY !== 0) {
                  setChangeHeight(changeHeight + deltaY);
                }
              };
              const mouseUpHandler = () => {
                document.removeEventListener('mousemove', mouseMoveHandler);
              };

              document.addEventListener('mousemove', mouseMoveHandler);
              document.addEventListener('mouseup', mouseUpHandler, { once: true });
            }}
            onMouseUp={handleOnMouseUp}
          />
          {mouseover ? (
            <UpdateEventIcon
              style={{ transform: `translateY(${finalPosition}px)` }}
              fixedPosition={fixedPosition}
              timecap={timecap}
              finalChangeHeight={finalChangeHeight}
              onClick={() => {
                handleModal();
              }}
            >
              <CalendarWithoutClock width={16} height={16} />
            </UpdateEventIcon>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

const Wrapper = styled.div<{
  focus: boolean;
  timecap: number;
  fixedPosition: number;
  disable: boolean;
  resize: boolean;
  overlapCount: number;
  finalChangeHeight: number;
}>`
  height: calc(calc(${(props) => props.timecap - 1}px * 1.6) + ${(props) => props.finalChangeHeight}px);
  min-height: 48px;
  width: 165px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: ${COLORS.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  position: absolute;
  top: ${(props) => props.fixedPosition}px;
  left: 45px;
  border: solid 1px ${COLORS.brand1};
  :active {
    background-color: ${COLORS.sub3};
  }

  ${(props) =>
    props.focus &&
    css`
      background-color: ${COLORS.sub3};
      border: solid 1px ${COLORS.brand1};
      box-shadow: 0px 8px 16px 0px #1a1e2729;
    `};

  ${(props) =>
    props.disable &&
    css`
      background-color: ${COLORS.gray100};
      border: solid 1px ${COLORS.gray500};
      cursor: no-drop;
      box-shadow: 0px 0px 0px 0px;
    `};

  ${(props) =>
    props.overlapCount &&
    css`
      width: calc(${props.overlapCount} * -25px + 190px);
      left: calc(${props.overlapCount} * 25px + 20px);
      z-index: calc(${props.overlapCount} + 4);
    `}

  ${(props) =>
    props.resize
      ? props.overlapCount > 1
        ? css`
            width: 182px;
            background-color: ${COLORS.sub3};
            z-index: 100;
            left: 45px;
          `
        : css`
            width: 182px;
            background-color: ${COLORS.sub3};
            z-index: 100;
          `
      : css``};
`;

const FontBadge = styled.div<{ disable: boolean }>`
  margin-top: 6px;
  color: ${COLORS.gray600};
  ${(props) =>
    props.disable &&
    css`
      color: ${COLORS.gray400};
    `};
`;

const FontH4 = styled.div<{ disable: boolean }>`
  color: ${COLORS.brand1};
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  ${(props) =>
    props.disable &&
    css`
      color: ${COLORS.gray600};
    `};
`;

const FontsBlock = styled.div<{ disable: boolean }>`
  color: ${COLORS.gray500};
`;

const Resize = styled.div<{
  fixedPosition: number;
  timecap: number;
  finalChangeHeight: number;
  overlapCount: number;
}>`
  width: 165px;
  height: 8px;
  cursor: n-resize;
  position: absolute;
  top: calc(${(props) => props.fixedPosition}px + calc(${(props) => props.timecap}px * 1.6) - 10px + ${(props) => props.finalChangeHeight}px);
  left: 45px;
  z-index: 100;

  ${(props) =>
    props.overlapCount &&
    css`
      width: calc(${props.overlapCount} * -25px + 190px);
      left: calc(${props.overlapCount} * 25px + 20px);
      z-index: calc(${props.overlapCount} + 4);
    `}
`;

const UpdateEventIcon = styled.div<{ fixedPosition: number; timecap: number; finalChangeHeight: number }>`
  width: 26px;
  height: 26px;
  border: 1px solid ${COLORS.gray500};
  border-radius: 50%;
  background-color: ${COLORS.white};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  z-index: 100;
  top: calc(${(props) => props.fixedPosition}px + calc(${(props) => props.timecap}px * 1.6) - 35px + ${(props) => props.finalChangeHeight}px);
  right: 25px;
  :hover {
    background-color: ${COLORS.gray200};
  }
`;

// calc(${props => props.timecap - 1}px * 1.6)
