import styled from '@emotion/styled';
import { COLORS } from 'styles/constants';
import { Popover, Divider, TextField } from '@mui/material';
import { OutTaskboxDetailResponse } from 'queries/model';
import { Icons } from 'components';
import { StaticDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

interface Props {
  event?: OutTaskboxDetailResponse;
  eventId?: string | null;
  postpone?: HTMLElement | null;
  onClosePostpone?: () => void;
  onClickTomorrow?: () => void;
  onClickNextWeek?: () => void;
  onClickLater?: () => void;
  onClickCalendar?: (value: dayjs.Dayjs) => void;
}

export const TaskboxPostponeDetail = ({
  event,
  eventId,
  postpone,
  onClosePostpone = () => {},
  onClickTomorrow = () => {},
  onClickNextWeek = () => {},
  onClickLater = () => {},
  onClickCalendar = () => {},
}: Props) => {
  return (
    <>
      <Popover
        open={Boolean(postpone)}
        anchorEl={postpone}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={onClosePostpone}
        sx={{ marginTop: '4px' }}
      >
        <Container>
          <MenuList>
            <MenuItem
              className="item-text"
              onClick={() => {
                onClickTomorrow();
              }}
            >
              <span style={{ marginRight: 8 }}>
                <Icons.Tomorrow />
              </span>
              <span>다음 날</span>
            </MenuItem>
            <MenuItem
              className="item-text"
              onClick={() => {
                onClickNextWeek();
              }}
            >
              <span style={{ marginRight: 8 }}>
                <Icons.NextMonday stroke="#1C1B1F" fill="#1C1B1F" />
              </span>
              <span>다음주 월요일</span>
            </MenuItem>
            <MenuItem
              className="item-text"
              onClick={() => {
                onClickLater();
              }}
            >
              <span style={{ marginRight: 8 }}>
                <Icons.Later width={16} height={16} fill="#1C1B1F" />
              </span>
              <span>나중에</span>
            </MenuItem>
          </MenuList>
          <Divider style={{ margin: '16px 0px' }}></Divider>
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            value={event?.start?.date ? dayjs(event?.start?.date) : dayjs(event?.start?.datetime)}
            onChange={(newValue) => onClickCalendar(newValue!)}
            renderInput={(params) => <TextField {...params} />}
          />
        </Container>
      </Popover>
    </>
  );
};

const Container = styled.div`
  width: fit-content;
  height: fit-content;
  min-width: 138px;
  background-color: ${COLORS.white};
  box-shadow: 0px 8px 16px 0px rgba(26, 30, 39, 0.16);
  border-radius: 8px;
  padding: 12px;
  z-index: 10;

  .item-text {
    display: flex;
    align-items: center;
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: ${COLORS.gray100};
    }
  }

  .MuiTypography-root {
    width: 20px;
    height: 20px;
  }

  .MuiPickersDay-root {
    width: 20px;
    height: 20px;
  }

  .MuiPickerStaticWrapper-content {
    min-width: 0px;
    height: 190px;
  }

  .MuiPickersCalendarHeader-root {
    margin-top: 0px;
  }

  .MuiCalendarPicker-root {
    width: 200px;
  }

  .css-epd502 {
    width: 200px;
    height: 190px;
  }

  .MuiButtonBase-root {
    padding: 0px;
  }

  .MuiPickersArrowSwitcher-spacer {
    width: 8px;
  }

  .MuiCalendarPicker-viewTransitionContainer {
    > div {
      height: 150px;
    }
  }

  .PrivatePickersSlideTransition-root {
    min-height: 130px;
  }
`;

const MenuList = styled.ul``;

const MenuItem = styled.li`
  font-size: 12px;
`;
