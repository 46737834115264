import { SVGProps } from 'react';

export const FirstPriorityIssueCheck = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="16" height="16" fill="white" />
      <circle cx="8" cy="8" r="8" fill="#F64F59" />
      <path d="M4 8.5L6.66667 11L12 6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
