import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button, Dialog, IconButton, Popover, Tooltip } from '@mui/material';
import { Checkbox, Icons } from 'components';
import Fonts from 'components/Fonts';
import { TimeSelectionModal } from 'components/TimeSelectionModal';
import {
  updateTaskboxesV1TaskboxesTaskboxIdPut,
  createTaskboxTaskV1TaskboxesTaskboxIdTasksPost,
  removeTimeboxV1TaskboxesTaskboxIdDelete,
  updateTaskV1TasksTaskIdPatch,
  detachTaskV1TaskboxesTaskboxIdTasksTaskIdDelete,
  updateIssueV1WorksIssuesIssueIdPut,
  listPlanSectionsV1PlansSectionsGet,
  updateTaskV1WorksWorkIdPut,
  createTaskV1WorksPost,
  removeTaskV1WorksWorkIdDelete,
  moveWorkToTaskboxV1WorksMoveTaskboxIdPost,
} from 'queries';
import {
  UpdateTaskbox,
  AppApplicationTaskboxesSchemasOutTask,
  OutPlanWeeklyBrief,
  UpdateIssueDetail,
  OutTaskboxDetailResponse,
  OutPlanSection,
  CreateWork,
  WorkSectionType,
  MoveWorkToTaskbox,
} from 'queries/model';
import { ForwardedRef, forwardRef, RefObject, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { COLORS } from 'styles/constants';
import { setCaretToEnd } from 'utils';
import { v4 as uuidv4 } from 'uuid';
import { convertDatetimeFormat, DATE_FORMAT_1, DATE_FORMAT_4, DATE_FORMAT_5, TIME_FORMAT_2 } from 'utils/datetimeFormat';
import { TimelineTaskboxDetail } from './TimelineTaskboxDetail';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { getCaretPosition } from 'utils/moveCaret';
import { IssueLinkage } from 'components/Icons';
import IssueDetailView from './IssueDetailView';
import TaskboxIssueCheckbox from 'components/TaskboxCheckbox';
import { TaskboxPostponeDetail } from './TaskboxPostponeDetail';
import dayjs, { Dayjs } from 'dayjs';
import { useAtom } from 'jotai';
import { dragContextAtom, fetchTasksAtom, WorkDragContext } from 'atoms/works';
import toast from 'react-hot-toast';
import { SubtaskDetail } from './SubtaskDetail';
import { DataArraySharp } from '@mui/icons-material';
import { useClickOutside } from '@react-hookz/web';
import SuccessStories from 'stories/ToastPopup/Success.stories';

export interface Props {
  event?: OutTaskboxDetailResponse;
  events?: OutTaskboxDetailResponse[];
  date?: Date;
  selectedEvent?: OutTaskboxDetailResponse;
  index?: number;
  onChange?: (date: Date) => void;
  onAddTask?: (index: number, tasks: AppApplicationTaskboxesSchemasOutTask[]) => void;
  onDragTaskboxImage?: (event: OutTaskboxDetailResponse) => void;
  onClickTaskbox?: (taskbox: OutTaskboxDetailResponse) => void;
  draggingTask?: WorkDragContext | null;
  expanded?: boolean;
}

type Tasks = {
  id: string;
  content?: string;
  simple: boolean;
  beforeId?: string;
};

export const TimelineTaskbox = forwardRef(
  (
    {
      event,
      events = [],
      date,
      selectedEvent,
      index,
      onChange = () => {},
      onAddTask = () => {},
      onDragTaskboxImage = () => {},
      onClickTaskbox = () => {},
      draggingTask,
      expanded,
    }: Props,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const [selectedTaskbox, setSelectedTaskbox] = useState<string | undefined>('');
    const [timeSelection, setTimeSelection] = useState<HTMLElement | null>(null);
    const [taskboxDetail, setTaskboxDetail] = useState<HTMLElement | null>(null);
    const [taskboxPostpone, setTaskboxPostpone] = useState<HTMLElement | null>(null);
    const [subTaskDetail, setSubTaskDetail] = useState<HTMLElement | null>(null);
    const [tasks, setTasks] = useState<AppApplicationTaskboxesSchemasOutTask[]>(event!.tasks!);
    const [selectedTasks, setSelectedTasks] = useState<AppApplicationTaskboxesSchemasOutTask[]>(event!.tasks!);
    const [selectTaskId, setSelectedTaskId] = useState<string>('');
    const [taskDone, setTaskDone] = useState(tasks?.length > 0 && tasks?.filter((task) => task.done === true).length === tasks.length ? true : false);
    const [clickedIssue, setClickedIssue] = useState<string | undefined>();
    const [sectionTitle, setSectionTitle] = useState<string>('');
    const [plan, setPlan] = useState<OutPlanWeeklyBrief[]>([]);
    const [taskboxHeight, setTaskboxHeight] = useState<number | undefined>();
    const refTaskbox = useRef<HTMLDivElement>(null);
    const [taskboxDrag, setTaskboxDrag] = useAtom(dragContextAtom);
    const [, fetchTasks] = useAtom(fetchTasksAtom);
    const [isDragging, setIsDragging] = useState(false);
    const [draggingToTaskbox, setDraggingToTaskbox] = useState(false);
    const [defaultTime, setDefaultTime] = useState<Dayjs>();
    const [focusedTaskboxId, setFocusedTaskboxId] = useState<OutTaskboxDetailResponse | undefined>(selectedEvent);
    const [showSubtask, setShowSubtask] = useState<OutTaskboxDetailResponse | undefined>();
    const [getCaret, setGetCaret] = useState<number | null>(null);

    useEffect(() => {
      setTasks(event?.tasks ? event.tasks : []);
      setSelectedTasks(event?.tasks ? event.tasks.filter((task) => task.simple === false) : []);
      if (event?.plansWeekly && event.plansWeekly.length > 0) setPlan(event?.plansWeekly);
    }, [events]);

    // useEffect(() => {
    //   if (clickedIssue !== undefined) fetchSection();
    // }, [clickedIssue]);

    useLayoutEffect(() => {
      const filteredTask = tasks?.filter((task) => task.done === true);
      if (event?.done) {
        setTaskDone(true);
      }

      if (tasks.length > 0) {
        if (filteredTask.length === tasks.length) {
          setTaskDone(true);
          if (event!.done === false && taskDone) handleClickTaskboxCheck();
        } else {
          setTaskDone(false);
          // if (event!.done === true && !taskDone) {
          //   handleClickTaskboxCheck();
          // }
        }
      }
    }, [tasks]);

    useEffect(() => {
      setTaskboxHeight(refTaskbox.current?.getBoundingClientRect().height);
    }, [tasks, focusedTaskboxId, expanded]);

    useEffect(() => {
      if (getCaret) {
        focusInput(event!.id!);
      }
      setGetCaret(null);
    }, [getCaret]);

    useEffect(() => {
      if (selectedEvent) {
        setShowSubtask(selectedEvent);
        setFocusedTaskboxId(selectedEvent);
      }
    }, [selectedEvent, showSubtask]);

    /**태스크박스 외부 클릭 시 태스크박스 닫기 */
    useClickOutside(
      refTaskbox,
      () => {
        if (showSubtask) {
          setShowSubtask(undefined);
          setFocusedTaskboxId(undefined);
        }
      },
      ['click'],
    );

    const fetchSection = async () => {
      const section = await listPlanSectionsV1PlansSectionsGet();
      if (plan[0] && plan[0].planSectionId) {
        setSectionTitle(section.find((v) => v.id === plan[0].planSectionId)!.name!);
      }
    };

    const generateTaskId = () => uuidv4();

    const showingTime = (events: OutTaskboxDetailResponse) => {
      let timeboxTime = '';
      if (events.start?.datetime && events.end?.datetime) {
        timeboxTime = `${convertDatetimeFormat(events.start.datetime, TIME_FORMAT_2)} ~ ${convertDatetimeFormat(events.end.datetime, TIME_FORMAT_2)}`;
      } else {
        timeboxTime = '시간 설정';
      }
      return timeboxTime;
    };

    const handleCloseTimelineDetail = () => {
      setSelectedTaskbox(undefined);
      setTaskboxDetail(null);
    };

    const setFocusElement = (blockId: string) => {
      setTimeout(() => {
        const el = document.querySelector(`[data-id="${blockId}"]`) as HTMLDivElement;
        el && setCaretToEnd(el);
      }, 20);
    };

    /** 타임라인 제목에서 focus out 시 저장 */
    const handleFocusoutTitle = async (e: React.FocusEvent<HTMLDivElement>, event: OutTaskboxDetailResponse) => {
      const title = e.currentTarget.textContent;

      if (event.title !== title) {
        const targetTimeline: UpdateTaskbox = { title };
        const success = await updateTaskboxesV1TaskboxesTaskboxIdPut(event.id!, targetTimeline);
        if (success) {
          toast.success('태스크박스를 수정하였습니다.');
          onChange(date!);
        }
      }
    };

    /** 타임라인 시간 변경 */
    const handleChangeTime = async (startTime: string, endTime: string, eventId: string) => {
      const updateTaskbox: UpdateTaskbox = { start: { datetime: startTime }, end: { datetime: endTime } };
      const success = await updateTaskboxesV1TaskboxesTaskboxIdPut(eventId, updateTaskbox);

      if (success) {
        toast.success('태스크박스를 수정하였습니다.');
        onChange(date!);
      }
    };

    /** 타임라인 삭제 */
    const handleRemoveTimeline = async (event: OutTaskboxDetailResponse) => {
      const remove = await removeTimeboxV1TaskboxesTaskboxIdDelete(event.id!);
      setTaskboxDetail(null);
      if (remove) {
        toast.success('태스크박스를 삭제하였습니다.');
        onChange(date!);
      }
    };

    /** 타임라인 키다운 로직 */
    const handleTimelineKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>, event: OutTaskboxDetailResponse) => {
      const filteredTimeline = events;
      const title = e.currentTarget.textContent;
      const timelineIndex = events.map(({ id }) => id).indexOf(event.id);
      const filteredTimelineIndex = filteredTimeline.map(({ id }) => id).indexOf(event.id);

      if (e.key === 'Enter') {
        if (event.title === title) {
          e.preventDefault();
          const newTask: Tasks = {
            id: generateTaskId(),
            content: '',
            simple: true,
          };
          const taskList: Tasks[] = [newTask, ...tasks];
          onAddTask(timelineIndex, taskList);
          await createTaskboxTaskV1TaskboxesTaskboxIdTasksPost(event.id!, newTask);
          onChange(date!);
          setFocusElement(newTask.id);
        } else {
          e.preventDefault();
          if (e.nativeEvent.isComposing) return;
          const targetTimeline: UpdateTaskbox = { title };
          const success = await updateTaskboxesV1TaskboxesTaskboxIdPut(event.id!, targetTimeline);
          if (success) {
            toast.success('태스크박스를 수정하였습니다.');
            onChange(date!);
          }

          setFocusElement(event.id!);
        }
        return;
      }

      if (e.key === 'Backspace') {
        if (title!.length !== 0) return;
        if (e.repeat) {
          e.preventDefault();
          return;
        }
      }

      if (e.key === 'ArrowUp') {
        e.preventDefault();
        const prevTimeline = filteredTimelineIndex !== 0 ? filteredTimeline[filteredTimelineIndex - 1] : filteredTimeline[filteredTimeline.length - 1];
        const taskTitles = document.querySelectorAll('.task');
        if (prevTimeline) {
          const filteredTaskTitles = Array.prototype.filter.call(taskTitles, (taskTitle) => taskTitle.getAttribute('data-timeboxid') === prevTimeline.id);
          if (filteredTaskTitles.length !== 0) {
            setFocusElement(filteredTaskTitles[filteredTaskTitles.length - 1].dataset.id);
          } else {
            setFocusElement(prevTimeline.id!);
          }
        }
        return;
      }

      if (e.key === 'ArrowDown') {
        e.preventDefault();
        if (event.tasks && event.tasks.length !== 0) {
          setFocusElement(event.tasks[0].id);
        } else {
          const nextTimeline = filteredTimeline[filteredTimelineIndex + 1];
          if (nextTimeline) setFocusElement(nextTimeline.id!);
          else setFocusElement(filteredTimeline[0].id!);
        }
        return;
      }
    };

    /** tasks 로직 */
    const createTask = (newTask: Tasks) => {
      setTasks([...tasks, newTask]);
    };

    const insertTask = (index: number, newTask: Tasks) => {
      tasks.splice(index + 1, 0, newTask);
      setTasks([...tasks]);
    };

    const removeTask = (taskId: string) => {
      setTasks(tasks.filter(({ id }) => id !== taskId));
    };

    /** 태스크 키다운 로직 */
    const handleTaskKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>, taskId: string, index: number) => {
      const text = e.currentTarget.textContent || '';

      if (e.nativeEvent.isComposing === false && e.key === 'Enter' && event) {
        const caret = getCaretPosition(e.target);
        const taskIndex = tasks.map(({ id }) => id).indexOf(taskId);
        const timelineIndex = events.findIndex((item) => item.id === event!.id);
        if (e.repeat) {
          e.preventDefault();
          return;
        }
        if (caret === text.length) {
          const newTask: Tasks = {
            id: generateTaskId(),
            content: '',
            beforeId: taskId,
            simple: true,
          };
          insertTask(taskIndex, newTask);
          onAddTask(timelineIndex, tasks);
          setFocusElement(newTask.id);
          e.preventDefault();
          await createTaskboxTaskV1TaskboxesTaskboxIdTasksPost(event.id!, newTask);
          return;
        } else if (caret === 0 && event) {
          const beforeId = tasks[taskIndex - 1] ? tasks[taskIndex - 1].id : undefined;
          const newTask: Tasks = {
            id: generateTaskId(),
            content: '',
            beforeId,
            simple: true,
          };
          insertTask(taskIndex - 1, newTask);
          onAddTask(timelineIndex, tasks);
          setFocusElement(newTask.id);
          e.preventDefault();
          await createTaskboxTaskV1TaskboxesTaskboxIdTasksPost(event.id!, newTask);
          return;
        } else {
          const prevText = text.slice(0, caret);
          const nextText = text.slice(caret);
          const targetTask = { ...tasks[taskIndex], content: prevText };
          e.preventDefault();
          tasks[taskIndex] = targetTask;
          setTasks([...tasks]);
          onAddTask(timelineIndex, tasks);
          await updateTaskV1TasksTaskIdPatch(tasks[taskIndex].id, targetTask);

          const newTask: Tasks = {
            id: generateTaskId(),
            content: nextText,
            beforeId: taskId,
            simple: true,
          };
          insertTask(taskIndex, newTask);
          onAddTask(timelineIndex, tasks);
          setFocusElement(newTask.id);
          await createTaskboxTaskV1TaskboxesTaskboxIdTasksPost(event.id!, newTask);
        }
      }

      if (e.key === 'Backspace' && event) {
        if (text.length !== 0) return;
        if (e.repeat) {
          e.preventDefault();
          return;
        }

        const findIndex = index === 0 ? index + 1 : index - 1;
        const task = tasks[findIndex];
        if (task) {
          setFocusElement(task.id!);
        } else {
          setFocusElement(event.id!);
        }
        removeTask(taskId);
        await detachTaskV1TaskboxesTaskboxIdTasksTaskIdDelete(event.id!, taskId);

        const taskboxIndex = events.findIndex((itme) => itme.id === event.id);
        const changeTasks = tasks.filter(({ id }) => id !== taskId);
        onAddTask(taskboxIndex, changeTasks);

        e.preventDefault();
        return;
      }

      if (e.key === 'ArrowDown') {
        const filteredTimeline = events!;
        if (index === tasks.length - 1) {
          const timeboxIndex = filteredTimeline!.map(({ id }) => id).indexOf(event!.id);
          if (filteredTimeline[timeboxIndex + 1]) {
            setFocusElement(filteredTimeline[timeboxIndex + 1].id!);
            e.preventDefault();
          } else {
            setFocusElement(filteredTimeline[0].id!);
            e.preventDefault();
          }
        } else {
          const task = tasks[index + 1];
          if (task) setFocusElement(task.id!);
          e.preventDefault();
        }
        return;
      }

      if (e.key === 'ArrowUp') {
        if (index === 0) {
          setFocusElement(event!.id!);
          e.preventDefault();
        } else {
          const task = tasks[index - 1];
          if (task) setFocusElement(task.id!);
          e.preventDefault();
        }
        return;
      }
    };

    /** 업무를 추가해주세요 클릭 시 테스크 생성 */
    const handleClickTaskAdd = async (event: OutTaskboxDetailResponse) => {
      const timelineIndex = events.findIndex((item) => item.id === event.id);
      const prevTask = tasks[tasks.length - 1];
      const newTask: Tasks = {
        id: generateTaskId(),
        content: '',
        beforeId: prevTask && prevTask.id,
        simple: true,
      };
      createTask(newTask);
      const taskList = [...tasks, newTask];
      onAddTask(timelineIndex, taskList);
      setFocusElement(newTask.id);
      event && (await createTaskboxTaskV1TaskboxesTaskboxIdTasksPost(event.id!, newTask));
    };

    /** Task 완료 여부 저장 */
    const handleCheckbox = async (taskIndex: number) => {
      if (event?.done) {
        await handleClickTaskboxCheck();
      } else {
        if (tasks[taskIndex].done) {
          tasks[taskIndex].done = false;
        } else {
          tasks[taskIndex].done = true;
        }
        setTasks([...tasks]);
        const timelineIndex = events.findIndex((item) => item.id === event!.id);
        onAddTask(timelineIndex, tasks);
        await updateTaskV1TasksTaskIdPatch(tasks[taskIndex].id, tasks[taskIndex]);
      }
    };

    /** focus out 시 변경된 일일업무 저장 */
    const handleSaveChangedTask = async (e: React.FocusEvent<HTMLDivElement>, taskIndex: number) => {
      const content = e.currentTarget.textContent!;
      if (tasks[taskIndex].content !== content) {
        const targetTask = { ...tasks[taskIndex], content };
        tasks[taskIndex] = targetTask;
        setTasks([...tasks]);
        const timelineIndex = events.findIndex((item) => item.id === event!.id);
        onAddTask(timelineIndex, tasks);
        await updateTaskV1TasksTaskIdPatch(tasks[taskIndex].id, targetTask);
      }
    };

    /**이슈 상세창 열기 */
    const handleClickIssueDetail = (id: string) => {
      setClickedIssue(id);
      // setSelectedTasks(event?.tasks ? event.tasks : []);
    };

    /**이슈 상세창 닫기 */
    const handleClickCancelIssue = () => {
      setClickedIssue(undefined);
    };

    /**이슈 상세창 저장 */
    const handleClickSaveIssue = async (id: string, value: UpdateIssueDetail, selectedTaskIds?: string[]) => {
      if (!value) return;

      await updateIssueV1WorksIssuesIssueIdPut(id, { content: value.content, description: value.description, dueDate: value.dueDate, tasks: value.tasks });

      // if (tasks.filter((task) => task.simple === true).length > 0) {
      //   const selectedTask = tasks.filter((task) => selectedTaskIds?.includes(task.id));
      //   const seletedId = selectedTask.map((item) => item.id);

      //   const addId = selectedTaskIds!.filter((id) => !seletedId.includes(id));
      //   const addTask: UpdateTaskboxTask[] = value.tasks!.filter((v) => addId.includes(v.id!)).map(({ id, content }) => ({ id: id!, content }));

      //   const renewalTask = [...selectedTask, ...tasks.filter((v) => v.simple === true)];
      //   const newTask: UpdateTaskboxTask[] = [...renewalTask, ...addTask];

      //   await updateTaskboxesV1TaskboxesTaskboxIdPut(event!.id!, {
      //     ...event,
      //     tasks: newTask,
      //   });
      // } else {
      //   const newTask = value.tasks!.filter((task) => selectedTaskIds?.includes(task.id!)).map(({ id, content }) => ({ id: id!, content }));
      //   await updateTaskboxesV1TaskboxesTaskboxIdPut(event!.id!, {
      //     ...event,
      //     tasks: newTask,
      //   });
      // }

      // onChange();
      setClickedIssue(undefined);
    };

    /**태스크박스 완료 체크 */
    const handleClickTaskboxCheck = async () => {
      if (event) {
        const updateTaskbox: UpdateTaskbox = { done: !event.done };
        await updateTaskboxesV1TaskboxesTaskboxIdPut(event.id!, updateTaskbox);
        if (tasks.length === 0) setTaskDone(!taskDone);
        onChange(date!);
      }
    };

    /**태스크 박스 미루기 상세창 닫기 */
    const handleClosePostpone = () => {
      setSelectedTaskbox(undefined);
      setTaskboxPostpone(null);
    };

    /** 태스크박스 오늘로 옮기기 */
    const handleClickTomorrow = async () => {
      setTaskboxPostpone(null);
      const newDate = dayjs(date).add(1, 'day').format(DATE_FORMAT_4);
      const updateTaskbox: UpdateTaskbox = { start: { date: newDate }, end: { date: newDate } };
      const success = await updateTaskboxesV1TaskboxesTaskboxIdPut(event!.id!, updateTaskbox);
      if (success) {
        toast.success('업무를 내일로 옮겼습니다.');
        onChange(dayjs(date).add(1, 'day').toDate());

        setTimeout(() => {
          onChange(date!);
        }, 100);
      }
    };

    /**태스크박스 다음주로 옮기기 */
    const handleClickNextWeek = async () => {
      setTaskboxPostpone(null);
      const newDate = dayjs(date).add(1, 'week').day(1).format(DATE_FORMAT_4);
      const updateTaskbox: UpdateTaskbox = { start: { date: newDate }, end: { date: newDate } };
      const success = await updateTaskboxesV1TaskboxesTaskboxIdPut(event!.id!, updateTaskbox);
      if (success) {
        toast.success('업무를 다음주 월요일로 옮겼습니다.');
        onChange(dayjs(date).add(1, 'week').day(1).toDate());

        setTimeout(() => {
          onChange(date!);
        }, 100);
      }
    };

    /** 태스크박스 나중에로 옮기기 */
    const handleClickLater = async () => {
      setTaskboxPostpone(null);
      if (event && tasks.length === 0) {
        const createWork: CreateWork = {
          type: 'task',
          workSectionType: 'someday',
          id: event.id,
          content: event.title === '' ? '제목 없음' : event.title!,
        };
        const remove = await removeTimeboxV1TaskboxesTaskboxIdDelete(event.id!);
        const success = await createTaskV1WorksPost(createWork);
        if (remove && success) {
          toast.success('업무를 나중에로 옮겼습니다.');
          onChange(date!);
          fetchTasks();
        }
      } else if (event && tasks.length > 0) {
        await removeTimeboxV1TaskboxesTaskboxIdDelete(event.id!);
        tasks.map(async (item) => {
          const createWork: CreateWork = {
            type: 'task',
            workSectionType: 'someday',
            id: item.id,
            content: item.content === '' ? '제목 없음' : item.content!,
            dueDate: item.dueDate,
          };
          await createTaskV1WorksPost(createWork);
        });
        toast.success('업무를 나중에로 옮겼습니다.');
        onChange(date!);
        fetchTasks();
      }
    };

    /**태스크박스 특정 일자로 미루는 로직 */
    const handleClickCalendar = async (value: dayjs.Dayjs) => {
      setTaskboxPostpone(null);
      const newDate = dayjs(value).format(DATE_FORMAT_4);
      const updateTaskbox: UpdateTaskbox = { start: { date: newDate }, end: { date: newDate } };
      const success = await updateTaskboxesV1TaskboxesTaskboxIdPut(event!.id!, updateTaskbox);
      if (success) {
        toast.success(`업무를 ${dayjs(value).format(DATE_FORMAT_5)}로 옮겼습니다.`);
        onChange(date!);
        onChange(dayjs(value).toDate());
      }
    };

    /**태스크박스 드래그 시작될 때 로직(캘린더로 옮길 때) */
    const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
      onDragTaskboxImage(event!);
      const refTaskboard = ref as React.RefObject<HTMLDivElement>;
      if (!refTaskboard || !refTaskboard.current) {
        return;
      } else {
        const el = refTaskboard.current.querySelector('#taskbox-drag-image');
        e.dataTransfer.setDragImage(el!, 24, 24);
        if (event) setTaskboxDrag({ id: event.id, title: event.title!, data: event?.tasks });
      }
    };

    /**서브태스크 상세창 닫는 로직 */
    const handleCloseSubTaskDetail = () => {
      setSubTaskDetail(null);
    };

    /**서브태스크 나중에로 옮기는 로직 */
    const handleClickLaterSubtask = async (taskId: string) => {
      setSubTaskDetail(null);
      const task = tasks.find((item) => item.id === taskId);
      const createWork: CreateWork = {
        type: 'task',
        workSectionType: 'someday',
        id: task?.id,
        content: task?.content === '' ? '제목 없음' : task?.content,
      };

      removeTask(taskId);
      const remove = await detachTaskV1TaskboxesTaskboxIdTasksTaskIdDelete(event!.id!, taskId);
      const success = await createTaskV1WorksPost(createWork);
      if (remove && success) {
        toast.success('업무를 나중에로 옮겼습니다.');
        fetchTasks();
      }
    };

    /**서브태스크 삭제 로직  */
    const handleClickRemoveSubtask = async (taskId: string) => {
      setSubTaskDetail(null);
      removeTask(taskId);
      await detachTaskV1TaskboxesTaskboxIdTasksTaskIdDelete(event!.id!, taskId);

      const taskboxIndex = events.findIndex((itme) => itme.id === event!.id);
      const changeTasks = tasks.filter(({ id }) => id !== taskId);
      onAddTask(taskboxIndex, changeTasks);
    };

    /**태스크박스에서 캘린더로의 드래그 시작되는 것 알리는 로직  */
    const handleDragToCalendarStart = () => {
      // 드래그 핸들에서 드래그 시작 시 호출되는 콜백 함수
      setIsDragging(true);
    };

    /**태스크박스에서 캘린더로의 드래그 끝나는 것 알리는 로직 */
    const handleDragToCalendarEnd = () => {
      setIsDragging(false);
    };

    /**태스크박스 락인/락아웃 로직 */
    const handleLockInOutButton = async (lock: boolean) => {
      // let newTitle = event?.title;
      // if (!lock) {
      //   if (event?.title?.includes('🌟')) {
      //     newTitle = event.title;
      //   } else {
      //     newTitle = `🌟${event?.title}`;
      //   }
      // } else {
      //   if (event?.title?.includes('🌟')) {
      //     newTitle = event?.title!.slice(2);
      //   } else {
      //     newTitle = event?.title;
      //   }
      // }

      const success = await updateTaskboxesV1TaskboxesTaskboxIdPut(event!.id!, {
        // title: newTitle,
        lockedIn: !lock,
      });

      if (success && lock) {
        toast.success('구글 캘린더 고정 해제하였습니다.');
        onChange(date!);
      } else if (success && !lock) {
        toast.success('구글 캘린더에 고정하였습니다.');
        onChange(date!);
      }
    };

    const handleShowSubTask = () => {};

    /**태스크박스에 태스크 들어왔을 때 로직 */
    const handleDragEnterToTaskbox = () => {
      if (draggingTask?.type === 'task') setDraggingToTaskbox(true);
    };

    /**태스크박스에서 태스크 떠났을 때 로직 */
    const handleDragLeaveFromTaskbox = () => {
      setDraggingToTaskbox(false);
    };

    /**태스크를 태스크박스 안에 드랍하여 하위 태스크로 만드는 로직 */
    const handleDragDropToTaskbox = async (eventId: string) => {
      setDraggingToTaskbox(false);
      if (draggingTask && eventId) {
        const { id, type, title, data } = draggingTask;
        if (data) {
          const newData = data.map((item: AppApplicationTaskboxesSchemasOutTask) => item.id!);
          const subTaskList: MoveWorkToTaskbox = { workIdList: newData };
          await moveWorkToTaskboxV1WorksMoveTaskboxIdPost(eventId!, subTaskList);
          onChange(date!);
          fetchTasks();
        } else {
          const subTaskList: MoveWorkToTaskbox = { workIdList: [id!] };
          await moveWorkToTaskboxV1WorksMoveTaskboxIdPost(eventId!, subTaskList);
          onChange(date!);
          fetchTasks();
        }
      }
    };

    // const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    //   console.log('drag over');
    //   console.log(e.currentTarget);
    //   if (draggingTask) {
    //     setDragging(true);
    //   } else {
    //     setDragging(false);
    //   }
    // };

    /** 타임라인에서 할 일 목록으로 옮기기 위해 시간 없애는 로직 */
    const handleUnSelectTime = async (eventId: string) => {
      const updateTaskbox: UpdateTaskbox = { start: { date: dayjs(date).format(DATE_FORMAT_4) }, end: { date: dayjs(date).format(DATE_FORMAT_4) } };
      const success = await updateTaskboxesV1TaskboxesTaskboxIdPut(eventId, updateTaskbox);

      if (success) {
        toast.success('태스크박스를 수정하였습니다.');
        onChange(date!);
      }
    };

    /** 시간 없는 태스크박스 시간 부여 시 시간 초기 값 부여하는 로직 */
    const handleSetDefaultTime = () => {
      const defaultdate = dayjs(date);
      const minute = dayjs().format('mm');
      const newDate = dayjs().set('year', defaultdate.year()).set('month', defaultdate.month()).set('date', defaultdate.date()).set('second', 0);

      if (Number(minute) <= 15) {
        setDefaultTime(dayjs(newDate).set('minute', 15));
      } else if (Number(minute) <= 30) {
        setDefaultTime(dayjs(newDate).set('minute', 30));
      } else if (Number(minute) <= 45) {
        setDefaultTime(dayjs(newDate).set('minute', 45));
      } else {
        setDefaultTime(dayjs(newDate).add(1, 'hour').set('minute', 0));
      }
    };

    const focusInput = (blockId: string) => {
      setTimeout(() => {
        const el = document.querySelector(`[data-id="${blockId}"]`) as HTMLDivElement;
        const range = document.createRange();
        const selection = window.getSelection()!;
        range.selectNodeContents(el);
        range.setStart(el.childNodes[0], getCaret!);
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
      }, 50);
    };

    const handleClickTaskbox = (event: OutTaskboxDetailResponse) => {
      setShowSubtask(event);
      onClickTaskbox(event);
    };

    return (
      <>
        {event?.allDay ? (
          <>
            <Draggable draggableId={`${event?.id}`} index={index!}>
              {(provided, snapshot) => {
                const style = {
                  backgroundColor: snapshot.isDragging ? 'white' : '',
                  boxShadow: snapshot.isDragging ? `0px 8px 16px 0px rgba(26, 30, 39, 0.16)` : '',
                  borderRadius: snapshot.isDragging ? '0px 16px 16px 0px' : '',
                  marginBottom: '8px',
                  ...provided.draggableProps.style,
                };
                return (
                  <TaskboxDragContainer
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={style}
                    draggable={isDragging}
                    onDragStart={handleDragStart}
                    onDragEnter={handleDragEnterToTaskbox}
                    // onDragLeave={handleDragLeave}
                    // onDragOver={handleDragOver}
                    hidden={draggingToTaskbox}
                  >
                    <div ref={refTaskbox}>
                      <Tooltip title={'왼쪽으로 드래그해서 캘린더로 옮깁니다.'} disableInteractive>
                        <TaskboxDragToCalendarIcon
                          className="taskbox-drag-handle"
                          taskboxHeight={taskboxHeight}
                          onMouseDown={handleDragToCalendarStart}
                          onMouseUp={handleDragToCalendarEnd}
                        ></TaskboxDragToCalendarIcon>
                      </Tooltip>
                      <Tooltip title={'위아래로 드래그해서 순서를 변경합니다.'} disableInteractive>
                        <TaskboxDragHandleIcon {...provided.dragHandleProps} className="taskbox-drag-handle" taskboxHeight={taskboxHeight} />
                      </Tooltip>
                      {expanded || focusedTaskboxId?.id === event?.id ? (
                        <Droppable key={event?.id} droppableId={`${event?.id}`} type="task">
                          {(provided) => (
                            <TaskDragContainer
                              className="timeline-container"
                              key={event?.id}
                              data-scroll={`${event?.id}`}
                              event={event}
                              taskDone={taskDone}
                              selectedEvent={focusedTaskboxId}
                              plan={plan}
                              selectedTaskbox={selectedTaskbox}
                              onClick={(e) => {
                                if (e.target === e.currentTarget) {
                                  handleClickTaskbox(event);
                                }
                              }}
                            >
                              {event && (
                                <div className="flex gap-4 timeline-border">
                                  <div className="line" style={taskDone ? { backgroundColor: `${COLORS.gray300}` } : {}} />
                                  <div className="flex-1" style={{ pointerEvents: 'all' }}>
                                    <div className="flex justify-between items-start relative">
                                      {/* 타임박스 제목 */}
                                      <div className="timelineEvents-title-container">
                                        {/* <Icons.PriorityIssueCheck
                                  // stroke={event.plansWeekly && event.plansWeekly[0] ? COLORS.brand1 : COLORS.gray400}
                                  stroke={plan[0] && plan[0].priority! > 0 ? COLORS.negative1 : plan[0] && plan[0].id! ? COLORS.brand1 : COLORS.gray400}
                                /> */}
                                        <TaskboxIssueCheckbox
                                          checked={!!event.done}
                                          // onChange={(_, checked) => handleChangeIssue(issue.id, checked)}
                                          // onClick={(e) => onChangeIssue && e.stopPropagation()}
                                          onClick={handleClickTaskboxCheck}
                                          style={{ marginTop: '2px' }}
                                        />
                                        <TaskboxInputWrapper>
                                          {event.lockedIn && <LockInIcon event={event}></LockInIcon>}
                                          <TaskboxInput
                                            className="timelineEvents-title"
                                            data-id={`${event.id}`}
                                            onBlur={(e) => handleFocusoutTitle(e, event)}
                                            onKeyDown={(e) => handleTimelineKeyDown(e, event)}
                                            placeholder="Title"
                                            contentEditable={true}
                                            suppressContentEditableWarning={true}
                                            onClick={(e) => {
                                              if (e.target === e.currentTarget) {
                                                handleClickTaskbox(event);
                                              }
                                            }}
                                          >
                                            {event.title}
                                          </TaskboxInput>
                                        </TaskboxInputWrapper>

                                        {event.issueId && (
                                          <Button
                                            variant="outlined"
                                            color="inherit"
                                            onClick={() => handleClickIssueDetail(event.issueId!)}
                                            sx={{
                                              width: '100px',
                                              height: '24px',
                                              borderRadius: '8px',
                                              padding: '0px',
                                              color: `${COLORS.gray400}`,
                                              border: `1px solid ${COLORS.gray400}`,
                                            }}
                                          >
                                            <div className="timelineEvents-issue-detail">
                                              <IssueLinkage />
                                              <Fonts.H5 style={{ marginLeft: '4px' }}>이슈 상세보기</Fonts.H5>
                                            </div>
                                          </Button>
                                        )}
                                      </div>
                                      <div style={{ display: 'flex', alignItems: 'center', marginTop: '1px' }}>
                                        {event.tasks!.length > 0 && (
                                          <div className="show-subtask">
                                            <Icons.Subtask></Icons.Subtask>
                                            <span style={{ marginLeft: '4px', marginTop: '2px', fontSize: '13px', color: COLORS.gray500 }}>
                                              {event.tasks?.length}
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                      <TaskboxOptionsContainer
                                        className="taskbox-option"
                                        lockIn={event.lockedIn}
                                        style={selectedTaskbox ? { visibility: 'visible' } : {}}
                                      >
                                        <TaskboxOptionsContent>
                                          <TaskboxOptionsIcon>
                                            <Tooltip title="시간 지정하기" disableInteractive>
                                              <IconButton
                                                sx={{ borderRadius: '8px', padding: '5px' }}
                                                style={{ cursor: 'pointer' }}
                                                onClick={(e) => {
                                                  setSelectedTaskbox(event.id!);
                                                  setTimeSelection(e.currentTarget);
                                                  handleSetDefaultTime();
                                                }}
                                              >
                                                <Icons.SelectTime />
                                              </IconButton>
                                            </Tooltip>
                                          </TaskboxOptionsIcon>
                                          {event.id === selectedTaskbox && (
                                            <Popover
                                              open={Boolean(timeSelection)}
                                              anchorEl={timeSelection}
                                              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                              sx={{ marginTop: '4px' }}
                                              onClose={() => {
                                                setTimeSelection(null);
                                                setSelectedTaskbox(undefined);
                                              }}
                                            >
                                              <div style={{ width: '323px', height: '128px' }}>
                                                <TimeSelectionModal
                                                  onClose={() => {
                                                    setTimeSelection(null);
                                                    setSelectedTaskbox(undefined);
                                                  }}
                                                  onChangeTime={handleChangeTime}
                                                  start={dayjs(defaultTime).format(DATE_FORMAT_1)}
                                                  end={dayjs(defaultTime).add(1, 'hour').format(DATE_FORMAT_1)}
                                                  eventId={event.id}
                                                />
                                              </div>
                                            </Popover>
                                          )}
                                        </TaskboxOptionsContent>
                                        <TaskboxOptionsContent>
                                          <TaskboxOptionsIcon>
                                            <Tooltip title="미루기" disableInteractive sx={{ fontSize: '12px' }}>
                                              <IconButton
                                                sx={{ borderRadius: '8px', padding: '5px' }}
                                                style={{ cursor: 'pointer' }}
                                                onClick={(e) => {
                                                  setSelectedTaskbox(event.id!);
                                                  setTaskboxPostpone(e.currentTarget);
                                                }}
                                              >
                                                <Icons.Postpone />
                                              </IconButton>
                                            </Tooltip>
                                          </TaskboxOptionsIcon>
                                        </TaskboxOptionsContent>
                                        <TaskboxOptionsContent>
                                          <TaskboxOptionsIcon>
                                            <Tooltip title={event.lockedIn ? '구글 캘린더에서 해제하기' : '구글 캘린더에 표시하기'} disableInteractive>
                                              {event.lockedIn ? (
                                                <IconButton
                                                  sx={{ borderRadius: '8px', padding: '5px' }}
                                                  style={{ cursor: 'pointer' }}
                                                  onClick={() => handleLockInOutButton(event.lockedIn!)}
                                                >
                                                  <Icons.LockOut />
                                                </IconButton>
                                              ) : (
                                                <IconButton
                                                  sx={{ borderRadius: '8px', padding: '5px' }}
                                                  style={{ cursor: 'pointer' }}
                                                  onClick={() => handleLockInOutButton(event.lockedIn!)}
                                                >
                                                  <Icons.LockIn />
                                                </IconButton>
                                              )}
                                            </Tooltip>
                                          </TaskboxOptionsIcon>
                                        </TaskboxOptionsContent>
                                        <TaskboxOptionsContent>
                                          <TaskboxOptionsIcon>
                                            <Tooltip title="더보기" disableInteractive>
                                              <IconButton
                                                sx={{ borderRadius: '8px', padding: '5px' }}
                                                style={{ cursor: 'pointer' }}
                                                onClick={(e) => {
                                                  setSelectedTaskbox(event.id!);
                                                  setTaskboxDetail(e.currentTarget);
                                                }}
                                              >
                                                <Icons.More width={16} height={16} fill={'#C8CDDB'} />
                                              </IconButton>
                                            </Tooltip>
                                          </TaskboxOptionsIcon>
                                        </TaskboxOptionsContent>
                                      </TaskboxOptionsContainer>
                                      {event.id === selectedTaskbox && (
                                        <TaskboxPostponeDetail
                                          event={event}
                                          postpone={taskboxPostpone}
                                          onClosePostpone={handleClosePostpone}
                                          onClickTomorrow={handleClickTomorrow}
                                          onClickNextWeek={handleClickNextWeek}
                                          onClickLater={handleClickLater}
                                          onClickCalendar={handleClickCalendar}
                                        />
                                      )}
                                      {event.id === selectedTaskbox && (
                                        <TimelineTaskboxDetail
                                          event={event}
                                          detail={taskboxDetail}
                                          onCloseDetail={handleCloseTimelineDetail}
                                          onRemoveTimeline={handleRemoveTimeline}
                                        />
                                      )}
                                    </div>

                                    <div className={`${event.id} tasks-view`} {...provided.droppableProps} ref={provided.innerRef}>
                                      <SubtaskView
                                        onClick={() => {
                                          handleClickTaskbox(event);
                                        }}
                                      >
                                        <div className="flex-1" style={{ pointerEvents: 'all' }}>
                                          {event &&
                                            tasks?.map((task, index) => (
                                              <Draggable draggableId={task.id} index={index} key={task.id}>
                                                {(provided) => (
                                                  <EditableTextFieldWrapper
                                                    {...provided.draggableProps}
                                                    key={task.id}
                                                    className="flex justify-between items-center relative mt-1"
                                                    style={{ ...provided.draggableProps.style }}
                                                    ref={provided.innerRef}
                                                  >
                                                    <div className="flex mt-1 items-center box-border task-container">
                                                      <DragHandleIcon {...provided.dragHandleProps} className="drag-handle" />
                                                      <Checkbox checked={task.done ? true : false} onClick={() => handleCheckbox(index)} disabled={taskDone} />
                                                      <Taskbox
                                                        className="task flex items-center ml-2"
                                                        data-id={`${task.id}`}
                                                        data-timeboxid={event.id}
                                                        onBlur={(e) => handleSaveChangedTask(e, index)}
                                                        onKeyDown={(e) => handleTaskKeyDown(e, task.id!, index)}
                                                        placeholder="Title"
                                                        contentEditable={true}
                                                        suppressContentEditableWarning={true}
                                                        allTaskDone={taskDone}
                                                      >
                                                        {task.content}
                                                      </Taskbox>
                                                    </div>
                                                    <div
                                                      className="detail task-detail"
                                                      style={subTaskDetail && selectTaskId === task.id ? { opacity: 1 } : {}}
                                                      onClick={(e) => {
                                                        setSelectedTaskId(task.id);
                                                        setSubTaskDetail(e.currentTarget);
                                                      }}
                                                    >
                                                      <Icons.ColorMore />
                                                    </div>
                                                    {selectTaskId === task.id && (
                                                      <SubtaskDetail
                                                        taskId={task.id}
                                                        detail={subTaskDetail}
                                                        onCloseDetail={handleCloseSubTaskDetail}
                                                        onClickLaterSubtask={handleClickLaterSubtask}
                                                        onClickRemoveSubtask={handleClickRemoveSubtask}
                                                      ></SubtaskDetail>
                                                    )}
                                                  </EditableTextFieldWrapper>
                                                )}
                                              </Draggable>
                                            ))}
                                          {provided!.placeholder!}

                                          <SubtaskContainer data-timeboxid={event.id} className="flex gap-1 items-center mt-1.5">
                                            <div className="subtask" onClick={() => handleClickTaskAdd(event)}>
                                              <Icons.Plus width={16} height={16} fill={taskDone ? `${COLORS.gray400}` : `${COLORS.gray500}`} />
                                              <Fonts.Body2 className="add-task">하위업무 추가하기</Fonts.Body2>
                                            </div>
                                          </SubtaskContainer>
                                        </div>
                                      </SubtaskView>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </TaskDragContainer>
                          )}
                        </Droppable>
                      ) : (
                        <TaskDragContainer
                          className="timeline-container"
                          key={event?.id}
                          data-scroll={`${event?.id}`}
                          event={event}
                          taskDone={taskDone}
                          selectedEvent={focusedTaskboxId}
                          plan={plan}
                          onClick={(e) => {
                            if (e.target === e.currentTarget) {
                              handleClickTaskbox(event);
                            }
                          }}
                          selectedTaskbox={selectedTaskbox}
                        >
                          {event && (
                            <div
                              className="flex gap-4 timeline-border"
                              onClick={(e) => {
                                if (e.target === e.currentTarget) {
                                  handleClickTaskbox(event);
                                }
                              }}
                            >
                              <div className="line" style={taskDone ? { backgroundColor: `${COLORS.gray300}` } : {}} />
                              <div className="flex-1" style={{ pointerEvents: 'all' }}>
                                <div className=" flex justify-between items-center relative">
                                  {/* 타임박스 제목 */}
                                  <div className="timelineEvents-title-container">
                                    {/* <Icons.PriorityIssueCheck
                              // stroke={event.plansWeekly && event.plansWeekly[0] ? COLORS.brand1 : COLORS.gray400}
                              stroke={plan[0] && plan[0].priority! > 0 ? COLORS.negative1 : plan[0] && plan[0].id! ? COLORS.brand1 : COLORS.gray400}
                            /> */}

                                    <TaskboxIssueCheckbox
                                      checked={!!event.done}
                                      // onChange={(_, checked) => handleChangeIssue(issue.id, checked)}
                                      // onClick={(e) => onChangeIssue && e.stopPropagation()}
                                      onClick={handleClickTaskboxCheck}
                                      style={{ marginTop: '2px' }}
                                    />
                                    <TaskboxInputWrapper>
                                      {event.lockedIn && <LockInIcon event={event}></LockInIcon>}
                                      <div style={event.lockedIn ? { width: '456px' } : { width: '482px' }}>
                                        <TaskboxInput
                                          className="timelineEvents-title"
                                          data-id={`${event.id}`}
                                          onBlur={(e) => handleFocusoutTitle(e, event)}
                                          onKeyDown={(e) => handleTimelineKeyDown(e, event)}
                                          placeholder="Title"
                                          contentEditable={true}
                                          suppressContentEditableWarning={true}
                                          onClick={(e) => {
                                            if (e.target === e.currentTarget) {
                                              handleClickTaskbox(event);

                                              setGetCaret(window.getSelection()!.anchorOffset);
                                            }
                                          }}
                                          style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '100%' }}
                                        >
                                          {event.title}
                                        </TaskboxInput>
                                      </div>
                                    </TaskboxInputWrapper>

                                    {event.issueId && (
                                      <Button
                                        variant="outlined"
                                        color="inherit"
                                        onClick={() => handleClickIssueDetail(event.issueId!)}
                                        sx={{
                                          width: '100px',
                                          height: '24px',
                                          borderRadius: '8px',
                                          padding: '0px',
                                          color: `${COLORS.gray400}`,
                                          border: `1px solid ${COLORS.gray400}`,
                                        }}
                                      >
                                        <div className="timelineEvents-issue-detail">
                                          <IssueLinkage />
                                          <Fonts.H5 style={{ marginLeft: '4px' }}>이슈 상세보기</Fonts.H5>
                                        </div>
                                      </Button>
                                    )}
                                  </div>
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {event.tasks!.length > 0 && (
                                      <div className="show-subtask">
                                        <Icons.Subtask></Icons.Subtask>
                                        <span style={{ marginLeft: '4px', marginTop: '1px', fontSize: '13px', color: COLORS.gray500 }}>
                                          {event.tasks?.length}
                                        </span>
                                      </div>
                                    )}
                                  </div>

                                  <TaskboxOptionsContainer
                                    className="taskbox-option"
                                    lockIn={event.lockedIn}
                                    style={selectedTaskbox ? { visibility: 'visible' } : {}}
                                  >
                                    <TaskboxOptionsContent>
                                      <TaskboxOptionsIcon>
                                        <Tooltip title="시간 지정하기" disableInteractive>
                                          <IconButton
                                            sx={{ borderRadius: '8px', padding: '5px' }}
                                            style={{ cursor: 'pointer' }}
                                            onClick={(e) => {
                                              setSelectedTaskbox(event.id!);
                                              setTimeSelection(e.currentTarget);
                                              handleSetDefaultTime();
                                            }}
                                          >
                                            <Icons.SelectTime />
                                          </IconButton>
                                        </Tooltip>
                                      </TaskboxOptionsIcon>
                                      {event.id === selectedTaskbox && (
                                        <Popover
                                          open={Boolean(timeSelection)}
                                          anchorEl={timeSelection}
                                          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                          sx={{ marginTop: '4px' }}
                                          onClose={() => {
                                            setSelectedTaskbox(undefined);
                                            setTimeSelection(null);
                                          }}
                                        >
                                          <div style={{ width: '323px', height: '128px' }}>
                                            <TimeSelectionModal
                                              onClose={() => {
                                                setSelectedTaskbox(undefined);
                                                setTimeSelection(null);
                                              }}
                                              onChangeTime={handleChangeTime}
                                              start={dayjs(defaultTime).format(DATE_FORMAT_1)}
                                              end={dayjs(defaultTime).add(1, 'hour').format(DATE_FORMAT_1)}
                                              eventId={event.id}
                                            />
                                          </div>
                                        </Popover>
                                      )}
                                    </TaskboxOptionsContent>
                                    <TaskboxOptionsContent>
                                      <TaskboxOptionsIcon>
                                        <Tooltip title="미루기" disableInteractive sx={{ fontSize: '12px' }}>
                                          <IconButton
                                            sx={{ borderRadius: '8px', padding: '5px' }}
                                            style={{ cursor: 'pointer' }}
                                            onClick={(e) => {
                                              setSelectedTaskbox(event.id!);
                                              setTaskboxPostpone(e.currentTarget);
                                            }}
                                          >
                                            <Icons.Postpone />
                                          </IconButton>
                                        </Tooltip>
                                      </TaskboxOptionsIcon>
                                    </TaskboxOptionsContent>
                                    <TaskboxOptionsContent>
                                      <TaskboxOptionsIcon>
                                        <Tooltip title={event.lockedIn ? '구글 캘린더에서 해제하기' : '구글 캘린더에 표시하기'} disableInteractive>
                                          {event.lockedIn ? (
                                            <IconButton
                                              sx={{ borderRadius: '8px', padding: '5px' }}
                                              style={{ cursor: 'pointer' }}
                                              onClick={() => handleLockInOutButton(event.lockedIn!)}
                                            >
                                              <Icons.LockOut />
                                            </IconButton>
                                          ) : (
                                            <IconButton
                                              sx={{ borderRadius: '8px', padding: '5px' }}
                                              style={{ cursor: 'pointer' }}
                                              onClick={() => handleLockInOutButton(event.lockedIn!)}
                                            >
                                              <Icons.LockIn />
                                            </IconButton>
                                          )}
                                        </Tooltip>
                                      </TaskboxOptionsIcon>
                                    </TaskboxOptionsContent>
                                    <TaskboxOptionsContent>
                                      <TaskboxOptionsIcon>
                                        <Tooltip title="더보기" disableInteractive>
                                          <IconButton
                                            sx={{ borderRadius: '8px', padding: '5px' }}
                                            style={{ cursor: 'pointer' }}
                                            onClick={(e) => {
                                              setSelectedTaskbox(event.id!);
                                              setTaskboxDetail(e.currentTarget);
                                            }}
                                          >
                                            <Icons.More width={16} height={16} fill={'#C8CDDB'} />
                                          </IconButton>
                                        </Tooltip>
                                      </TaskboxOptionsIcon>
                                    </TaskboxOptionsContent>
                                  </TaskboxOptionsContainer>
                                  {event.id === selectedTaskbox && (
                                    <TaskboxPostponeDetail
                                      event={event}
                                      postpone={taskboxPostpone}
                                      onClosePostpone={handleClosePostpone}
                                      onClickTomorrow={handleClickTomorrow}
                                      onClickNextWeek={handleClickNextWeek}
                                      onClickLater={handleClickLater}
                                      onClickCalendar={handleClickCalendar}
                                    />
                                  )}
                                  {event.id === selectedTaskbox && (
                                    <TimelineTaskboxDetail
                                      event={event}
                                      detail={taskboxDetail}
                                      onCloseDetail={handleCloseTimelineDetail}
                                      onRemoveTimeline={handleRemoveTimeline}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </TaskDragContainer>
                      )}
                    </div>

                    {!!clickedIssue && plan && (
                      <Dialog open={true} sx={{ borderRadius: 8 }} PaperProps={{ style: { maxWidth: '100%' } }}>
                        <IssueDetailView
                          issueId={event!.issueId!}
                          selectable={true}
                          priority={plan.length > 0 ? plan[0].priority : undefined}
                          // timelineView={timelineView}
                          // tasks={selectedTasks}
                          onClickCancel={handleClickCancelIssue}
                          onClickSave={handleClickSaveIssue}
                        />
                      </Dialog>
                    )}
                  </TaskboxDragContainer>
                );
              }}
            </Draggable>

            <TaskDropContainer
              height={taskboxHeight}
              onDrop={() => handleDragDropToTaskbox(event.id!)}
              onDragLeave={handleDragLeaveFromTaskbox}
              hidden={!draggingToTaskbox}
            />
          </>
        ) : (
          <>
            {expanded || focusedTaskboxId?.id === event?.id ? (
              <Droppable key={event?.id} droppableId={`${event?.id}`} type="task">
                {(provided) => (
                  <TaskDragContainer
                    className="timeline-container"
                    key={event?.id}
                    data-scroll={`${event?.id}`}
                    event={event}
                    taskDone={taskDone}
                    selectedEvent={focusedTaskboxId}
                    plan={plan}
                    onDragEnter={handleDragEnterToTaskbox}
                    hidden={draggingToTaskbox}
                    ref={refTaskbox}
                    selectedTaskbox={selectedTaskbox}
                    onClick={(e) => {
                      if (e.target === e.currentTarget) {
                        handleClickTaskbox(event!);
                      }
                    }}
                  >
                    {event && (
                      <div className="flex gap-4 timeline-border">
                        <div className="line" style={taskDone ? { backgroundColor: `${COLORS.gray300}` } : {}} />
                        <div className="flex-1" style={{ pointerEvents: 'all' }}>
                          {/* 타임박스 시간 */}
                          <div
                            className="flex gap-1 items-center time-modal"
                            onClick={(e) => {
                              if (e.target === e.currentTarget) {
                                handleClickTaskbox(event);
                              }
                            }}
                          >
                            <div
                              className="time-modal-container"
                              onClick={(e) => {
                                setSelectedTaskbox(event.id!);
                                !!event.start?.datetime && setTimeSelection(e.currentTarget);
                              }}
                            >
                              {event.start?.datetime ? <Icons.Time fill={COLORS.gray500} /> : <Icons.Today />}
                              <Fonts.Blockquote className="text-gray500" style={{ marginLeft: '4px' }}>
                                {event.start?.datetime ? showingTime(event) : '오늘'}
                              </Fonts.Blockquote>
                            </div>

                            {/* 시간 선택 모달 */}
                            {event.id === selectedTaskbox && (
                              <Popover
                                open={Boolean(timeSelection)}
                                anchorEl={timeSelection}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                onClose={() => {
                                  setSelectedTaskbox(undefined), setTimeSelection(null);
                                }}
                              >
                                <div style={{ width: '323px', height: '128px' }}>
                                  <TimeSelectionModal
                                    onClose={() => {
                                      setSelectedTaskbox(undefined), setTimeSelection(null);
                                    }}
                                    onChangeTime={handleChangeTime}
                                    start={event.start!.datetime!}
                                    end={event.end!.datetime!}
                                    eventId={event.id}
                                  />
                                </div>
                              </Popover>
                            )}
                          </div>

                          <div className="flex justify-between items-start relative">
                            {/* 타임박스 제목 */}
                            <div className="timelineEvents-title-container">
                              <TaskboxIssueCheckbox
                                checked={!!event.done}
                                // onChange={(_, checked) => handleChangeIssue(issue.id, checked)}
                                // onClick={(e) => onChangeIssue && e.stopPropagation()}
                                onClick={handleClickTaskboxCheck}
                                style={{ marginTop: '2px' }}
                              />
                              <TaskboxInputWrapper>
                                {event.lockedIn && <LockInIcon event={event}></LockInIcon>}
                                <TaskboxInput
                                  className="timelineEvents-title"
                                  data-id={`${event.id}`}
                                  onBlur={(e) => handleFocusoutTitle(e, event)}
                                  onKeyDown={(e) => handleTimelineKeyDown(e, event)}
                                  placeholder="Title"
                                  contentEditable={true}
                                  suppressContentEditableWarning={true}
                                  onClick={(e) => {
                                    if (e.target === e.currentTarget) {
                                      handleClickTaskbox(event);
                                    }
                                  }}
                                >
                                  {event.title}
                                </TaskboxInput>
                              </TaskboxInputWrapper>

                              {event.issueId && (
                                <Button
                                  variant="outlined"
                                  color="inherit"
                                  onClick={() => {
                                    setClickedIssue(event.issueId!);
                                  }}
                                  sx={{
                                    width: '100px',
                                    height: '24px',
                                    borderRadius: '8px',
                                    padding: '0px',
                                    color: `${COLORS.gray400}`,
                                    border: `1px solid ${COLORS.gray400}`,
                                  }}
                                >
                                  <div className="timelineEvents-issue-detail">
                                    <IssueLinkage />
                                    <Fonts.H5 style={{ marginLeft: '4px' }}>이슈 상세보기</Fonts.H5>
                                  </div>
                                </Button>
                              )}
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              {event.tasks!.length > 0 && (
                                <div className="show-subtask">
                                  <Icons.Subtask></Icons.Subtask>
                                  <span style={{ marginLeft: '4px', marginTop: '2px', fontSize: '13px', color: COLORS.gray500 }}>{event.tasks?.length}</span>
                                </div>
                              )}
                            </div>
                            <TaskboxOptionsContainer
                              className="taskbox-option"
                              lockIn={event.lockedIn}
                              style={selectedTaskbox ? { visibility: 'visible' } : {}}
                            >
                              <TaskboxOptionsContent>
                                <TaskboxOptionsIcon>
                                  <Tooltip title="시간 해제하기" disableInteractive>
                                    <IconButton
                                      sx={{ borderRadius: '8px', padding: '5px' }}
                                      style={{ cursor: 'pointer' }}
                                      onClick={(e) => {
                                        handleUnSelectTime(event.id!);
                                      }}
                                    >
                                      <Icons.UnselectTime />
                                    </IconButton>
                                  </Tooltip>
                                </TaskboxOptionsIcon>
                              </TaskboxOptionsContent>
                              <TaskboxOptionsContent>
                                <TaskboxOptionsIcon>
                                  <Tooltip title="미루기" disableInteractive sx={{ fontSize: '12px' }}>
                                    <IconButton
                                      sx={{ borderRadius: '8px', padding: '5px' }}
                                      style={{ cursor: 'pointer' }}
                                      onClick={(e) => {
                                        setSelectedTaskbox(event.id!);
                                        setTaskboxPostpone(e.currentTarget);
                                      }}
                                    >
                                      <Icons.Postpone />
                                    </IconButton>
                                  </Tooltip>
                                </TaskboxOptionsIcon>
                              </TaskboxOptionsContent>
                              <TaskboxOptionsContent>
                                <TaskboxOptionsIcon>
                                  <Tooltip title={event.lockedIn ? '구글 캘린더에서 해제하기' : '구글 캘린더에 표시하기'} disableInteractive>
                                    {event.lockedIn ? (
                                      <IconButton
                                        sx={{ borderRadius: '8px', padding: '5px' }}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => handleLockInOutButton(event.lockedIn!)}
                                      >
                                        <Icons.LockOut />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        sx={{ borderRadius: '8px', padding: '5px' }}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => handleLockInOutButton(event.lockedIn!)}
                                      >
                                        <Icons.LockIn />
                                      </IconButton>
                                    )}
                                  </Tooltip>
                                </TaskboxOptionsIcon>
                              </TaskboxOptionsContent>
                              <TaskboxOptionsContent>
                                <TaskboxOptionsIcon>
                                  <Tooltip title="더보기" disableInteractive>
                                    <IconButton
                                      sx={{ borderRadius: '8px', padding: '5px' }}
                                      style={{ cursor: 'pointer' }}
                                      onClick={(e) => {
                                        setSelectedTaskbox(event.id!);
                                        setTaskboxDetail(e.currentTarget);
                                      }}
                                    >
                                      <Icons.More width={16} height={16} fill={'#C8CDDB'} />
                                    </IconButton>
                                  </Tooltip>
                                </TaskboxOptionsIcon>
                              </TaskboxOptionsContent>
                            </TaskboxOptionsContainer>
                            {event.id === selectedTaskbox && (
                              <TaskboxPostponeDetail
                                event={event}
                                postpone={taskboxPostpone}
                                onClosePostpone={handleClosePostpone}
                                onClickTomorrow={handleClickTomorrow}
                                onClickNextWeek={handleClickNextWeek}
                                onClickLater={handleClickLater}
                                onClickCalendar={handleClickCalendar}
                              />
                            )}
                            {event.id === selectedTaskbox && (
                              <TimelineTaskboxDetail
                                event={event}
                                detail={taskboxDetail}
                                onCloseDetail={handleCloseTimelineDetail}
                                onRemoveTimeline={handleRemoveTimeline}
                              />
                            )}
                          </div>

                          <div className={`${event.id} tasks-view`} {...provided.droppableProps} ref={provided.innerRef}>
                            <SubtaskView
                              onClick={() => {
                                handleClickTaskbox(event);
                              }}
                            >
                              <div className="flex-1" style={{ pointerEvents: 'all' }}>
                                {event &&
                                  tasks?.map((task, index) => (
                                    <Draggable draggableId={task.id} index={index} key={task.id}>
                                      {(provided) => (
                                        <EditableTextFieldWrapper
                                          {...provided.draggableProps}
                                          key={task.id}
                                          className="flex justify-between items-center relative mt-1"
                                          style={{ ...provided.draggableProps.style }}
                                          ref={provided.innerRef}
                                        >
                                          <div className="flex mt-1 items-center box-border task-container">
                                            <DragHandleIcon {...provided.dragHandleProps} className="drag-handle" />
                                            <Checkbox checked={task.done ? true : false} onClick={() => handleCheckbox(index)} disabled={taskDone} />
                                            <Taskbox
                                              className="task flex items-center ml-2"
                                              data-id={`${task.id}`}
                                              data-timeboxid={event.id}
                                              onBlur={(e) => handleSaveChangedTask(e, index)}
                                              onKeyDown={(e) => handleTaskKeyDown(e, task.id!, index)}
                                              placeholder="Title"
                                              contentEditable={true}
                                              suppressContentEditableWarning={true}
                                              allTaskDone={taskDone}
                                            >
                                              {task.content}
                                            </Taskbox>
                                          </div>
                                          <div
                                            className="detail task-detail"
                                            style={subTaskDetail && selectTaskId === task.id ? { opacity: 1 } : {}}
                                            onClick={(e) => {
                                              setSelectedTaskId(task.id);
                                              setSubTaskDetail(e.currentTarget);
                                            }}
                                          >
                                            <Icons.ColorMore />
                                          </div>
                                          {selectTaskId === task.id && (
                                            <SubtaskDetail
                                              taskId={task.id}
                                              detail={subTaskDetail}
                                              onCloseDetail={handleCloseSubTaskDetail}
                                              onClickLaterSubtask={handleClickLaterSubtask}
                                              onClickRemoveSubtask={handleClickRemoveSubtask}
                                            ></SubtaskDetail>
                                          )}
                                        </EditableTextFieldWrapper>
                                      )}
                                    </Draggable>
                                  ))}
                                {provided!.placeholder!}

                                <SubtaskContainer data-timeboxid={event.id} className="flex gap-1 items-center mt-1.5">
                                  <div className="subtask" onClick={() => handleClickTaskAdd(event)}>
                                    <Icons.Plus width={16} height={16} fill={taskDone ? `${COLORS.gray400}` : `${COLORS.gray500}`} />
                                    <Fonts.Body2 className="add-task">하위업무 추가하기</Fonts.Body2>
                                  </div>
                                </SubtaskContainer>
                              </div>
                            </SubtaskView>
                          </div>
                        </div>
                      </div>
                    )}
                    {!!clickedIssue && plan && (
                      <Dialog open={true} sx={{ borderRadius: 8 }} PaperProps={{ style: { maxWidth: '100%' } }}>
                        <IssueDetailView
                          issueId={event!.issueId!}
                          selectable={true}
                          priority={plan.length > 0 ? plan[0].priority : undefined}
                          sectionTitle={sectionTitle ? sectionTitle : '섹션 없음'}
                          // timelineView={timelineView}
                          // tasks={selectedTasks}
                          onClickCancel={handleClickCancelIssue}
                          onClickSave={handleClickSaveIssue}
                        />
                      </Dialog>
                    )}
                  </TaskDragContainer>
                )}
              </Droppable>
            ) : (
              <TaskDragContainer
                className="timeline-container"
                key={event?.id}
                data-scroll={`${event?.id}`}
                event={event}
                taskDone={taskDone}
                selectedEvent={focusedTaskboxId}
                plan={plan}
                style={{ position: 'relative' }}
                onDragEnter={handleDragEnterToTaskbox}
                hidden={draggingToTaskbox}
                ref={refTaskbox}
                selectedTaskbox={selectedTaskbox}
                onClick={(e) => {
                  if (e.target === e.currentTarget) {
                    handleClickTaskbox(event!);
                  }
                }}
              >
                {event && (
                  <div className="flex gap-4 timeline-border">
                    <div className="line" style={taskDone ? { backgroundColor: `${COLORS.gray300}` } : {}} />
                    <div className="flex-1" style={{ pointerEvents: 'all' }}>
                      <div
                        className="flex gap-1 items-center time-modal"
                        onClick={(e) => {
                          if (e.target === e.currentTarget) {
                            handleClickTaskbox(event);
                          }
                        }}
                      >
                        <div
                          className="time-modal-container"
                          onClick={(e) => {
                            setSelectedTaskbox(event.id!);
                            !!event.start?.datetime && setTimeSelection(e.currentTarget);
                          }}
                        >
                          {event.start?.datetime ? <Icons.Time fill={COLORS.gray500} /> : <Icons.Today />}
                          <Fonts.Blockquote className="text-gray500" style={{ marginLeft: '4px' }}>
                            {event.start?.datetime ? showingTime(event) : '오늘'}
                          </Fonts.Blockquote>
                        </div>

                        {/* 시간 선택 모달 */}
                        {event.id === selectedTaskbox && (
                          <Popover
                            open={Boolean(timeSelection)}
                            anchorEl={timeSelection}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                            onClose={() => {
                              setSelectedTaskbox(undefined), setTimeSelection(null);
                            }}
                          >
                            <div style={{ width: '323px', height: '128px' }}>
                              <TimeSelectionModal
                                onClose={() => {
                                  setSelectedTaskbox(undefined), setTimeSelection(null);
                                }}
                                onChangeTime={handleChangeTime}
                                start={event.start!.datetime!}
                                end={event.end!.datetime!}
                                eventId={event.id}
                              />
                            </div>
                          </Popover>
                        )}
                      </div>

                      <div className="flex justify-between items-center relative">
                        {/* 타임박스 제목 */}
                        <div className="timelineEvents-title-container">
                          {/* <Icons.PriorityIssueCheck
                              // stroke={event.plansWeekly && event.plansWeekly[0] ? COLORS.brand1 : COLORS.gray400}
                              stroke={plan[0] && plan[0].priority! > 0 ? COLORS.negative1 : plan[0] && plan[0].id! ? COLORS.brand1 : COLORS.gray400}
                            /> */}

                          <TaskboxIssueCheckbox
                            checked={!!event.done}
                            // onChange={(_, checked) => handleChangeIssue(issue.id, checked)}
                            // onClick={(e) => onChangeIssue && e.stopPropagation()}
                            onClick={handleClickTaskboxCheck}
                            style={{ marginTop: '2px' }}
                          />
                          <TaskboxInputWrapper>
                            {event.lockedIn && <LockInIcon event={event}></LockInIcon>}
                            <div style={event.lockedIn ? { width: '456px' } : { width: '482px' }}>
                              <TaskboxInput
                                className="timelineEvents-title"
                                data-id={`${event.id}`}
                                onBlur={(e) => handleFocusoutTitle(e, event)}
                                onKeyDown={(e) => handleTimelineKeyDown(e, event)}
                                placeholder="Title"
                                contentEditable={true}
                                suppressContentEditableWarning={true}
                                onClick={(e) => {
                                  if (e.target === e.currentTarget) {
                                    handleClickTaskbox(event);

                                    setGetCaret(window.getSelection()!.anchorOffset);
                                  }
                                }}
                                style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '100%' }}
                              >
                                {event.title}
                              </TaskboxInput>
                            </div>
                          </TaskboxInputWrapper>

                          {event.issueId && (
                            <Button
                              variant="outlined"
                              color="inherit"
                              onClick={() => handleClickIssueDetail(event.issueId!)}
                              sx={{
                                width: '100px',
                                height: '24px',
                                borderRadius: '8px',
                                padding: '0px',
                                color: `${COLORS.gray400}`,
                                border: `1px solid ${COLORS.gray400}`,
                              }}
                            >
                              <div className="timelineEvents-issue-detail">
                                <IssueLinkage />
                                <Fonts.H5 style={{ marginLeft: '4px' }}>이슈 상세보기</Fonts.H5>
                              </div>
                            </Button>
                          )}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {event.tasks!.length > 0 && (
                            <div className="show-subtask">
                              <Icons.Subtask></Icons.Subtask>
                              <span style={{ marginLeft: '4px', marginTop: '1px', fontSize: '13px', color: COLORS.gray500 }}>{event.tasks?.length}</span>
                            </div>
                          )}
                        </div>
                        <TaskboxOptionsContainer className="taskbox-option" lockIn={event.lockedIn} style={selectedTaskbox ? { visibility: 'visible' } : {}}>
                          <TaskboxOptionsContent>
                            <TaskboxOptionsIcon>
                              <Tooltip title="시간 해제하기" disableInteractive>
                                <IconButton
                                  sx={{ borderRadius: '8px', padding: '5px' }}
                                  style={{ cursor: 'pointer' }}
                                  onClick={(e) => {
                                    handleUnSelectTime(event.id!);
                                  }}
                                >
                                  <Icons.UnselectTime />
                                </IconButton>
                              </Tooltip>
                            </TaskboxOptionsIcon>
                          </TaskboxOptionsContent>
                          <TaskboxOptionsContent>
                            <TaskboxOptionsIcon>
                              <Tooltip title="미루기" disableInteractive sx={{ fontSize: '12px' }}>
                                <IconButton
                                  sx={{ borderRadius: '8px', padding: '5px' }}
                                  style={{ cursor: 'pointer' }}
                                  onClick={(e) => {
                                    setSelectedTaskbox(event.id!);
                                    setTaskboxPostpone(e.currentTarget);
                                  }}
                                >
                                  <Icons.Postpone />
                                </IconButton>
                              </Tooltip>
                            </TaskboxOptionsIcon>
                          </TaskboxOptionsContent>
                          <TaskboxOptionsContent>
                            <TaskboxOptionsIcon>
                              <Tooltip title={event.lockedIn ? '구글 캘린더에서 해제하기' : '구글 캘린더에 표시하기'} disableInteractive>
                                {event.lockedIn ? (
                                  <IconButton
                                    sx={{ borderRadius: '8px', padding: '5px' }}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleLockInOutButton(event.lockedIn!)}
                                  >
                                    <Icons.LockOut />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    sx={{ borderRadius: '8px', padding: '5px' }}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleLockInOutButton(event.lockedIn!)}
                                  >
                                    <Icons.LockIn />
                                  </IconButton>
                                )}
                              </Tooltip>
                            </TaskboxOptionsIcon>
                          </TaskboxOptionsContent>
                          <TaskboxOptionsContent>
                            <TaskboxOptionsIcon>
                              <Tooltip title="더보기" disableInteractive>
                                <IconButton
                                  sx={{ borderRadius: '8px', padding: '5px' }}
                                  style={{ cursor: 'pointer' }}
                                  onClick={(e) => {
                                    setSelectedTaskbox(event.id!);
                                    setTaskboxDetail(e.currentTarget);
                                  }}
                                >
                                  <Icons.More width={16} height={16} fill={'#C8CDDB'} />
                                </IconButton>
                              </Tooltip>
                            </TaskboxOptionsIcon>
                          </TaskboxOptionsContent>
                        </TaskboxOptionsContainer>
                        {event.id === selectedTaskbox && (
                          <TaskboxPostponeDetail
                            event={event}
                            postpone={taskboxPostpone}
                            onClosePostpone={handleClosePostpone}
                            onClickTomorrow={handleClickTomorrow}
                            onClickNextWeek={handleClickNextWeek}
                            onClickLater={handleClickLater}
                            onClickCalendar={handleClickCalendar}
                          />
                        )}
                        {event.id === selectedTaskbox && (
                          <TimelineTaskboxDetail
                            event={event}
                            detail={taskboxDetail}
                            onCloseDetail={handleCloseTimelineDetail}
                            onRemoveTimeline={handleRemoveTimeline}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {!!clickedIssue && plan && (
                  <Dialog open={true} sx={{ borderRadius: 8 }} PaperProps={{ style: { maxWidth: '100%' } }}>
                    <IssueDetailView
                      issueId={event!.issueId!}
                      selectable={true}
                      priority={plan.length > 0 ? plan[0].priority : undefined}
                      sectionTitle={sectionTitle ? sectionTitle : '섹션 없음'}
                      // timelineView={timelineView}
                      // tasks={selectedTasks}
                      onClickCancel={handleClickCancelIssue}
                      onClickSave={handleClickSaveIssue}
                    />
                  </Dialog>
                )}
              </TaskDragContainer>
            )}

            <TaskDropContainer
              height={taskboxHeight}
              onDrop={() => handleDragDropToTaskbox(event!.id!)}
              onDragLeave={handleDragLeaveFromTaskbox}
              hidden={!draggingToTaskbox}
            />
          </>
        )}
      </>
    );
  },
);

const TaskboxDragContainer = styled.div`
  position: relative;
  :hover {
    .taskbox-drag-handle {
      opacity: 0.4;
    }
  }
`;

const TaskDragContainer = styled.div<{
  event?: OutTaskboxDetailResponse;
  taskDone?: boolean;
  selectedEvent?: OutTaskboxDetailResponse;
  plan: OutPlanWeeklyBrief[];
  focusedTaskboxId?: string;
  selectedTaskbox?: string;
}>`
  width: 100%;
  border: 1px solid ${COLORS.gray200};
  border-radius: 8px;
  padding: 8px 12px;
  background-color: ${COLORS.white};
  pointer-events: all;
  margin-bottom: 8px;

  ${(props) =>
    props.selectedTaskbox === props.event?.id &&
    css`
      border: 1px solid ${COLORS.gray300};
      border-radius: 8px;
      box-shadow: 1px 1px 20px 1px ${COLORS.gray200};
    `}

  ${(props) =>
    props.focusedTaskboxId === props.event?.id &&
    css`
      border: 1px solid ${COLORS.sub2};
      border-radius: 8px;
    `}

  ${(props) =>
    props.selectedEvent?.id === props.event?.id &&
    css`
      border: 1px solid ${COLORS.sub2};
      border-radius: 8px;
    `}

  ${(props) =>
    props.selectedEvent?.id === props.event?.id &&
    props.event?.done &&
    css`
      border: 1px solid ${COLORS.gray300};
      border-radius: 8px;
    `}

  ${(props) =>
    props.plan[0]?.id &&
    props.selectedEvent?.id === props.event?.id &&
    css`
      border: 1px solid ${COLORS.brand1};
      border-radius: 2px 8px 8px 2px;
    `}

  ${(props) =>
    props.plan[0]?.priority &&
    props.plan[0].priority > 0 &&
    props.selectedEvent?.id === props.event?.id &&
    css`
      background-color: ${COLORS.white};
      border: 1px solid transparent;
      background: linear-gradient(#fff 0 0) padding-box, linear-gradient(180.02deg, #c471ed 1.78%, #f64f59 97.94%) border-box;
      border-radius: 2px 8px 8px 2px;
    `}

  :hover {
    ${(props) =>
      !(props.selectedEvent?.id === props.event?.id) &&
      css`
        border: 1px solid ${COLORS.gray300};
        box-shadow: 1px 1px 20px 1px ${COLORS.gray200};
      `}

    .taskbox-option {
      visibility: visible;
    }
  }

  .line {
    width: 4px;
    background-color: ${COLORS.brand1};
    border-radius: 4px;

    ${(props) =>
      props.plan[0]?.id &&
      css`
        background-color: ${COLORS.brand1};
      `}

    ${(props) =>
      props.plan[0]?.priority &&
      props.plan[0]?.priority > 0 &&
      css`
        background: linear-gradient(180.02deg, #c471ed 1.78%, #f64f59 97.94%);
      `}
  }

  .show-subtask {
    display: flex;
    align-items: center;
  }

  .timelineEvents-background {
    padding-right: 20px;
  }

  .time-modal-container {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    cursor: pointer;
  }

  .timelineEvents-title-container {
    display: flex;
    width: 100%;
  }

  .timelineEvents-issue-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .timelineEvents-detail-text {
    height: 34px;
    padding-top: 8px;
    padding-left: 16px;
    &:hover {
      background-color: ${COLORS.sub3};
    }
  }

  .timelineEvents-title {
    width: 100%;
    ${(props) =>
      props.taskDone === true &&
      css`
        color: ${COLORS.gray500};
      `}

    ${(props) =>
      props.taskDone === true &&
      css`
        text-decoration: line-through;
      `}
  }

  .remove-timelineEvents {
    position: absolute;
    top: 40%;
    left: 38%;
    z-index: 210;
  }

  .add-task {
    margin-top: 2px;
    margin-left: 4px;
    color: ${COLORS.gray500};
    ${(props) =>
      props.taskDone === true &&
      css`
        color: ${COLORS.gray400};
      `}
  }

  .tasks-view {
    margin-left: 2px;
  }
`;

const TaskboxInputWrapper = styled.div`
  display: flex;
  margin-left: 8px;
  width: 100%;
`;

const LockInIcon = styled.div<{ event?: OutTaskboxDetailResponse }>`
  margin-right: 4px;
  margin-top: 4px;
  width: 16px;
  height: 16px;

  ${(props) =>
    !props.event?.done &&
    css`
      background-image: url('${require('assets/images/Star.png')}');
      background-position: center;
      background-size: cover;
    `}

  ${(props) =>
    props.event?.done &&
    css`
      background-image: url('${require('assets/images/LockInDone.png')}');
      background-position: center;
      background-size: cover;
      opacity: 50%;
    `}
`;

const TaskboxInput = styled.div`
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.25px;
  margin-right: 8px;

  &:empty {
    content: attr(placeholder);
    color: ${COLORS.gray500};
    &:before {
      content: attr(placeholder);
    }
  }
`;

const SubtaskView = styled.div`
  width: 100%;

  .event-background {
    padding-right: 20px;
  }

  .detail {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    opacity: 0;
  }

  .event-detail-text {
    height: 34px;
    padding-top: 8px;
    padding-left: 16px;
    &:hover {
      background-color: ${COLORS.sub3};
    }
  }

  .remove-event {
    position: absolute;
    top: 40%;
    left: 38%;
    z-index: 210;
  }
`;

const Taskbox = styled.div<{ allTaskDone?: boolean }>`
  max-width: 460px;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.25px;
  color: ${COLORS.gray900};
  cursor: text;
  &:empty {
    content: attr(placeholder);
    color: ${COLORS.gray500};
    &:before {
      content: attr(placeholder);
    }
  }

  ${(props) =>
    props.allTaskDone &&
    css`
      color: ${COLORS.gray500};
      text-decoration: line-through;
    `}
`;

const EditableTextFieldWrapper = styled.div`
  width: 100%;
  position: relative;
  word-break: break-all;
  cursor: 'text';

  .task-container {
    width: 100%;
  }

  .task {
    width: 100%;
  }

  :hover {
    .drag-handle {
      opacity: 0.4;
    }
    .detail {
      opacity: 1;
    }
  }
`;

const TaskboxDragHandleIcon = styled.span<{ taskboxHeight?: number }>`
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  width: 19px;
  height: 22px;
  border-radius: 6px;
  top: ${(props) => props.taskboxHeight! / 2 + 1}px;
  left: -21px;
  background-image: url('https://s2.svgbox.net/materialui.svg?ic=swap_vert');
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center center;
  :hover {
    background-color: ${COLORS.gray300};
  }
`;

const TaskboxDragToCalendarIcon = styled.span<{ taskboxHeight?: number; isDragging?: boolean }>`
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  width: 19px;
  height: 22px;
  border-radius: 6px;
  top: ${(props) => props.taskboxHeight! / 2 - 22}px;
  left: -21px;
  cursor: grab;
  background-image: url('https://s2.svgbox.net/materialui.svg?ic=arrow_back');
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center center;
  :hover {
    background-color: ${COLORS.gray300};
  }
`;

const DragHandleIcon = styled.span`
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  left: -16px;
  width: 12px;
  height: 16px;
  background-image: url('https://s2.svgbox.net/materialui.svg?ic=drag_indicator');
  background-repeat: no-repeat;
  background-size: 130%;
`;

const TaskboxOptionsContainer = styled.div<{ lockIn?: boolean }>`
  position: absolute;
  border: 1px solid ${COLORS.gray200};
  display: flex;
  border-radius: 8px;
  padding: 1px;
  visibility: hidden;
  z-index: 50;
  background-color: ${COLORS.white};
  right: 0px;
  top: -3.5px;
`;

const TaskboxOptionsContent = styled.div`
  position: relative;
  .taskbox-tooltip {
    z-index: 1000;
  }
`;

const TaskboxOptionsIcon = styled.div`
  :hover ~ .taskbox-tooltip {
    visibility: visible;
  }
`;

const TaskboxOptionsTooltip = styled.div`
  position: absolute;
  font-size: 12px;
  color: ${COLORS.white};
  background-color: ${COLORS.gray900};
  padding: 7px 12px;
  border-radius: 4px;
  right: -13px;
  top: 30px;
  text-align: center;
  visibility: hidden;
`;

const SubtaskContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .subtask {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .subtask-count {
    display: flex;
    align-items: center;
  }
`;

const TaskDropContainer = styled.div<{ height?: number }>`
  width: 100%;
  height: ${(props) => props.height}px;
  background-color: ${COLORS.sub5};
  border: 1px dashed ${COLORS.sub4};
  border-radius: 8px;
  margin-bottom: 8px;
`;
