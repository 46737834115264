/** Stage 코드 */
// import { Outlet, useLocation } from 'react-router-dom';
// import PlanWeekly from 'pages/Plan/Weekly';
// import PlanPlanning from 'pages/Plan/Planning';

// export const Plan = () => {
//   const location = useLocation();
//   const planViewEntered = localStorage.getItem('planning-view-entered');
//   return <>{location.pathname === '/plan' ? planViewEntered ? <PlanWeekly /> : <PlanPlanning /> : <Outlet />}</>;
// };

// export default Plan;

/** Beta 코드 */
import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { Icons } from 'components';
import Fonts from 'components/Fonts';
import { COLORS } from 'styles/constants';

export const Plan = () => {
  return (
    <Container>
      <LoungeWrapper>
        <LoungeMain>
          <LoungeIcon>
            <Icons.PlanBeta width={170} height={170} />
          </LoungeIcon>

          <LoungeMainContent>
            <MainContentHeader>
              <Fonts.H4>준비중</Fonts.H4>
            </MainContentHeader>
            <MainContentMain>
              <Icons.DoubleQuotesL width={22} height={22} style={{ marginRight: '6px' }} />
              <Fonts.H1>일주일은 인생의 축소판이다</Fonts.H1>
              <Icons.DoubleQuotesR width={22} height={22} style={{ marginLeft: '6px' }} />
            </MainContentMain>
            <MainContentFooter>
              <Fonts.Body1>
                플랜에서는 일주일의 업무를 계획하고
                <br />
                우선순위를 정할 수 있습니다.
              </Fonts.Body1>
            </MainContentFooter>
          </LoungeMainContent>
        </LoungeMain>
        <LoungeFooter>
          <Fonts.Body1>현재 겪는 불편이 있거나 제안하고 싶은 기능이 있다면 제안해주세요.</Fonts.Body1>
          <Button
            variant="contained"
            href="https://bit.ly/slash_survey_plan"
            target="_blank"
            sx={{
              backgroundColor: `${COLORS.brand1}`,
              borderRadius: '8px',
              width: '81px',
              marginTop: '12px',
            }}
          >
            제안하기
          </Button>
        </LoungeFooter>
      </LoungeWrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  background-color: ${COLORS.gray100};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LoungeWrapper = styled.div`
  height: 460px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const LoungeMain = styled.div`
  width: 700px;
  height: 200px;
  display: flex;
  justify-content: center;
  position: relative;
`;

const LoungeIcon = styled.div`
  position: absolute;
  left: 60px;
`;

const LoungeMainContent = styled.div`
  position: absolute;
  left: 265px;
`;

const MainContentHeader = styled.div`
  color: ${COLORS.brand1};
  width: fit-content;
  background-color: ${COLORS.sub3};
  padding: 4px 12px;
  border-radius: 100px;
  margin-left: 30px;
`;

const MainContentMain = styled.div`
  display: flex;
  margin-top: 17px;
  margin-bottom: 12px;
`;

const MainContentFooter = styled.div`
  margin-left: 30px;
`;

const LoungeFooter = styled.div`
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${COLORS.gray600};
`;

export default Plan;
