import { saveToken, removeTokens, saveWorkspaceToken, getToken } from 'utils/token';
import { useNavigate } from 'react-router-dom';
import { createWorkspaceV1AuthWorkspacesPost, getUserMeV1UsersMeGet, listWorkspacesV1AuthWorkspacesGet, userMeV1AuthMeGet } from 'queries';
import { saveItemToSession, WORKSPACE_ID } from 'utils/storage';
import { useAtom } from 'jotai';
import { meAtom, userAtom, workspacesAtom } from 'atoms/user';
import toast from 'react-hot-toast';

const useAuthAction = () => {
  const navigate = useNavigate();
  const [me, setMe] = useAtom(meAtom);
  const [, setUser] = useAtom(userAtom);
  const [, setWorkspaces] = useAtom(workspacesAtom);

  const isAuthenticated = () => me.name && !!getToken();

  const login = async (token: string) => {
    try {
      saveToken(token);

      const [me, workspaces] = await Promise.all([userMeV1AuthMeGet(), listWorkspacesV1AuthWorkspacesGet()]);
      setMe(me);

      if (workspaces.length) {
        saveItemToSession(WORKSPACE_ID, workspaces[0].id);
        saveWorkspaceToken(workspaces[0].accessToken || '');

        const user = await getUserMeV1UsersMeGet();
        setUser(user);
        setWorkspaces(workspaces);
        navigate('/task'); // TODO: To home
      } else {
        const createWorkspace = {
          name: me.name!,
        };
        const success = await createWorkspaceV1AuthWorkspacesPost(createWorkspace);
        if (success) toast.success('새로운 워크스페이스를 생성하였습니다.');
        else toast.error('새로운 워크스페이스 생성에 실패하였습니다.');
        navigate('/task');
      }
    } catch (e) {
      removeTokens();
      console.log(e);
      navigate('/signIn');
    }
  };

  const reLogin = async () => {
    try {
      const [me, workspaces] = await Promise.all([userMeV1AuthMeGet(), listWorkspacesV1AuthWorkspacesGet()]);
      setMe(me);

      if (workspaces.length) {
        saveItemToSession(WORKSPACE_ID, workspaces[0].id);
        saveWorkspaceToken(workspaces[0].accessToken || '');

        const user = await getUserMeV1UsersMeGet();
        setUser(user);
        setWorkspaces(workspaces);
      } else {
        const createWorkspace = {
          name: me.name!,
        };
        const success = await createWorkspaceV1AuthWorkspacesPost(createWorkspace);
        if (success) toast.success('새로운 워크스페이스를 생성하였습니다.');
        else toast.error('새로운 워크스페이스 생성에 실패하였습니다.');
        navigate('/task');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const logout = () => {
    removeTokens();
    redirectLogin();
  };

  const redirectLogin = () => window.location.replace(`${window.location.origin}/signIn`);

  return {
    login,
    logout,
    reLogin,
    redirectLogin,
    isAuthenticated,
  };
};

export { useAuthAction };
