import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import styled from '@emotion/styled';
import { COLORS } from 'styles/constants';
import { v4 as uuidv4 } from 'uuid';
import { setCaretToEnd } from 'utils';
import { Icons } from 'components';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { isEqual } from 'lodash';
import TaskTag from 'components/TaskTag';
import dayjs from 'lib/dayjs';
import { StaticDatePicker } from '@mui/x-date-pickers';
import { Checkbox, IconButton, Popover, TextField } from '@mui/material';
import InboxContextMenuPopover, { InboxContextMenuType } from 'components/InboxContextMenuPopover';
import { createTaskV1WorksPost } from 'queries';

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const TaskBlockInputListContainer = styled.div`
  width: 100%;
  max-height: 240px;
  overflow: hidden;
  overflow-y: auto;
  padding-left: 22px;
`;

const BlockAddArea = styled.div`
  display: flex;
  align-items: center;
  padding-left: 22px;
`;

const EditableTextFieldWrapper = styled.div`
  position: relative;
  word-break: break-all;
  cursor: 'text';

  .task-more-btn {
    opacity: 0;
  }

  :hover {
    .task-more-btn {
      opacity: 1;
    }
    .drag-handle {
      opacity: 0.4;
    }
  }
`;

const DragHandleIcon = styled.span<{ isHover?: boolean }>`
  position: absolute;
  opacity: ${(props) => (props.isHover ? 0.4 : 0)};
  transition: opacity 0.3s ease-in-out;
  left: -16px;
  width: 12px;
  height: 16px;
  background-image: url('https://s2.svgbox.net/materialui.svg?ic=drag_indicator');
  background-repeat: no-repeat;
  background-size: 130%;
`;

const EditableTextField = styled.div`
  width: 100%;
  display: inline-block;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 13px;
  overflow-wrap: anywhere;
`;

const TruncateTaskTag = styled(TaskTag)`
  max-width: 120px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CalendarInitButton = styled.button`
  margin-top: -1px;
  width: 100%;
  height: 48px;
  font-size: 12px;
  color: ${COLORS.gray500};

  :hover {
    color: ${COLORS.gray900};
  }
`;

const TaskMoreButtonWrapper = styled.div`
  display: flex;
  opacity: 0;
  transition: opacity 0.3s ease-out;
`;

export type TaskBlock = {
  id: string;
  content?: string;
  dueDate?: string;
  done?: boolean;
  doneAt?: string;
  meetingNoteId?: string;
  meetingNoteTitle?: string;
};

export interface TaskBlockHandle {
  add: () => void;
}

export interface TaskBlockInputListProps {
  tasks?: TaskBlock[];
  draggable?: boolean;
  suppressKeyEvent?: boolean;
  suppressModification?: boolean;
  suppressCheckboxToggle?: boolean;
  suppressVisibleTag?: boolean;
  done?: boolean;
  onChange?: (tasks: TaskBlock[]) => void;
}

const TaskBlockInputList = (props: TaskBlockInputListProps, ref: any) => {
  const {
    tasks = [],
    draggable = true,
    suppressKeyEvent = false,
    suppressModification = false,
    suppressCheckboxToggle = false,
    suppressVisibleTag = false,
    done = false,
    onChange,
  } = props;
  const [blocks, setBlocks] = useState<TaskBlock[]>([]);
  const [clickedTask, setClickedTask] = useState<TaskBlock | undefined>();
  const [taskCalendarPopover, setTaskCalendarPopover] = useState<HTMLElement | null>(null);
  const [contextMenuPopover, setContextMenuPopover] = useState<HTMLElement | null>();

  useImperativeHandle(ref, () => ({
    add: () => {
      const block = createBlock();
      insertBlock(block);
      setFocusElement(block.id);
    },
  }));

  useEffect(() => {
    const taskList = tasks.map((item) => ({ ...item, id: item.id }));
    if (isEqual(tasks, blocks)) {
      taskList.forEach((task) => {
        const index = blocks.findIndex((block) => block.id === task.id);
        if (index !== -1) blocks[index] = { ...task, id: task.id, content: task.content || '' };
      });
    } else {
      setBlocks(tasks.map((item) => ({ ...item, id: item.id, content: item.content || '' })));
    }
  }, [tasks]);

  const updateBlocks = (blocks: TaskBlock[] = []) => {
    setBlocks(blocks);
    onChange && onChange(blocks);
  };

  const createBlock = (content?: any): TaskBlock => ({
    id: uuidv4(),
    content: content || '',
    done: false,
  });

  const insertBlock = (block: TaskBlock, index = -1) => {
    if (suppressModification) return;

    if (index === -1) {
      updateBlocks([...blocks, block]);
    } else {
      const newBlocks = blocks.slice(0);
      newBlocks.splice(index, 0, block);
      updateBlocks(newBlocks);
    }
  };

  const deleteBlock = (blockId: string) => {
    if (suppressModification) return;

    const newBlocks = blocks.filter(({ id }) => id !== blockId);
    updateBlocks(newBlocks);
  };

  const swapBlock = (srcBlock: TaskBlock, dstBlock: TaskBlock) => {
    if (!srcBlock) return;
    if (!dstBlock) return;

    const dstBlockIndex = blocks.findIndex((item) => item.id === dstBlock.id);
    if (dstBlockIndex === -1) return;

    const newBlocks = blocks.filter((item) => item.id !== srcBlock.id);
    newBlocks.splice(dstBlockIndex, 0, srcBlock);

    updateBlocks([...newBlocks]);
  };

  const updateBlockData = (id: string, data: { content?: string; dueDate?: string; done?: boolean }) => {
    const blockIndex = blocks.map(({ id }) => id).indexOf(id);
    if (blockIndex === -1) return;

    blocks[blockIndex] = { ...blocks[blockIndex], ...data };
  };

  const setFocusElement = (blockId: string) => {
    setTimeout(() => {
      const el = document.querySelector(`[data-field-id="${blockId}"]`) as HTMLDivElement;
      el && setCaretToEnd(el);
    }, 50);
  };

  const handleClickAdd = () => {
    const block = createBlock();
    insertBlock(block);
    setFocusElement(block.id);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>, blockId: string, index: number) => {
    if (suppressKeyEvent) {
      if (e.key === 'Enter') e.preventDefault();
      return;
    }

    const text = e.currentTarget.textContent || '';
    if (e.key === 'Backspace') {
      if (text.length !== 0) return;
      if (e.repeat) {
        e.preventDefault();
        return;
      }

      const nextBlock = blocks[index === 0 ? index + 1 : index - 1];
      if (nextBlock) setFocusElement(nextBlock.id);

      const dstBlock = blocks.find(({ id }) => id === blockId);
      if (dstBlock) deleteBlock(dstBlock.id);

      e.preventDefault();
      return;
    }

    if (e.key === 'Enter') {
      if (e.nativeEvent.isComposing) return;
      if (e.repeat) {
        e.preventDefault();
        return;
      }

      const block = createBlock();
      const realIndex = blocks.map(({ id }) => id).indexOf(blockId);

      e.currentTarget.blur();
      insertBlock(block, realIndex + 1);
      setFocusElement(block.id);
      e.preventDefault();
      return;
    }

    if (e.key === 'ArrowDown') {
      const blockIndex = index >= blocks.length - 1 ? blocks.length - 1 : index + 1;
      setFocusElement(blocks[blockIndex].id);
      e.preventDefault();
      return;
    }

    if (e.key === 'ArrowUp') {
      const blockIndex = index <= 0 ? 0 : index - 1;
      setFocusElement(blocks[blockIndex].id);
      e.preventDefault();
      return;
    }
  };

  const handleChangeBlock = (e: React.FormEvent<HTMLDivElement>, id: string) => {
    updateBlockData(id, { content: `${e.currentTarget.innerText || ''}` });
  };

  const handleKeydownAddButton = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (suppressKeyEvent) return;
    if (e.key !== 'Enter') return;
    if (e.nativeEvent.isComposing) return;
    if (e.repeat) {
      e.preventDefault();
      return;
    }

    const block = createBlock();
    insertBlock(block);
    setFocusElement(block.id);
    e.preventDefault();
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    if (result.source.index === result.destination.index) return;

    const srcBlock = blocks.find((item) => item.id === result.draggableId);
    if (!srcBlock) return;

    const dstBlock = blocks[result.destination.index];
    if (!dstBlock) return;

    swapBlock(srcBlock, dstBlock);
  };

  const handleBlur = () => {
    onChange && onChange(blocks);
  };

  const handleCloseTaskCalendarPopover = () => {
    setClickedTask(undefined);
    setTaskCalendarPopover(null);
  };

  const handleClickDueDate = (e: React.MouseEvent<HTMLSpanElement>, task: TaskBlock) => {
    setClickedTask(task);
    setTaskCalendarPopover(e.currentTarget);
  };

  const handleChangeDueDate = (value: Date | null) => {
    if (!clickedTask) return;
    const task = clickedTask;
    const dueDate = value ? (dayjs.isDayjs(value) ? value.format('YYYY-MM-DD') : dayjs(value).format('YYYY-MM-DD')) : null;

    if (task.dueDate !== dueDate) {
      updateBlockData(task.id, { dueDate: dueDate as any });
    }

    setClickedTask(undefined);
    setTaskCalendarPopover(null);
    onChange && onChange(blocks);
  };

  const handleChangeDone = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const newBlocks = tasks.map((item) => (item.id === id ? { ...item, done: e.currentTarget.checked } : item));
    onChange && onChange(newBlocks);
  };

  const handleClickMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setContextMenuPopover(e.currentTarget);
  };

  const handleClickContextMenu = async (id: string, type: string, menu: InboxContextMenuType) => {
    switch (menu) {
      case 'DELETE':
        deleteBlock(id);
        // await removeTaskV1WorksWorkIdDelete(id);
        // toast.success('태스크를 삭제하였습니다.');
        break;
      case 'MOVE_TO_AFTER': {
        const block = blocks.find((item) => item.id === id);
        if (!block) return;

        await createTaskV1WorksPost({ id: uuidv4(), type: 'task', workSectionType: 'someday', content: block?.content || '' });
        deleteBlock(id);
        break;
      }
    }
    setContextMenuPopover(null);
  };

  return (
    <Container>
      <TaskBlockInputListContainer>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId={'task-list'}>
            {(provided) => (
              <div className={'task-list'} {...provided.droppableProps} ref={provided.innerRef}>
                {blocks.map(({ id, content, done: taskDone, dueDate, meetingNoteTitle }, idx) => (
                  <Draggable draggableId={id} index={idx} key={id} isDragDisabled={!draggable}>
                    {(provided) => (
                      <EditableTextFieldWrapper
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        style={{ ...provided.draggableProps.style, display: 'flex', flexDirection: 'column', marginBottom: 8 }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {draggable && <DragHandleIcon {...provided.dragHandleProps} className="drag-handle" />}
                          <Checkbox sx={{ padding: 0 }} checked={!!taskDone} disabled={suppressCheckboxToggle} onChange={(e) => handleChangeDone(e, id)} />
                          <EditableTextField
                            style={{ marginLeft: 8, color: taskDone ? COLORS.gray500 : COLORS.gray800, textDecoration: taskDone ? 'line-through' : 'none' }}
                            tabIndex={0}
                            contentEditable={true}
                            suppressContentEditableWarning={true}
                            data-field-id={`${id}`}
                            onKeyDown={(e) => handleKeyDown(e, id, idx)}
                            onInput={(e) => handleChangeBlock(e, id)}
                            onMouseEnter={(e) => e.stopPropagation()}
                            onBlur={handleBlur}
                            dangerouslySetInnerHTML={{ __html: content || '' }}
                          />
                          <TaskMoreButtonWrapper className="task-more-btn">
                            <IconButton aria-label="more" sx={{ background: 'white', padding: 0 }} size="small" onClick={handleClickMenu} data-task-id={id}>
                              <Icons.ColorMore />
                            </IconButton>
                          </TaskMoreButtonWrapper>
                        </div>
                        {!suppressVisibleTag && (
                          <div style={{ display: 'flex', alignItems: 'center', marginTop: 2, marginLeft: 24 }}>
                            {meetingNoteTitle && <TruncateTaskTag style={{ marginRight: 8 }}>{meetingNoteTitle}</TruncateTaskTag>}
                            {dueDate && (
                              <TaskTag onClick={(e) => handleClickDueDate(e, blocks[idx])}>
                                <Icons.Flag />
                                {dayjs(dueDate).format('MM월 DD일')}
                              </TaskTag>
                            )}
                          </div>
                        )}
                      </EditableTextFieldWrapper>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </TaskBlockInputListContainer>
      {!suppressModification && (
        <BlockAddArea onKeyDown={(e) => handleKeydownAddButton(e)}>
          <div onClick={() => handleClickAdd()} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <span style={{ minWidth: 16, minHeight: 16, marginRight: 8 }}>
              <Icons.Plus width={16} height={16} fill={done ? `${COLORS.gray400}` : `${COLORS.gray500}`} />
            </span>
            <span style={{ fontSize: 13, color: done ? COLORS.gray400 : COLORS.gray500 }}>{`하위 업무 추가하기`}</span>
          </div>
        </BlockAddArea>
      )}
      {taskCalendarPopover && (
        <Popover
          open={Boolean(taskCalendarPopover)}
          anchorEl={taskCalendarPopover}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          sx={{ marginTop: 0.5 }}
          onClose={handleCloseTaskCalendarPopover}
        >
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            value={new Date(clickedTask?.dueDate || '')}
            onChange={handleChangeDueDate}
            renderInput={(params) => <TextField {...params} />}
          />
          <CalendarInitButton onClick={() => handleChangeDueDate(null)}>지정 안함</CalendarInitButton>
        </Popover>
      )}
      {contextMenuPopover && (
        <InboxContextMenuPopover
          id={contextMenuPopover.getAttribute('data-task-id') || ''}
          type={'task'}
          open={Boolean(contextMenuPopover)}
          anchorEl={contextMenuPopover}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          sx={{ marginTop: 0.5, marginLeft: 2 }}
          hiddenMenuItems={['SWITCH_TO_ISSUE', 'SWITCH_TO_TASK', 'MOVE_TO_THIS_WEEK', 'MOVE_TO_NEXT_WEEK']}
          onClose={() => setContextMenuPopover(null)}
          onClickMenu={handleClickContextMenu}
        />
      )}
    </Container>
  );
};

export default forwardRef<TaskBlockHandle, TaskBlockInputListProps>(TaskBlockInputList);
