import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import Toaster from 'components/Toast';
import styled from '@emotion/styled';
import GNB from 'components/GNB';
import { getToken } from 'utils/token';
import { useAtom } from 'jotai';
import { meAtom } from 'atoms/user';
import { useEffect, useState } from 'react';
import { useAuthAction } from 'hooks/useAuthAction';

// const SignIn = lazy(() => import(/* webpackChunkName: "sign-in" */ './pages/SignIn'));
// const Home = lazy(() => import(/* webpackChunkName: "home" */ './pages/Home'));
// const Meeting = lazy(() => import(/* webpackChunkName: "meeting" */ './pages/Meeting'));
// const Task = lazy(() => import(/* webpackChunkName: "task" */ './pages/Task'));
// const TaskToday = lazy(() => import(/* webpackChunkName: "task-today" */ './pages/Task/Today'));
// const TaskWeek = lazy(() => import(/* webpackChunkName: "task-week" */ './pages/Task/Week'));
// const DailyTask = lazy(() => import(/* webpackChunkName: "daily-task" */ './pages/DailyTask'));
// const JoinWorkspace = lazy(() => import(/* webpackChunkName: "join-workspace" */ './pages/JoinWorkspace'));
// const NotFound = lazy(() => import(/* webpackChunkName: "404" */ './pages/NotFound'));

import SignIn from './pages/SignIn';
import Home from './pages/Home';
import Meeting from './pages/Meeting';
import Task from './pages/Task';
import TaskToday from './pages/Task/Today';
import TaskWeek from './pages/Task/Week';
import PlanWeekly from './pages/Plan/Weekly';
import PlanPlanning from './pages/Plan/Planning';
import Plan from './pages/Plan';
import JoinWorkspace from './pages/JoinWorkspace';
import Auth from 'pages/Auth';
import BetaLounge from 'pages/Beta/Lounge';
import BetaGraph from 'pages/Beta/Graph';
import BetaPlan from 'pages/Beta/Plan';

const Layout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const MeneLayout = styled.div`
  width: 80px;
`;

const PageLayout = styled.div`
  width: calc(100% - 80px);
  height: 100%;
  display: flex;
`;

const AppLayout = () => {
  const [me] = useAtom(meAtom);
  const token = getToken();
  const { reLogin } = useAuthAction();
  const [isAuthChecked, setIsAuthChecked] = useState(false);

  useEffect(() => {
    (async () => {
      if (token) await reLogin();
      setIsAuthChecked(true);
    })();
  }, []);

  if (!isAuthChecked) return <></>;
  return me.name && token ? (
    <>
      <Layout>
        <MeneLayout>
          <GNB />
        </MeneLayout>
        <PageLayout>
          <Outlet />
        </PageLayout>
      </Layout>
    </>
  ) : (
    <Navigate to="/signIn" />
  );
};

const App = () => {
  return (
    <>
      <Toaster />
      <BrowserRouter>
        <Routes>
          {process.env.REACT_APP_MODE === 'production' && (
            <Route element={<AppLayout />}>
              <Route path="/task" element={<Task />}>
                <Route path="today" element={<TaskToday />} />
                <Route path="week" element={<TaskWeek />} />
              </Route>
              <Route path="/plan" element={<BetaPlan />} />
              <Route path="/lounge" element={<BetaLounge />} />
              <Route path="/graph" element={<BetaGraph />} />
            </Route>
          )}
          {process.env.REACT_APP_MODE === 'development' && (
            <Route element={<AppLayout />}>
              <Route path="/home" element={<Home />} />
              <Route path="/meeting" element={<Meeting />} />
              <Route path="/task" element={<Task />}>
                <Route path="today" element={<TaskToday />} />
                <Route path="week" element={<TaskWeek />} />
              </Route>
              <Route path="/plan" element={<Plan />}>
                <Route path="weekly" element={<PlanWeekly />} />
                <Route path="planning" element={<PlanPlanning />} />
              </Route>
            </Route>
          )}
          <Route path="/signIn" element={<SignIn isSignIn={true} />} />
          <Route path="/signUp" element={<SignIn isSignIn={false} />} />
          <Route path="/joinWorkspace" element={<JoinWorkspace />} />
          <Route path="/auth" element={<Auth />} />
          <Route path="/" element={<Navigate to="/task" />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
