import { SVGProps } from 'react';

export const Flag = ({ fill = '#656871', ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0.999674 11.9993C0.810786 11.9993 0.652341 11.9353 0.524341 11.8073C0.396786 11.6798 0.333008 11.5216 0.333008 11.3327V1.33268C0.333008 1.14379 0.396786 0.985349 0.524341 0.857349C0.652341 0.729794 0.810786 0.666016 0.999674 0.666016H5.78301C5.93856 0.666016 6.07745 0.716016 6.19967 0.816016C6.3219 0.916016 6.39967 1.04379 6.43301 1.19935L6.59967 1.99935H9.66634C9.85523 1.99935 10.0135 2.06313 10.141 2.19068C10.269 2.31868 10.333 2.47713 10.333 2.66602V7.99935C10.333 8.18824 10.269 8.34646 10.141 8.47402C10.0135 8.60202 9.85523 8.66602 9.66634 8.66602H6.21634C6.06079 8.66602 5.9219 8.61601 5.79967 8.51602C5.67745 8.41601 5.59967 8.28824 5.56634 8.13268L5.39967 7.33268H1.66634V11.3327C1.66634 11.5216 1.60256 11.6798 1.47501 11.8073C1.34701 11.9353 1.18856 11.9993 0.999674 11.9993ZM6.76634 7.33268H8.99967V3.33268H5.49967L5.23301 1.99935H1.66634V5.99935H6.49967L6.76634 7.33268Z"
        fill={fill}
      />
    </svg>
  );
};
