// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useState } from 'react';
import { Icons } from 'components';
import { useNavigate } from 'react-router-dom';
import { ColorLink } from '../Icons';
import { OutTimeboxWithTasks } from 'queries/model';
import { assignTaskV1TimeboxesTimeboxIdTasksTaskIdPost, createTaskV1TasksPost, removeTaskV1TasksTaskIdDelete, updateTaskV1TasksTaskIdPatch } from 'queries';
import toast from 'react-hot-toast';
import { COLORS } from 'styles/constants';
import Fonts from 'components/Fonts';
import { setCaretToEnd } from 'utils';

// TODO: 파일 삭제 예정

const Wrapper = styled.div<{ checked: boolean }>`
  display: flex;
  border: 1px solid ${COLORS.gray200};
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: ${COLORS.white};
  padding: 4px 14px 4px 16px;
  margin-top: 16px;
  margin-bottom: 8px;
  cursor: pointer;
  ${(props) =>
    props.checked &&
    css`
      background-color: ${COLORS.gray100};
    `}

  .task-title {
    max-width: 160px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    &:focus {
      text-overflow: initial;
    }
    &:empty {
      content: attr(placeholder);
      color: ${COLORS.gray500};
      &:before {
        content: attr(placeholder);
      }
    }
  }

  .option-modal {
    position: absolute;
    padding: 16px;
    width: 112px;
    z-index: 10;
    right: 16px;
    top: 48px;
    background-color: ${COLORS.white};
    box-shadow: 0px 8px 16px rgba(26, 30, 39, 0.16);
    border-radius: 8px;
  }

  .timebox-modal {
    text-align: center;
    position: absolute;
    width: 250px;
    padding: 10px;
    z-index: 10;
    right: 26px;
    top: 48px;
    background-color: ${COLORS.white};
    box-shadow: 0px 8px 16px rgba(26, 30, 39, 0.16);
    border-radius: 8px;
    max-height: 220px;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }
  }

  .timebox-modal-title {
    height: 40px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    &:hover {
      background-color: ${COLORS.sub3};
    }
  }
`;

const StyledInput = styled.input<{ defaultChecked: boolean }>`
  appearance: none;
  margin: 0px;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 1px solid ${COLORS.brand1};
  cursor: pointer;

  ${(props) =>
    props.defaultChecked &&
    css`
      border: none;
      background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
      background-size: 100% 100%;
      background-position: 50%;
      background-repeat: no-repeat;
      background-color: ${COLORS.gray300};
    `}
`;

interface Props {
  task: any;
  // onClick: (event: any, task: TaskType) => void;
  loadTimeBoxes: () => void;
  initialize: () => void;
  timeboxes: Timeboxes[] | null;
  // targetDate: string;
  link?: boolean;
}

interface Timeboxes extends OutTimeboxWithTasks {
  event?: boolean;
}

export const TaskListItem = ({ task, loadTimeBoxes, initialize, timeboxes }: Props) => {
  const [visibleOptionModal, setVisibleOptionModal] = useState(false);
  const [visibleCalendarModal, setVisibleCalendarModal] = useState(false);
  const [visibleTimeboxModal, setVisibleTimeboxModal] = useState(false);
  const navigate = useNavigate();
  const [{ x, y }, setPosition] = useState({
    x: 0,
    y: 0,
  });

  const onToggleDone = async (event: any, taskId: string, task: any) => {
    console.log(task);
    task.done = !task.done;
    await updateTaskV1TasksTaskIdPatch(taskId, task!);
    // const params = { date: `${targetDate}T00:00:00+09:00` };
    if (task.isLinked) loadTimeBoxes();
    initialize();
  };

  const onRemoveTask = async (task: any) => {
    if (task.meetingNoteId) {
      toast.error('회의록 관련 업무는 삭제할 수 없습니다.');
    } else {
      const remove = await removeTaskV1TasksTaskIdDelete(task.id!);
      initialize();
      if (task.isLinked) loadTimeBoxes();
      if (remove) {
        toast.success('업무를 삭제하였습니다.');
      } else {
        toast.error('업무 삭제 실패하였습니다.');
      }
    }
  };

  const onSaveTask = async (e: any, taskId: string, task: any) => {
    const content = e.currentTarget.textContent;
    const targetTask = { ...task, content };
    const success = await updateTaskV1TasksTaskIdPatch(taskId, targetTask);
    if (task.isLinked) loadTimeBoxes();
    if (success) {
      toast.success('업무를 수정하였습니다.');
    } else {
      toast.error('업무 수정 실패하였습니다.');
    }
    initialize();
  };

  const onCreateTask = async (e: any) => {
    const content = e.currentTarget.textContent;
    const targetTask = { content: content };
    const success = await createTaskV1TasksPost(targetTask);
    if (success) toast.success('업무를 생성하였습니다.');
    else toast.error('업무 생성 실패하였습니다.');
    initialize();
  };

  const onBlur = async (e: any) => {
    const content = e.currentTarget.textContent;
    if (task.content !== content) {
      onSaveTask(e, task.id, task);
    }
    scrollBack(e);
  };

  const assignTask = async (timebox: Timeboxes, taskId: string, prevId?: string, indent?: number) => {
    const requestBody = {
      prevId: prevId,
      indent: indent,
    };
    if (timebox.title === '') {
      toast.error('타임박스를 먼저 생성해주세요.');
    } else {
      const assign = await assignTaskV1TimeboxesTimeboxIdTasksTaskIdPost(timebox.id, taskId, requestBody);
      loadTimeBoxes();
      initialize();
      if (assign) {
        toast.success('성공');
        loadTimeBoxes();
        console.log(timeboxes);
        initialize();
      }
    }
    setVisibleTimeboxModal(!visibleTimeboxModal);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const content = e.currentTarget.textContent;
    if (e.nativeEvent.isComposing === false && e.key === 'Enter') {
      e.preventDefault();
      if (task.content !== content) {
        onSaveTask(e, task.id, task);
        setFocusElement(task.id);
      }
    }
  };
  const setFocusElement = (taskId: string, time?: number) => {
    setTimeout(
      () => {
        const el = document.querySelector(`[data-id="${taskId}"]`) as HTMLDivElement;
        el && setCaretToEnd(el);
      },
      time ? time : 50,
    );
  };

  const scrollBack = (el: any) => {
    el.target.scrollLeft = 0;
    console.log(el.scrollLeft);
  };

  return (
    <Wrapper
      // style={{ transform: `translateX(${x}px) translateY(${y}px)` }}
      checked={!!task.doneAt}
      className={'relative'}
      // onClick={(event) => onClick(event, task)}
      onMouseDown={(clickEvent: any) => {
        const mouseMoveHandler = (moveEvent: any) => {
          const deltaX = moveEvent.screenX - clickEvent.screenX;
          const deltaY = moveEvent.screenY - clickEvent.screenY;
          setPosition({ x: x + deltaX, y: y + deltaY });
        };
        const mouseUpHandler = () => {
          document.removeEventListener('mousemove', mouseMoveHandler);
          // onRemoveTask(task);
        };
        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler, { once: true });
      }}
    >
      <div className="mr-2 w-4 h-4">
        <StyledInput type="checkbox" defaultChecked={!!task.doneAt} onClick={(event) => onToggleDone(event, task.id!, task)} />
      </div>
      {/* 컨텐츠 */}
      <div className="flex-1">
        <Fonts.Badge style={{ color: `${COLORS.gray500}` }}>{task.meetingNoteTitle ? task.meetingNoteTitle : '개인 업무'}</Fonts.Badge>
        <div className="flex gap-1 items-center ">
          <Fonts.Body2
            data-id={task.id}
            className="task-title text-gray900"
            contentEditable={true}
            suppressContentEditableWarning={true}
            placeholder="Title"
            onBlur={(e: any) => {
              scrollBack(e);
              onBlur(e);
            }}
            onKeyDown={onKeyDown}
          >
            {task.content}
          </Fonts.Body2>
          {task.isLinked ? <ColorLink /> : null}
        </div>
        <Fonts.Badge className="text-gray500 ">{task.dueDate ? `${task.dueDate}까지` : null}</Fonts.Badge>
      </div>
      <div className="flex items-center">
        {timeboxes && timeboxes.length > 0 ? (
          <Fonts.Blockquote className="mr-2" onClick={() => setVisibleTimeboxModal(!visibleTimeboxModal)}>
            타임박스
            {visibleTimeboxModal && (
              <div className="timebox-modal">
                {timeboxes!
                  .sort((a: any, b: any) => (a.startTime.toLowerCase() < b.startTime.toLowerCase() ? -1 : 1))
                  .map((timebox) => (
                    <div className="timebox-modal-title" key={timebox.id} onClick={() => assignTask(timebox, task.id)}>
                      <div> {timebox.title ? timebox.title : '생성중인 타임박스'}</div>
                    </div>
                  ))}
              </div>
            )}
          </Fonts.Blockquote>
        ) : null}

        <div onClick={() => setVisibleOptionModal(!visibleOptionModal)}>
          <Icons.ColorMore />
          {visibleOptionModal && (
            <div className="option-modal">
              <Fonts.Blockquote className="text-gray600 pb-4 cursor-pointer" onClick={() => setVisibleCalendarModal(!visibleCalendarModal)}>
                날짜 수정
              </Fonts.Blockquote>
              <Fonts.Blockquote className="text-gray600 pb-4 cursor-pointer" onClick={() => navigate(`/meeting?id=${task.meetingNoteId}`)}>
                회의록으로 이동
              </Fonts.Blockquote>
              <Fonts.Blockquote
                className="text-gray600 cursor-pointer"
                onClick={() => {
                  onRemoveTask(task);
                }}
              >
                삭제
              </Fonts.Blockquote>
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
};
