import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ReactComponent as Home } from 'assets/images/icons/menus/home.svg';
import { ReactComponent as Meeting } from 'assets/images/icons/menus/meeting.svg';
import { ReactComponent as Work } from 'assets/images/icons/menus/work.svg';
import { ReactComponent as Plan } from 'assets/images/icons/menus/plan.svg';
import { ReactComponent as Stats } from 'assets/images/icons/menus/stats.svg';
import { ReactComponent as Lounge } from 'assets/images/icons/menus/lounge.svg';
import { Link } from 'react-router-dom';

const MenuItemWrapper = styled.div<{ active?: boolean }>`
  width: 72px;
  height: 70px;
  margin: 8px 0px 8px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  &:hover {
    filter: ${(props) =>
      !props.active && 'brightness(0) saturate(100%) invert(12%) sepia(71%) saturate(4473%) hue-rotate(218deg) brightness(98%) contrast(101%);'};
  }

  .icon {
    margin-right: 6px;
    margin-bottom: 4px;
    filter: ${(props) =>
      props.active
        ? 'brightness(0) saturate(100%) invert(12%) sepia(71%) saturate(4473%) hue-rotate(218deg) brightness(98%) contrast(101%)'
        : 'brightness(0) saturate(100%) invert(34%) sepia(9%) saturate(420%) hue-rotate(191deg) brightness(94%) contrast(90%)'};
  }

  label {
    margin-right: 6px;
    font-size: 12px;
    color: ${(props) => (props.active ? 'var(--brand1)' : 'var(--gray-700)')};
    user-select: none;
    cursor: pointer;
  }

  ${(props) =>
    props.active &&
    css`
      background-color: #f2f5fc;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;

      ::before {
        content: '';
        position: absolute;
        background-color: transparent;
        bottom: -20px;
        right: 0px;
        height: 20px;
        width: 12px;
        border-top-right-radius: 30px;
        box-shadow: 0px -10px 0px 0px #f2f5fc;
      }

      ::after {
        content: '';
        position: absolute;
        background-color: transparent;
        bottom: 70px;
        right: 0px;
        height: 20px;
        width: 12px;
        border-top-left-radius: 30px;
        box-shadow: 0px -10px 0px 0px #f2f5fc;
        transform: rotate(0.5turn);
      }
    `}
`;

const MenuItem = ({ icon, label, active }: { icon?: ReactNode; label?: string; active?: boolean }) => (
  <MenuItemWrapper active={active}>
    <span className="icon">{icon}</span>
    <label>
      <b>{label}</b>
    </label>
  </MenuItemWrapper>
);

export const menus =
  process.env.REACT_APP_MODE === 'production'
    ? [
        { name: 'task', label: '업무', path: '/task', icon: <Work /> },
        { name: 'plan', label: '플랜', path: '/plan', icon: <Plan /> },
        { name: 'lounge', label: '라운지', path: '/lounge', icon: <Lounge /> },
        { name: 'graph', label: '통계', path: '/graph', icon: <Stats /> },
      ]
    : [
        { name: 'home', label: '홈', path: '/home', icon: <Home /> },
        { name: 'meeting', label: '회의', path: '/meeting', icon: <Meeting /> },
        { name: 'task', label: '업무', path: '/task', icon: <Work /> },
        { name: 'plan', label: '플랜', path: '/plan', icon: <Plan /> },
        { name: 'graph', label: '통계', path: '/graph', icon: <Stats /> },
      ];

const Menu = ({ selectedMenu }: { selectedMenu?: string }) => {
  return (
    <div>
      {menus.map((v) =>
        v.name !== 'home' && v.name !== 'stats' ? (
          <Link to={v.path} key={v.name}>
            <MenuItem icon={v.icon} label={v.label} active={selectedMenu === v.name} />
          </Link>
        ) : (
          <MenuItem key={v.name} icon={v.icon} label={v.label} active={selectedMenu === v.name} />
        ),
      )}
    </div>
  );
};

export default Menu;
