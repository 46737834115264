export const isElementInViewPort = (element: HTMLElement, container: HTMLElement) => {
  if (!element || !container) return false;

  const containerRect = container.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();

  return (
    elementRect.top >= containerRect.top &&
    elementRect.left >= containerRect.left &&
    elementRect.bottom - elementRect.height <= containerRect.bottom &&
    elementRect.right <= containerRect.right
  );
};
