import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { useGoogleLogin, UseGoogleLoginOptionsAuthCodeFlow } from '@react-oauth/google';
import { Icons, Login } from 'components';
import Fonts from 'components/Fonts';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Slide from './Slide';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  section.signin {
    white-space: pre-line;
    padding: 24px;
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .title {
      font-weight: 700;
      font-size: 32px;
      line-height: 44px;
      letter-spacing: -0.25px;
    }
    footer {
      position: absolute;
      bottom: 80px;
    }
  }
  section.intro {
    flex: 3;
    overflow: hidden;
    background-color: #2d6adf;
  }
`;

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID!;

interface Props {
  isSignIn: boolean;
}

const GoogleLogInButton = ({ label, options }: { label: string; options: UseGoogleLoginOptionsAuthCodeFlow }) => {
  const onHandleLoginGoogle = useGoogleLogin(options);
  return <Login onClick={onHandleLoginGoogle} label={label} />;
};

const SignIn = ({ isSignIn }: Props) => {
  const navigate = useNavigate();

  const googleLoginOptions: UseGoogleLoginOptionsAuthCodeFlow = {
    scope: 'openid email profile https://www.googleapis.com/auth/calendar.events',
    flow: 'auth-code',
    ux_mode: 'redirect',
    redirect_uri: `${process.env.REACT_APP_SERVER_URL}/auth/google`,
    state: `${window.location.origin}/auth`,
    onSuccess: (res) => console.log(res),
    onError: (err) => console.error(err),
  };

  return (
    <Wrapper>
      <section className="signin">
        <div className="pb-6">
          <Icons.Logo />
        </div>
        <div className="title pb-3">{'슬래시에 오신 것을\n환영합니다'}</div>
        <Fonts.Body1 className="text-gray800 pb-6">{'슬래시는 여러분의 편리한 일정 관리를 위해\n구글 아이디를 사용하여 구글 캘린더를 연동합니다.'}</Fonts.Body1>
        {isSignIn ? (
          <>
            <div className="pb-8">
              <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                <GoogleLogInButton options={googleLoginOptions} label={'구글 계정으로 로그인'} />
              </GoogleOAuthProvider>
            </div>
            <Fonts.Blockquote className="flex gap-1">
              <div className="text-gray600">슬래시가 처음이신가요?</div>
              <div className="text-brand1 cursor-pointer" onClick={() => navigate('/signUp')}>
                회원 가입하기
              </div>
            </Fonts.Blockquote>
          </>
        ) : (
          <>
            <div className="pb-8">
              <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                <GoogleLogInButton options={googleLoginOptions} label={'구글 계정으로 가입하기'} />
              </GoogleOAuthProvider>
            </div>
            <Fonts.Blockquote className="flex gap-1">
              <div className="text-gray600">이미 가입하셨나요?</div>
              <div className="text-brand1 cursor-pointer" onClick={() => navigate('/signIn')}>
                로그인하기
              </div>
            </Fonts.Blockquote>
          </>
        )}
        <footer>
          <Fonts.Blockquote className="text-gray600 cursor-pointer flex">
            <a href="/">이용약관</a>
            <div style={{ margin: '0px 8px' }}>|</div>
            <a href="http://policy.doslash.io/" target="_blank" rel="noreferrer">
              개인 정보 취급 방침
            </a>
          </Fonts.Blockquote>
        </footer>
      </section>
      <section className="intro px-16">
        <Slide />
      </section>
    </Wrapper>
  );
};

export default SignIn;
