import React, { forwardRef, useImperativeHandle, useRef, useState, CSSProperties } from 'react';
import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import { CreateTaskbox } from 'queries/model';
import { COLORS } from 'styles/constants';
import TaskBlockInputList, { TaskBlockHandle } from './TaskBlockInputList';
import { useClickOutside } from '@react-hookz/web';
import TaskboxCheck from 'components/TaskboxCheckbox';

const TaskboxCreationContainer = styled.div<{ focus?: boolean }>`
  width: 100%;
  border: 1px solid ${COLORS.gray200};
  border-radius: 8px;
  padding: 0px 20px 16px 0px;
  border: ${(props) => props.focus && '1px solid var(--brand1)'};

  /* &:focus-within {
    border: 1px solid var(--brand1);
    color: var(--gray-900);
  } */
`;

const TaskListWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 8px;
`;

export type TaskboxCreationType = 'current' | 'today' | 'tomorrow' | 'anotherDay' | 'custom';

export interface TaskboxCreationImperativeHandle {
  focus?: () => void;
}

export interface TaskboxCreationViewProps {
  taskbox: (CreateTaskbox & { done?: boolean }) | undefined;
  currentDate?: Date | null;
  cancelable?: boolean;
  schedulable?: boolean;
  onChange?: (taskbox: CreateTaskbox | undefined) => void;
  onClickCancel?: () => void;
  onClickSave?: (taskbox: CreateTaskbox, type: TaskboxCreationType, custom?: { start: string; end: string }) => void;
  style?: CSSProperties;
}

const TaskboxCreationView = ({ taskbox, onChange, style }: TaskboxCreationViewProps, ref: any) => {
  const refTaskboxInput = useRef<HTMLInputElement>(null);
  const refTaskBlockList = useRef<TaskBlockHandle>(null);
  const refContainer = useRef<HTMLDivElement>(null);
  const [focused, setFocused] = useState(false);

  useClickOutside(refContainer, () => {
    setFocused(false);
  });

  useImperativeHandle(ref, () => ({
    focus: () => {
      refTaskboxInput?.current?.focus();
    },
  }));

  const updateTaskbox = (taskbox: (CreateTaskbox & { done?: boolean }) | undefined) => {
    onChange && onChange(taskbox);
  };

  const handleChangeTaskBlocks = (tasks: { id: string; content?: string; done?: boolean }[] = []) => {
    let taskboxDone = taskbox?.done;
    if (tasks.filter((v: any) => v.done === true).length === tasks.length || tasks.filter((v: any) => v.done === false).length === tasks.length) {
      taskboxDone = !!tasks[0]?.done;
      updateTaskbox({ ...taskbox, tasks: tasks.map((v: any) => ({ id: v.id, content: v.content || '', done: taskboxDone })), done: taskboxDone });
    } else {
      if (tasks.length >= 2) {
        taskboxDone = false;
      }
      updateTaskbox({ ...taskbox, tasks: tasks.map((v: any) => ({ id: v.id, content: v.content || '', done: v.done })), done: taskboxDone });
    }

    if (tasks.length === 0) setTimeout(() => refTaskboxInput?.current?.focus(), 50);
  };

  const handleTaskboxKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      if (taskbox?.title || taskbox?.tasks?.length) return;

      e.preventDefault();
      refTaskboxInput?.current?.blur();
      setFocused(false);
      updateTaskbox(undefined);
    }

    if (e.key === 'Enter') {
      if (e.nativeEvent.isComposing) return;
      if (e.repeat) {
        e.preventDefault();
        return;
      }
      refTaskBlockList?.current?.add();
    }
  };

  const handleChangeDone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const done = e.currentTarget.checked;
    updateTaskbox({ ...taskbox, tasks: taskbox?.tasks?.map((v: any) => ({ id: v.id, content: v.content || '', done: done })), done: done });
  };

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateTaskbox({ ...taskbox, title: e.target.value });
  };

  return (
    <TaskboxCreationContainer ref={refContainer} onFocusCapture={() => setFocused(true)} focus={focused} style={style}>
      <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 20 }}>
        <TaskboxCheck checked={!!taskbox?.done} onChange={handleChangeDone} style={{ marginRight: 8 }} />
        {taskbox?.lockedIn && <span style={{ marginRight: 4 }}>{taskbox.done ? '✅' : '🌟'}</span>}
        <TextField
          inputRef={refTaskboxInput}
          autoComplete="off"
          fullWidth
          variant="standard"
          placeholder="태스크박스 이름을 입력해주세요."
          value={taskbox?.title || ''}
          onChange={handleChangeTitle}
          onKeyDown={handleTaskboxKeydown}
          InputProps={{
            readOnly: !!taskbox?.done,
            disableUnderline: true,
            style: taskbox?.done
              ? { fontWeight: 'bold', color: COLORS.gray500, textDecoration: 'line-through' }
              : { fontWeight: 'bold', color: COLORS.gray900 },
          }}
        />
      </div>
      <TaskListWrapper>
        <TaskBlockInputList
          ref={refTaskBlockList}
          tasks={taskbox?.tasks || []}
          suppressVisibleTag={true}
          done={taskbox?.done}
          onChange={handleChangeTaskBlocks}
        />
      </TaskListWrapper>
    </TaskboxCreationContainer>
  );
};

export default forwardRef<TaskboxCreationImperativeHandle, TaskboxCreationViewProps>(TaskboxCreationView);
