import styled from '@emotion/styled';
import { Checkbox, Dialog, TextField } from '@mui/material';
import { useKeyboardEvent } from '@react-hookz/web';
import { createTaskV1WorksPost } from 'queries';
import { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { COLORS } from 'styles/constants';
import TaskToday from './Today';
import TaskWeek from './Week';
import { v4 as uuidv4 } from 'uuid';
import toast from 'react-hot-toast';
import { fetchTasksAtom } from 'atoms/works';
import { useAtom } from 'jotai';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const TaskInputWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 24px;
`;

const QuickTaskInputPopup = ({ isOpen, onClose, onCreate }: { isOpen: boolean; onClose?: () => void; onCreate?: (value: string) => void }) => {
  const [value, setValue] = useState('');

  const handleKeydownInput = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (e.nativeEvent.isComposing) return;
      if (e.repeat) {
        e.preventDefault();
        return;
      }

      onCreate?.(`${value}`.trim());
    }
  };

  const handleClose = () => {
    onClose?.();
  };

  return (
    <Dialog
      open={isOpen}
      transitionDuration={0}
      sx={{
        'borderRadius': 8,
        'marginTop': '24px',
        '& .MuiDialog-container': {
          alignItems: 'flex-start',
        },
      }}
      PaperProps={{
        style: { width: '500px', height: '72px', boxShadow: '0px 8px 16px 0px #1A1E2729', border: `1px solid ${COLORS.gray200}` },
      }}
      hideBackdrop={true}
      onBackdropClick={handleClose}
      onClose={handleClose}
    >
      <TaskInputWrapper>
        <Checkbox sx={{ padding: 0 }} disabled={true} checked={false} />
        <TextField
          value={value}
          onChange={(e) => setValue(e.target.value)}
          autoFocus={true}
          autoComplete="off"
          fullWidth
          variant="standard"
          placeholder="태스크를 입력해주세요."
          onKeyDown={handleKeydownInput}
          InputProps={{ disableUnderline: true, style: { fontSize: 16, fontWeight: 'bold', color: COLORS.gray800 } }}
          style={{ marginLeft: 8 }}
        />
      </TaskInputWrapper>
    </Dialog>
  );
};

export const Task = () => {
  const location = useLocation();
  const defaultView = localStorage.getItem('task-view');
  const [isOpen, setIsOpen] = useState(false);
  const [, fetchTasks] = useAtom(fetchTasksAtom);

  useKeyboardEvent(
    true,
    (ev) => {
      const isQuickTaskCmd = (ev.metaKey || ev.ctrlKey) && ev.key === '/';
      if (!isQuickTaskCmd) return;

      setIsOpen(true);
    },
    [],
    { eventOptions: { passive: true } },
  );

  const handleCreateTask = async (value: string) => {
    if (value) {
      await createTaskV1WorksPost({ id: uuidv4(), type: 'task', content: value });
      toast.success('새로운 태스크를 생성하였습니다.');
      fetchTasks();
    }

    setIsOpen(false);
  };

  return (
    <Container>
      {location.pathname === '/task' ? <>{defaultView === 'week' ? <TaskWeek /> : <TaskToday />}</> : <Outlet />}
      {isOpen && <QuickTaskInputPopup isOpen={isOpen} onClose={() => setIsOpen(false)} onCreate={handleCreateTask} />}
    </Container>
  );
};

export default Task;
