import { SVGProps } from 'react';

export const GraphBeta = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="148" height="151" viewBox="0 0 148 151" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g filter="url(#filter0_b_10087_131639)">
        <path
          d="M70.0491 9.08594C56.2459 9.08594 42.7527 13.2171 31.2752 20.9573C19.7977 28.6974 10.8513 39.6988 5.56718 52.5708C0.283018 65.4428 -1.10168 79.6073 1.58814 93.2735C4.27796 106.94 10.9215 119.494 20.6789 129.35C30.4363 139.205 42.8694 145.919 56.4063 148.642C69.9433 151.365 83.9762 149.976 96.7311 144.65C109.486 139.324 120.39 130.3 128.065 118.718C135.739 107.137 139.84 93.5192 139.848 79.5858H70.0491V9.08594Z"
          fill="url(#paint0_linear_10087_131639)"
          fillOpacity="0.5"
        />
        <path
          d="M127.327 23.707L77.9512 73.7517H147.75C147.75 73.7517 147.75 73.7163 147.75 73.7021C147.761 64.4163 145.963 55.2198 142.458 46.6403C138.953 38.0607 133.811 30.2672 127.327 23.707Z"
          fill="url(#paint1_linear_10087_131639)"
          fillOpacity="0.5"
        />
        <path
          d="M75.3164 6.03513e-05V69.7991L124.044 20.4529C117.654 13.9591 110.06 8.80902 101.697 5.29891C93.3343 1.7888 84.3689 -0.0120174 75.3164 6.03513e-05Z"
          fill="url(#paint2_linear_10087_131639)"
          fillOpacity="0.5"
        />
      </g>
      <defs>
        <filter id="filter0_b_10087_131639" x="-44.75" y="-45" width="237.5" height="240" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="22.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_10087_131639" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_10087_131639" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_10087_131639" x1="70.2424" y1="11.5727" x2="70.203" y2="147.12" gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D6ADF" />
          <stop offset="1" stopColor="#E2ECFF" />
        </linearGradient>
        <linearGradient id="paint1_linear_10087_131639" x1="112.947" y1="24.5902" x2="112.937" y2="72.7286" gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D6ADF" />
          <stop offset="1" stopColor="#E2ECFF" />
        </linearGradient>
        <linearGradient id="paint2_linear_10087_131639" x1="99.7477" y1="1.23178" x2="99.72" y2="68.3721" gradientUnits="userSpaceOnUse">
          <stop stopColor="#2D6ADF" />
          <stop offset="1" stopColor="#E2ECFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
