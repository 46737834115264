import { atom } from 'jotai';
import { listWorksV1WorksGet } from 'queries';
import { OutWorks } from 'queries/model';

export type WorkDragView = 'plan' | 'inbox' | 'backlog' | 'planning' | 'plan-inbox-issue' | 'plan-inbox-task' | 'plan-planning';
export type WorkDraggableType = 'task' | 'issues' | 'issue';
export type WorkDragContext = { id?: string; view?: WorkDragView; title?: string; type?: WorkDraggableType; data?: any };

export const dragContextAtom = atom<WorkDragContext | undefined | null>(null);
export const currentDateAtom = atom<Date>(new Date());
export const worksAtom = atom<OutWorks>({});

export const inboxAtom = atom<OutWorks>({});
export const backlogAtom = atom<OutWorks>({});
export const fetchTasksAtom = atom(
  (get) => [get(inboxAtom), get(backlogAtom)],
  async (get, set) => {
    const [inbox, someday] = await Promise.all([listWorksV1WorksGet({ section_type: 'inbox' }), listWorksV1WorksGet({ section_type: 'someday' })]);
    set(inboxAtom, inbox || {});
    set(backlogAtom, someday || {});
  },
);

export default { dragContextAtom, currentDateAtom, worksAtom };
