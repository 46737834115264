import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Icons } from 'components';
import Fonts from 'components/Fonts';
import { Crisis } from 'components/Icons';
import { OutPlanWeekly, OutSubTask, OutWork, UpdateWork, WorkType } from 'queries/model';
import { useEffect, useState } from 'react';
import { COLORS } from 'styles/constants';
import { DragContext } from '../tabs/Plan';
import { PlanSections } from '../tabs/Plan';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import FirstPriorityIssueCheckbox from 'components/\bFirstPriorityIssueCheckbox';
import PriorityIssueCheckbox from 'components/PriorityIssueCheckbox';

const Wrapper = styled.div`
  margin-top: 40px;
`;

const PriorityHeader = styled.div`
  display: flex;
  align-items: center;
`;

const PriorityItemContainer = styled.div<{ priorityLength?: boolean }>`
  border-radius: 8px;
  background-color: ${COLORS.gray200};
  margin-top: 8px;
  padding: 12px;

  ${(props) =>
    props.priorityLength &&
    css`
      padding: 12px 4px;
    `};
`;

const PriorityItem = styled.div<{ priorityLength?: boolean }>`
  width: 100%;
  height: 200px;
  border: 1px dashed ${COLORS.negative1};
  border-radius: 8px;
  padding: 24px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  ${(props) =>
    props.priorityLength &&
    css`
      border: none;
      padding: 0px;
      align-items: normal;
      justify-content: space-between;
    `};
`;

const PriorityItemText = styled.div`
  text-align: center;
  color: ${COLORS.gray700};
`;

const PriorityIssueContainer = styled.div<{ done?: boolean }>`
  width: 100%;
  align-items: center;
  background-color: ${COLORS.white};
  border: 1px solid transparent;
  border-radius: 8px;
  background: linear-gradient(#fff 0 0) padding-box, linear-gradient(90deg, #c471ed 0%, #f64f59 88.02%) border-box;
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  position: relative;
  height: 56px;

  ${(props) =>
    props.done &&
    css`
      opacity: 50%;
    `}

  .priority-icon {
    opacity: 0;
    cursor: pointer;
  }

  :hover .priority-icon {
    opacity: 1;
  }
`;

const PlanPriorityIssueContext = styled.div`
  display: flex;
  align-items: center;
`;

const PriorityIssueText = styled.div``;

const PriorityIssueDetail = styled.div<{ done?: boolean }>`
  display: flex;
  > div {
    :last-child {
      color: ${COLORS.gray400};
      margin-left: 4px;
    }
  }
  ${(props) =>
    props.done &&
    css`
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 11px;
        left: -1px;
        width: 105%;
        height: 1px;
        background-color: black;
      }
    `}
`;

interface PlanPriorityProps {
  plan?: OutPlanWeekly[];
  section?: PlanSections[];
  dragContext?: DragContext;
  onDragStart?: (sectionId: string, issueId: string) => void;
  onDragEnd?: () => void;
  onDragOver?: (e: React.DragEvent<HTMLDivElement>, id: string, idx?: number) => void;
  onPriorityDragStart?: (issueId: string, idx: number) => void;
  onPriorityDragEnd?: () => void;
  onPriorityDragOver?: (e: React.DragEvent<HTMLDivElement>, id: string, idx?: number) => void;
  onDropPriority?: (priorityLength: number, priority: number) => void;
  onRemovePriority?: (issuId: string) => void;
  onClickCheckbox?: (issueId: string, issue: UpdateWork) => void;
}

export const PanelPlanPriority = ({
  plan = [],
  section = [],
  dragContext,
  onDragStart = () => {},
  onDragEnd = () => {},
  onPriorityDragEnd = () => {},
  onPriorityDragOver = () => {},
  onPriorityDragStart = () => {},
  onDropPriority = () => {},
  onRemovePriority = () => {},
  onDragOver = () => {},
  onClickCheckbox = () => {},
}: PlanPriorityProps) => {
  const [priorityLength, setPriorityLength] = useState<number>();
  const [priorityIssue, setPriorityIssue] = useState<OutPlanWeekly[]>([]);
  const [priority, setPriority] = useState<number>();

  useEffect(() => {
    fetchPriorityPlan();
  }, [plan]);

  const fetchPriorityPlan = () => {
    const priorityPlan = plan.filter((plan) => plan.priority! >= 1);
    const limit = 3;
    const length = priorityPlan.length;

    if (length !== 0) {
      const fakeData: OutPlanWeekly = {
        issue: {
          id: '',
        },
        id: '',
        userId: '',
        year: 0,
        week: 0,
      };
      const test = Array.from(Array(limit - length).keys()).map((item) => fakeData);
      setPriorityIssue([...priorityPlan, ...test]);
    } else {
      setPriorityIssue(priorityPlan);
    }

    setPriority(priorityPlan.length + 1);
    setPriorityLength(priorityPlan.length);
  };

  const handleDropPriority = (e: React.DragEvent<HTMLDivElement>) => {
    onDropPriority(priorityLength!, priority!);
  };

  const handleRemovePriority = (issueId: string) => {
    onRemovePriority(issueId);
  };

  const handleClickCheckbox = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, issueId: string, issue: OutWork) => {
    e.stopPropagation();
    const updateIssue: UpdateWork = {
      done: issue.doneAt ? false : true,
    };
    onClickCheckbox(issueId, updateIssue);
  };

  return (
    <Wrapper>
      <PriorityHeader>
        <Fonts.H3>이번 주 중요한 일</Fonts.H3>
        <Fonts.H3 style={{ color: `${COLORS.gray400}`, margin: '0px 0px 1px 5px' }}>{`(${priorityIssue.length})`} </Fonts.H3>
      </PriorityHeader>

      <PriorityItemContainer
        priorityLength={priorityLength === 0 ? false : true}
        onDrop={(e) => {
          handleDropPriority(e);
        }}
        onDragOver={(e) => {
          onDragOver(e, 'priority');
        }}
      >
        <PriorityItem priorityLength={priorityLength === 0 ? false : true}>
          {priorityIssue.length === 0 ? (
            <>
              <Crisis width={30} height={33}></Crisis>
              <PriorityItemText>
                <Fonts.H5 style={{ margin: '13px 0px 8px 0px' }}>이번 주 가장 중요한 일은 무엇인가요?</Fonts.H5>
                <Fonts.Blockquote>Drag & Drop으로 이슈를 이곳에 넣어주세요.</Fonts.Blockquote>
              </PriorityItemText>
            </>
          ) : (
            priorityIssue
              .sort((a, b) => a.priority! - b.priority!)
              .map((item) => item.issue)
              .map((v, idx) => {
                const sectionId = plan.find((plan) => plan.issue.id === v.id)?.sectionId;
                const priority = plan.find((plan) => plan.issue.id === v.id)?.priority;
                const sectionTitle = sectionId === null ? '섹션 없음' : section.find((section) => section.id === sectionId)?.name;
                return (
                  <div key={idx}>
                    {priority! >= 1 ? (
                      <PriorityIssueContainer
                        done={v.doneAt ? true : false}
                        draggable={true}
                        onDragStart={() => {
                          onPriorityDragStart(v.id, idx);
                        }}
                        onDragEnd={onPriorityDragEnd}
                        onDragOver={(e) => onPriorityDragOver(e, v.id, idx)}
                      >
                        <PlanPriorityIssueContext>
                          {priority === 1 ? (
                            <FirstPriorityIssueCheckbox
                              checked={v.doneAt ? true : false}
                              onClick={(e) => {
                                handleClickCheckbox(e, v.id, v);
                              }}
                            ></FirstPriorityIssueCheckbox>
                          ) : (
                            <PriorityIssueCheckbox
                              checked={v.doneAt ? true : false}
                              onClick={(e) => {
                                handleClickCheckbox(e, v.id, v);
                              }}
                            ></PriorityIssueCheckbox>
                          )}
                          <PriorityIssueText>
                            <Fonts.Badge style={{ color: `${COLORS.gray600}` }}>{sectionTitle}</Fonts.Badge>
                            <PriorityIssueDetail done={v.doneAt ? true : false}>
                              <Fonts.H3>{v.content} </Fonts.H3>
                              <Fonts.H3>{`(${v.tasks?.length})`}</Fonts.H3>
                            </PriorityIssueDetail>
                          </PriorityIssueText>
                        </PlanPriorityIssueContext>

                        <div className="priority-icon" onClick={() => handleRemovePriority(v.id)}>
                          <Icons.Close width={16} height={16}></Icons.Close>
                        </div>
                      </PriorityIssueContainer>
                    ) : (
                      <PriorityIssueContainer style={{ border: `1px dashed ${COLORS.negative1}`, backgroundColor: `${COLORS.gray200}`, background: 'none' }}>
                        <Fonts.H3 style={{ color: `${COLORS.gray400}`, width: '100%', textAlign: 'center' }}>{idx + 1}</Fonts.H3>
                      </PriorityIssueContainer>
                    )}
                  </div>
                );
              })
          )}
        </PriorityItem>
      </PriorityItemContainer>
    </Wrapper>
  );
};
