import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';
import Profile from './Profile';
import Menu, { menus } from './Menu';
import { IconButton, Tooltip } from '@mui/material';
import { Icons } from 'components';
import { COLORS } from 'styles/constants';

const Container = styled.nav`
  display: flex;
  flex-direction: column;
  width: 80px;
  height: 100%;
  max-width: 80px;
  justify-content: space-between;
`;

const Proposal = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  .css-qvtngr-MuiTooltip-tooltip {
    margin-bottom: 4px;
  }
`;

const GNB = () => {
  const location = useLocation();
  const [selectedMenu, setSelectedMenu] = useState('');

  useEffect(() => {
    const menu = menus.find((item) => location.pathname.includes(item.path));
    if (menu) {
      setSelectedMenu(menu.name);
    } else {
      setSelectedMenu(menus[0].name);
    }
  }, [location]);

  return (
    <Container>
      <div>
        <Profile />
        <Menu selectedMenu={selectedMenu} />
      </div>

      <Proposal>
        <Tooltip title="제안하기">
          <IconButton
            href="https://tally.so/r/31Xjk1"
            target="_blank"
            style={{ border: `1px solid ${COLORS.gray200}`, width: 48, height: 48, margin: '2px 4px' }}
          >
            <Icons.Bulb />
          </IconButton>
        </Tooltip>
      </Proposal>
    </Container>
  );
};

export default GNB;
