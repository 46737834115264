import Axios, { AxiosRequestConfig } from 'axios';
import { merge } from 'lodash';
import { getToken, getWorkspaceToken } from 'utils/token';

export const instance = Axios.create({ baseURL: `${process.env.REACT_APP_SERVER_URL}`.replace('/v1', '') });

export const customInstance = <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = instance({
    ...config,
    ...options,
    cancelToken: source.token,
  }).then(({ data }) => data) as any;

  promise.cancel = () => {
    source.cancel('Query was cancelled');
  };

  return promise;
};

instance.interceptors.request.use(
  async (config) => {
    const token = `${config.url}`.match(
      /v1\/users|v1\/notes|v1\/invitation|v1\/meeting-notes|v1\/events|v1\/timeboxes|v1\/daily-memos|v1\/tasks|v1\/slack|v1\/taskboxes|v1\/works|v1\/plans|v1\/frequentwork|v1\/autocomplete/g,
    )
      ? getWorkspaceToken()
      : getToken();

    merge(config.headers, { Authorization: `Bearer ${token}` });
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  async (response) => {
    // Error handling
    return response;
  },

  (error) => {
    return Promise.reject(error);
  },
);
