import styled from '@emotion/styled';
import {
  createPlanWeeklyV1PlansWeeklyPost,
  createTaskboxV1TaskboxesPost,
  createTaskV1WorksPost,
  removeTaskV1WorksWorkIdDelete,
  updateIssueV1WorksIssuesIssueIdPut,
  updateTaskboxesV1TaskboxesTaskboxIdPut,
  updateTaskV1WorksWorkIdPut,
} from 'queries';
import { UpdateIssueDetail, UpdateWork } from 'queries/model';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { InboxContextMenuType } from 'components/InboxContextMenuPopover';
import dayjs from 'dayjs';
import { ListItemText, MenuItem, MenuList, Popover } from '@mui/material';
import { Check } from '@mui/icons-material';
import IssueListView from 'components/Issue/IssueListView';
import TaskListView from 'components/Task/TaskListView';
import { v4 as uuidv4 } from 'uuid';
import { fetchTasksAtom } from 'atoms/works';
import { useAtom } from 'jotai';
import { DATE_FORMAT_4 } from 'utils/datetimeFormat';

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const BacklogListWrapper = styled.div`
  width: 100%;
  height: calc(100% - 120px);
  display: flex;
`;

export interface BacklogProps {
  active?: boolean;
  showFilter?: boolean;
}

const Backlog = ({ active }: BacklogProps) => {
  const [filterMenuPopover, setFilterMenuPopover] = useState<HTMLElement | null>(null);
  const [selectedFilter, setSelectedFilter] = useState<'task' | 'issue'>('task');
  const [tasksAtom, fetchTasks] = useAtom(fetchTasksAtom);
  const backlog = tasksAtom[1];

  useEffect(() => {
    if (active) fetchBacklog();
  }, [active]);

  const fetchBacklog = async () => {
    fetchTasks();
  };

  const handleClickContextMenu = async (id: string, type: string, menu: InboxContextMenuType) => {
    try {
      switch (menu) {
        case 'SWITCH_TO_TASK':
          await updateTaskV1WorksWorkIdPut(id, { type: 'task' });
          toast.success('이슈를 태스크로 전환하였습니다.');
          break;
        case 'SWITCH_TO_ISSUE':
          await updateTaskV1WorksWorkIdPut(id, { type: 'issue' });
          toast.success('태스크를 이슈로 전환하였습니다.');
          break;
        case 'MOVE_TO_THIS_WEEK':
          await createPlanWeeklyV1PlansWeeklyPost({
            year: dayjs().year(),
            week: dayjs().week(),
            issueId: id,
          });
          toast.success('이슈를 이동하였습니다.');
          break;
        case 'MOVE_TO_NEXT_WEEK':
          await createPlanWeeklyV1PlansWeeklyPost({
            year: dayjs().add(1, 'week').year(),
            week: dayjs().add(1, 'week').week(),
            issueId: id,
          });
          toast.success('이슈를 이동하였습니다.');
          break;
        case 'DELETE':
          await removeTaskV1WorksWorkIdDelete(id);
          toast.success(`${type === 'task' ? '태스크' : '이슈'}를 삭제하였습니다.`);
          break;
      }
    } catch (e) {
      toast.error('작업을 수행할 수 없습니다.');
    }

    fetchBacklog();
  };
  const handleCreateTask = async (value: string) => {
    await createTaskV1WorksPost({ id: uuidv4(), type: 'task', content: value, workSectionType: 'someday' });
    toast.success('새로운 태스크를 생성하였습니다.');
    fetchBacklog();
  };
  const handleCreateIssue = async (value: string) => {
    await createTaskV1WorksPost({ id: uuidv4(), type: 'issue', content: value });
    toast.success('새로운 이슈를 생성하였습니다.');
    fetchBacklog();
  };

  const handleChangeTask = async (id: string, params: UpdateWork) => {
    if (params.done === true) {
      const taskboxId = uuidv4();
      await createTaskboxV1TaskboxesPost({
        id: taskboxId,
        title: params.content || '',
        start: { date: dayjs().format(DATE_FORMAT_4) },
        end: { date: dayjs().format(DATE_FORMAT_4) },
      });
      await updateTaskboxesV1TaskboxesTaskboxIdPut(taskboxId, { done: true });
      await removeTaskV1WorksWorkIdDelete(id!);
      toast.success('완료된 태스크를 오늘 일지에 추가했습니다.');
    } else {
      await updateTaskV1WorksWorkIdPut(id, params);
      toast.success('태스크를 수정하였습니다.');
    }
    fetchBacklog();
  };

  const handleClickSaveIssue = async (id: string, value: UpdateIssueDetail) => {
    if (!value) return;

    await updateIssueV1WorksIssuesIssueIdPut(id, value);
    fetchBacklog();
    toast.success('이슈를 수정하였습니다.');
  };

  const handleCheckIssue = async (id: string, checked: boolean) => {
    await updateIssueV1WorksIssuesIssueIdPut(id, { done: checked });
    fetchBacklog();
  };

  const handleCheckIssueTask = async (id: string, checked: boolean) => {
    await updateTaskV1WorksWorkIdPut(id, { done: checked });
    fetchBacklog();
  };

  const handleClickCancelIssue = () => {
    fetchBacklog();
  };

  const handleClickFilterMenu = (value: 'task' | 'issue') => {
    setSelectedFilter(value);
    setFilterMenuPopover(null);
  };

  return (
    <Container>
      {/* {showFilter && (
        <FilterWrapper>
          <Button
            disableElevation
            color="inherit"
            variant="outlined"
            sx={{ margin: 0 }}
            style={{ background: COLORS.gray200, minWidth: 32 }}
            onClick={(e) => setFilterMenuPopover(e.currentTarget)}
          >
            <FilterAlt sx={{ width: 16, height: 16 }} />
            {selectedFilter && <span style={{ marginLeft: 4 }}>{{ task: '태스크 보기', issue: '이슈 보기' }[selectedFilter]}</span>}
          </Button>
        </FilterWrapper>
      )} */}
      <BacklogListWrapper>
        {selectedFilter === 'task' && (
          <TaskListView
            tasks={backlog?.tasks || []}
            draggable={true}
            editable={true}
            multipleDrag={true}
            dragView="backlog"
            checkboxTooltip="완료하고 오늘로 보내기"
            onCreate={handleCreateTask}
            onChangeTask={handleChangeTask}
            onClickContextMenu={handleClickContextMenu}
            hiddenContextMenus={['SWITCH_TO_TASK', 'MOVE_TO_THIS_WEEK', 'MOVE_TO_NEXT_WEEK', 'MOVE_TO_AFTER']}
          />
        )}
        {selectedFilter === 'issue' && (
          <IssueListView
            issues={backlog?.issues?.map((item) => ({ issue: item })) || []}
            draggable={true}
            onCreate={handleCreateIssue}
            onChangeIssue={handleCheckIssue}
            onChangeTask={handleCheckIssueTask}
            onClickContextMenu={handleClickContextMenu}
            onCloseIssueDetailPopup={handleClickCancelIssue}
            onApplyIssueDetailPopup={handleClickSaveIssue}
          />
        )}
      </BacklogListWrapper>
      {filterMenuPopover && (
        <Popover
          open={Boolean(filterMenuPopover)}
          anchorEl={filterMenuPopover}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClose={() => setFilterMenuPopover(null)}
        >
          <MenuList dense style={{ padding: 12 }}>
            <MenuItem onClick={() => handleClickFilterMenu('task')} style={{ padding: '0 8px' }}>
              <span style={{ width: 24 }}>{selectedFilter === 'task' && <Check color={'primary'} style={{ width: 16, height: 16 }} />}</span>
              <ListItemText>태스크 보기</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleClickFilterMenu('issue')} style={{ padding: '0 8px' }}>
              <span style={{ width: 24 }}>{selectedFilter === 'issue' && <Check color={'primary'} style={{ width: 16, height: 16 }} />}</span>
              <ListItemText>이슈 보기</ListItemText>
            </MenuItem>
          </MenuList>
        </Popover>
      )}
    </Container>
  );
};

export default Backlog;
