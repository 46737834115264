import styled from '@emotion/styled';
import { COLORS } from 'styles/constants';
import { memo, useMemo, useRef, useState } from 'react';
import { Culture, DateLocalizer, DateRange, EventProps, SlotInfo, stringOrDate, Event } from 'react-big-calendar';
import dayjs from 'lib/dayjs';
import { useMountEffect } from '@react-hookz/web';
import { DATE_FORMAT_1 } from 'utils/datetimeFormat';
import { isEqual } from 'lodash';
import { IconButton } from '@mui/material';
import { CalendarWithoutClock } from 'components/Icons';
import DnDCalendar from 'components/DnDCalendar';
import { truncate } from 'styles/utils';

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #f2f5fc;
  font-size: 10px;
  color: ${COLORS.gray600};
  overflow: hidden;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  .rbc-today {
    background-color: transparent;
  }

  .rbc-day-slot {
    margin-top: 6px;
  }

  .rbc-day-slot .rbc-time-slot {
    border-top: none;

    &:nth-of-type(1) {
      border-top: 1px solid ${COLORS.gray300};
    }
  }

  .rbc-day-slot .rbc-event-label {
    font-size: 10px;
    margin-bottom: 4px;
  }

  .rbc-slot-selection {
    border: 1px solid ${COLORS.sub2};
    border-radius: 8px;
  }

  .rbc-time-view .rbc-row {
    min-height: 0px;
  }

  .rbc-time-view .rbc-row div:nth-of-type(1) {
    height: 0px !important;
  }

  .rbc-time-header {
    display: none;
  }

  .rbc-time-content {
    overflow: hidden;
    padding: 10px 0px;
  }

  .rbc-event {
    border: 1px solid ${COLORS.brand1};
    background: white;
    border-radius: 8px;
    :hover {
      box-shadow: 0px 8px 16px rgba(26, 30, 39, 0.16);
    }
  }

  .rbc-event-label {
    display: none;
  }

  .rbc-event.rbc-selected {
    background-color: ${COLORS.sub3};
    box-shadow: 0px 8px 16px rgba(26, 30, 39, 0.16);
  }

  .rbc-current-time-indicator {
    height: 2px;
    background-color: ${COLORS.sub2};

    ::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: -4px;
      left: -4px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: ${COLORS.sub2};
    }
  }
`;

const EventWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 6px 6px 8px;
`;

const TextTruncate = styled.div`
  ${truncate('auto')};
`;

export type CustomEvent = { id?: string | undefined; created?: string; data?: unknown } & Event;

export interface MeetingCalendarViewProps {
  events?: CustomEvent[];
  date?: string | Date;
  onChange?: (value: { id: string; start: string; end: string }) => void;
  onSelectEvent?: (id: string | undefined) => void;
  onSelectSlot?: (value: { start: string; end: string }) => void;
  onClickFloatingAction?: (e: React.MouseEvent<HTMLButtonElement>, data?: unknown) => void;
}

const EventComponent = (props: EventProps<CustomEvent> & { onClickFloatingAction?: (e: React.MouseEvent<HTMLButtonElement>, data?: unknown) => void }) => {
  const { event, onClickFloatingAction } = props;
  const [isHovered, setHovered] = useState(false);
  const range = useMemo(() => `${dayjs(event.start).format('a hh:mm')} ~ ${dayjs(event.end).format('a hh:mm')}`, [event.start, event.end]);
  const isGreaterThanMinimum = useMemo(() => Math.abs(dayjs(event?.start).diff(event?.end, 'minutes')) > 15, [event.start, event.end]);

  return (
    <div onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} style={{ width: '100%', height: '100%' }}>
      <EventWrapper>
        {isGreaterThanMinimum && <TextTruncate style={{ marginBottom: 6 }}>{range}</TextTruncate>}
        <div style={{ fontSize: 12, color: COLORS.brand1, marginBottom: 8 }}>
          <strong>{event.title}</strong>
        </div>
        <div>{event.created}</div>
        {isHovered && (
          <div style={{ position: 'absolute', right: 4, bottom: 4 }}>
            <IconButton
              aria-label="calendar"
              sx={{ border: `1px solid ${COLORS.gray500}` }}
              size="small"
              onClick={(e) => onClickFloatingAction && onClickFloatingAction(e, event.data)}
            >
              <CalendarWithoutClock width={16} height={16} />
            </IconButton>
          </div>
        )}
      </EventWrapper>
    </div>
  );
};

const MeetingCalendarView = ({ date, events, onChange, onSelectEvent, onSelectSlot, onClickFloatingAction }: MeetingCalendarViewProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useMountEffect(() => {
    scrollToCurrentIndicator();
  });

  const formats = useMemo(
    () =>
      ({
        timeGutterFormat: 'a hh시',
        eventTimeRangeFormat: (range: DateRange, culture?: Culture, localizer?: DateLocalizer) =>
          `${localizer?.format(range.start, 'a hh:mm', culture)}~${localizer?.format(range.end, 'a hh:mm', culture)}`,
      } as any),
    [],
  );

  const scrollToCurrentIndicator = () => {
    setTimeout(() => {
      if (!ref || !ref.current) return;
      const el = ref.current.querySelector('.rbc-current-time-indicator') as HTMLDivElement;
      if (el) ref.current.scrollTo({ top: Math.max(el.offsetTop - 200, 0), behavior: 'smooth' });
    }, 30);
  };

  const handleEventDrop = ({ event, start, end }: { event: CustomEvent; start: stringOrDate; end: stringOrDate; isAllDay: boolean }) => {
    onChange && onChange({ id: event.id!, start: dayjs(start).format(DATE_FORMAT_1), end: dayjs(end).format(DATE_FORMAT_1) });
  };

  const handleEventResize = ({ event, start, end }: { event: CustomEvent; start: stringOrDate; end: stringOrDate; isAllDay: boolean }) => {
    onChange && onChange({ id: event.id!, start: dayjs(start).format(DATE_FORMAT_1), end: dayjs(end).format(DATE_FORMAT_1) });
  };

  const handleSelectEvent = (event: CustomEvent) => {
    onSelectEvent && onSelectEvent(event.id);
  };

  const handleSelectSlot = (slot: SlotInfo) => {
    onSelectSlot && onSelectSlot({ start: dayjs(slot.start).format(DATE_FORMAT_1), end: dayjs(slot.end).format(DATE_FORMAT_1) });
  };

  const handleClickPopover = (e: React.MouseEvent<HTMLButtonElement>, data?: unknown) => {
    onClickFloatingAction && onClickFloatingAction(e, data);
  };

  const EventComponentWrapper = (props: EventProps<CustomEvent>) => <EventComponent {...props} onClickFloatingAction={handleClickPopover} />;

  return (
    <Container ref={ref}>
      <DnDCalendar
        style={{ height: 2400, marginBottom: 40 }}
        date={date}
        defaultView="day"
        toolbar={false}
        timeslots={4}
        step={15}
        max={dayjs(date).endOf('date').toDate()}
        formats={formats}
        events={events}
        dayLayoutAlgorithm={'overlap'}
        selectable={true}
        onEventDrop={handleEventDrop}
        onEventResize={handleEventResize}
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
        onNavigate={() => ({})}
        components={{ event: EventComponentWrapper }}
      />
    </Container>
  );
};

export default memo(MeetingCalendarView, (prevProps, nextProps) => prevProps.date === nextProps.date && isEqual(prevProps.events, nextProps.events));
