import React, { useEffect, useRef, useState } from 'react';
import { Popover, PopoverProps } from 'react-tiny-popover';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { COLORS, FontSize } from 'styles/constants';
import { AppViewsOutMeetingNote } from 'queries/model';
import { listRecurringMeetingNotesV1MeetingNotesMeetingNoteIdRecurringGet } from 'queries';
import dayjs from 'lib/dayjs';

const ContentContainer = styled(motion.div)`
  width: 100%;
  border: 1px solid ${COLORS.gray200};
  background: var(--white);
  box-shadow: 0px 8px 16px ${COLORS.shadow100};
  border-radius: 8px;
`;

const ContentItemWrapper = styled.ul`
  width: 228px;
  max-width: 228px;
  border-radius: 8px;
  padding: 5px 0px;
  max-height: 420px;
  overflow-y: auto;
`;

const MeetingNoteListItemContainer = styled.li<{ selected?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 16px;
  font-size: ${FontSize.h6};
  color: ${COLORS.gray500};
  background-color: ${(props) => (props.selected ? '#E2ECFF !important' : 'white')};
  cursor: pointer;
  :hover {
    background-color: #f2f5fc;
  }
`;

const MeetingNoteListItemDate = styled.div<{ selected: boolean }>`
  font-size: 13px;
  font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};
  color: ${(props) => (props.selected ? COLORS.brand1 : COLORS.gray900)};
`;

const MeetingNoteListItemSummaryWrapper = styled.div`
  display: flex;
  color: ${COLORS.gray700};
`;

const MeetingNoteListItemTitle = styled.span`
  max-width: 76px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export interface MeetingNoteListPopoverProps extends Omit<PopoverProps, 'content'> {
  meetingNoteId: string;
  onClickItem?: (meetingNote: AppViewsOutMeetingNote | undefined) => void;
  content?: JSX.Element;
}

interface MeetingNoteListItemProps {
  value: AppViewsOutMeetingNote;
  selected: boolean;
  onClickItem?: MeetingNoteListPopoverProps['onClickItem'];
}

const MeetingNoteListItem = ({ value, selected, onClickItem }: MeetingNoteListItemProps) => {
  return (
    <MeetingNoteListItemContainer key={value.id} onClick={() => onClickItem && onClickItem(value)} selected={selected} className={selected ? 'selected' : ''}>
      <MeetingNoteListItemDate selected={selected}>{dayjs(value.createdAt).format('YYYY년 MM월 DD일 ddd요일')}</MeetingNoteListItemDate>
      <MeetingNoteListItemSummaryWrapper>
        <MeetingNoteListItemTitle> {value.title}</MeetingNoteListItemTitle>
        <span style={{ margin: '0 4px' }}>|</span>
        <span>{`${dayjs(value.startTime).format('a hh:mm')}~${dayjs(value.endTime).format('a hh:mm')}`}</span>
      </MeetingNoteListItemSummaryWrapper>
    </MeetingNoteListItemContainer>
  );
};

const MeetingNoteListPopover = ({ meetingNoteId, isOpen = false, onClickItem, children, ...props }: MeetingNoteListPopoverProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [meetingNotes, setMeetingNotes] = useState<AppViewsOutMeetingNote[]>([]);
  const refList = useRef<HTMLUListElement | null>(null);

  useEffect(() => {
    setIsPopoverOpen(isOpen);
    if (isOpen) {
      fetchMeetingNoteList();
    }
  }, [isOpen]);

  const fetchMeetingNoteList = async () => {
    const { data } = await listRecurringMeetingNotesV1MeetingNotesMeetingNoteIdRecurringGet(meetingNoteId);
    setMeetingNotes((data as AppViewsOutMeetingNote[]) || []);
    scrollToSelectedItem();
  };

  const handleClickContent = (value: AppViewsOutMeetingNote | undefined) => {
    setIsPopoverOpen(false);
    onClickItem && onClickItem(value);
  };

  const handleClickPopoverOutside = () => {
    setIsPopoverOpen(false);
  };

  const scrollToSelectedItem = () => {
    setTimeout(() => {
      if (!refList || !refList.current) return;
      const el = refList.current.querySelector('.selected') as HTMLLIElement;
      if (el) refList.current.scrollTo({ top: Math.max(el.offsetTop - 50, 0), behavior: 'smooth' });
    }, 30);
  };

  return (
    <Popover
      {...props}
      isOpen={isPopoverOpen}
      align={'center'}
      positions={['bottom']}
      padding={8}
      content={
        <ContentContainer initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <ContentItemWrapper ref={refList}>
            {meetingNotes.map((v) => (
              <MeetingNoteListItem key={v.id} value={v} selected={meetingNoteId === v.id} onClickItem={handleClickContent} />
            ))}
          </ContentItemWrapper>
        </ContentContainer>
      }
      onClickOutside={props.onClickOutside ? props.onClickOutside : handleClickPopoverOutside}
      clickOutsideCapture={true}
    >
      {children}
    </Popover>
  );
};

export default MeetingNoteListPopover;
