import styled from '@emotion/styled';
import {
  createTaskboxV1TaskboxesPost,
  createTaskV1WorksPost,
  getEventsV1EventsGet,
  readTaskboxesV1TaskboxesGet,
  removeTaskV1WorksWorkIdDelete,
  removeTimeboxV1TaskboxesTaskboxIdDelete,
  updateTaskboxesV1TaskboxesTaskboxIdPut,
  updateTaskV1TasksTaskIdPatch,
} from 'queries';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import WeekCalendarView, { CustomEvent } from './WeekCalendarView';
import dayjs from 'lib/dayjs';
import { DATE_FORMAT_1, DATE_FORMAT_4, DATE_FORMAT_7, TIME_FORMAT_2 } from 'utils/datetimeFormat';
import { CreateTaskbox, OutEvent, OutTaskboxDetailResponse } from 'queries/model';
import { useEventListener, useUpdateEffect } from '@react-hookz/web';
import { v4 as uuidv4 } from 'uuid';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { dragContextAtom, currentDateAtom, fetchTasksAtom } from 'atoms/works';
import TaskboxCreationView from '../components/TaskboxCreationView';
import { CircularProgress, Divider, IconButton, MenuItem, MenuList, Popover, TextField, Tooltip } from '@mui/material';
import { COLORS } from 'styles/constants';
import { Icons } from 'components';
import { SlotInfo } from 'react-big-calendar';
import { pick } from 'lodash';
import InboxContextMenuPopover, { InboxContextMenuType } from 'components/InboxContextMenuPopover';
import { StaticDatePicker } from '@mui/x-date-pickers';
import SidePanel from '../SidePanel';
import { TimeSelectionModal } from 'components/TimeSelectionModal';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
`;

const WeekCalendarViewWrapper = styled.div`
  min-width: 994px;
  height: 100%;
  border-right: 1px solid ${COLORS.gray200};
`;

const TaskboxCalendarContainer = styled.div`
  font-size: 12px;
  margin: 12px;
  /* width: 200px;
  height: 190px; */

  .MuiTypography-root {
    width: 20px;
    height: 20px;
  }

  .MuiPickersDay-root {
    width: 20px;
    height: 20px;
  }

  .MuiPickerStaticWrapper-content {
    min-width: 0px;
    height: 190px;
  }

  .MuiPickerStaticWrapper-root {
    width: 200px;
  }

  .MuiPickersCalendarHeader-root {
    margin-top: 0px;
  }

  .MuiCalendarPicker-root {
    width: 200px;
    margin: 0;
  }

  .MuiButtonBase-root {
    padding: 0px;
  }

  .MuiPickersArrowSwitcher-spacer {
    width: 8px;
  }

  .MuiCalendarPicker-viewTransitionContainer {
    > div {
      height: 150px;
    }
  }

  .PrivatePickersSlideTransition-root {
    min-height: 130px;
  }
`;

export interface TaskboxCalendarProps {
  date: Date;
  onChange?: (date: Date | null, isAllDay?: boolean) => void;
}

const TaskboxCalendar = ({ date, onChange }: TaskboxCalendarProps) => {
  return (
    <>
      <TaskboxCalendarContainer>
        <MenuList>
          <MenuItem style={{ marginBottom: 4 }} onClick={() => onChange?.(dayjs(date).add(1, 'days').toDate(), true)}>
            <span style={{ marginRight: 8 }}>
              <Icons.Tomorrow />
            </span>
            <span style={{ fontSize: 12 }}>다음 날</span>
          </MenuItem>
          <MenuItem style={{ marginBottom: 4 }} onClick={() => onChange?.(dayjs(date).day(8).toDate(), true)}>
            <span style={{ marginRight: 8 }}>
              <Icons.Postpone stroke="#1C1B1F" fill="#1C1B1F" />
            </span>
            <span style={{ fontSize: 12 }}>다음주 월요일</span>
          </MenuItem>
          <MenuItem onClick={() => onChange?.(null, true)}>
            <span style={{ marginRight: 8 }}>
              <Icons.Later width={16} height={16} fill="#1C1B1F" />
            </span>
            <span style={{ fontSize: 12 }}>나중에</span>
          </MenuItem>
        </MenuList>
        <Divider style={{ margin: '12px 0px' }} />
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          value={date}
          onChange={(newValue) => onChange?.(dayjs.isDayjs(newValue) ? newValue.toDate() : dayjs(newValue).toDate(), true)}
          renderInput={(params) => <TextField {...params} />}
        />
      </TaskboxCalendarContainer>
    </>
  );
};

export const TaskWeek = () => {
  const navigate = useNavigate();
  const [meetings, setMeetings] = useState<OutEvent[]>([]);
  const [taskboxes, setTaskboxes] = useState<OutTaskboxDetailResponse[]>([]);
  const [currentDate, setCurrentDate] = useAtom(currentDateAtom);
  const [, setSelectedEvent] = useState<CustomEvent | undefined>();
  const [newTaskbox, setNewTaskbox] = useState<CreateTaskbox & { allDay?: boolean; type?: string }>();
  const refCalendarView = useRef<HTMLDivElement>(null);
  const [taskboxCreationPopover, setTaskboxCreationPopover] = useState<{ top: number; left: number } | null>(null);
  const [selectedTaskbox, setSelectedTaskbox] = useState<OutTaskboxDetailResponse>();
  const [taskboxDetailPopover, setTaskboxDetailPopover] = useState<{ top: number; left: number } | null>(null);
  const refTaskboxInput = useRef<HTMLInputElement>(null);
  const [coords, setCoord] = useState({ x: 0, y: 0 });
  const [isLoading, setLoading] = useState(false);
  const [contextMenuPopover, setContextMenuPopover] = useState<HTMLElement | null>(null);
  const [taskboxCalendarPopover, setTaskboxCalendarPopover] = useState<HTMLElement | null>(null);
  const [taskViewDragContext] = useAtom(dragContextAtom);
  const [, fetchTasks] = useAtom(fetchTasksAtom);
  const refIsAllDayDrop = useRef(false);
  const [timeSelectionPopover, setTimeSelectionPopover] = useState<HTMLElement | null>(null);

  const refresh = useCallback(async () => {
    setLoading(true);
    await fetchMeetings();
    await fetchTaskboxes();
    setLoading(false);
  }, [currentDate]);

  useEffect(() => {
    refresh();
    localStorage.setItem('task-view', 'week');
  }, []);

  useUpdateEffect(() => {
    refresh();
  }, [currentDate]);

  useEventListener(
    window,
    'mousemove',
    (event: React.MouseEvent) => {
      // 커서 입력시 마우스 위치 업데이트 중지
      if (!taskboxCreationPopover && !taskboxDetailPopover) setCoord({ x: event.clientX, y: event.clientY });
    },
    { passive: true },
  );

  const calendarEvents = useMemo(() => {
    const meetingEvents: CustomEvent[] = meetings.map((item: OutEvent) => ({
      id: item.eventId || '',
      title: item.title || '',
      start: dayjs(item.startTime).toDate(),
      end: dayjs(item.endTime).toDate(),
      data: item,
      type: 'meeting',
      allDay: item.allDay,
    }));
    const taskboxEvents: CustomEvent[] = taskboxes.map((item: OutTaskboxDetailResponse) => ({
      id: item.id || '',
      title: item.title || '',
      start: item.allDay ? dayjs(item.start?.date).toDate() : dayjs(item.start?.datetime).toDate(),
      end: item.allDay ? dayjs(item.end?.date).toDate() : dayjs(item.end?.datetime).toDate(),
      data: item.tasks,
      type: 'task',
      done: item.done,
      lockedIn: item.lockedIn,
      allDay: item.allDay,
    }));
    const newTaskboxEvent: CustomEvent[] =
      newTaskbox && newTaskbox.id
        ? [
            {
              id: newTaskbox.id,
              title: newTaskbox.title || '',
              start: newTaskbox.start?.date ? dayjs(newTaskbox.start?.date).toDate() : dayjs(newTaskbox.start?.datetime).toDate(),
              end: newTaskbox.end?.date ? dayjs(newTaskbox.end?.date).toDate() : dayjs(newTaskbox.end?.datetime).toDate(),
              type: 'task',
              allDay: newTaskbox.allDay,
            },
          ]
        : [];
    return [...taskboxEvents, ...meetingEvents, ...newTaskboxEvent];
  }, [meetings, taskboxes, newTaskbox]);

  const fetchMeetings = async () => {
    const meetingList = await getEventsV1EventsGet({
      startTime: dayjs(currentDate).weekday(0).startOf('day').format(DATE_FORMAT_1),
      endTime: dayjs(currentDate).weekday(6).endOf('day').format(DATE_FORMAT_1),
    });

    setMeetings(meetingList.data || []);
  };

  const fetchTaskboxes = async () => {
    const taskboxList = await readTaskboxesV1TaskboxesGet({
      start_date: dayjs(currentDate).weekday(0).format(DATE_FORMAT_4),
      end_date: dayjs(currentDate).weekday(6).format(DATE_FORMAT_4),
    });

    setTaskboxes(taskboxList);
    if (selectedTaskbox) {
      const foundTaskbox = taskboxList.find((item) => item.id === selectedTaskbox.id);
      if (foundTaskbox) setSelectedTaskbox(foundTaskbox);
    }
  };

  const handleRefresh = () => refresh();

  const handleSelectEvent = async (eventId: string) => {
    const event = calendarEvents.find((item) => item.id === eventId);
    if (!event) return;

    const taskbox = taskboxes.find((item) => item.id === event?.id);
    if (!taskbox) return;

    setSelectedEvent(event || undefined);
    setSelectedTaskbox(taskbox);
    setTaskboxDetailPopover({ top: coords.y, left: coords.x });
  };

  const handleUpdateEvent = async ({ eventId, startTime, endTime, isAllDay }: { eventId: string; startTime: string; endTime: string; isAllDay: boolean }) => {
    if (startTime && endTime && Math.abs(dayjs(startTime).diff(endTime, 'minute')) < 15) return; // 15분 미만 변경 불가

    const start = isAllDay ? { date: dayjs(startTime).format(DATE_FORMAT_4) } : { datetime: startTime };
    const end = isAllDay ? { date: dayjs(endTime).format(DATE_FORMAT_4) } : { datetime: endTime };

    const taskbox = taskboxes.find((item) => item.id === eventId);
    if (taskbox) {
      setTaskboxes(taskboxes.map((item) => (item.id === taskbox.id ? { ...taskbox, start, end, allDay: isAllDay } : item)));
      await updateTaskboxesV1TaskboxesTaskboxIdPut(taskbox.id!, { ...taskbox, start, end });
      toast.success('태스크박스를 수정하였습니다.');
      fetchTaskboxes();
      setTaskboxDetailPopover(null);
    }
  };

  const handleUpdateEventTitle = async ({ eventId, title }: { eventId: string; title: string; isAllDay: boolean }) => {
    if (newTaskbox && newTaskbox.id === eventId) {
      if (title) {
        const result = await createTaskboxV1TaskboxesPost({ ...newTaskbox, id: newTaskbox.id || uuidv4(), title: title });
        if (result) {
          fetchTaskboxes();
          setNewTaskbox(undefined);
          toast.success('새로운 태스크박스를 생성하였습니다.');
        }
      } else {
        setNewTaskbox(undefined);
      }
    } else {
      const taskbox = taskboxes.find((item) => item.id === eventId);
      if (!taskbox) return;
      if (title === taskbox.title) return;

      await updateTaskboxesV1TaskboxesTaskboxIdPut(taskbox.id!, { ...pick(taskbox, ['lockedIn', 'tasks', 'start', 'end']), title });
      fetchTaskboxes();
      toast.success('태스크박스를 수정하였습니다.');
    }
  };

  const handleClickTimeSlot = async ({
    action,
    startTime,
    endTime,
    isAllDay,
  }: {
    action: SlotInfo['action'];
    bounds: SlotInfo['bounds'];
    box: SlotInfo['box'];
    startTime: string;
    endTime: string;
    isAllDay: boolean;
  }) => {
    if (action === 'select' || action === 'click') {
      const id = uuidv4();
      setNewTaskbox((prevTaskbox) => {
        if (prevTaskbox) return { ...prevTaskbox, id: id, start: { datetime: startTime }, end: { datetime: endTime }, allDay: isAllDay };
        else return { id: id, title: '', tasks: [], start: { datetime: startTime }, end: { datetime: endTime }, allDay: isAllDay };
      });
    } else {
      setNewTaskbox(undefined);
      setTaskboxCreationPopover(null);
    }
  };

  const handleCloseTaskboxCreationPopover = () => {
    setNewTaskbox(undefined);
    setSelectedTaskbox(undefined);
    setTaskboxCreationPopover(null);
  };

  const handleChangeDetailTaskbox = async (taskbox: CreateTaskbox | undefined) => {
    setSelectedTaskbox(taskbox as OutTaskboxDetailResponse);
  };

  const handleCloseTaskboxDetailPopover = async () => {
    if (!selectedTaskbox) return;

    const start = selectedTaskbox?.start;
    const end = selectedTaskbox?.end;

    // 마지막 항목이 비어있다면 삭제
    const tasks = selectedTaskbox?.tasks || [];
    if (tasks.length > 0 && tasks[tasks.length - 1].content === '') tasks.pop();

    await updateTaskboxesV1TaskboxesTaskboxIdPut(selectedTaskbox.id!, { ...selectedTaskbox, tasks, start, end });
    for (const item of tasks) await updateTaskV1TasksTaskIdPatch(item.id, { done: (item as any)?.done });

    setSelectedTaskbox(undefined);
    setTaskboxDetailPopover(null);
    fetchTaskboxes();
    fetchTasks();
  };

  const handleClickContextMenu = async (id: string, type: string, menu: InboxContextMenuType) => {
    try {
      switch (menu) {
        case 'DELETE':
          await removeTimeboxV1TaskboxesTaskboxIdDelete(id);
          setSelectedTaskbox(undefined);
          setTaskboxDetailPopover(null);
          toast.success('태스크박스를 삭제하였습니다.');
          fetchTaskboxes();
          break;
      }
    } catch (e) {
      toast.error('작업을 수행할 수 없습니다.');
    }

    setContextMenuPopover(null);
  };

  const handleClickTaskboxLockIn = async () => {
    if (!selectedTaskbox) return;

    const toggleLock = !selectedTaskbox?.lockedIn;
    const result = await updateTaskboxesV1TaskboxesTaskboxIdPut(selectedTaskbox.id!, { ...selectedTaskbox, lockedIn: toggleLock });
    if (result) toggleLock ? toast.success('구글 캘린더에 고정하였습니다.') : toast.success('구글 캘린더 고정 해제하였습니다.');

    fetchTaskboxes();
  };

  const handleChangeTaskboxDate = async (date: Date | null, isAllDay = false) => {
    if (!selectedTaskbox) return;

    if (date) {
      const start = isAllDay
        ? { date: dayjs(date).format(DATE_FORMAT_4) }
        : { datetime: dayjs(`${dayjs(date).format('YYYY-MM-DD')}T${dayjs(selectedTaskbox.start?.datetime).format('HH:mm:ss')}`).format(DATE_FORMAT_1) };
      const end = isAllDay
        ? { date: dayjs(date).format(DATE_FORMAT_4) }
        : { datetime: dayjs(`${dayjs(date).format('YYYY-MM-DD')}T${dayjs(selectedTaskbox.end?.datetime).format('HH:mm:ss')}`).format(DATE_FORMAT_1) };

      await updateTaskboxesV1TaskboxesTaskboxIdPut(selectedTaskbox.id!, { start, end });
      toast.success('태스크박스를 수정하였습니다.');
    } else {
      const tasks = selectedTaskbox?.tasks || [];
      if (tasks.length === 0) {
        await createTaskV1WorksPost({ id: uuidv4(), type: 'task', workSectionType: 'someday', content: selectedTaskbox?.title || '' });
      } else {
        for (const item of tasks) {
          const id = uuidv4();
          await createTaskV1WorksPost({ id: id, type: 'task', workSectionType: 'someday', content: item.content });
        }
      }

      await removeTimeboxV1TaskboxesTaskboxIdDelete(selectedTaskbox.id!);
      toast.success('업무를 나중에로 옮겼습니다.');
    }

    setTaskboxCalendarPopover(null);
    setSelectedTaskbox(undefined);
    setTaskboxDetailPopover(null);
    fetchTaskboxes();
    fetchTasks();
  };

  const handleDropFromOutside = async ({ startTime, endTime, isAllDay }: { startTime: string; endTime: string; isAllDay: boolean }) => {
    if (!taskViewDragContext) return;
    if (refIsAllDayDrop.current) return;

    const allDay = Math.abs(dayjs(startTime).diff(endTime, 'day')) > 0 || isAllDay;
    refIsAllDayDrop.current = allDay;

    const { id, type, title, data } = taskViewDragContext;
    const taskbox = taskboxes.find((item) => item.id === id);
    if (taskbox) {
      const diffDay = dayjs(startTime).diff(endTime, 'day');
      if (Math.abs(diffDay) > 0) return;

      const start = allDay ? { date: dayjs(startTime).format(DATE_FORMAT_4) } : { datetime: startTime };
      const end = allDay ? { date: dayjs(endTime).format(DATE_FORMAT_4) } : { datetime: dayjs(startTime).add(60, 'minute').format(DATE_FORMAT_1) };
      const result = await updateTaskboxesV1TaskboxesTaskboxIdPut(id!, { ...taskbox, start: start, end: end });
      if (result) {
        toast.success('태스크박스를 수정하였습니다.');
      }
    } else {
      const result = await createTaskboxV1TaskboxesPost({
        id: uuidv4(),
        title: title || '',
        start: allDay ? { date: dayjs(startTime).format(DATE_FORMAT_4) } : { datetime: startTime },
        end: allDay ? { date: dayjs(endTime).format(DATE_FORMAT_4) } : { datetime: dayjs(startTime).add(60, 'minute').format(DATE_FORMAT_1) },
        issueId: type === 'issue' ? id : undefined,
      });

      if (result && result.id) {
        if (Array.isArray(data)) {
          const tasks = data.map((item) => ({ ...item, id: uuidv4(), type: 'task' }));
          await updateTaskboxesV1TaskboxesTaskboxIdPut(result.id, { tasks: tasks });
          for (const item of data) {
            if (item.id) await removeTaskV1WorksWorkIdDelete(item.id);
          }
        } else {
          await removeTaskV1WorksWorkIdDelete(id!);
        }
        toast.success('새로운 태스크박스를 생성하였습니다.');
        fetchTasks();
      }
    }

    setSelectedTaskbox(undefined);
    setTaskboxDetailPopover(null);
    fetchTaskboxes();
    refIsAllDayDrop.current = false;
  };

  return (
    <>
      <Container>
        <div style={{ flex: 1 }}>
          {isLoading && (
            <div style={{ position: 'absolute', top: `calc(100% - 50%)`, left: 'calc(100% - 50%)' }}>
              <CircularProgress size={32} sx={{ color: COLORS.brand1 }} />
            </div>
          )}
          <WeekCalendarViewWrapper ref={refCalendarView} onKeyDown={(e) => e.key === 'Escape' && handleCloseTaskboxCreationPopover()}>
            <WeekCalendarView
              newEventId={newTaskbox?.id}
              events={calendarEvents}
              currentDate={currentDate}
              onClickRefresh={handleRefresh}
              onClickToggleView={() => navigate('/task/today')}
              onSelectEvent={handleSelectEvent}
              onUpdateEvent={handleUpdateEvent}
              onClickTimeSlot={handleClickTimeSlot}
              onUpdateEventTitle={handleUpdateEventTitle}
              onChangeCurrentDate={(date) => setCurrentDate(date)}
              onDropFromOutside={handleDropFromOutside}
            />
          </WeekCalendarViewWrapper>
          <>
            {taskboxDetailPopover && (
              <Popover
                anchorEl={null}
                anchorReference={'anchorPosition'}
                open={Boolean(taskboxDetailPopover)}
                anchorPosition={{ top: taskboxDetailPopover.top, left: taskboxDetailPopover.left }}
                sx={{ marginTop: 1, marginLeft: 1 }}
                onClose={handleCloseTaskboxDetailPopover}
              >
                <div style={{ position: 'relative' }}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', margin: '16px 16px 0 20px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', border: '1px solid #E7EAF4', borderRadius: 8, padding: 2 }}>
                      {selectedTaskbox?.start?.date && (
                        <Tooltip title={'시간 지정하기'}>
                          <IconButton
                            sx={{ borderRadius: '8px', padding: '4px' }}
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => setTimeSelectionPopover(e.currentTarget)}
                          >
                            <Icons.SelectTime />
                          </IconButton>
                        </Tooltip>
                      )}
                      {selectedTaskbox?.start?.datetime && (
                        <Tooltip title={'시간 해제하기'}>
                          <IconButton
                            sx={{ borderRadius: '8px', padding: '4px' }}
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              handleUpdateEvent({
                                eventId: selectedTaskbox.id!,
                                startTime: selectedTaskbox?.start?.datetime || '',
                                endTime: selectedTaskbox?.end?.datetime || '',
                                isAllDay: true,
                              })
                            }
                          >
                            <Icons.UnselectTime />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title={'미루기'}>
                        <IconButton
                          sx={{ borderRadius: '8px', padding: '4px' }}
                          style={{ cursor: 'pointer' }}
                          onClick={(e) => setTaskboxCalendarPopover(e.currentTarget)}
                        >
                          <Icons.Postpone />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={selectedTaskbox?.lockedIn ? '구글 캘린더에서 해제하기' : '구글 캘린더에 표시하기'}>
                        <IconButton sx={{ borderRadius: '8px', padding: '4px' }} style={{ cursor: 'pointer' }} onClick={handleClickTaskboxLockIn}>
                          {selectedTaskbox?.lockedIn ? <Icons.LockOut /> : <Icons.LockIn />}
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={'더보기'}>
                        <IconButton
                          sx={{ borderRadius: '8px', padding: '5px' }}
                          style={{ cursor: 'pointer' }}
                          onClick={(e) => setContextMenuPopover(e.currentTarget)}
                        >
                          <Icons.More width={14} height={14} fill={'#C8CDDB'} />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                  <div style={{ marginLeft: 20 }}>
                    {selectedTaskbox?.start?.datetime && (
                      <div style={{ display: 'flex', cursor: 'pointer' }}>
                        <Icons.Time width={16} height={16} fill={COLORS.gray500} />
                        <div style={{ marginLeft: 6, fontSize: 12, color: COLORS.gray500 }} onClick={(e) => setTimeSelectionPopover(e.currentTarget)}>
                          {`${dayjs(selectedTaskbox?.start?.datetime).format(TIME_FORMAT_2)} - ${dayjs(selectedTaskbox?.end?.datetime).format(TIME_FORMAT_2)}`}
                        </div>
                      </div>
                    )}
                  </div>
                  <TaskboxCreationView
                    ref={refTaskboxInput}
                    taskbox={selectedTaskbox}
                    currentDate={selectedTaskbox?.allDay ? null : dayjs(selectedTaskbox?.start?.datetime).toDate()}
                    style={{ border: 'none', width: 580 }}
                    onChange={handleChangeDetailTaskbox}
                    // onClickCancel={handleCloseTaskboxDetailPopover}
                    // onClickSave={handleSaveDetailTaskbox}
                    schedulable={true}
                  />
                </div>
              </Popover>
            )}
            {contextMenuPopover && selectedTaskbox && (
              <InboxContextMenuPopover
                id={selectedTaskbox.id!}
                type={'task'}
                open={Boolean(contextMenuPopover)}
                anchorEl={contextMenuPopover}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={{ marginTop: 0.5, marginLeft: 2 }}
                hiddenMenuItems={['SWITCH_TO_ISSUE', 'SWITCH_TO_TASK', 'MOVE_TO_AFTER', 'MOVE_TO_THIS_WEEK', 'MOVE_TO_NEXT_WEEK']}
                onClose={() => setContextMenuPopover(null)}
                onClickMenu={handleClickContextMenu}
              />
            )}
            {taskboxCalendarPopover && (
              <Popover
                open={Boolean(taskboxCalendarPopover)}
                anchorEl={taskboxCalendarPopover}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={{ marginTop: 0.5, marginLeft: 2 }}
                onClose={() => setTaskboxCalendarPopover(null)}
              >
                <TaskboxCalendar date={dayjs(selectedTaskbox?.start?.date || selectedTaskbox?.start?.datetime).toDate()} onChange={handleChangeTaskboxDate} />
              </Popover>
            )}
            {timeSelectionPopover && selectedTaskbox && (
              <Popover
                open={Boolean(timeSelectionPopover)}
                anchorEl={timeSelectionPopover}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                sx={{ marginTop: 1 }}
                onClose={() => setTimeSelectionPopover(null)}
              >
                <TimeSelectionModal
                  onChangeTime={(startTime, endTime) => handleUpdateEvent({ eventId: selectedTaskbox.id!, startTime, endTime, isAllDay: false })}
                  onClose={() => setTimeSelectionPopover(null)}
                  start={
                    selectedTaskbox?.start?.datetime ||
                    dayjs(selectedTaskbox?.start?.date)
                      .set('hour', currentDate.getHours())
                      .set('minute', currentDate.getMinutes())
                      .ceil('minutes', 15)
                      .format(DATE_FORMAT_7)
                  }
                  end={
                    selectedTaskbox?.end?.datetime ||
                    dayjs(selectedTaskbox?.end?.date)
                      .set('hour', currentDate.getHours())
                      .set('minute', currentDate.getMinutes())
                      .add(30, 'minutes')
                      .ceil('minutes', 15)
                      .format(DATE_FORMAT_7)
                  }
                />
              </Popover>
            )}
          </>
        </div>
        <SidePanel />
      </Container>
    </>
  );
};

export default TaskWeek;
