import styled from '@emotion/styled';
import { Box, Tab, Tabs, Tooltip } from '@mui/material';
import { Icons } from 'components';
import { useState } from 'react';
import Inbox from './tabs/Inbox';
import Plan from './tabs/Plan';
import Backlog from './tabs/Backlog';

const Container = styled.div`
  max-width: 374px;
  min-width: 374px;
  width: 100%;
  height: 100%;
  padding: 8px 20px 0px 20px;
  background: #f2f5fc;
`;

const TabPanel = styled.div`
  height: 100%;
  width: 100%;
`;

const SidePanel = () => {
  const [tabIndex, setTabIndex] = useState(1);

  return (
    <Container>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabIndex} onChange={(_, v) => setTabIndex(v)} centered>
          {process.env.REACT_APP_MODE === 'production' && (
            <Tooltip title="준비중" placement="bottom">
              <div>
                <Tab icon={<Icons.Plan />} iconPosition="start" label="플랜" style={{ minWidth: 106 }} disabled />
              </div>
            </Tooltip>
          )}
          {process.env.REACT_APP_MODE !== 'production' && <Tab icon={<Icons.Plan />} iconPosition="start" label="플랜" style={{ minWidth: 106 }} />}
          <Tab icon={<Icons.Inbox />} iconPosition="start" label="인박스" style={{ minWidth: 106 }} />
          <Tab icon={<Icons.Later />} iconPosition="start" label="나중에" style={{ minWidth: 106 }} />
        </Tabs>
      </Box>
      <div style={{ marginTop: 16, height: '100%', width: '100%' }}>
        <TabPanel role="tabpanel" hidden={!(tabIndex === 0)}>
          <Plan />
        </TabPanel>
        <TabPanel role="tabpanel" hidden={!(tabIndex === 1)}>
          <Inbox active={tabIndex === 1} />
        </TabPanel>
        <TabPanel role="tabpanel" hidden={!(tabIndex === 2)}>
          <Backlog active={tabIndex === 2} />
        </TabPanel>
      </div>
    </Container>
  );
};

export default SidePanel;
