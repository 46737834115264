import { SVGProps } from 'react';

export const UnselectTime = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.99935 8.66536H9.99935M13.9993 3.9987L12.666 2.66536M6.66602 1.33203H9.33268M7.99935 13.9987C5.05383 13.9987 2.66602 11.6109 2.66602 8.66536C2.66602 5.71985 5.05383 3.33203 7.99935 3.33203C10.9449 3.33203 13.3327 5.71985 13.3327 8.66536C13.3327 11.6109 10.9449 13.9987 7.99935 13.9987Z"
        stroke="#C8CDDB"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
