import styled from '@emotion/styled';
import Fonts from '../../components/Fonts';
import { COLORS } from '../../styles/constants';

interface LoginProps {
  onClick: () => void;
  label: string;
}

export const Login = ({ onClick, label }: LoginProps) => {
  return (
    <Wrapper onClick={onClick}>
      <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.252 16.2544C27.252 15.4602 27.1808 14.6966 27.0484 13.9635H16.5V18.2959H22.5276C22.268 19.6959 21.4789 20.8821 20.2927 21.6762V24.4864H23.9124C26.0302 22.5366 27.252 19.6653 27.252 16.2544Z"
          fill="#4285F4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.5 27.1999C19.524 27.1999 22.0592 26.197 23.9123 24.4865L20.2927 21.6763C19.2898 22.3483 18.0069 22.7454 16.5 22.7454C13.5829 22.7454 11.1138 20.7752 10.233 18.1279H6.49121V21.0298C8.33412 24.6901 12.1218 27.1999 16.5 27.1999Z"
          fill="#34A853"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.2329 18.1282C10.0089 17.4562 9.88163 16.7383 9.88163 16.0002C9.88163 15.262 10.0089 14.5442 10.2329 13.8722V10.9703H6.49108C5.73253 12.4823 5.2998 14.1929 5.2998 16.0002C5.2998 17.8074 5.73253 19.518 6.49108 21.03L10.2329 18.1282Z"
          fill="#FBBC05"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.5 9.2546C18.1443 9.2546 19.6207 9.8197 20.7814 10.9295L23.9938 7.71715C22.0542 5.90987 19.5189 4.80005 16.5 4.80005C12.1218 4.80005 8.33412 7.30987 6.49121 10.9702L10.233 13.8721C11.1138 11.2248 13.5829 9.2546 16.5 9.2546Z"
          fill="#EA4335"
        />
      </svg>
      <Fonts.H3 style={{ marginLeft: '8px' }}>{label}</Fonts.H3>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 400px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.gray200};
  border-radius: 8px;
  color: ${COLORS.gray900};
  &:hover {
    background-color: ${COLORS.gray100};
  }
  cursor: pointer;
`;
