import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { IconCircle, Icons, NotificationBar, TextWithIcon } from 'components';
import toast from 'react-hot-toast';
import {
  createEventV1EventsPost,
  getEventsV1EventsGet,
  readBlocksV1MeetingNotesMeetingNoteIdGet,
  readInvitationV1NotesMeetingNoteIdInvitationGet,
  readMeetingNoteByEventIdV1MeetingNotesGet,
  removeEventV1EventsEventIdDelete,
  sendInvitationV1NotesMeetingNoteIdInvitationSendPost,
  updateEventsV1EventsEventIdPut,
} from 'queries';
import { CreateInvitationShare, OutInvitation, AppApplicationMeetingNotesSchemasOutMeetingNote, OutEvent } from 'queries/model';
import { COLORS } from 'styles/constants';
import InvitationForm from './InvitationForm';
import MeetingNoteForm from './MeetingNoteForm';
import { MemoBar } from 'components/MemoBar';
import InvitationModal from './components/InvitationModal';
import MeetingNoteListPopover from './components/MeetingNoteListPopover';
import { Alarm, ArrowDownBig, ArrowLeftSmall, ArrowRightSmall, Calendar, People, Map as MapIcon } from 'components/Icons';
import { Avatar, Button, Popover } from '@mui/material';
import dayjs from 'lib/dayjs';
import CalendarView from './CalendarView';
import EventCreationModal, { EventCreationModalProps } from './components/EventCreationModal';
import { DATE_FORMAT_1, DATE_FORMAT_7 } from 'utils/datetimeFormat';
import { useUpdateEffect } from '@react-hookz/web';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const MeetingNoteWrapper = styled.div`
  flex: 1;
  display: flex;
  min-width: 702px;
  padding: 24px 32px 0px;
  justify-content: center;
  background-color: ${COLORS.gray200};
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

const MeetingInfoList = styled.ul`
  display: flex;
`;

const MeetingInfoItem = styled.li`
  display: flex;
  align-items: center;

  & > :first-of-type {
    margin-right: 4px;
  }

  &:not(:last-child):after {
    content: '';
    background-color: ${COLORS.gray300};
    height: 50%;
    width: 1px;
    margin: 0px 8px;
  }
`;

const MeetingNoteFixedCenter = styled.div`
  width: 630px;
`;

const EmptyScheduleWrapper = styled.div`
  flex: 1;
  min-width: 702px;
  padding: 24px 32px 0px;
  background-color: ${COLORS.gray200};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const NavigationAreaWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const NavigationButton = styled.button`
  display: flex;
  align-items: center;
  font-size: 13px;
  color: ${(props) => (props.disabled ? COLORS.gray400 : COLORS.gray700)};

  &.disabled {
    color: ${COLORS.gray400};
  }

  svg {
    padding-bottom: 1px;
    color: red;
    stroke: red;
  }
`;

const NextMeetingCreationPopoverWrapper = styled.div`
  width: 170px;
  height: 120px;
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const MeetingMemberListPopoverWrapper = styled.div`
  width: 162px;
  max-height: 236px;
  padding: 16px;
  overflow-y: auto;
`;

const MeetingMemberListItem = styled.li`
  display: flex;
  align-items: center;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  & > :first-of-type {
    margin-right: 8px;
  }
`;

const MeetingMemberListItemName = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 13px;
  color: ${COLORS.gray900};
`;

export const MeetingNote = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [events, setEvents] = useState<OutEvent[]>([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [meetingNoteId, setMeetingNoteId] = useState('');
  const [meetingNote, setMeetingNote] = useState<AppApplicationMeetingNotesSchemasOutMeetingNote | null>();
  const [invitationNote, setInvitationNote] = useState<OutInvitation | null>();
  const [isInvitationFormCollapsed, setIsInvitationFormCollapsed] = useState(false);
  const [isVisibleInvitationModal, setIsVisibleInvitationModal] = useState(false);
  const [isMeetingNoteListPopover, setIsMeetingNoteListPopover] = useState(false);
  const [isVisibleInvitationForm, setIsVisibleInvitationForm] = useState(false);
  const [isVisibleMemo, setIsVisibleMemo] = useState(false);
  const [sendChannel, setSendChannel] = useState({ slack: true, email: true });
  const [nextMeetingEl, setNextMeetingEl] = useState<HTMLElement | null>(null);
  const [meetingMemberListEl, setMeetingMemberListEl] = useState<HTMLElement | null>(null);
  const [eventCreationModalProps, setEventCreationModalProps] = useState<EventCreationModalProps | null>();

  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    const id = searchParams.get('id');
    if (id) {
      setMeetingNoteId(id);
      setIsMeetingNoteListPopover(false);
      setIsInvitationFormCollapsed(false);
      fetchNote(id);
    } else {
      setMeetingNoteId('');
      setMeetingNote(null);
      setInvitationNote(null);
    }
  }, [location]);

  useUpdateEffect(() => {
    fetchEvents();
  }, [currentDate]);

  const meetingDate = dayjs(meetingNote?.startTime).format('YYYY년 MM월 DD일 ddd요일');
  const meetingDuration = `${dayjs(meetingNote?.startTime).format('a hh:mm')}~${dayjs(meetingNote?.endTime).format('a hh:mm')}`;

  const fetchEvents = async () => {
    const params = { startTime: dayjs(currentDate).startOf('day').format(DATE_FORMAT_1), endTime: dayjs(currentDate).endOf('day').format(DATE_FORMAT_1) };
    const data = await getEventsV1EventsGet(params);
    setEvents(data?.data || []);
  };

  const fetchNote = async (id: string) => {
    const meetingNoteData = await readBlocksV1MeetingNotesMeetingNoteIdGet(id);
    if (!meetingNoteData.invitationSent) {
      const invitationNoteData = await readInvitationV1NotesMeetingNoteIdInvitationGet(meetingNoteData.id);
      setInvitationNote(invitationNoteData);
    }
    setMeetingNote(meetingNoteData);
    setIsVisibleInvitationForm((meetingNoteData?.participants || [])?.length > 0 && !meetingNoteData?.invitationSent && meetingNoteData?.status !== 'Done');
  };

  const handelCollapseInvitationForm = async () => {
    await fetchNote(meetingNoteId);
    setIsInvitationFormCollapsed(!isInvitationFormCollapsed);
  };

  const handleOkInvitationForm = (value: { slack: boolean; email: boolean }) => {
    if (!value) return;

    setSendChannel(value);
    setIsVisibleInvitationModal(true);
  };

  const handleClickSendInvitation = async (value: CreateInvitationShare) => {
    if (!value) return;
    if (!meetingNote || !meetingNote.id) return;

    const response = await sendInvitationV1NotesMeetingNoteIdInvitationSendPost(meetingNote.id, value);
    if (response) {
      setIsInvitationFormCollapsed(true);
      setIsVisibleInvitationModal(false);
      await fetchNote(meetingNoteId);

      toast.success('수고하셨습니다! 회의록이 공유되었습니다');
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const handleChangeEvent = async (eventId: string) => {
    const note = await readMeetingNoteByEventIdV1MeetingNotesGet({ event_id: eventId });
    note?.id && navigate(`/meeting?id=${note.id}`);
  };

  const handleUpdateEvent = async (eventId: string, start: string, end: string) => {
    if (start && end && Math.abs(dayjs(start).diff(end, 'minute')) < 15) return; // 15분 미만 변경 불가

    const event = events.find((item) => item.eventId === eventId);
    if (!event) return;

    setEvents(events.map((item) => (item.eventId === event.eventId ? { ...item, startTime: start, endTime: end } : item)));
    await updateEventsV1EventsEventIdPut(eventId, { title: event.title || '', start: start, end: end });
    fetchEvents();
  };

  const handleSaveEvent = async (event: Partial<Pick<OutEvent, 'eventId' | 'title' | 'startTime' | 'endTime' | 'participants' | 'location'>>) => {
    if (!event) return;

    const payload = {
      title: event.title!,
      start: dayjs(event.startTime).format(DATE_FORMAT_1),
      end: dayjs(event.endTime).format(DATE_FORMAT_1),
      timezone: 'Asia/Seoul',
      participants: (event.participants || []).map((item) => ({ email: item.email })),
      location: event.location,
    };

    let updateEventId = '';
    if (event.eventId) {
      const response = await updateEventsV1EventsEventIdPut(event.eventId, payload);
      if (response) {
        toast.success('일정을 수정하였습니다.');
        updateEventId = event.eventId;
      }
    } else {
      const response = await createEventV1EventsPost(payload);
      if (response) {
        toast.success('새로운 일정을 생성하였습니다.');
        updateEventId = response.eventId!;
      }
    }

    if (updateEventId) {
      setEventCreationModalProps({ open: false });
      fetchEvents();
      handleChangeEvent(updateEventId);
    }
  };

  const handleDeleteEvent = async (id: string) => {
    await removeEventV1EventsEventIdDelete(id);
    toast.success('일정을 삭제하였습니다.');
    setEventCreationModalProps({ open: false });
    fetchEvents();
    navigate(`/meeting`);
  };

  const handleClickOpenEventModal = (event?: Partial<OutEvent>) => {
    if (event) {
      setEventCreationModalProps({
        open: true,
        currentDate: dayjs(event.startTime).toDate(),
        eventId: event.eventId,
        eventTitle: event.title,
        eventStartTime: event.startTime || dayjs(currentDate).ceil('minutes', 15).format(DATE_FORMAT_7),
        eventEndTime: event.endTime || dayjs(currentDate).add(15, 'minutes').ceil('minutes', 15).format(DATE_FORMAT_7),
        eventParticipants: event.participants,
        eventLocation: event.location,
      });
    } else {
      setEventCreationModalProps({
        open: true,
        currentDate: dayjs(currentDate).toDate(),
        eventStartTime: dayjs(currentDate).ceil('minutes', 15).format(DATE_FORMAT_7),
        eventEndTime: dayjs(currentDate).add(15, 'minutes').ceil('minutes', 15).format(DATE_FORMAT_7),
      });
    }
  };

  return (
    <Container>
      <CalendarView
        events={events}
        currentDate={currentDate}
        onRefresh={fetchEvents}
        onSelectEvent={handleChangeEvent}
        onUpdateEvent={handleUpdateEvent}
        onClickOpenEventModal={handleClickOpenEventModal}
        onChangeCurrentDate={(date) => setCurrentDate(date)}
      />
      {meetingNote ? (
        <>
          <MeetingNoteWrapper>
            <MeetingNoteFixedCenter>
              <div className="pb-5">
                <div className="flex w-full justify-between">
                  <div className="flex items-center overflow-hidden">
                    <h1 className="heading-1 mr-2 text-ellipsis whitespace-nowrap overflow-hidden">{meetingNote.title}</h1>
                    {meetingNote.recurring && (
                      <MeetingNoteListPopover
                        meetingNoteId={meetingNote?.id}
                        isOpen={isMeetingNoteListPopover}
                        onClickItem={(item) => item && navigate(`/meeting?id=${item.id}`)}
                        onClickOutside={() => setIsMeetingNoteListPopover(false)}
                      >
                        <div onClick={() => setIsMeetingNoteListPopover(!isMeetingNoteListPopover)} style={{ cursor: 'pointer', userSelect: 'none' }}>
                          <ArrowDownBig />
                        </div>
                      </MeetingNoteListPopover>
                    )}
                  </div>
                  <div className="flex" style={{ marginLeft: 24 }}>
                    <div onClick={() => alert('준비 중')}>
                      <IconCircle pointer={true} icon={<Icons.Share width={16} height={16} />} />
                    </div>
                    <div style={{ marginLeft: '8px', width: 90 }} onClick={() => setIsVisibleMemo(true)}>
                      <TextWithIcon
                        pointer={true}
                        bgColor={COLORS.gray100}
                        color={COLORS.white}
                        icon={<Icons.Memo width={16} height={16} fill={COLORS.white} />}
                        text="개인 메모"
                      />
                    </div>
                  </div>
                </div>
                <blockquote className="blockquote" style={{ color: 'var(--gray-500)' }}>
                  <MeetingInfoList>
                    <MeetingInfoItem>
                      <Calendar width={16} height={16} fill={COLORS.gray500} /> {meetingDate}
                    </MeetingInfoItem>
                    <MeetingInfoItem>
                      <Alarm width={16} height={16} fill={COLORS.gray500} />
                      {meetingDuration}
                    </MeetingInfoItem>
                    <MeetingInfoItem>
                      <MapIcon width={16} height={16} fill={COLORS.gray500} />
                      {meetingNote.location}
                    </MeetingInfoItem>
                    <MeetingInfoItem
                      style={{ cursor: meetingNote.participants.length ? 'pointer' : 'default' }}
                      onClick={(e) => meetingNote.participants.length && setMeetingMemberListEl(e.currentTarget)}
                    >
                      <People width={16} height={16} fill={COLORS.gray500} />
                      {meetingNote.participants.length}명
                    </MeetingInfoItem>
                    <Popover
                      open={Boolean(meetingMemberListEl)}
                      anchorEl={meetingMemberListEl}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                      onClose={() => setMeetingMemberListEl(null)}
                      sx={{ marginTop: 0.5 }}
                    >
                      <MeetingMemberListPopoverWrapper>
                        <ul>
                          {meetingNote.participants.map((v) => (
                            <MeetingMemberListItem key={v.id}>
                              <div style={{ position: 'relative', marginRight: 12 }}>
                                <Avatar alt={v.name || v.email} src={v.profileImage} sx={{ width: 32, height: 32 }} />
                                {meetingNote.host?.id === v.id && (
                                  <span style={{ position: 'absolute', bottom: 0, right: -4 }}>
                                    <Icons.OrganizerMarker />
                                  </span>
                                )}
                              </div>
                              <MeetingMemberListItemName>{v.name || v.email}</MeetingMemberListItemName>
                            </MeetingMemberListItem>
                          ))}
                        </ul>
                      </MeetingMemberListPopoverWrapper>
                    </Popover>
                  </MeetingInfoList>
                </blockquote>
              </div>
              {meetingNote.recurring && (
                <NavigationAreaWrapper>
                  <NavigationButton disabled={!meetingNote.prevId} onClick={() => meetingNote.prevId && navigate(`/meeting?id=${meetingNote.prevId}`)}>
                    <ArrowLeftSmall fill={meetingNote.prevId ? COLORS.gray700 : COLORS.gray400} />
                    이전 회의
                  </NavigationButton>
                  <NavigationButton
                    className={`${meetingNote.nextId ? '' : 'disabled'}`}
                    onClick={(e) => (meetingNote.nextId ? navigate(`/meeting?id=${meetingNote.nextId}`) : setNextMeetingEl(e.currentTarget))}
                  >
                    다음 회의
                    <ArrowRightSmall fill={meetingNote.nextId ? COLORS.gray700 : COLORS.gray400} />
                  </NavigationButton>
                  <Popover
                    open={Boolean(nextMeetingEl)}
                    anchorEl={nextMeetingEl}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    onClose={() => setNextMeetingEl(null)}
                    sx={{ marginTop: 0.5 }}
                  >
                    <NextMeetingCreationPopoverWrapper>
                      <span style={{ fontSize: 13, marginBottom: 8 }}>다음 반복 회의가 없습니다. 반복 회의를 만들까요?</span>
                      <Button variant="outlined" color="inherit" sx={{ border: '1px solid #E7EAF4', borderRadius: 2 }}>
                        반복 회의 추가하기
                      </Button>
                    </NextMeetingCreationPopoverWrapper>
                  </Popover>
                </NavigationAreaWrapper>
              )}
              {isVisibleInvitationForm && (
                <InvitationForm
                  meetingNoteId={meetingNote?.id || ''}
                  invitationNote={invitationNote || undefined}
                  collapsed={isInvitationFormCollapsed}
                  onOk={handleOkInvitationForm}
                  onCollapse={handelCollapseInvitationForm}
                />
              )}
              <MeetingNoteForm meetingNote={meetingNote} disabled={isVisibleInvitationForm && !isInvitationFormCollapsed} />
            </MeetingNoteFixedCenter>
          </MeetingNoteWrapper>
          {isVisibleInvitationModal && (
            <InvitationModal
              participants={meetingNote.participants}
              sendSlack={sendChannel.slack}
              sendEmail={sendChannel.email}
              open={true}
              onClose={() => setIsVisibleInvitationModal(false)}
              onClickSend={handleClickSendInvitation}
            />
          )}
        </>
      ) : (
        <EmptyScheduleWrapper>
          <h3 className="heading-3 whitespace-pre-line text-center">{'등록된 일정이 없습니다.\n일정을 만들어보세요.'}</h3>
          <span className="pt-4">
            <Button variant="contained" disableElevation onClick={() => handleClickOpenEventModal()}>
              + 일정 추가
            </Button>
          </span>
        </EmptyScheduleWrapper>
      )}
      {isVisibleMemo ? <MemoBar onClose={() => setIsVisibleMemo(!isVisibleMemo)} meetingNoteId={meetingNote?.id || ''} /> : <NotificationBar />}
      {!!eventCreationModalProps?.open && (
        <EventCreationModal
          {...eventCreationModalProps}
          open={!!eventCreationModalProps?.open}
          onClose={() => setEventCreationModalProps({ ...eventCreationModalProps, open: false })}
          onClickSave={handleSaveEvent}
          onClickDelete={handleDeleteEvent}
        />
      )}
    </Container>
  );
};

export default MeetingNote;
