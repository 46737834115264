import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Icons } from 'components';
import { COLORS } from 'styles/constants';
import { Button, IconButton, LinearProgress, ToggleButton, ToggleButtonGroup, Typography, linearProgressClasses } from '@mui/material';
import dayjs from 'lib/dayjs';
import WeekCalendarEvent from './WeekCalendarEvent';
import { DATE_FORMAT_1, DATE_FORMAT_4 } from 'utils/datetimeFormat';
import CalendarPopover from 'components/CalendarPopover';
import ArrowToggleButton from 'components/ArrowToggleButton';
import DnDCalendar from 'components/DnDCalendar';
import { useCallback, useMemo, useRef } from 'react';
import { Culture, DateLocalizer, DateRange, EventProps, SlotInfo, stringOrDate, Event } from 'react-big-calendar';
import { useMountEffect, useRafCallback } from '@react-hookz/web';
import { readTaskboxesV1TaskboxesGet } from 'queries';
import { groupBy } from 'lodash';
import { OutTaskboxDetailResponse } from 'queries/model';

const CalendarViewWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 24px 28px 0px 28px;
  background-color: #f2f5fc;
  position: relative;
`;

const CalendarViewControlWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const CalendarViewDaySchedulerWrapper = styled.div`
  height: calc(100% - 80px);
  display: flex;
  flex-direction: column;
`;

const CalendarContainer = styled.div`
  width: 100%;
  height: 100%;
  background: ${COLORS.gray100};
  font-size: 10px;
  color: ${COLORS.gray600};

  .rbc-time-header {
    border-bottom: 2px solid ${COLORS.gray300};
    margin-right: 0px !important;
  }

  .rbc-time-header > .rbc-time-header-gutter {
    width: 50px !important;
    min-width: 50px !important;
    max-width: 50px !important;
  }

  .rbc-time-header-cell {
    /* margin-bottom: 8px; */
    display: none;
  }

  .rbc-time-header-content {
    border-left: 1px solid ${COLORS.gray200};
  }

  .rbc-header {
    color: ${COLORS.gray900};
    font-size: 20px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.rbc-today {
      background-color: transparent;
      color: ${COLORS.brand1};
    }

    & > button {
      cursor: default;
    }

    /* :nth-of-type(1),
    :nth-last-of-type(1) {
      color: ${COLORS.gray500};
    } */
  }

  .rbc-today {
    background-color: rgba(226, 236, 255, 0.45);
  }

  .rbc-allday-cell {
    height: 100px;
    overflow: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
    border-top: 1px solid ${COLORS.gray300};
  }

  .rbc-day-slot {
    margin-top: 6px;
  }

  .rbc-day-slot .rbc-time-slot {
    border-top: none;

    &:nth-of-type(1) {
      border-top: 1px solid ${COLORS.gray300};
    }
    &:nth-of-type(3n) {
      border-top: 1px dashed ${COLORS.gray200};
    }
  }

  .rbc-day-bg.rbc-selected-cell {
    /* background-color: #f2f5fc !important; */
  }

  .rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid ${COLORS.gray200};
  }

  .rbc-day-slot .rbc-event-label {
    font-size: 10px;
    margin-bottom: 4px;
  }

  .rbc-slot-selection {
    border: 1px solid ${COLORS.sub2};
    border-radius: 8px;
  }

  .rbc-time-content {
    & > * + * > * {
      border: none;
    }

    & > :nth-of-type(2) {
      border-right: 1px solid ${COLORS.gray200};
    }

    overflow: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }

  .rbc-event {
    border: 1px solid ${COLORS.gray200};
    background: white;
    border-radius: 8px;
    :hover {
      box-shadow: 0px 8px 16px rgba(26, 30, 39, 0.16);
    }
  }

  .rbc-event.rbc-selected {
    background-color: none;
    box-shadow: none;
  }

  .rbc-event-label {
    display: none;
  }

  .rbc-current-time-indicator {
    height: 2px;
    background-color: ${COLORS.sub2};

    ::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: -4px;
      left: -4px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: ${COLORS.sub2};
    }
  }

  .rbc-background-event {
    background: white;
    border: 1px solid ${COLORS.gray200};
    padding-top: 2px;
    opacity: 1;
    width: -webkit-fill-available;
  }

  .rbc-selected.rbc-background-event {
    background: white;
    opacity: 1;
  }

  .rbc-timeslot-group {
    min-height: 56px;
  }

  .rbc-event.rbc-event-allday {
    border: 1px solid ${COLORS.gray200};
    background: white;
    border-radius: 8px;
    :hover {
      box-shadow: none;
    }
  }

  .rbc-event.rbc-selected.rbc-event-allday {
    color: ${COLORS.gray600};
    background: ${COLORS.sub3};
  }

  .rbc-row-content {
    z-index: 0;
  }
`;

const AllDayEventContainer = styled.div<{ height?: number }>`
  width: 100%;
  display: flex;
  position: relative;
`;

const ViewMoreButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0px;
  left: 24px;
  cursor: pointer;
`;

const CalendarHeaderWrapper = styled.div`
  display: flex;
  height: 88px;
  width: 100%;
  border-right: 1px solid #f2f5fc;
  border-left: 1px solid #f2f5fc;
`;

const CalendarHeaderTimeGutter = styled.div`
  width: 50px;
  min-width: 50px;
`;

const CalendarHeaderDayWrapper = styled.div`
  display: flex;
  width: 100%;
  border-left: 1px solid ${COLORS.gray200};
`;

const WeekTaskDayWrapper = styled.div<{ focused?: boolean }>`
  display: flex;
  flex-basis: 14.2857%;
  max-width: 14.2857%;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid ${COLORS.gray200};
  background-color: ${(props) => (props.focused ? 'rgba(226, 236, 255, 0.45)' : 'inherit')};
`;

const WeekTaskDayTextWrapper = styled.div<{ bgColor?: string }>`
  display: flex;
  width: 38px;
  height: 38px;
  /* padding: 4px; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 20px;
  font-weight: 600;
  background-color: ${(props) => props.bgColor || 'inherit'};
  color: ${(props) => (props.bgColor === COLORS.brand1 ? 'white' : COLORS.gray900)};
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => (props.bgColor === COLORS.brand1 ? COLORS.brand1 : COLORS.gray200)};
  }
`;

interface CustomLinearProgressProps {
  value?: number;
  color?: string;
  barColor?: string;
}
const CustomLinearProgress = ({ value = 0, color = COLORS.gray200, barColor = 'transparent' }: CustomLinearProgressProps) => {
  return (
    <LinearProgress
      value={value}
      variant="determinate"
      sx={{
        height: '12px',
        borderRadius: '8px',
        border: '2px solid white',
        [`&.${linearProgressClasses.colorPrimary}`]: {
          backgroundColor: color,
        },
        [`& .${linearProgressClasses.bar}`]: {
          backgroundColor: barColor,
        },
      }}
    />
  );
};

export type CustomEvent = {
  id?: string;
  type?: 'task' | 'meeting';
  data?: unknown;
} & Event;

export interface CalendarViewProps {
  newEventId?: string;
  events?: CustomEvent[];
  currentDate: Date;
  onClickRefresh?: () => void;
  onClickToggleView?: () => void;
  onSelectEvent?: (eventId: string) => void;
  onUpdateEventTitle?: ({ eventId, title, isAllDay }: { eventId: string; title: string; isAllDay: boolean }) => void;
  onUpdateEvent?: ({ eventId, startTime, endTime, isAllDay }: { eventId: string; startTime: string; endTime: string; isAllDay: boolean }) => void;
  onDeleteEvent?: (eventId: string) => void;
  onClickTimeSlot?: ({
    action,
    bounds,
    startTime,
    endTime,
    isAllDay,
  }: {
    action: 'select' | 'click' | 'doubleClick';
    bounds:
      | {
          x: number;
          y: number;
          top: number;
          bottom: number;
          left: number;
          right: number;
        }
      | undefined;
    box:
      | {
          x: number;
          y: number;
          clientX: number;
          clientY: number;
        }
      | undefined;
    startTime: string;
    endTime: string;
    isAllDay: boolean;
  }) => void;
  onChangeCurrentDate?: (date: Date) => void;
  onDropFromOutside?: ({ startTime, endTime, isAllDay }: { startTime: string; endTime: string; isAllDay: boolean }) => void;
  onDragStart?: ({ title, startTime, endTime, isAllDay }: { title: string; startTime: string; endTime: string; isAllDay: boolean }) => void;
}

const WeekCalendarView = ({
  newEventId,
  events = [],
  currentDate,
  onClickRefresh,
  onClickToggleView,
  onSelectEvent,
  onUpdateEvent,
  onClickTimeSlot,
  onChangeCurrentDate,
  onDropFromOutside,
  onUpdateEventTitle,
}: CalendarViewProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isVisibleViewMore, setIsVisibleViewMore] = useState(false);
  const [isVisibleCalendarPopover, setIsVisibleCalendarPopover] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<CustomEvent | null>(null);
  const [weekTasks, setWeekTasks] = useState<{ date: Date; tasks: OutTaskboxDetailResponse[] }[]>([]);

  const formats = useMemo(
    () => ({
      dayFormat: (date: Date, culture?: Culture, localizer?: DateLocalizer) => `${localizer?.format(date, 'DD (ddd)', culture)}`,
      timeGutterFormat: 'a hh시',
      eventTimeRangeFormat: (range: DateRange, culture?: Culture, localizer?: DateLocalizer) =>
        `${localizer?.format(range.start, 'a hh:mm', culture)}~${localizer?.format(range.end, 'a hh:mm', culture)}`,
    }),
    [],
  );
  const draggableAccessor = useCallback((event: CustomEvent) => event.type === 'task', [events]);
  const resizableAccessor = useCallback((event: CustomEvent) => event.type === 'task' && !event.allDay, [events]);
  const allDayAccessor = useCallback((event: CustomEvent) => !!event.allDay, [events]);
  const eventPropGetter = useCallback(
    (event: CustomEvent, start: Date, end: Date, isSelected: boolean) => {
      if (event.type === 'meeting') return {};

      if (event.type === 'task' && event.id === newEventId) {
        return {
          style: {
            background: `rgba(0, 57, 167, 0.3)`,
          },
        };
      }

      // 종일 taskbox를 특정 시간대로 이동시 스타일
      if (event.type === 'task' && event.allDay && isSelected === undefined) {
        return {
          style: { maxHeight: '40px' },
        };
      }

      return {
        style: { border: `1px solid ${isSelected ? COLORS.brand1 : COLORS.gray200}` },
      };
    },
    [events],
  );

  useEffect(() => {
    fetchWeekTasks(currentDate);
  }, [events, currentDate]);

  useMountEffect(() => {
    scrollToCurrentIndicator();
  });

  const fetchWeekTasks = async (date: Date) => {
    const start = dayjs(date).startOf('week').format(DATE_FORMAT_4);
    const end = dayjs(date).endOf('week').format(DATE_FORMAT_4);
    const data = await readTaskboxesV1TaskboxesGet({ start_date: start, end_date: end });
    const taskboxes = data.map((item) => ({ ...item, date: dayjs(item.start?.date || item.start?.datetime).format(DATE_FORMAT_4) }));
    const groups = groupBy(taskboxes, 'date');
    setWeekTasks(
      [...Array(7).keys()].map((idx) => ({
        date: dayjs(start).add(idx, 'day').toDate(),
        tasks: groups[dayjs(start).add(idx, 'day').format(DATE_FORMAT_4)] || [],
      })),
    );
  };

  const [updateEventTitle] = useRafCallback((event: CustomEvent, title: string) => {
    onUpdateEventTitle && onUpdateEventTitle({ eventId: event.id!, title: title, isAllDay: Boolean(event.allDay) });
  });

  const scrollToCurrentIndicator = () => {
    setTimeout(() => {
      if (!ref || !ref.current) return;
      const el = ref.current.querySelector('.rbc-current-time-indicator') as HTMLDivElement;
      if (el) (ref.current.querySelector('.rbc-time-content') as HTMLDivElement)?.scrollTo({ top: Math.max(el.offsetTop - 200, 0), behavior: 'smooth' });
    }, 30);
  };

  const handleClickCalendarPopoverItem = (value: Date | null) => {
    if (!value) return;

    setIsVisibleCalendarPopover(false);
    onChangeCurrentDate && onChangeCurrentDate(value);
  };

  const handleChangeCurrentDate = (date: Date) => {
    onChangeCurrentDate && onChangeCurrentDate(date);
  };

  const handleEventDrop = ({ event, start, end, isAllDay }: { event: CustomEvent; start: stringOrDate; end: stringOrDate; isAllDay: boolean }) => {
    // if (event.allDay && isAllDay) return; // 종일 > 종일로 이동 불가
    let endTime = event.allDay && !isAllDay ? dayjs(start).add(30, 'minute') : dayjs(end); // 종일 이벤트를 특정시간으로 이동시 30분으로 조정

    // endTime이 15분 단위가 아닌 경우 15분으로 올림
    if (dayjs(endTime).get('minute') % 15 !== 0) {
      endTime = dayjs(endTime).set('minutes', Math.round(dayjs(endTime).get('minute') / 15) * 15);
    }

    onUpdateEvent &&
      onUpdateEvent({ eventId: event.id!, startTime: dayjs(start).format(DATE_FORMAT_1), endTime: dayjs(endTime).format(DATE_FORMAT_1), isAllDay });
  };

  const handleEventResize = ({ event, start, end, isAllDay }: { event: CustomEvent; start: stringOrDate; end: stringOrDate; isAllDay: boolean }) => {
    onUpdateEvent && onUpdateEvent({ eventId: event.id!, startTime: dayjs(start).format(DATE_FORMAT_1), endTime: dayjs(end).format(DATE_FORMAT_1), isAllDay });
  };

  const handleSelectEvent = (event: CustomEvent) => {
    if (event.type === 'meeting') return;

    setSelectedEvent(event);
    onSelectEvent && onSelectEvent(event.id!);
  };

  const handleSelectSlot = (slot: SlotInfo) => {
    const days = dayjs(slot.end).startOf('day').diff(dayjs(slot.start).startOf('day'), 'day');
    if (slot.action === 'select' && days > 1) return;

    const startTime = slot.slots.length === 2 ? dayjs(slot.start).format(DATE_FORMAT_1) : dayjs(slot.start).format(DATE_FORMAT_1);
    const endTime =
      slot.slots.length === 2
        ? dayjs(Math.min(+dayjs(slot.start).add(60, 'minute'), +dayjs(slot.start).endOf('day'))).format(DATE_FORMAT_1)
        : dayjs(slot.end).format(DATE_FORMAT_1);

    onClickTimeSlot &&
      onClickTimeSlot({
        action: slot.action,
        bounds: slot.bounds,
        box: slot.box,
        startTime,
        endTime,
        isAllDay: slot?.slots.length === 1 || days > 1 ? true : false,
      });
  };

  const handleDropFromOutside = (args: { start: stringOrDate; end: stringOrDate; allDay: boolean }) => {
    onDropFromOutside &&
      onDropFromOutside({
        startTime: dayjs(args.start).format(DATE_FORMAT_1),
        endTime: dayjs(args.end).format(DATE_FORMAT_1),
        isAllDay: args.allDay,
      });
  };

  const handleClickViewMore = () => {
    const timeHeader = ref.current?.querySelector('.rbc-allday-cell') as HTMLDivElement;
    timeHeader.style.height = `${isVisibleViewMore ? 100 : 188}px`;
    setIsVisibleViewMore(!isVisibleViewMore);
  };

  const handleEventInput = (event: CustomEvent, title: string) => {
    updateEventTitle(event, title);
  };

  const handleClickWeekTaskDate = (date: Date) => {
    onChangeCurrentDate && onChangeCurrentDate(date);
    onClickToggleView?.();
  };

  const EventComponentWrapper = (props: EventProps<CustomEvent>) => <WeekCalendarEvent {...props} onInput={(event, value) => handleEventInput(event, value)} />;

  return (
    <CalendarViewWrapper>
      <CalendarViewControlWrapper>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CalendarPopover
            isOpen={isVisibleCalendarPopover}
            defaultValue={currentDate}
            onClickOutside={() => setIsVisibleCalendarPopover(false)}
            onClickItem={handleClickCalendarPopoverItem}
          >
            <span onClick={() => setIsVisibleCalendarPopover(!isVisibleCalendarPopover)}>
              <ArrowToggleButton isToggle={isVisibleCalendarPopover}>
                <Typography variant="subtitle1" fontWeight={'bold'} color={COLORS.gray800}>
                  {dayjs(currentDate).format('M월')}
                </Typography>
              </ArrowToggleButton>
            </span>
          </CalendarPopover>
          <ToggleButtonGroup color="primary" value={'week'} onChange={() => onClickToggleView?.()}>
            <ToggleButton value="day" size="small" style={{ height: 24, width: 36, fontSize: 8 }}>
              일간
            </ToggleButton>
            <ToggleButton value="week" size="small" style={{ height: 24, width: 36, fontSize: 8 }}>
              주간
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div style={{ marginLeft: 24 }}>
          <IconButton aria-label="previous day" onClick={() => handleChangeCurrentDate(dayjs(currentDate).subtract(7, 'day').toDate())} sx={{ padding: '4px' }}>
            <Icons.ArrowLeftSmall />
          </IconButton>
          <Button
            sx={{ borderRadius: 2, background: 'white', color: 'black', border: '1px solid #E7EAF4' }}
            size="small"
            style={{ minWidth: '48px', marginLeft: 2, marginRight: 2 }}
            onClick={() => handleChangeCurrentDate(new Date())}
          >
            <b>오늘</b>
          </Button>
          <IconButton aria-label="next day" onClick={() => handleChangeCurrentDate(dayjs(currentDate).add(7, 'day').toDate())} sx={{ padding: '4px' }}>
            <Icons.ArrowRightSmall />
          </IconButton>
        </div>
      </CalendarViewControlWrapper>
      <CalendarViewDaySchedulerWrapper>
        <AllDayEventContainer>
          <ViewMoreButtonWrapper style={{ top: isVisibleViewMore ? 256 : 164 }} onClick={handleClickViewMore}>
            <IconButton size="small" style={{ padding: 2, border: `1px solid ${COLORS.gray200}`, background: COLORS.gray200 }}>
              {isVisibleViewMore ? <Icons.ArrowUpSmall /> : <Icons.ArrowDownSmall />}
            </IconButton>
          </ViewMoreButtonWrapper>
        </AllDayEventContainer>
        <CalendarContainer ref={ref}>
          <CalendarHeaderWrapper style={{ display: 'flex', height: 88, width: '100%', borderRight: '1px solid #f2f5fc', borderLeft: '1px solid #f2f5fc' }}>
            <CalendarHeaderTimeGutter style={{ width: '50px', minWidth: '50px' }} />
            <CalendarHeaderDayWrapper style={{ display: 'flex', width: '100%', borderLeft: `1px solid ${COLORS.gray200}` }}>
              {weekTasks.map((item, idx) => {
                const { date, tasks } = item;
                const isDone = tasks.length > 0 && tasks.every((task) => task.done);
                const taskDoneCount = tasks.filter((task) => task.done).length;
                const isToday = dayjs(date).isToday();
                const statusColor = isDone ? COLORS.sub4 : COLORS.brand1;
                const progress = isDone ? 100 : (tasks.filter((task) => task.done).length / tasks.length) * 100;

                return (
                  <WeekTaskDayWrapper key={idx} focused={isToday}>
                    <div style={{ fontSize: 13, color: COLORS.gray500, marginBottom: 4, marginTop: 2 }}>{dayjs(date).format('ddd')}</div>
                    <WeekTaskDayTextWrapper bgColor={isToday ? COLORS.brand1 : ''} style={{ marginBottom: 4 }} onClick={() => handleClickWeekTaskDate(date)}>
                      {dayjs(date).format('DD')}
                    </WeekTaskDayTextWrapper>
                    {tasks.length > 0 && (
                      <div style={{ width: '80%' }}>
                        <CustomLinearProgress
                          value={progress}
                          color={dayjs().isAfter(date, 'day') ? 'rgba(252, 223, 230, 0.6)' : COLORS.gray200}
                          barColor={taskDoneCount ? statusColor : 'transparent'}
                        />
                      </div>
                    )}
                  </WeekTaskDayWrapper>
                );
              })}
            </CalendarHeaderDayWrapper>
          </CalendarHeaderWrapper>
          <DnDCalendar
            style={{ height: '100%' }}
            date={currentDate}
            selected={selectedEvent}
            formats={formats}
            view="week"
            defaultView="week"
            toolbar={false}
            timeslots={4}
            step={15}
            max={dayjs(currentDate).endOf('day').toDate()}
            events={events.filter((v) => v.type === 'task' || v.allDay)}
            backgroundEvents={events.filter((v) => v.type === 'meeting' && !v.allDay)}
            dayLayoutAlgorithm={'overlap'}
            selectable={true}
            draggableAccessor={draggableAccessor}
            resizableAccessor={resizableAccessor}
            allDayAccessor={allDayAccessor}
            components={{ event: EventComponentWrapper }}
            eventPropGetter={eventPropGetter}
            onDropFromOutside={handleDropFromOutside}
            onEventDrop={handleEventDrop}
            onEventResize={handleEventResize}
            onSelectEvent={handleSelectEvent}
            onSelectSlot={handleSelectSlot}
            // onDragStart={handleDragStart}
            // onDoubleClickEvent={handleDoubleClickEvent}
            onView={() => 'week'}
            onNavigate={() => ({})}
            showAllEvents={true}
            showMultiDayTimes={true}
          />
        </CalendarContainer>
      </CalendarViewDaySchedulerWrapper>
      <div style={{ position: 'absolute', bottom: 24, right: 8 }}>
        <IconButton
          aria-label="sync"
          sx={{ boxShadow: `0px 8px 16px ${COLORS.shadow100}` }}
          style={{ marginLeft: 8, background: 'white' }}
          onClick={onClickRefresh}
        >
          <Icons.Reload width={28} height={28} strokeWidth={1} />
        </IconButton>
      </div>
    </CalendarViewWrapper>
  );
};

export default WeekCalendarView;
