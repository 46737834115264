import styled from '@emotion/styled';
import { IconButton, TextField, Checkbox, Typography, Tooltip } from '@mui/material';
import { Icons } from 'components';
import { OutWork, UpdateWork } from 'queries/model';
import React, { PropsWithChildren, ReactNode, useReducer, useRef, useState } from 'react';
import { COLORS } from 'styles/constants';
import { hideScroll } from 'styles/utils';
import { useClickOutside } from '@react-hookz/web';
import { useAtom } from 'jotai';
import { dragContextAtom, WorkDragContext, WorkDragView } from 'atoms/works';
import { InboxContextMenuPopoverProps, InboxContextMenuType } from 'components/InboxContextMenuPopover';
import TaskItem from 'components/Task/TaskItem';
import { detectOS } from 'utils/detectOS';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const TaskListViewDroppableArea = styled.div<{ dragOver?: boolean }>`
  position: absolute;
  min-height: 240px;
  top: 32px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${(props) => (props.dragOver ? COLORS.sub5 : COLORS.sub3)};
  border: 1px dashed ${(props) => (props.dragOver ? COLORS.sub4 : COLORS.brand1)};
  border-radius: 8px;
  z-index: 100;

  :hover {
    border: 1px dashed red;
  }
`;

const TaskListTitle = styled.h3`
  font-size: 16px;
  font-weight: bold;
`;

const TaskListTitleCount = styled.h3`
  font-weight: bold;
  color: ${COLORS.gray400};
`;

const TaskListHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  padding: 0 4px;
`;

const NewTaskInputWrapper = styled.div`
  width: 100%;
  min-height: 36px;
  display: flex;
  align-items: center;
  background: ${COLORS.white};
  border-radius: 8px;
  border: 1px solid ${COLORS.gray300};
  padding: 0 16px;
  margin-bottom: 8px;
`;

const TaskListViewItemWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  ${hideScroll()}
`;

const TaskListDragImage = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  width: 300px;
  height: 40px;
  border-radius: 8px;
  background-color: white;
  padding: 0 12px;
  left: -1000px;
  right: -1000px;
  z-index: 10000;
`;

const EmptyListWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 12px;
  border-radius: 8px;
  background: linear-gradient(180deg, #e7eaf4 0%, #f2f5fc 100%);
`;

const TaskListDragImageCount = styled.div`
  min-width: 16px;
  max-width: 16px;
  min-height: 16px;
  max-height: 16px;
  border: 1px solid ${COLORS.brand1};
  background: ${COLORS.brand1};
  border-radius: 4px;
  display: inline-block;
  color: ${COLORS.white};
  font-size: 10px;
  text-align: center;
`;

const KeyboardButtonRect = styled.span<{ small?: boolean }>`
  width: 16px;
  height: 16px;
  background: #ffffff;
  border: 1px solid ${COLORS.gray400};
  border-radius: 2px;
  font-size: 10px;
  color: ${COLORS.gray500};
  padding: ${(props) => `${props.small ? '1px' : '4px'}`};
`;

export interface TaskListViewProps extends PropsWithChildren {
  title?: ReactNode;
  tasks?: OutWork[];
  draggable?: boolean;
  droppable?: boolean;
  dragView?: WorkDragView;
  editable?: boolean;
  multipleDrag?: boolean;
  hiddenContextMenus?: InboxContextMenuType[];
  droppableViews?: string[];
  showAddTooltip?: boolean;
  checkboxTooltip?: string;
  onCreate?: (value: string) => void;
  onChangeTask?: (id: string, params: UpdateWork) => void;
  onClickContextMenu?: InboxContextMenuPopoverProps['onClickMenu'];
  onDropOutside?: (context?: WorkDragContext) => void;
}

export type TaskListDragContext = Partial<{ dragging: boolean; draggingId: string }>;

const TaskListView = (props: TaskListViewProps) => {
  const {
    title,
    tasks = [],
    draggable,
    droppable,
    dragView,
    editable,
    multipleDrag,
    hiddenContextMenus = ['SWITCH_TO_TASK', 'MOVE_TO_THIS_WEEK', 'MOVE_TO_NEXT_WEEK'],
    checkboxTooltip,
    droppableViews = [],
    showAddTooltip = false,
    onCreate,
    onClickContextMenu,
    onChangeTask,
    onDropOutside,
  } = props;
  const [isVisibleInput, setIsVisibleInput] = useState(false);
  const refListView = useRef<HTMLDivElement>(null);
  const refInput = useRef<HTMLInputElement>(null);
  const [selectedTaskIds, setSelectedTaskIds] = useState<Map<string, boolean>>(new Map());
  const [outsideDragContext, setOutsideDragContext] = useAtom(dragContextAtom);
  const [dragOver, setDragOver] = useState(false);
  const [dragContext, setDragContext] = useReducer((prev: TaskListDragContext, next: TaskListDragContext) => ({ ...prev, ...next }), {
    dragging: false,
    draggingId: '',
  });
  const { dragging, draggingId } = dragContext;
  const outsideDragging =
    droppable &&
    outsideDragContext &&
    outsideDragContext?.type === 'issue' &&
    (!droppableViews.length || droppableViews.includes(outsideDragContext?.view || '')) &&
    !tasks.some((item) => item.id === outsideDragContext?.id);
  const os = detectOS();

  useClickOutside(refListView, () => {
    setSelectedTaskIds(new Map());
  });

  const handleClickCreate = () => {
    setIsVisibleInput(true);
    setTimeout(() => refInput.current?.focus(), 100);
  };

  const handleKeydownTaskInput = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!refInput.current) return;

    const value = refInput.current?.value;
    if (e.key === 'Escape') {
      if (value) return;

      e.preventDefault();
      setIsVisibleInput(false);
    }

    if (e.key === 'Enter') {
      if (e.nativeEvent.isComposing) return;
      if (e.repeat) {
        e.preventDefault();
        return;
      }

      refInput.current.value = '';
      onCreate?.(value);
    }
  };

  const handleChangeTask = (id: string, params: UpdateWork) => {
    onChangeTask?.(id, params);
  };

  const handleClickTaskItem = (e: React.MouseEvent<HTMLDivElement>, id: string) => {
    if (e.ctrlKey || e.metaKey) {
      if (selectedTaskIds.has(id)) {
        setSelectedTaskIds((prev) => {
          const newState = new Map(prev);
          newState.delete(id);
          return newState;
        });
      } else {
        setSelectedTaskIds((prev) => new Map(prev).set(id, true));
      }
    } else {
      if (selectedTaskIds.size) setSelectedTaskIds(new Map());
    }
  };

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>, id: string) => {
    setDragContext({ draggingId: id });

    const el = refListView?.current?.querySelector('#task-list-drag-image');
    e.dataTransfer.setDragImage(el!, 24, 24);

    const selectedTasks = selectedTaskIds.size ? tasks.filter((item) => selectedTaskIds.has(item.id)) : tasks.filter((item) => item.id === id);
    setOutsideDragContext({ id, view: dragView, title: '', type: 'task', data: selectedTasks });
  };

  const handleDrag = (/*e: React.DragEvent<HTMLDivElement>*/) => {
    setDragContext({ dragging: true });
  };

  const handleDragEnd = () => {
    setDragContext({ draggingId: '', dragging: false });
    setOutsideDragContext(null);
    setSelectedTaskIds(new Map());
  };

  const handleDrop = () => {
    setDragOver(false);
    if (outsideDragContext) onDropOutside && onDropOutside(outsideDragContext);
  };

  return (
    <Container ref={refListView} onDragEnd={handleDragEnd}>
      {outsideDragging && (
        <TaskListViewDroppableArea
          onDrop={handleDrop}
          onDragOver={(e) => {
            e.preventDefault();
            setDragOver(true);
          }}
          onDragLeave={() => setDragOver(false)}
          dragOver={dragOver}
        />
      )}
      <TaskListHeaderWrapper>
        {title ? (
          <>{title}</>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TaskListTitle style={{ marginLeft: 4 }}>태스크</TaskListTitle>
            <TaskListTitleCount style={{ marginLeft: 4 }}>({tasks.length})</TaskListTitleCount>
          </div>
        )}
        {showAddTooltip ? (
          <Tooltip
            placement="bottom-start"
            title={
              <div style={{ margin: '2px 4px' }}>
                <span>태스크 추가</span>
                <KeyboardButtonRect small style={{ marginLeft: 4 }}>
                  {os === 'Windows' ? 'Ctrl' : '⌘'}
                </KeyboardButtonRect>{' '}
                + <KeyboardButtonRect small>/</KeyboardButtonRect>
              </div>
            }
          >
            <IconButton size={'small'} color="inherit" style={{ padding: 0 }} onClick={handleClickCreate}>
              <Icons.Plus width={16} height={16} strokeWidth={2} />
            </IconButton>
          </Tooltip>
        ) : (
          <IconButton size={'small'} color="inherit" style={{ padding: 0 }} onClick={handleClickCreate}>
            <Icons.Plus width={16} height={16} strokeWidth={2} />
          </IconButton>
        )}
      </TaskListHeaderWrapper>
      {isVisibleInput && (
        <NewTaskInputWrapper>
          <Checkbox sx={{ padding: 0 }} style={{ marginRight: 8 }} disabled />
          <TextField
            inputRef={refInput}
            autoComplete="off"
            fullWidth
            variant="standard"
            placeholder="새로운 태스크 만들기"
            onKeyDown={handleKeydownTaskInput}
            onBlur={() => !refInput?.current?.value && setIsVisibleInput(false)}
            InputProps={{ disableUnderline: true, style: { fontSize: 13, color: COLORS.gray800 } }}
          />
        </NewTaskInputWrapper>
      )}
      {tasks.length > 0 && (
        <TaskListViewItemWrapper>
          {tasks.map((v) => {
            const isSelected = selectedTaskIds.has(v.id);
            const selectionCount = selectedTaskIds.size;
            const isHidden = (isSelected && dragging) || (selectionCount === 0 && v.id === draggingId && dragging);
            return (
              <TaskItem
                key={v.id}
                task={v}
                editable={editable}
                selected={multipleDrag && isSelected}
                hidden={multipleDrag && isHidden}
                draggable={draggable}
                dragView={dragView}
                multipleDrag={multipleDrag && selectionCount > 1}
                hiddenContextMenus={hiddenContextMenus}
                checkboxTooltip={checkboxTooltip}
                onDragStart={handleDragStart}
                onDrag={handleDrag}
                onClick={(e) => handleClickTaskItem(e, v.id)}
                onChangeTask={handleChangeTask}
                onClickContextMenu={onClickContextMenu}
              />
            );
          })}
        </TaskListViewItemWrapper>
      )}
      {!isVisibleInput && tasks.length === 0 && (
        <EmptyListWrapper>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 28 }}>
            <Icons.Todo width={32} height={32} />
            <Typography variant={'subtitle2'} fontWeight={'bold'} fontSize={12} color={COLORS.gray700} style={{ marginLeft: 4 }}>
              할 일을 모아볼까요?
            </Typography>
            <span style={{ color: COLORS.gray500 }}>
              <KeyboardButtonRect>{os === 'Windows' ? 'Ctrl' : '⌘'}</KeyboardButtonRect> + <KeyboardButtonRect>/</KeyboardButtonRect>
              <Typography variant={'button'} fontWeight={'bold'} fontSize={12} color={COLORS.gray500} style={{ marginLeft: 4 }}>
                로 태스크를 빠르게 입력하세요.
              </Typography>
            </span>
          </div>
        </EmptyListWrapper>
      )}
      <TaskListDragImage id="task-list-drag-image">
        <TaskListDragImageCount>{selectedTaskIds.size}</TaskListDragImageCount>
        <span style={{ fontSize: 13, marginLeft: 8 }}>태스크 선택됨</span>
      </TaskListDragImage>
    </Container>
  );
};

export default TaskListView;
