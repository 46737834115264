import styled from '@emotion/styled';
import { Button, MenuItem, Select } from '@mui/material';
import Fonts from 'components/Fonts';
import { Calendar } from 'components/Icons';
import dayjs from 'dayjs';
import {
  createPlanSectionV1PlansSectionsPost,
  createPlanWeeklyV1PlansWeeklyPost,
  createTaskV1WorksPost,
  deletePlanSectionV1PlansSectionsPlanSectionIdDelete,
  listPlanSectionsV1PlansSectionsGet,
  listPlanWeeklyV1PlansWeeklyGet,
  removeTaskV1WorksWorkIdDelete,
  updateIssueV1WorksIssuesIssueIdPut,
  updatePlanSectionV1PlansSectionsPlanSectionIdPut,
  updatePlanWeeklyV1PlansWeeklyPlanWeeklyIdPut,
  updateTaskV1WorksWorkIdPut,
} from 'queries';
import {
  CreatePlanSection,
  CreatePlanWeekly,
  ListPlanWeeklyV1PlansWeeklyGetParams,
  OutPlanWeekly,
  UpdateIssueDetail,
  UpdatePlanSection,
  UpdatePlanWeekly,
  UpdateWork,
} from 'queries/model';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { COLORS } from 'styles/constants';
import { PlanPriority } from './PlanPriority';
import { PlanSection } from './PlanSection';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { InboxContextMenuType } from 'components/InboxContextMenuPopover';
export interface PlanSections {
  id?: string;
  userId?: string;
  name?: string;
}

export type DragContext = {
  startIdx?: number;
  endIdx?: number;
  dragging?: boolean;
  draggingSectionId?: string;
  draggingIssueId?: string;
  dragOverId?: string;
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${COLORS.gray100};
  padding: 24px 30px 0px 30px;
`;

const PlanHeader = styled.div`
  display: flex;
`;

const PlanBtn = styled.div`
  display: flex;
  align-items: center;
`;

const PlanDate = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  position: relative;
`;

const PlanDateContent = styled.div`
  display: flex;
  align-items: center;
  :hover .plan-date {
    opacity: 1;
  }
`;

const PlanFullDate = styled.div`
  position: absolute;
  top: 35px;
  left: 40px;
  width: 138px;
  height: 32px;
  color: ${COLORS.white};
  background-color: ${COLORS.black};
  padding: 7px 12px;
  text-align: center;
  border-radius: 4px;
  opacity: 0;
`;

export const Weekly = () => {
  const [planSection, setPlanSection] = useState<PlanSections[]>([]);
  const [weeklyPlan, setWeeklyPlan] = useState<OutPlanWeekly[]>([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedWeek, setSelectedWeek] = useState<number>();
  const [dragContext, setDragContext] = useState<DragContext>({
    dragging: false,
    draggingSectionId: '',
    draggingIssueId: '',
    dragOverId: '',
  });
  const [dragPriorityContext, setDragPriorityContext] = useState<DragContext>({
    dragging: false,
    draggingIssueId: '',
    dragOverId: '',
  });

  const { thisWeekDate, periodOfThisWeek } = useMemo(
    () => ({
      thisWeekDate: dayjs(currentDate).weekday(0).toDate(),
      periodOfThisWeek: `${dayjs(currentDate).weekday(0).startOf('day').format('DD(ddd요일)')}~${dayjs(currentDate)
        .weekday(6)
        .startOf('day')
        .format('DD(ddd요일)')}`,
    }),
    [currentDate],
  );
  const navigate = useNavigate();

  useEffect(() => {
    fetchSection();
  }, [thisWeekDate, selectedWeek]);

  const selectableWeek = [
    ...Array.from(Array(52).keys()).map((item) =>
      dayjs(currentDate)
        .startOf('year')
        .add(item * 7, 'day')
        .toDate(),
    ),
  ];

  const fetchSection = async () => {
    const params: ListPlanWeeklyV1PlansWeeklyGetParams = {
      year: dayjs(thisWeekDate).year(),
      week: selectedWeek ? selectedWeek : dayjs(thisWeekDate).week(),
    };

    const sectionData = await listPlanSectionsV1PlansSectionsGet();
    const weeklyPlanData = await listPlanWeeklyV1PlansWeeklyGet(params);

    setPlanSection([...sectionData]);
    setWeeklyPlan([...weeklyPlanData]);
  };

  const handleCreateSection = async (title: string) => {
    const newSection: CreatePlanSection = {
      name: title,
    };

    await createPlanSectionV1PlansSectionsPost(newSection);
    toast.success('새로운 섹션이 생성되었습니다.');
    fetchSection();
  };

  const handlePatchSection = async (id: string, section: UpdatePlanSection) => {
    await updatePlanSectionV1PlansSectionsPlanSectionIdPut(id, section);
    toast.success('섹션명을 변경하였습니다.');
    fetchSection();
  };

  const handleRemoveSection = async (id: string) => {
    await deletePlanSectionV1PlansSectionsPlanSectionIdDelete(id);
    toast.success('섹션이 삭제되었습니다.');
    fetchSection();
  };

  const handleCreateIssue = async (value: string, sectionId: string) => {
    const issueId = uuidv4();
    await createTaskV1WorksPost({ id: issueId, type: 'issue', content: value });

    const newWeeklyPlan: CreatePlanWeekly = {
      year: dayjs(thisWeekDate).year(),
      week: dayjs(thisWeekDate).week(),
      issueId,
      sectionId: sectionId === 'default' ? undefined : sectionId,
    };
    await createPlanWeeklyV1PlansWeeklyPost(newWeeklyPlan);
    toast.success('새로운 이슈를 생성하였습니다.');
    fetchSection();
  };

  const handleClickSaveIssue = async (id: string, value: UpdateIssueDetail) => {
    await updateIssueV1WorksIssuesIssueIdPut(id, {
      content: value.content,
      description: value.description,
      dueDate: value.dueDate,
      tasks: value.tasks,
    });
    toast.success('이슈를 수정하였습니다.');
    fetchSection();
  };

  const handleClickDeleteIssue = async (id: string) => {
    await removeTaskV1WorksWorkIdDelete(id);
    toast.success('이슈를 삭제하였습니다.');
    fetchSection();
  };

  const handleDragStart = (sectionId: string, issueId: string) => {
    setDragContext({ ...dragContext, dragging: true, draggingIssueId: issueId, draggingSectionId: sectionId });
  };

  const handleDrag = (e: React.DragEvent<HTMLDivElement>, issueId: string) => {
    e.stopPropagation();
    e.preventDefault();

    if (dragContext.dragging === false) setDragContext({ ...dragContext, dragging: true });
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>, sectionId: string, priority?: number) => {
    e.stopPropagation();
    e.preventDefault();

    if (sectionId !== dragContext.dragOverId) setDragContext({ ...dragContext, dragOverId: sectionId || '' });
  };

  const handleDragEnd = () => {
    setDragContext({ dragging: false, draggingSectionId: '', draggingIssueId: '', dragOverId: '' });
  };

  const handlePriorityDragStart = (issueId: string, idx: number) => {
    setDragPriorityContext({ ...dragPriorityContext, dragging: true, draggingIssueId: issueId, startIdx: idx });
  };

  const handlePriorityDrag = (e: React.DragEvent<HTMLDivElement>, issueId: string) => {
    e.stopPropagation();
    e.preventDefault();

    if (dragContext.dragging === false) setDragPriorityContext({ ...dragPriorityContext, dragging: true });
  };

  const handlePriorityDragOver = (e: React.DragEvent<HTMLDivElement>, id: string, idx?: number) => {
    e.stopPropagation();
    e.preventDefault();

    if (id !== dragPriorityContext.dragOverId) setDragPriorityContext({ ...dragPriorityContext, dragOverId: id || '', endIdx: idx });
  };

  const handlePriorityDragEnd = () => {
    setDragPriorityContext({ dragging: false, draggingSectionId: '', draggingIssueId: '', dragOverId: '' });
  };

  const handleDropIssueItem = async (sectionId: string) => {
    const plan = weeklyPlan?.find((plan) => plan.issue.id === dragContext.draggingIssueId);

    const updateWeeklyPlan: UpdatePlanWeekly = {
      ...plan!,
      sectionId: sectionId === 'default' ? undefined : sectionId,
      issueId: dragContext.draggingIssueId!,
    };

    await updatePlanWeeklyV1PlansWeeklyPlanWeeklyIdPut(plan!.id, updateWeeklyPlan);
    fetchSection();
  };

  const handleDropPriority = async (priorityLength?: number, priority?: number) => {
    if (dragPriorityContext.draggingIssueId) {
      const startPlan = weeklyPlan?.find((plan) => plan.issue.id === dragPriorityContext.draggingIssueId);
      const endPlan = weeklyPlan?.find((plan) => plan.issue.id === dragPriorityContext.dragOverId);
      if (dragPriorityContext.startIdx !== dragPriorityContext.endIdx) {
        const updateStartPlan: UpdatePlanWeekly = {
          ...startPlan!,
          issueId: dragPriorityContext.draggingIssueId,
          priority: dragPriorityContext.endIdx! + 1,
        };
        await updatePlanWeeklyV1PlansWeeklyPlanWeeklyIdPut(startPlan!.id, updateStartPlan);

        const updateEndPlan: UpdatePlanWeekly = {
          ...endPlan!,
          issueId: dragPriorityContext.dragOverId!,
          priority: dragPriorityContext.startIdx! + 1,
        };
        await updatePlanWeeklyV1PlansWeeklyPlanWeeklyIdPut(endPlan!.id, updateEndPlan);

        fetchSection();
      }
    } else {
      const plan = weeklyPlan?.find((plan) => plan.issue.id === dragContext.draggingIssueId);
      if (plan?.priority) {
        toast.error('이미 중요한 일에 설정되어 있어요.');
        return;
      }

      if (priorityLength! > 2) {
        toast.error('이미 다 찼어요.');
        return;
      } else {
        const updateWeeklyPlan: UpdatePlanWeekly = {
          ...plan!,
          issueId: dragContext.draggingIssueId!,
          priority,
        };
        await updatePlanWeeklyV1PlansWeeklyPlanWeeklyIdPut(plan!.id, updateWeeklyPlan);
        toast.success('이번주 중요한 일이군요!');
        fetchSection();
      }
    }
  };

  const handleRemovePriority = async (issueId: string) => {
    const plan = weeklyPlan?.find((plan) => plan.issue.id === issueId);
    const updateWeeklyPlan: UpdatePlanWeekly = {
      ...plan!,
      issueId,
      priority: 0,
    };
    await updatePlanWeeklyV1PlansWeeklyPlanWeeklyIdPut(plan!.id, updateWeeklyPlan);
    toast.success('이번주 중요한 일이 아니군요!');

    const priorityPlan = weeklyPlan.filter((plan) => plan.priority! >= 1).sort((a, b) => a.priority! - b.priority!);
    const index = priorityPlan.findIndex((item) => item.issue.id === issueId);
    if (index === 0) {
      if (priorityPlan[1]) {
        const plan = weeklyPlan?.find((plan) => plan.id === priorityPlan[1].id);
        const updateWeeklyPlan: UpdatePlanWeekly = {
          ...plan!,
          issueId: priorityPlan[1].issue.id,
          priority: 1,
        };
        await updatePlanWeeklyV1PlansWeeklyPlanWeeklyIdPut(plan!.id, updateWeeklyPlan);
      }

      if (priorityPlan[2]) {
        const secondPlan = weeklyPlan?.find((plan) => plan.id === priorityPlan[2].id);
        const secondUpdateWeeklyPlan: UpdatePlanWeekly = {
          ...plan!,
          issueId: priorityPlan[2].issue.id,
          priority: 2,
        };
        await updatePlanWeeklyV1PlansWeeklyPlanWeeklyIdPut(secondPlan!.id, secondUpdateWeeklyPlan);
      }
    } else if (index === 1) {
      if (priorityPlan[2]) {
        const plan = weeklyPlan?.find((plan) => plan.id === priorityPlan[2].id);
        const updateWeeklyPlan: UpdatePlanWeekly = {
          ...plan!,
          issueId: priorityPlan[2].issue.id,
          priority: 2,
        };
        await updatePlanWeeklyV1PlansWeeklyPlanWeeklyIdPut(plan!.id, updateWeeklyPlan);
      }
    }
    fetchSection();
  };

  const handlePatchPlan = async (plan: OutPlanWeekly, sectionId: string) => {
    const updateWeeklyPlan: UpdatePlanWeekly = {
      ...plan!,
      sectionId,
      issueId: plan.issue.id,
    };
    await updatePlanWeeklyV1PlansWeeklyPlanWeeklyIdPut(plan!.id, updateWeeklyPlan);
  };

  const handleClickTodayBtn = () => {
    const date = dayjs(new Date()).weekday(0).toDate();
    setSelectedWeek(Number(dayjs(date).week()));
  };

  const handleClickContextMenu = async (id: string, type: string, menu: InboxContextMenuType) => {
    try {
      switch (menu) {
        case 'DELETE':
          await removeTaskV1WorksWorkIdDelete(id);
          toast.success('이슈를 삭제하였습니다.');
          fetchSection();
          break;
      }
    } catch (e) {
      toast.error('작업을 수행할 수 없습니다.');
    }
  };

  const handleClickCheckbox = async (issueId: string, issue: UpdateWork) => {
    await updateIssueV1WorksIssuesIssueIdPut(issueId!, issue);
    fetchSection();
  };

  return (
    <Wrapper
      onDragOver={(e) => {
        handleDragOver(e, ''), handlePriorityDragOver(e, '', 0);
      }}
    >
      <PlanHeader>
        <Fonts.H1>위클리 플랜</Fonts.H1>
        <Button
          onClick={() => navigate('/plan/planning')}
          variant="outlined"
          color="inherit"
          sx={{
            backgroundColor: `${COLORS.white}`,
            borderRadius: '8px',
            color: `${COLORS.gray800}`,
            border: ` 1px solid ${COLORS.gray200}`,
            marginLeft: '12px',
          }}
        >
          <PlanBtn>
            <Calendar width={16} height={16} />
            <Fonts.H4 style={{ marginLeft: '4px' }}> 한 주를 계획해보세요</Fonts.H4>
          </PlanBtn>
        </Button>
      </PlanHeader>
      <PlanDate>
        <PlanDateContent>
          <Fonts.H4 style={{ color: `${COLORS.gray600}` }}>{dayjs(currentDate).format('YYYY년 MM월')} </Fonts.H4>
          <Select
            value={selectedWeek ? selectedWeek : Number(dayjs(thisWeekDate).week())}
            onChange={(e) => setSelectedWeek(Number(e.target.value))}
            sx={{ width: '74px', height: '32px', backgroundColor: `${COLORS.gray200}`, marginLeft: '4px', marginRight: '8px', fontSize: '13px' }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                },
              },
            }}
          >
            {selectableWeek.map((item, idx) => (
              <MenuItem key={idx} value={Number(dayjs(item).week())}>
                <span>{`W${dayjs(item).week()}`}</span>
              </MenuItem>
            ))}
          </Select>
          <PlanFullDate className="plan-date">
            <Fonts.Blockquote>{periodOfThisWeek}</Fonts.Blockquote>
          </PlanFullDate>
        </PlanDateContent>
        <Button
          sx={{ borderRadius: 2, background: 'white', color: 'black', border: '1px solid #E7EAF4' }}
          size="small"
          style={{ minWidth: '48px' }}
          onClick={handleClickTodayBtn}
        >
          <b>오늘</b>
        </Button>
      </PlanDate>

      <PlanPriority
        plan={weeklyPlan}
        section={planSection}
        dragContext={dragContext}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragOver={handleDragOver}
        onPriorityDragStart={handlePriorityDragStart}
        onPriorityDragEnd={handlePriorityDragEnd}
        onPriorityDragOver={handlePriorityDragOver}
        onDropPriority={handleDropPriority}
        onRemovePriority={handleRemovePriority}
        onClickCheckbox={handleClickCheckbox}
      ></PlanPriority>
      <PlanSection
        planSection={planSection}
        weeklyPlan={weeklyPlan}
        dragContext={dragContext}
        onCreateSection={handleCreateSection}
        onPatchSection={handlePatchSection}
        onRemoveSection={handleRemoveSection}
        onCreateIssue={handleCreateIssue}
        onClickSaveIssue={handleClickSaveIssue}
        onClickDeleteIssue={handleClickDeleteIssue}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDrag={handleDrag}
        onDragOver={handleDragOver}
        onDrop={handleDropIssueItem}
        onClickMenu={handleClickContextMenu}
        onClickCheckbox={handleClickCheckbox}
      ></PlanSection>
    </Wrapper>
  );
};

export default Weekly;
