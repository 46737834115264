import { useEffect, useState } from 'react';
import { MenuItem, Select, Stack, Button } from '@mui/material';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { DATE_FORMAT_1, TIME_FORMAT_2, DATE_FORMAT_7 } from 'utils/datetimeFormat';
import toast from 'react-hot-toast';
import { COLORS } from 'styles/constants';

interface Props {
  onChangeTime?: (startTime: string, endTime: string, eventId: string) => void;
  onClose?: () => void;
  start?: string;
  end?: string;
  eventId?: string;
}

export const TimeSelectionModal = ({ onChangeTime = () => {}, start, end, eventId, onClose = () => {} }: Props) => {
  const [startTime, setStartTime] = useState<string | undefined>(start ? dayjs(start).format(DATE_FORMAT_7) : undefined);
  const [endTime, setEndTime] = useState<string | undefined>(end ? dayjs(end).format(DATE_FORMAT_7) : undefined);
  const [select, setSelect] = useState<boolean>(false);
  const selectableTime = [
    ...Array.from(Array(24 * 4).keys()).map((item) =>
      dayjs(start)
        .startOf('day')
        .add(item * 15, 'minutes')
        .format(DATE_FORMAT_7),
    ),
  ];
  const [endTimeSelection, setEndTimeSelection] = useState<string[]>([
    ...selectableTime.slice(selectableTime.findIndex((v) => v === dayjs(end).format(DATE_FORMAT_7))),
  ]);

  useEffect(() => {
    const end = select ? dayjs(startTime).add(1, 'hour') : endTime;
    setEndTime(dayjs(end).format(DATE_FORMAT_7));
    const index = selectableTime.findIndex((v) => v === dayjs(startTime).add(15, 'minute').format(DATE_FORMAT_7));
    const timeSelection = selectableTime.slice(index);
    setEndTimeSelection(timeSelection);
  }, [startTime]);

  const handleClickSave = () => {
    if (+dayjs(startTime) >= +dayjs(endTime)) {
      toast.error('시간을 올바르게 입력해주세요.');
      return;
    }
    onChangeTime(dayjs(startTime).format(DATE_FORMAT_1), dayjs(endTime).format(DATE_FORMAT_1), eventId!);
    onClose();
  };

  return (
    <Wrapper>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <Select
          value={startTime}
          onChange={(e) => {
            setStartTime(e.target.value), setSelect(true);
          }}
          style={{ width: 138, height: 40 }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 350,
              },
            },
          }}
        >
          {selectableTime.map((v, idx) => (
            <MenuItem key={idx} value={v}>
              <span>{dayjs(v).format(TIME_FORMAT_2)}</span>
            </MenuItem>
          ))}
        </Select>
        <span style={{ margin: '0 4px' }}>-</span>
        <Select
          value={endTime}
          onChange={(e) => setEndTime(e.target.value)}
          style={{ width: 138, height: 40 }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 372,
              },
            },
          }}
        >
          {endTimeSelection.map((v, idx) => (
            <MenuItem key={idx} value={v}>
              <span>{dayjs(v).format(TIME_FORMAT_2)}</span>
            </MenuItem>
          ))}
        </Select>
      </Stack>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{ mt: 2 }}>
        <Button
          variant="contained"
          disableElevation
          onClick={onClose}
          style={{ width: 138, height: 40, color: `${COLORS.black}`, backgroundColor: `${COLORS.gray100}` }}
        >
          취소
        </Button>

        <Button
          variant="contained"
          disableElevation
          onClick={() => {
            handleClickSave();
          }}
          style={{ width: 138, height: 40 }}
        >
          적용
        </Button>
      </Stack>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: 323px;
  height: 128px;
  background-color: ${COLORS.white};
  z-index: 100;
  box-shadow: 0px 8px 16px rgba(26, 30, 39, 0.16);
  border-radius: 8px;
  padding: 16px;
`;
