import { Checkbox, CheckboxProps } from '@mui/material';
import { Icons } from 'components';

export interface IssueCheckboxProps extends CheckboxProps {}

const TaskboxCheckbox = (props: IssueCheckboxProps) => {
  const UncheckedIcon = <Icons.TaskboxUncheck />;
  const CheckedIcon = <Icons.TaskboxCheck />;
  return (
    <Checkbox
      {...props}
      icon={UncheckedIcon}
      checkedIcon={CheckedIcon}
      sx={{ padding: 0, margin: 0, ...props.sx }}
      style={{ width: 20, height: 20, ...props.style }}
    />
  );
};
export default TaskboxCheckbox;
