import { meAtom } from 'atoms/user';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  // TODO: 추후 제거
  const navigate = useNavigate();
  const [me] = useAtom(meAtom);
  useEffect(() => {
    if (me.name) navigate('/task');
    else navigate('/');
  }, []);
  return <></>;
};
export default Home;
