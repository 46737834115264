import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Checkbox, Dialog, IconButton } from '@mui/material';
import { Icons } from 'components';
import IssueCheckbox from 'components/IssueCheckbox';
import { OutWork, UpdateIssueDetail } from 'queries/model';
import { COLORS } from 'styles/constants';
import { truncate } from 'styles/utils';
import { useAtom } from 'jotai';
import { dragContextAtom, WorkDragContext, WorkDragView } from 'atoms/works';
import InboxContextMenuPopover, { InboxContextMenuPopoverProps, InboxContextMenuType } from 'components/InboxContextMenuPopover';
import { useState } from 'react';
import IssueDetailView, { IssueDetailViewProps } from 'pages/Task/components/IssueDetailView';

const Container = styled.div<{ priority?: number; dragOver?: boolean }>`
  width: 100%;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  padding: 8px 8px;
  margin-bottom: 8px;
  border: 1px solid ${COLORS.gray200};
  background: ${COLORS.white};
  border-radius: 8px;

  :hover .issue-item-more-btn {
    opacity: 1;
  }

  ${(props) => {
    if (props.priority) {
      return css`
        border: 1px solid transparent;
        background: linear-gradient(#fff 0 0) padding-box, linear-gradient(90deg, #c471ed 0%, #f64f59 88.02%) border-box;
      `;
    }
    if (props.dragOver) {
      return css`
        border: 1px dashed ${COLORS.sub4};
        background: ${COLORS.sub5};
        & > * {
          background-color: ${COLORS.sub5} !important;
        }
      `;
    }
  }};
`;

const IssueItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
`;

const IssueItemTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 24px;
  cursor: pointer;
`;

const IssueMoreButtonWrapper = styled.div`
  display: flex;
  opacity: 0;
  transition: opacity 0.3s ease-out;
`;

const TextTruncate = styled.div`
  ${truncate('100%')};
`;

const IssueTaskWrapper = styled.div<{ selected?: boolean; ghosting?: boolean }>`
  width: 100%;
  height: 28px;
  display: ${(props) => (props.ghosting ? 'none' : 'flex')};
  align-items: center;
  border-radius: 4px;
  padding: 0px 8px;
  background: ${(props) => (props.selected ? COLORS.sub3 : COLORS.white)};
  transition: transform 0.1s !important;

  :hover {
    background: ${(props) => (props.selected ? COLORS.sub3 : `#f2f5fc`)};
  }
`;

interface IssueItemProps {
  issue: OutWork;
  priority?: number;
  draggable?: boolean;
  droppable?: boolean;
  dragView?: WorkDragView;
  hiddenContextMenus?: InboxContextMenuType[];
  onDrop?: (id: string, context?: WorkDragContext) => void;
  onChangeIssue?: (id: string, checked: boolean) => void;
  onChangeTask?: (id: string, checked: boolean) => void;
  onClickContextMenu?: InboxContextMenuPopoverProps['onClickMenu'];
  onCloseIssueDetailPopup?: () => void;
  onApplyIssueDetailPopup?: IssueDetailViewProps['onClickSave'];
}

const IssueItem = (props: IssueItemProps) => {
  const {
    issue,
    priority,
    draggable,
    droppable,
    dragView,
    hiddenContextMenus,
    onDrop,
    onChangeIssue,
    onChangeTask,
    onClickContextMenu,
    onCloseIssueDetailPopup,
    onApplyIssueDetailPopup,
  } = props;
  const [contextMenuPopover, setContextMenuPopover] = useState<HTMLElement | null>();
  const [isVisibleIssueDetailPopup, setVisibleIssueDetailPopup] = useState<boolean>(false);
  const [dragContext, setDragContext] = useAtom(dragContextAtom);
  const [isDragOver, setDragOver] = useState(false);

  const handleDragStart = () => {
    setDragContext({ id: issue.id, view: dragView, title: issue.content, type: 'issue', data: issue.tasks });
  };

  const handelDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    setDragOver(true);
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDrop = () => {
    setDragOver(false);
    droppable && onDrop?.(issue.id, dragContext!);
  };

  const handleDragEnd = () => {
    setDragContext(undefined);
    setDragOver(false);
  };

  const handleClick = () => {
    setVisibleIssueDetailPopup(true);
  };

  const handleChangeIssue = (id: string, checked: boolean) => {
    onChangeIssue?.(id, checked);
  };

  const handleChangeTask = (id: string, checked: boolean) => {
    onChangeTask?.(id, checked);
  };

  const handleClickMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setContextMenuPopover(e.currentTarget);
  };

  const handleCloseContextMenu = () => {
    setContextMenuPopover(null);
  };

  const handleClickContextMenu = (id: string, type: string, menu: InboxContextMenuType) => {
    onClickContextMenu?.(id, type, menu);
    setContextMenuPopover(null);
  };

  const handleClickCancelIssueDetailPopup = () => {
    onCloseIssueDetailPopup?.();
    setVisibleIssueDetailPopup(false);
  };

  const handleClickSaveIssueDetailPopup = (id: string, value: UpdateIssueDetail) => {
    onApplyIssueDetailPopup?.(id, value);
    setVisibleIssueDetailPopup(false);
  };

  return (
    <div>
      <Container
        priority={priority}
        onDragStart={handleDragStart}
        onDrag={handelDrag}
        onDragEnter={() => setDragOver(true)}
        onDragOver={handleDragOver}
        onDragLeave={() => setDragOver(false)}
        onDrop={handleDrop}
        onDragEnd={handleDragEnd}
        onClick={handleClick}
        draggable={draggable}
        dragOver={droppable && isDragOver}
      >
        <IssueItemWrapper>
          <IssueItemTitle>
            <IssueCheckbox
              style={{ marginRight: 8 }}
              checked={!!issue.doneAt}
              onChange={(_, checked) => handleChangeIssue(issue.id, checked)}
              onClick={(e) => onChangeIssue && e.stopPropagation()}
            />
            <TextTruncate>
              <span style={{ fontSize: 16, fontWeight: 'bold' }}>{issue.content || ''}</span>
            </TextTruncate>
          </IssueItemTitle>
          <IssueMoreButtonWrapper className="issue-item-more-btn" style={contextMenuPopover ? { opacity: 1 } : {}}>
            <IconButton aria-label="more" sx={{ background: 'white', padding: 0, marginLeft: '4px' }} size="small" onClick={handleClickMenu}>
              <Icons.ColorMore />
            </IconButton>
          </IssueMoreButtonWrapper>
        </IssueItemWrapper>
        {issue.tasks?.map((task) => (
          <IssueTaskWrapper key={task.id}>
            <Checkbox
              style={{ marginRight: 8 }}
              sx={{ padding: 0 }}
              checked={!!task.doneAt}
              onChange={(_, checked) => handleChangeTask(task.id!, checked)}
              onClick={(e) => onChangeTask && e.stopPropagation()}
            />
            <TextTruncate style={{ fontSize: 13 }}>{task.content}</TextTruncate>
          </IssueTaskWrapper>
        ))}
      </Container>
      {contextMenuPopover && (
        <InboxContextMenuPopover
          id={issue.id}
          type={'issue'}
          open={Boolean(contextMenuPopover)}
          anchorEl={contextMenuPopover}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          sx={{ marginTop: 0.5, marginLeft: 2 }}
          hiddenMenuItems={hiddenContextMenus}
          onClose={handleCloseContextMenu}
          onClickMenu={handleClickContextMenu}
        />
      )}
      {isVisibleIssueDetailPopup && (
        <Dialog
          open={true}
          sx={{ borderRadius: 8 }}
          PaperProps={{ style: { maxWidth: '100%' } }}
          onBackdropClick={handleClickCancelIssueDetailPopup}
          onClose={handleClickCancelIssueDetailPopup}
        >
          <IssueDetailView issueId={issue.id} onClickCancel={handleClickCancelIssueDetailPopup} onClickSave={handleClickSaveIssueDetailPopup} />
        </Dialog>
      )}
    </div>
  );
};

export default IssueItem;
