import styled from '@emotion/styled';
import { COLORS } from 'styles/constants';
import { EventProps, Event as RBCEvent } from 'react-big-calendar';
import dayjs from 'lib/dayjs';
import 'moment/locale/ko';
import { truncate } from 'styles/utils';
import { has } from 'lodash';

const Container = styled.div<{ done?: boolean }>`
  height: 100%;
  width: 100%;
  background-color: ${(props) => (props.done ? '#F9FAFE' : 'white')};
  border-radius: 8px;
`;

const EventWrapper = styled.div<{ done?: boolean }>`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 6px 6px 5px 8px;
`;

const AllDayEventWrapper = styled.div<{ done?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px 6px 4px 8px;
  height: 28px;
`;

const TextTruncate = styled.div`
  ${truncate('auto')};
`;

const AllDayEventTitle = styled.div<{ done?: boolean; color?: string }>`
  width: 100%;
  font-size: 13px;
  font-weight: bold;
  color: ${(props) => props.color};
  ${truncate('auto')};
`;

const EventTitle = styled.div<{ done?: boolean; color?: string }>`
  width: 100%;
  font-size: 13px;
  font-weight: bold;
  color: ${(props) => props.color};
`;

const VerticalBar = styled.div<{ done?: boolean }>`
  border-right: ${(props) => (props.done ? '2px solid #C8CDDB' : '2px solid #0039a7')};
  outline: 1px solid #e7eaf4;
  border-radius: 12px;
`;

export interface TaskboxInputProps {
  value?: string;
  onChange?: (value: string) => void;
}

export interface TodayCalendarEventProps extends EventProps<RBCEvent> {
  event: RBCEvent & {
    id?: string;
    type?: string;
    done?: boolean;
    lockedIn?: boolean;
  };
  onInput?: (event: RBCEvent, title: string) => void;
}
const WeekCalendarEvent = (props: TodayCalendarEventProps) => {
  const { event } = props;
  const range = `${dayjs(event.start).format('a hh:mm')} ~ ${dayjs(event.end).format('a hh:mm')}`;
  const isGreaterThanMinimum = Math.abs(dayjs(event?.start).diff(event?.end, 'minutes')) > 15;
  const textColor = event.type === 'task' ? (event.done ? COLORS.gray400 : COLORS.gray900) : COLORS.gray600;
  const isNewEvent = !has(event, 'data');

  return (
    <Container done={event.type === 'task' && event?.done}>
      {event.allDay ? (
        <AllDayEventWrapper>
          {event?.type === 'task' && <VerticalBar done={event.done} style={{ marginRight: 8, height: '80%' }} />}
          <AllDayEventTitle done={event.done} color={textColor}>
            {event?.lockedIn && <span style={{ marginRight: 4, fontSize: isGreaterThanMinimum ? '11px' : '10px' }}>{event.done ? '✅' : '🌟'}</span>}
            <span style={{ textDecoration: event.done ? 'line-through' : '' }}>{isNewEvent ? '' : event.title || '제목 없음'}</span>
          </AllDayEventTitle>
        </AllDayEventWrapper>
      ) : (
        <EventWrapper style={isGreaterThanMinimum ? {} : { padding: '4px 5px' }}>
          {event?.type === 'task' && <VerticalBar done={event.done} style={{ marginRight: 8 }} />}
          <div style={{ display: 'flex', flexDirection: 'column', width: 'calc(100% - 14px)', overflow: 'hidden' }}>
            <EventTitle done={event.done} color={textColor} style={{ fontSize: isGreaterThanMinimum ? '13px' : '10px' }}>
              {event?.lockedIn && <span style={{ marginRight: 4, fontSize: isGreaterThanMinimum ? '11px' : '10px' }}>{event.done ? '✅' : '🌟'}</span>}
              <span style={{ textDecoration: event.done ? 'line-through' : '' }}>{isNewEvent ? '' : event.title || '제목 없음'}</span>
            </EventTitle>
            <TextTruncate style={{ marginTop: 6 }}>{range}</TextTruncate>
          </div>
        </EventWrapper>
      )}
    </Container>
  );
};

export default WeekCalendarEvent;
