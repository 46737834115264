import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Button, Checkbox, IconButton, Popover, TextField } from '@mui/material';
import { Icons } from 'components';
import { COLORS } from 'styles/constants';
import MUICheckbox from 'components/MuiCheckbox';
import { UpdateIssueDetail } from 'queries/model';
import dayjs from 'lib/dayjs';
import TaskBlockInputList, { TaskBlock } from './TaskBlockInputList';
import InboxContextMenuPopover, { InboxContextMenuType } from 'components/InboxContextMenuPopover';
import { readIssueV1WorksIssuesIssueIdGet, removeTaskV1WorksWorkIdDelete } from 'queries';
import toast from 'react-hot-toast';
import { StaticDatePicker } from '@mui/x-date-pickers';
import IssueCheckbox from 'components/IssueCheckbox';
import Fonts from 'components/Fonts';

const Container = styled.div`
  width: 732px;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  display: flex;
  margin-top: 24px;
  min-height: 392px;
  max-height: 392px;
`;

const IssueTaskWrapper = styled.div`
  width: 500px;
  padding-right: 24px;
`;

const AdditiveWrapper = styled.div`
  border-left: 1px solid #e7eaf4;
  padding-left: 24px;
  width: 232px;
`;

const FooterWrapper = styled.div`
  margin-top: 24px;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
`;

const IssueTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const IssueDescriptionWrapper = styled.div`
  margin: 8px 0px 24px 0px;
`;

const TaskListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`;

const SelectableTaskList = styled.div`
  width: 100%;
  max-height: 240px;
  overflow: hidden;
  overflow-y: auto;
`;

const TaskContentWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  padding-left: 4px;

  &:nth-last-of-type(1) {
    margin-bottom: 24px;
  }
`;

const TaskContents = styled.div<{ selected?: boolean }>`
  width: 100%;
  min-height: 28px;
  padding: 0px 6px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;
  background-color: ${(props) => props.selected && COLORS.sub3};
`;

const CalendarInitButton = styled.button`
  margin-top: -1px;
  width: 100%;
  height: 48px;
  font-size: 12px;
  color: ${COLORS.gray500};

  :hover {
    color: ${COLORS.gray900};
  }
`;

const IssuePriorityWrapper = styled.div`
  margin-bottom: 4px;
  background: linear-gradient(90deg, #c471ed 0%, #f64f59 88.02%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export interface IssueDetailViewProps {
  issueId: string;
  sectionTitle?: string;
  priority?: number;
  selectable?: boolean;
  onClickCancel?: () => void;
  onClickSave?: (id: string, value: UpdateIssueDetail) => void;
}

const IssueDetailView = ({ issueId, sectionTitle, priority, selectable, onClickCancel, onClickSave }: IssueDetailViewProps) => {
  const [issue, setIssue] = useState<UpdateIssueDetail & { id?: string }>({});
  const [toggleSelection, setToggleSelection] = useState(false);
  const [selectedTaskIds, setSelectedTaskIds] = useState<string[]>([]);
  const [contextMenuPopover, setContextMenuPopover] = useState<HTMLElement | null>(null);
  const [calendarPopover, setCalendarPopover] = useState<HTMLElement | null>(null);

  useEffect(() => {
    fetchIssue(issueId);
  }, []);

  const fetchIssue = async (id: string) => {
    const data = await readIssueV1WorksIssuesIssueIdGet(id);
    setIssue(data);
  };

  const handleClickClose = () => {
    onClickCancel && onClickCancel();
  };

  const handleClickSave = async () => {
    onClickSave && onClickSave(issue.id!, issue);
  };

  const handleClickTask = (id: string) => {
    setSelectedTaskIds(selectedTaskIds.includes(id) ? selectedTaskIds.filter((item) => item !== id) : [...selectedTaskIds, id]);
  };

  const handleChangeTaskBlocks = (tasks: { id: string; content?: string; dueDate?: string }[] = []) => {
    const taskIds = tasks?.map((item) => item.id) || [];
    setSelectedTaskIds(selectedTaskIds.filter((item) => taskIds.includes(item)));
    setIssue({ ...issue, tasks: tasks });
  };

  const handleToggleSelection = () => {
    const taskIds = issue?.tasks?.map((item) => item.id) || [];
    setSelectedTaskIds(selectedTaskIds.filter((item) => taskIds.includes(item)));
    setToggleSelection(!toggleSelection);
  };

  const handleClickContextMenu = async (id: string, type: string, menu: InboxContextMenuType) => {
    try {
      switch (menu) {
        // case 'SWITCH_TO_TASK':
        // await updateTaskV1WorksWorkIdPut(id, { type: 'task' });
        // toast.success('이슈를 태스크로 전환하였습니다.');
        // break;
        case 'DELETE':
          await removeTaskV1WorksWorkIdDelete(id);
          toast.success(`${type === 'task' ? '태스크' : '이슈'}를 삭제하였습니다.`);
          onClickCancel && onClickCancel();
          break;
      }
    } catch (e) {
      toast.error('작업을 수행할 수 없습니다.');
    }

    setContextMenuPopover(null);
  };

  const handleChangeCalendar = async (value: Date | null) => {
    const dueDate = value ? (dayjs.isDayjs(value) ? value.format('YYYY-MM-DD') : dayjs(value).format('YYYY-MM-DD')) : undefined;
    setIssue({ ...issue, dueDate: dueDate });
    setCalendarPopover(null);
  };

  return (
    <Container>
      <Title>이슈 상세</Title>
      <ContentWrapper>
        <IssueTaskWrapper>
          {priority! >= 1 && (
            <IssuePriorityWrapper>
              <Fonts.Blockquote>이번주 중요한 일</Fonts.Blockquote>
            </IssuePriorityWrapper>
          )}

          <IssueTitleWrapper>
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <IssueCheckbox
                style={{ marginRight: 8, width: 24, height: 24 }}
                checked={!!issue.done}
                onChange={(e) => setIssue({ ...issue, done: e.currentTarget.checked })}
              />
              <TextField
                autoComplete="off"
                fullWidth
                variant="standard"
                placeholder="이슈 이름을 입력해주세요."
                value={issue?.content || ''}
                onChange={(e) => setIssue({ ...issue, content: e.currentTarget.value })}
                InputProps={{ disableUnderline: true, style: { fontWeight: 'bold', color: COLORS.gray900 } }}
                style={{ marginRight: 8 }}
              />
            </div>
            <div>
              <IconButton aria-label="more" sx={{ background: 'white', padding: 0 }} size="small" onClick={(e) => setContextMenuPopover(e.currentTarget)}>
                <Icons.ColorMore width={24} height={24} />
              </IconButton>
            </div>
          </IssueTitleWrapper>
          <IssueDescriptionWrapper>
            <TextField
              multiline
              maxRows={3}
              fullWidth
              minRows={3}
              size="small"
              autoComplete="off"
              placeholder="설명을 입력해주세요."
              InputProps={{ style: { color: COLORS.gray900, fontSize: 12 } }}
              value={issue?.description}
              onChange={(e) => setIssue({ ...issue, description: e.currentTarget.value || '' })}
            />
          </IssueDescriptionWrapper>
          <TaskListWrapper>
            <SelectableTaskList>
              {toggleSelection &&
                issue?.tasks?.map((v) => (
                  <TaskContentWrapper key={v.id} onClick={() => handleClickTask(v.id!)}>
                    <Checkbox
                      icon={<Icons.RadioDisableUnchecked />}
                      checkedIcon={<Icons.RadioChecked />}
                      checked={selectedTaskIds.includes(v.id!)}
                      size="small"
                      sx={{ padding: 0 }}
                      style={{ marginRight: 8 }}
                    />
                    <TaskContents selected={selectedTaskIds.includes(v.id!)}>
                      <MUICheckbox style={{ marginRight: 8 }} disabled />
                      <div style={{ overflowWrap: 'anywhere', fontSize: 13 }}>{v.content}</div>
                    </TaskContents>
                  </TaskContentWrapper>
                ))}
            </SelectableTaskList>
            <div hidden={toggleSelection} style={{ marginLeft: -16 }}>
              <TaskBlockInputList tasks={(issue?.tasks as TaskBlock[]) || []} onChange={handleChangeTaskBlocks} draggable={true} />
            </div>
          </TaskListWrapper>
        </IssueTaskWrapper>
        <AdditiveWrapper>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ fontSize: 13, fontWeight: 'bold' }}>기한</div>
            <div style={{ fontSize: 13, display: 'flex', alignItems: 'center', gap: 4 }} onClick={(e) => setCalendarPopover(e.currentTarget)}>
              <IconButton sx={{ padding: 0 }}>
                <Icons.CalendarWithoutClock width={16} height={16} />
              </IconButton>
              <span style={{ cursor: 'pointer' }}>{issue?.dueDate && dayjs(issue?.dueDate).format('MM.DD ddd요일')}</span>
            </div>
          </div>
          {sectionTitle && (
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
              <div style={{ fontSize: 13, fontWeight: 'bold' }}>섹션</div>
              <div style={{ fontSize: 13, display: 'flex', alignItems: 'center', gap: 4 }}>
                <Icons.DonutLarge width={16} height={16} />
                <Fonts.Body2>{sectionTitle}</Fonts.Body2>
              </div>
            </div>
          )}
        </AdditiveWrapper>
      </ContentWrapper>
      <FooterWrapper>
        <div style={{ display: 'flex', justifyContent: selectable ? 'space-between' : 'flex-end', alignItems: 'center' }}>
          {selectable && (
            <Button variant="text" onClick={handleToggleSelection}>
              {toggleSelection ? '선택 완료하기' : '선택된 태스크'}
              {`(${selectedTaskIds.length})`}
            </Button>
          )}
          <div>
            <Button disableElevation color="inherit" variant="contained" onClick={handleClickClose} style={{ background: COLORS.gray200 }}>
              취소하기
            </Button>
            <Button disableElevation color="primary" variant="contained" onClick={handleClickSave} style={{ marginLeft: 8 }}>
              저장하기
            </Button>
          </div>
        </div>
      </FooterWrapper>
      {contextMenuPopover && issue && (
        <InboxContextMenuPopover
          id={issue.id!}
          type={'issue'}
          open={Boolean(contextMenuPopover)}
          anchorEl={contextMenuPopover}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          sx={{ marginTop: 0.5, marginLeft: 2 }}
          hiddenMenuItems={
            issue.tasks?.length ? ['SWITCH_TO_ISSUE', 'SWITCH_TO_TASK', 'MOVE_TO_AFTER'] : ['SWITCH_TO_ISSUE', 'SWITCH_TO_TASK', 'MOVE_TO_AFTER']
          }
          onClose={() => setContextMenuPopover(null)}
          onClickMenu={handleClickContextMenu}
        />
      )}
      {calendarPopover && (
        <Popover
          open={Boolean(calendarPopover)}
          anchorEl={calendarPopover}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          sx={{ marginTop: 0.5 }}
          onClose={() => setCalendarPopover(null)}
        >
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            value={new Date(issue?.dueDate || '')}
            onChange={handleChangeCalendar}
            renderInput={(params) => <TextField {...params} />}
          />
          <CalendarInitButton onClick={() => handleChangeCalendar(null)}>지정 안함</CalendarInitButton>
        </Popover>
      )}
    </Container>
  );
};

export default IssueDetailView;
