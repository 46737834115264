import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button, TextField } from '@mui/material';
import { Icons } from 'components';
import Fonts from 'components/Fonts';
import { Plus } from 'components/Icons';
import { CreatePlanSection, OutPlanWeekly, OutWork, UpdateIssueDetail, UpdatePlanSection, UpdateWork } from 'queries/model';
import { COLORS } from 'styles/constants';
import { DragContext, PlanSections } from '.';
import { useRef, useState } from 'react';
import { useEffect } from 'react';
import WeeklyPlanMenuPopover from './Components/WeeklyPlanMenuPopover';
import { PlanIssue } from './PlanIssue';
import { InboxContextMenuType } from 'components/InboxContextMenuPopover';
interface PlanSectionProps {
  planSection?: PlanSections[];
  weeklyPlan?: OutPlanWeekly[];
  dragContext?: DragContext;
  onCreateSection?: (title: string) => void;
  onPatchSection?: (id: string, section: UpdatePlanSection) => void;
  onRemoveSection?: (id: string) => void;
  onCreateIssue?: (value: string, sectionId: string) => void;
  onClickSaveIssue?: (id: string, value: UpdateIssueDetail) => void;
  onClickDeleteIssue?: (id: string) => void;
  onDragStart?: (sectionId: string, issueId: string) => void;
  onDragEnd?: () => void;
  onDrag?: (e: React.DragEvent<HTMLDivElement>, id: string) => void;
  onDragOver?: (e: React.DragEvent<HTMLDivElement>, id: string) => void;
  onDrop?: (sectionId: string) => void;
  onClickMenu?: (id: string, type: string, menu: InboxContextMenuType) => void;
  onClickCheckbox?: (issueId: string, issue: UpdateWork) => void;
}

const Container = styled.div`
  height: 500px;
  margin-top: 36px;
  display: flex;
  align-items: flex-start;
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

const DefaultSection = styled.div``;

const PlanSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 22px;
`;

const PlanSectionHeader = styled.div`
  width: 310px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const CreateIssueWrapper = styled.div`
  width: 100%;
  min-height: 40px;
  display: flex;
  align-items: center;
  background: ${COLORS.white};
  border-radius: 8px;
  border: 1px solid ${COLORS.gray200};
  padding: 0px 16px;
  margin-bottom: 8px;
`;

const IssueDropArea = styled.div`
  width: 100%;
  min-height: 400px;
  background-color: ${COLORS.sub3};
  border-radius: 8px;
  border: 1px dashed ${COLORS.brand1};
`;

const PlanSectionMain = styled.div<{ isVisibleSectionInput?: boolean }>`
  width: 100%;
  min-height: 400px;
  max-height: 400px;
  border-radius: 8px;
  background-color: ${COLORS.gray200};
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

const PlanSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 22px;
  color: ${COLORS.gray500};
`;

const PlanIcons = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const PlanAddSection = styled.div`
  min-width: 95px;
  display: flex;
  align-items: center;
  color: ${COLORS.gray600};
  cursor: pointer;
  margin-top: 2.8px;
`;

export const PlanSection = ({
  planSection = [],
  weeklyPlan = [],
  dragContext,
  onCreateSection = () => {},
  onPatchSection = () => {},
  onRemoveSection = () => {},
  onCreateIssue = () => {},
  onClickSaveIssue = () => {},
  onClickDeleteIssue = () => {},
  onDragStart = () => {},
  onDragEnd = () => {},
  onDrag = () => {},
  onDragOver = () => {},
  onDrop = () => {},
  onClickMenu = () => {},
  onClickCheckbox = () => {},
}: PlanSectionProps) => {
  const [defaultSection, setDefaultSection] = useState<PlanSections>({
    id: 'default',
    name: '섹션 없음',
  });
  const [defaultSectionIssue, setDefaultSectionIssue] = useState<OutWork[]>([]);
  const [sections, setSections] = useState<PlanSections[]>([]);
  const [sectionTitle, setSectionTitle] = useState<string>();
  const [issueTitle, setIssueTitle] = useState<string>();
  const [selectedSection, setSelectedSection] = useState<string>();
  const [clickedMenu, setClickedMenu] = useState({ id: '', type: '' });
  const [isVisibleSectionInput, setIsVisibleSectionInput] = useState(false);
  const [isVisibleIssueInput, setIsVisibleIssueInput] = useState(false);
  const refCreateSectionInput = useRef<HTMLInputElement>(null);
  const refIssueInput = useRef<HTMLInputElement>(null);
  const [sectionMenuPopover, setSectionMenuPopover] = useState<HTMLElement | null>(null);
  const [issueMenuPopover, setIssueMenuPopover] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setSections(planSection);
    const issue = weeklyPlan.filter((item) => item.sectionId === null).map((v) => v.issue);
    setDefaultSectionIssue(issue);
  }, [planSection]);

  const handleSectionAddBtn = () => {
    const newSection: CreatePlanSection = {
      name: '',
    };
    setIsVisibleSectionInput(true);
    setSections([...sections, newSection]);
    setTimeout(() => refCreateSectionInput.current?.focus(), 100);
  };

  const handleRemoveSection = (id: string) => {
    setSectionMenuPopover(null);
    onRemoveSection(id);
  };

  const handleKeydownSectionInput = (e: React.KeyboardEvent<HTMLDivElement>, id?: string, section?: PlanSections) => {
    if (refCreateSectionInput.current) {
      const value = refCreateSectionInput.current?.value;

      if (e.key === 'Escape') {
        if (value) return;

        e.preventDefault();
        sections.pop();
        setSections(sections);
        setIsVisibleSectionInput(false);
      }

      if (e.key === 'Enter') {
        if (e.nativeEvent.isComposing) return;
        if (e.repeat) {
          e.preventDefault();
          return;
        }

        onCreateSection(value);
        setIsVisibleSectionInput(false);
      }
    }

    if (section) {
      if (e.key === 'Escape') {
        e.preventDefault();
        setBlurElement(id!);
      }

      if (e.key === 'Enter') {
        if (e.nativeEvent.isComposing) return;
        if (e.repeat) {
          e.preventDefault();
          return;
        }
        setBlurElement(id!);
      }
    }
  };

  const handleBlurSectionInput = (id?: string, section?: PlanSections) => {
    refCreateSectionInput.current;
    if (refCreateSectionInput.current) {
      if (refCreateSectionInput.current?.value.length === 0) {
        sections.pop();
        setSections(sections);
      } else {
        onCreateSection(refCreateSectionInput.current.value!);
      }
      setIsVisibleSectionInput(false);
    }

    if (section) {
      if (!!sectionTitle && section?.name !== sectionTitle) {
        const updateSection: UpdatePlanSection = {
          name: sectionTitle!,
        };
        onPatchSection(id!, updateSection);
      }
    }
  };

  const handleCreateIssue = (id: string) => {
    setSelectedSection(id);
    setIsVisibleIssueInput(true);
    setFocusElement(id);
  };

  const setFocusElement = (id: string) => {
    setTimeout(() => {
      const el = document.querySelector(`[data-id="${id}"]`) as HTMLElement;
      const element = el.querySelector('input') as HTMLElement;

      element && element.focus();
    }, 100);
  };

  const setBlurElement = (id: string) => {
    setTimeout(() => {
      const el = document.querySelector(`[data-sectionid="${id}"]`) as HTMLElement;
      const element = el.querySelector('input') as HTMLElement;

      element && element.blur();
    }, 100);
  };

  const handleKeydownIssueInput = async (e: React.KeyboardEvent<HTMLDivElement>, sectionId: string) => {
    if (!refIssueInput.current) return;

    const value = issueTitle!;
    if (e.key === 'Escape') {
      if (value) return;
      e.preventDefault();
      setIsVisibleIssueInput(false);
    }

    if (e.key === 'Enter') {
      if (e.nativeEvent.isComposing) return;
      if (e.repeat) {
        e.preventDefault();
        return;
      }

      onCreateIssue(value, sectionId);
      setIssueTitle('');
      refIssueInput.current.value = '';
    }
  };

  return (
    <Container>
      <DefaultSection>
        <PlanSectionWrapper>
          <PlanSectionHeader>
            <TextField
              disabled={true}
              autoComplete="off"
              fullWidth
              variant="standard"
              color="success"
              data-sectionid={defaultSection.id}
              defaultValue={defaultSection.name}
              onKeyDown={(e) => handleKeydownSectionInput(e, defaultSection.id, defaultSection)}
              onBlur={() => {
                handleBlurSectionInput(defaultSection.id, defaultSection);
              }}
              onChange={(e) => {
                setSectionTitle(e.target.value);
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  fontSize: '16px',
                  fontWeight: 700,
                  color: COLORS.gray900,
                  height: '30px',
                  padding: '4px 16px 4px 0px',
                  width: 'auto',
                },
              }}
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#000000',
                },
              }}
            />
            {/* <Fonts.H3 style={{ color: `${COLORS.gray400}` }}>{`(${defaultSectionIssue.length})`}</Fonts.H3> */}

            <PlanIcons>
              <div onClick={() => handleCreateIssue(defaultSection.id!)}>
                <Icons.Plus width={16} height={16}></Icons.Plus>
              </div>
            </PlanIcons>
          </PlanSectionHeader>

          {selectedSection === defaultSection.id && isVisibleIssueInput && (
            <CreateIssueWrapper>
              <Icons.IssueCheck style={{ marginRight: 8 }} />
              <TextField
                data-id={defaultSection.id}
                autoComplete="off"
                fullWidth
                variant="standard"
                placeholder="이슈 제목을 입력해주세요."
                inputRef={refIssueInput}
                onKeyDown={(e) => {
                  handleKeydownIssueInput(e, defaultSection.id!);
                }}
                onChange={(e) => {
                  setIssueTitle(e.target.value);
                }}
                onBlur={() => !refIssueInput?.current?.value && setIsVisibleIssueInput(false)}
                InputProps={{ disableUnderline: true, style: { fontSize: 13, color: COLORS.gray600 } }}
              />
            </CreateIssueWrapper>
          )}

          {selectedSection === defaultSection.id && isVisibleIssueInput && defaultSectionIssue.length === 0 ? (
            <div></div>
          ) : (
            <PlanSectionMain
              hidden={!!dragContext?.draggingIssueId && dragContext.draggingSectionId !== defaultSection.id}
              style={defaultSectionIssue.length !== 0 ? { backgroundColor: `${COLORS.gray100}` } : {}}
            >
              {defaultSectionIssue.length !== 0 ? (
                <PlanIssue
                  sectionIssue={defaultSectionIssue}
                  section={sections}
                  weeklyPlan={weeklyPlan}
                  sectionId={defaultSection.id}
                  clickedMenu={clickedMenu}
                  onClickSaveIssue={onClickSaveIssue}
                  onDragStart={onDragStart}
                  onDragEnd={onDragEnd}
                  onDrag={onDrag}
                  onDragOver={onDragOver}
                  onDrop={onDrop}
                  onClickMenu={onClickMenu}
                ></PlanIssue>
              ) : (
                <PlanSectionContent>
                  <Icons.PriorityIssueUncheck stroke={COLORS.gray400} width={36} height={36}></Icons.PriorityIssueUncheck>
                  <Fonts.H5 style={{ marginTop: '12px' }}>어떤 일들을 해내고 싶나요?</Fonts.H5>
                  <Fonts.Blockquote style={{ marginTop: '4px' }}>이번 주 해야 할 이슈를 추가해주세요.</Fonts.Blockquote>
                  <Button sx={{ color: `${COLORS.gray800}`, backgroundColor: `${COLORS.white}`, padding: '6px 12px', borderRadius: '8px', marginTop: '12px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => handleCreateIssue(defaultSection.id!)}>
                      <Icons.Plus width={16} height={16} fill={`${COLORS.gray900}`}></Icons.Plus>
                      <Fonts.H4 style={{ marginLeft: '4px' }}>이슈 만들기</Fonts.H4>
                    </div>
                  </Button>
                </PlanSectionContent>
              )}
            </PlanSectionMain>
          )}

          {dragContext!.draggingSectionId !== defaultSection.id && (
            <IssueDropArea
              onDrop={() => onDrop(defaultSection.id!)}
              onDragOver={(e) => onDragOver(e, defaultSection.id!)}
              onDragEnd={onDragEnd}
              hidden={!dragContext?.draggingIssueId}
              style={
                dragContext?.dragging === true && dragContext.dragOverId === defaultSection.id
                  ? { backgroundColor: `${COLORS.sub5}`, borderColor: `${COLORS.sub4}` }
                  : {}
              }
            ></IssueDropArea>
          )}
        </PlanSectionWrapper>
      </DefaultSection>

      {sections?.map((item, idx) => {
        const sectionIssue = weeklyPlan.filter((plan) => plan.sectionId === item.id).map((v) => v.issue);

        return (
          <PlanSectionWrapper key={idx}>
            {isVisibleSectionInput && !item.id ? (
              <PlanSectionHeader>
                <TextField
                  inputRef={refCreateSectionInput}
                  autoComplete="off"
                  fullWidth
                  variant="standard"
                  placeholder="새로운 섹션 만들기"
                  onKeyDown={handleKeydownSectionInput}
                  onBlur={() => {
                    handleBlurSectionInput();
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      fontSize: 13,
                      color: COLORS.gray600,
                      backgroundColor: 'white',
                      border: `1px solid ${COLORS.gray300}`,
                      borderRadius: '8px',
                      height: '30px',
                      padding: '4px 16px',
                    },
                  }}
                />
              </PlanSectionHeader>
            ) : (
              <PlanSectionHeader>
                <TextField
                  autoComplete="off"
                  fullWidth
                  variant="standard"
                  color="success"
                  data-sectionid={item.id}
                  defaultValue={item.name}
                  onKeyDown={(e) => handleKeydownSectionInput(e, item.id, item)}
                  onBlur={() => {
                    handleBlurSectionInput(item.id, item);
                  }}
                  onChange={(e) => {
                    setSectionTitle(e.target.value);
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      fontSize: '16px',
                      fontWeight: 700,
                      color: COLORS.gray900,
                      height: '30px',
                      padding: '4px 16px 4px 0px',
                      width: 'auto',
                    },
                  }}
                />
                {/* <Fonts.H3 style={{ color: `${COLORS.gray400}` }}>{`(${sectionIssue.length})`}</Fonts.H3> */}

                <PlanIcons>
                  <div onClick={() => handleCreateIssue(item.id!)}>
                    <Icons.Plus width={16} height={16}></Icons.Plus>
                  </div>
                  <div
                    style={{ marginLeft: '8px' }}
                    onClick={(e) => {
                      setSectionMenuPopover(e.currentTarget);
                      setClickedMenu({ id: item.id!, type: 'section' });
                    }}
                  >
                    <Icons.ColorMore></Icons.ColorMore>
                  </div>
                </PlanIcons>
              </PlanSectionHeader>
            )}
            {selectedSection === item.id && isVisibleIssueInput && (
              <CreateIssueWrapper>
                <Icons.IssueCheck style={{ marginRight: 8 }} />
                <TextField
                  data-id={item.id}
                  inputRef={refIssueInput}
                  autoComplete="off"
                  fullWidth
                  variant="standard"
                  placeholder="이슈 제목을 입력해주세요."
                  onKeyDown={(e) => {
                    handleKeydownIssueInput(e, item.id!);
                  }}
                  onChange={(e) => {
                    setIssueTitle(e.target.value);
                  }}
                  onBlur={() => !refIssueInput?.current?.value && setIsVisibleIssueInput(false)}
                  InputProps={{ disableUnderline: true, style: { fontSize: 13, color: COLORS.gray600 } }}
                />
              </CreateIssueWrapper>
            )}

            {selectedSection === item.id && isVisibleIssueInput && sectionIssue.length === 0 ? (
              <div></div>
            ) : (
              <PlanSectionMain
                hidden={!!dragContext?.draggingIssueId && dragContext.draggingSectionId !== item.id}
                style={sectionIssue.length !== 0 ? { backgroundColor: `${COLORS.gray100}` } : {}}
              >
                {sectionIssue.length !== 0 ? (
                  <PlanIssue
                    sectionIssue={sectionIssue}
                    section={sections}
                    weeklyPlan={weeklyPlan}
                    sectionId={item.id}
                    clickedMenu={clickedMenu}
                    onClickSaveIssue={onClickSaveIssue}
                    onDragStart={onDragStart}
                    onDragEnd={onDragEnd}
                    onDrag={onDrag}
                    onDragOver={onDragOver}
                    onDrop={onDrop}
                    onClickMenu={onClickMenu}
                    onClickCheckbox={onClickCheckbox}
                  ></PlanIssue>
                ) : (
                  <PlanSectionContent>
                    <div style={{ display: 'flex', alignItems: 'center', width: '90%' }} onClick={() => handleCreateIssue(item.id!)}>
                      <Button
                        sx={{
                          color: `${COLORS.gray800}`,
                          backgroundColor: `${COLORS.gray200}`,
                          padding: '6px 12px',
                          borderRadius: '8px',
                          marginTop: '0px',
                          width: '100%',
                        }}
                      >
                        <Icons.Plus width={16} height={16} fill={`${COLORS.gray900}`}></Icons.Plus>
                        <Fonts.H4 style={{ marginLeft: '4px' }}>이슈 만들기</Fonts.H4>
                      </Button>
                    </div>
                  </PlanSectionContent>
                )}
              </PlanSectionMain>
            )}

            {dragContext!.draggingSectionId !== item.id && (
              <IssueDropArea
                onDrop={() => onDrop(item.id!)}
                onDragOver={(e) => onDragOver(e, item.id!)}
                onDragEnd={onDragEnd}
                hidden={!dragContext?.draggingIssueId}
                style={
                  dragContext?.dragging === true && dragContext.dragOverId === item.id
                    ? { backgroundColor: `${COLORS.sub5}`, borderColor: `${COLORS.sub4}` }
                    : {}
                }
              ></IssueDropArea>
            )}
          </PlanSectionWrapper>
        );
      })}

      {isVisibleSectionInput === false && (
        <PlanAddSection onClick={handleSectionAddBtn}>
          <div style={{ cursor: 'pointer', marginRight: '4px' }}>
            <Plus width={16} height={16}></Plus>
          </div>
          <Fonts.Body1>섹션 추가</Fonts.Body1>
        </PlanAddSection>
      )}

      {sectionMenuPopover && (
        <WeeklyPlanMenuPopover
          clickedMenu={clickedMenu!}
          open={Boolean(sectionMenuPopover)}
          anchorEl={sectionMenuPopover}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          sx={{ marginTop: 0.5, marginLeft: 2 }}
          onClose={() => {
            setSectionMenuPopover(null);
            setClickedMenu({ id: '', type: '' });
          }}
          onClickDelete={handleRemoveSection}
        ></WeeklyPlanMenuPopover>
      )}

      {issueMenuPopover && (
        <WeeklyPlanMenuPopover
          clickedMenu={clickedMenu}
          open={Boolean(issueMenuPopover)}
          anchorEl={issueMenuPopover}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          sx={{ marginTop: 0.5, marginLeft: 2 }}
          onClose={() => {
            setIssueMenuPopover(null);
            setClickedMenu({ id: '', type: '' });
          }}
          onClickDelete={onClickDeleteIssue}
        ></WeeklyPlanMenuPopover>
      )}
    </Container>
  );
};
