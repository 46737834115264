import { useState } from 'react';
import styled from '@emotion/styled';
import { Icons } from 'components';
import { OutEvent } from 'queries/model';
import { COLORS } from 'styles/constants';
import { Button, Divider, IconButton, Stack, Typography } from '@mui/material';
import dayjs from 'lib/dayjs';
import MeetingCalendarView from './components/CalendarViews/MeetingCalendarView';
import { DATE_FORMAT_2, DATE_FORMAT_3, DATE_FORMAT_7 } from 'utils/datetimeFormat';
import CalendarPopover from 'components/CalendarPopover';
import ArrowToggleButton from 'components/ArrowToggleButton';

const CalendarViewWrapper = styled.div`
  height: 100%;
  min-width: 300px;
  padding: 0px 30px;
  background-color: #f2f5fc;
`;

const CalendarViewControlWrapper = styled.div`
  height: 170px;
`;

const CalendarViewDaySchedulerWrapper = styled.div`
  height: calc(100% - 194px);
`;

export interface CalendarViewProps {
  events?: OutEvent[];
  currentDate: Date;
  onRefresh?: () => void;
  onSelectEvent?: (eventId: string) => void;
  onUpdateEvent?: (eventId: string, start: string, end: string) => void;
  onClickOpenEventModal?: (event: Partial<OutEvent>) => void;
  onChangeCurrentDate?: (date: Date) => void;
}

const CalendarView = ({ events = [], currentDate, onRefresh, onSelectEvent, onUpdateEvent, onClickOpenEventModal, onChangeCurrentDate }: CalendarViewProps) => {
  const [isVisibleCalendarPopover, setIsVisibleCalendarPopover] = useState(false);

  const createEventData = (event: OutEvent) => ({
    id: event.eventId || '',
    title: event.title,
    start: dayjs(event.startTime).toDate(),
    end: dayjs(event.endTime).toDate(),
    created: event.createdAt ? `생성됨: ${dayjs(event.createdAt).format(DATE_FORMAT_2)}` : '',
    data: event,
  });

  const handleClickCalendarPopoverItem = (value: Date | null) => {
    if (!value) return;

    setIsVisibleCalendarPopover(false);
    onChangeCurrentDate && onChangeCurrentDate(value);
  };

  const handleCalendarSelectEvent = async (id: string | undefined) => {
    if (!id) return;
    onSelectEvent && onSelectEvent(id);
  };

  const handleClickCreateEvent = () => {
    onClickOpenEventModal &&
      onClickOpenEventModal({
        startTime: dayjs(currentDate).ceil('minutes', 15).format(DATE_FORMAT_7),
        endTime: dayjs(currentDate).add(15, 'minutes').ceil('minutes', 15).format(DATE_FORMAT_7),
      });
  };

  const handleSelectSlot = async ({ start, end }: { start: string; end: string }) => {
    onClickOpenEventModal &&
      onClickOpenEventModal({
        startTime: dayjs(start).format(DATE_FORMAT_7),
        endTime: dayjs(end).format(DATE_FORMAT_7),
      });
  };

  const handleClickFloatingAction = (e: React.MouseEvent<HTMLButtonElement>, data: unknown) => {
    if (!data) return;
    const event = data as OutEvent;

    onClickOpenEventModal &&
      onClickOpenEventModal({
        eventId: event.eventId,
        title: event.title,
        startTime: dayjs(event.startTime).format(DATE_FORMAT_7),
        endTime: dayjs(event.endTime).format(DATE_FORMAT_7),
        participants: event.participants,
        location: event.location,
      });
  };

  const handleUpdateEvent = async ({ id, start, end }: { id: string; start: string; end: string }) => {
    onUpdateEvent && onUpdateEvent(id, start, end);
  };

  const handleChangeCurrentDate = (date: Date) => {
    onChangeCurrentDate && onChangeCurrentDate(date);
  };

  return (
    <CalendarViewWrapper>
      <CalendarViewControlWrapper>
        <Stack direction="row" justifyContent="space-between" style={{ marginTop: 24 }}>
          <Stack direction="row" alignItems="center">
            <Typography variant="h5" fontWeight={'bold'}>
              일정
            </Typography>
            <IconButton aria-label="sync" sx={{ background: 'white' }} style={{ marginLeft: 8 }} onClick={onRefresh}>
              <Icons.Reload />
            </IconButton>
          </Stack>
          <Stack direction="row" alignItems="center" style={{ cursor: 'pointer' }}>
            <IconButton aria-label="search">
              <Icons.ListSearch />
            </IconButton>
            <Typography variant="subtitle2" fontWeight={'bold'}>
              회의록 찾기
            </Typography>
          </Stack>
        </Stack>
        <Divider style={{ margin: '16px 0px' }} />
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <CalendarPopover
            isOpen={isVisibleCalendarPopover}
            defaultValue={currentDate}
            onClickOutside={() => setIsVisibleCalendarPopover(false)}
            onClickItem={handleClickCalendarPopoverItem}
          >
            <span onClick={() => setIsVisibleCalendarPopover(!isVisibleCalendarPopover)}>
              <ArrowToggleButton isToggle={isVisibleCalendarPopover}>
                <Typography variant="subtitle1" fontWeight={'bold'} color={COLORS.gray800}>
                  {dayjs(currentDate).format(DATE_FORMAT_3)}
                </Typography>
              </ArrowToggleButton>
            </span>
          </CalendarPopover>
          <div>
            <IconButton
              aria-label="previous day"
              sx={{ background: 'white' }}
              onClick={() => handleChangeCurrentDate(dayjs(currentDate).subtract(1, 'day').toDate())}
            >
              <Icons.ArrowLeftSmall />
            </IconButton>
            <IconButton
              aria-label="next day"
              sx={{ background: 'white' }}
              style={{ marginLeft: 8 }}
              onClick={() => handleChangeCurrentDate(dayjs(currentDate).add(1, 'day').toDate())}
            >
              <Icons.ArrowRightSmall />
            </IconButton>
          </div>
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" style={{ marginTop: 12 }}>
          <Button
            sx={{ borderRadius: 2, background: 'white', color: 'black', border: '1px solid #E7EAF4' }}
            size="small"
            style={{ minWidth: '48px' }}
            onClick={() => handleChangeCurrentDate(new Date())}
          >
            <b>오늘</b>
          </Button>
          <IconButton aria-label="create event" style={{ background: COLORS.brand1 }} onClick={handleClickCreateEvent}>
            <Icons.Plus fill={COLORS.white} width={16} height={16} />
          </IconButton>
        </Stack>
      </CalendarViewControlWrapper>
      <CalendarViewDaySchedulerWrapper>
        <MeetingCalendarView
          events={events.map((item) => createEventData(item))}
          date={currentDate}
          onChange={handleUpdateEvent}
          onSelectEvent={handleCalendarSelectEvent}
          onSelectSlot={handleSelectSlot}
          onClickFloatingAction={handleClickFloatingAction}
        />
      </CalendarViewDaySchedulerWrapper>
    </CalendarViewWrapper>
  );
};

export default CalendarView;
