/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * Slash
 * 
Slash API

 * OpenAPI spec version: 0.1.0
 */
import type {
  CreateGoogleUserByAppV1AuthGoogleGetParams,
  Token,
  OAuth,
  CreateUser,
  OutWorkspaceWithToken,
  OutWorkspace,
  CreateWorkspace,
  OutOAuthUser,
  OutUserDetail,
  OutBaseResponse,
  UpdateUserMe,
  AppModelsSchemasUserOutUser,
  UpdateUserPermission,
  UpdateUser,
  OutUserHistory,
  ReadUserHistoryV1UsersHistoryGetParams,
  PaginationOutEvent,
  GetEventsV1EventsGetParams,
  OutCreateEvent,
  CreateEvent,
  UpdateEvent,
  AppApplicationMeetingNotesSchemasOutMeetingNote,
  ReadMeetingNoteByEventIdV1MeetingNotesGetParams,
  SaveTransactionV1MeetingNotesMeetingNoteIdSavePostBodyItem,
  Pagination,
  ListRecurringMeetingNotesV1MeetingNotesMeetingNoteIdRecurringGetParams,
  PaginationOutSearchMeetingNoteDetail,
  ListNotesDetailV1NotesSearchGetParams,
  PaginationOutMeetingNote,
  ListMeetingNotesV1NotesListGetParams,
  AppModelsSchemasBaseOutRemovedEntity,
  OutAttachmentFile,
  CreateAttachmentFile,
  OutAttachment,
  UpdateAttachment,
  CreateAttachmentLink,
  SlackReceiverIds,
  InEmailShare,
  OutInvitation,
  UpdateInvitation,
  CreateInvitationShare,
  OutReminder,
  UpdateReminder,
  AppApplicationTasksSchemasOutTask,
  UpdateTask,
  PaginationOutMyTask,
  ReadTasksV1TasksGetParams,
  AppApplicationTasksSchemasCreateTask,
  OutTimeboxWithTasks,
  ReadTimeboxesV1TimeboxesGetParams,
  CreateTimebox,
  AppApplicationCommonSchemasOutRemovedEntity,
  OutTimebox,
  UpdateTimebox,
  CreateTimeboxTask,
  AssignTimeboxTask,
  PaginationOutNotification,
  ListNotificationsV1NotificationsGetParams,
  CreateNotification,
  PaginationSlackReceiver,
  ListReceiversV1SlackReceiversGetParams,
  IntegrateV1SlackAuthGetParams,
  OutDailyMemo,
  ReadDailyMemoV1DailyMemosGetParams,
  CreateDailyMemo,
  UpdateDailyMemo,
  OutTaskboxDetailResponse,
  ReadTaskboxesV1TaskboxesGetParams,
  OutTaskboxDetail,
  CreateTaskbox,
  UpdateTaskbox,
  AppApplicationTaskboxesSchemasOutTask,
  AppApplicationTaskboxesSchemasCreateTask,
  AssignTask,
  OutWorks,
  ListWorksV1WorksGetParams,
  OutWork,
  CreateWork,
  UpdateWork,
  SubTask,
  OutIssue,
  UpdateIssueDetail,
  MoveWorkToTaskbox,
  OutPlanSection,
  CreatePlanSection,
  UpdatePlanSection,
  OutPlanWeekly,
  ListPlanWeeklyV1PlansWeeklyGetParams,
  CreatePlanWeekly,
  UpdatePlanWeekly,
  CreateWorkInWorkBoxes,
  OutFrequentWork,
  CreateFrequentWork,
  UpdateFrequentWork,
  CreateFrequentWorkTask,
  OutAutoComplete,
  ReadAutocompleteListV1AutocompleteGetParams,
  OutWorksAndWorkBoxes,
  ListWorksV2WorkboxesGetParams,
  OutWorkBoxes,
  CreateWorkBoxes,
  UpdateWorkBoxesV2,
  UpdateWorkV2,
  OutCreatedEntity,
  MergeWorkOrWorkBoxes
} from './model'
import { customInstance } from '../lib/axios'



// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;


  /**
 * @summary google로 로그인합니다. access_token이 query string으로 붙어 redirect 됩니다.
 */
export const createGoogleUserByAppV1AuthGoogleGet = (
    params: CreateGoogleUserByAppV1AuthGoogleGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/auth/google`, method: 'get',
        params
    },
      options);
    }
  
/**
 * `code`: Google로부터 전달 받은 Authorization Code
 * @summary google로 로그인합니다.
 */
export const createGoogleUserV1AuthGooglePost = (
    oAuth: OAuth,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<Token>(
      {url: `/v1/auth/google`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: oAuth
    },
      options);
    }
  
/**
 * @deprecated
 * @summary [FOR TEST] email 로그인
 */
export const createUserV1AuthEmailPost = (
    createUser: CreateUser,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<Token>(
      {url: `/v1/auth/email`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createUser
    },
      options);
    }
  
/**
 * @summary 사용자의 워크스페이스 목록을 조회합니다.
 */
export const listWorkspacesV1AuthWorkspacesGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutWorkspaceWithToken[]>(
      {url: `/v1/auth/workspaces`, method: 'get'
    },
      options);
    }
  
/**
 * @summary 워크스페이스를 생성합니다.
 */
export const createWorkspaceV1AuthWorkspacesPost = (
    createWorkspace: CreateWorkspace,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutWorkspace>(
      {url: `/v1/auth/workspaces`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createWorkspace
    },
      options);
    }
  
/**
 * 해당 워크스페이스 리소스에 접근 가능한 access token을 제공합니다.
 * @summary 워크스페이스에 참가합니다.
 */
export const joinWorkspaceV1AuthWorkspacesWorkspaceIdJoinPost = (
    workspaceId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/auth/workspaces/${workspaceId}/join`, method: 'post'
    },
      options);
    }
  
/**
 * @summary 내 정보
 */
export const userMeV1AuthMeGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutOAuthUser>(
      {url: `/v1/auth/me`, method: 'get'
    },
      options);
    }
  
/**
 * 워크스페이스 내에서 내 정보를 조회합니다.
 * @summary 내 정보
 */
export const getUserMeV1UsersMeGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutUserDetail>(
      {url: `/v1/users/me`, method: 'get'
    },
      options);
    }
  
/**
 * @summary 워크스페이스 탈퇴
 */
export const removeV1UsersMeDelete = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutBaseResponse>(
      {url: `/v1/users/me`, method: 'delete'
    },
      options);
    }
  
/**
 * @summary 내 정보 수정
 */
export const updateMyProfileV1UsersMePatch = (
    updateUserMe: UpdateUserMe,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutUserDetail>(
      {url: `/v1/users/me`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: updateUserMe
    },
      options);
    }
  
/**
 * 워크스페이스 내의 팀원 정보룰 불러옵니다.
 * @summary 팀원 목록을 조회합니다.
 */
export const getMembersV1UsersMembersGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppModelsSchemasUserOutUser[]>(
      {url: `/v1/users/members`, method: 'get'
    },
      options);
    }
  
/**
 * @summary 팀원 권한을 변경합니다.
 */
export const grantPermissionV1UsersMembersPermissionPost = (
    updateUserPermission: UpdateUserPermission,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppModelsSchemasUserOutUser>(
      {url: `/v1/users/members/permission`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: updateUserPermission
    },
      options);
    }
  
/**
 * 이메일로 사용자를 초대합니다.
 * @summary 사용자 초대
 */
export const inviteMembersV1UsersInvitePost = (
    inviteMembersV1UsersInvitePostBody: string[],
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutBaseResponse>(
      {url: `/v1/users/invite`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: inviteMembersV1UsersInvitePostBody
    },
      options);
    }
  
/**
 * 사용자 이름, 계정 활성화 상태를 변경합니다.
 * @summary 사용자 정보 수정
 */
export const updateUserProfileV1UsersUserIdPatch = (
    userId: string,
    updateUser: UpdateUser,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutUserDetail>(
      {url: `/v1/users/${userId}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: updateUser
    },
      options);
    }
  
/**
 * 워크스페이스 내 소속 팀 목록을 조회합니다.
 * @summary 소속 팀 목록 조회
 */
export const readDepartmentsV1UsersDepartmentsGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<string[]>(
      {url: `/v1/users/departments`, method: 'get'
    },
      options);
    }
  
/**
 * 사용자의 히스토리를 조회합니다.
 * @summary 사용자 히스토리 조회
 */
export const readUserHistoryV1UsersHistoryGet = (
    params: ReadUserHistoryV1UsersHistoryGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutUserHistory[]>(
      {url: `/v1/users/history`, method: 'get',
        params
    },
      options);
    }
  
/**
 * @summary access token을 갱신합니다.
 */
export const refreshV1TokenRefreshPost = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<Token>(
      {url: `/v1/token/refresh`, method: 'post'
    },
      options);
    }
  
/**
 * @summary 사용자 일정을 조회합니다.
 */
export const getEventsV1EventsGet = (
    params?: GetEventsV1EventsGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<PaginationOutEvent>(
      {url: `/v1/events`, method: 'get',
        params
    },
      options);
    }
  
/**
 * 일정을 생성하면 빈 회의록이 자동으로 생성됩니다. 반복일정은 다음 [Google Calendar API](https://developers.google.com/calendar/api/v3/reference/events/insert#:~:text=writable-,recurrence,-%5B%5D) 문서 참고
 * @summary 사용자 일정을 생성합니다.
 */
export const createEventV1EventsPost = (
    createEvent: CreateEvent,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutCreateEvent>(
      {url: `/v1/events`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createEvent
    },
      options);
    }
  
/**
 * @summary 사용자 일정을 수정합니다.
 */
export const updateEventsV1EventsEventIdPut = (
    eventId: string,
    updateEvent: UpdateEvent,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutBaseResponse>(
      {url: `/v1/events/${eventId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: updateEvent
    },
      options);
    }
  
/**
 * @summary 사용자 일정 삭제
 */
export const removeEventV1EventsEventIdDelete = (
    eventId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutBaseResponse>(
      {url: `/v1/events/${eventId}`, method: 'delete'
    },
      options);
    }
  
/**
 * @summary Google calendar Push 
 */
export const handleEventNotificationV1EventsNotificationsPost = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/events/notifications`, method: 'post'
    },
      options);
    }
  
/**
 * @summary event id를 이용한 회의록 조회
 */
export const readMeetingNoteByEventIdV1MeetingNotesGet = (
    params: ReadMeetingNoteByEventIdV1MeetingNotesGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppApplicationMeetingNotesSchemasOutMeetingNote>(
      {url: `/v1/meeting-notes`, method: 'get',
        params
    },
      options);
    }
  
/**
 * @summary 회의록 읽기
 */
export const readBlocksV1MeetingNotesMeetingNoteIdGet = (
    meetingNoteId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppApplicationMeetingNotesSchemasOutMeetingNote>(
      {url: `/v1/meeting-notes/${meetingNoteId}`, method: 'get'
    },
      options);
    }
  
/**
 * @summary 회의록 저장
 */
export const saveTransactionV1MeetingNotesMeetingNoteIdSavePost = (
    meetingNoteId: string,
    saveTransactionV1MeetingNotesMeetingNoteIdSavePostBodyItem: SaveTransactionV1MeetingNotesMeetingNoteIdSavePostBodyItem[],
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/meeting-notes/${meetingNoteId}/save`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: saveTransactionV1MeetingNotesMeetingNoteIdSavePostBodyItem
    },
      options);
    }
  
/**
 * @summary 반복 회의 목록 조회
 */
export const listRecurringMeetingNotesV1MeetingNotesMeetingNoteIdRecurringGet = (
    meetingNoteId: string,
    params?: ListRecurringMeetingNotesV1MeetingNotesMeetingNoteIdRecurringGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<Pagination>(
      {url: `/v1/meeting-notes/${meetingNoteId}/recurring`, method: 'get',
        params
    },
      options);
    }
  
/**
 * 워크스페이스 내 회의록을 찾습니다.<br>`start_time`을 지정하지 않을 경우 현재부터 1년 전, `end_time`을 지정하지 않을 경우 현재 시간으로 조회합니다.
 * @summary 회의록 찾기
 */
export const listNotesDetailV1NotesSearchGet = (
    params?: ListNotesDetailV1NotesSearchGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<PaginationOutSearchMeetingNoteDetail>(
      {url: `/v1/notes/search`, method: 'get',
        params
    },
      options);
    }
  
/**
 * @summary 회의록 목록 조회
 */
export const listMeetingNotesV1NotesListGet = (
    params?: ListMeetingNotesV1NotesListGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<PaginationOutMeetingNote>(
      {url: `/v1/notes/list`, method: 'get',
        params
    },
      options);
    }
  
/**
 * 회의록을 삭제합니다.
 * @summary 회의록 제거
 */
export const removeMeetingNoteV1NotesMeetingNoteIdDelete = (
    meetingNoteId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppModelsSchemasBaseOutRemovedEntity>(
      {url: `/v1/notes/${meetingNoteId}`, method: 'delete'
    },
      options);
    }
  
/**
 * 업로드를 위해 미리 서명된 s3 링크를 제공합니다.<br>유효 기간은 500초 입니다. PUT method를 이용합니다.
 * @summary 첨부 파일 업로드
 */
export const retrieveUploadLinkV1NotesMeetingNoteIdAttachmentsFilePost = (
    meetingNoteId: string,
    createAttachmentFile: CreateAttachmentFile,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutAttachmentFile>(
      {url: `/v1/notes/${meetingNoteId}/attachments/file`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createAttachmentFile
    },
      options);
    }
  
/**
 * 다운로드 받기 위한 s3 링크를 제공합니다. <br>유효 기간은 500초 입니다.file 타입만 사용 가능합니다.
 * @summary 첨부 파일 다운로드
 */
export const retrieveDownloadLinkV1NotesMeetingNoteIdAttachmentsAttachmentIdFileGet = (
    meetingNoteId: string,
    attachmentId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutAttachmentFile>(
      {url: `/v1/notes/${meetingNoteId}/attachments/${attachmentId}/file`, method: 'get'
    },
      options);
    }
  
/**
 * 첨부 링크 또는 파일의 이름을 수정합니다.
 * @summary 첨부항목 이름 변경
 */
export const updateAttachmentContentV1NotesMeetingNoteIdAttachmentsAttachmentIdPut = (
    meetingNoteId: string,
    attachmentId: string,
    updateAttachment: UpdateAttachment,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutAttachment>(
      {url: `/v1/notes/${meetingNoteId}/attachments/${attachmentId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: updateAttachment
    },
      options);
    }
  
/**
 * 첨부항목을 삭제합니다.
 * @summary 첨부항목 삭제
 */
export const deleteAttachmentV1NotesMeetingNoteIdAttachmentsAttachmentIdDelete = (
    meetingNoteId: string,
    attachmentId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppModelsSchemasBaseOutRemovedEntity>(
      {url: `/v1/notes/${meetingNoteId}/attachments/${attachmentId}`, method: 'delete'
    },
      options);
    }
  
/**
 * 링크를 첨부합니다.
 * @summary 링크 첨부
 */
export const retrieveAttachmentLinkV1NotesMeetingNoteIdAttachmentsLinksPost = (
    meetingNoteId: string,
    createAttachmentLink: CreateAttachmentLink,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutAttachment>(
      {url: `/v1/notes/${meetingNoteId}/attachments/links`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createAttachmentLink
    },
      options);
    }
  
/**
 * @summary 회의록 슬랙 공유
 */
export const shareMeetingNoteBySlackV1NotesMeetingNoteIdShareSlackPost = (
    meetingNoteId: string,
    slackReceiverIds: SlackReceiverIds,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutBaseResponse>(
      {url: `/v1/notes/${meetingNoteId}/share/slack`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: slackReceiverIds
    },
      options);
    }
  
/**
 * @summary 회의록 이메일 공유
 */
export const shareMeetingNoteByEmailV1NotesMeetingNoteIdShareEmailPost = (
    meetingNoteId: string,
    inEmailShare: InEmailShare,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutBaseResponse>(
      {url: `/v1/notes/${meetingNoteId}/share/email`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: inEmailShare
    },
      options);
    }
  
/**
 * @summary 메모 조회
 */
export const readMemoV1NotesMeetingNoteIdMemosGet = (
    meetingNoteId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/notes/${meetingNoteId}/memos`, method: 'get'
    },
      options);
    }
  
/**
 * 메모 블록을 생성합니다. <br>
블록이 아이템에 속하는 경우 `section`, `attribute` 값이 필요합니다. <br>
아이템에 속하지 않는 경우 위 필드를 비워주세요.
 * @summary 메모 작성
 */
export const createMemoV1NotesMeetingNoteIdMemosPost = (
    meetingNoteId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/notes/${meetingNoteId}/memos`, method: 'post'
    },
      options);
    }
  
/**
 * 인비테이션을 조회합니다.
 * @summary 인비테이션 조회
 */
export const readInvitationV1NotesMeetingNoteIdInvitationGet = (
    meetingNoteId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutInvitation>(
      {url: `/v1/notes/${meetingNoteId}/invitation`, method: 'get'
    },
      options);
    }
  
/**
 * 인비테이션을 수정합니다.
 * @summary 인비테이션 수정
 */
export const updateInvitationV1NotesMeetingNoteIdInvitationPut = (
    meetingNoteId: string,
    updateInvitation: UpdateInvitation,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutInvitation>(
      {url: `/v1/notes/${meetingNoteId}/invitation`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: updateInvitation
    },
      options);
    }
  
/**
 * 인비테이션을 전송합니다.
 * @summary 인비테이션 전송
 */
export const sendInvitationV1NotesMeetingNoteIdInvitationSendPost = (
    meetingNoteId: string,
    createInvitationShare: CreateInvitationShare,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutBaseResponse>(
      {url: `/v1/notes/${meetingNoteId}/invitation/send`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createInvitationShare
    },
      options);
    }
  
/**
 * 리마인더를 조회합니다.
 * @summary 리마인더 조회
 */
export const readReminderV1NotesMeetingNoteIdInvitationReminderGet = (
    meetingNoteId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutReminder>(
      {url: `/v1/notes/${meetingNoteId}/invitation/reminder`, method: 'get'
    },
      options);
    }
  
/**
 * 리마인더를 설정합니다.
 * @summary 리마인더 설정
 */
export const setReminderV1NotesMeetingNoteIdInvitationReminderPost = (
    meetingNoteId: string,
    updateReminder: UpdateReminder,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutReminder>(
      {url: `/v1/notes/${meetingNoteId}/invitation/reminder`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: updateReminder
    },
      options);
    }
  
/**
 * @summary 업무 조회
 */
export const readTaskV1TasksTaskIdGet = (
    taskId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppApplicationTasksSchemasOutTask>(
      {url: `/v1/tasks/${taskId}`, method: 'get'
    },
      options);
    }
  
/**
 * @summary 업무 삭제
 */
export const removeTaskV1TasksTaskIdDelete = (
    taskId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppModelsSchemasBaseOutRemovedEntity>(
      {url: `/v1/tasks/${taskId}`, method: 'delete'
    },
      options);
    }
  
/**
 * @summary 업무 수정
 */
export const updateTaskV1TasksTaskIdPatch = (
    taskId: string,
    updateTask: UpdateTask,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppApplicationTasksSchemasOutTask>(
      {url: `/v1/tasks/${taskId}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: updateTask
    },
      options);
    }
  
/**
 * @summary 내 업무 조회
 */
export const readTasksV1TasksGet = (
    params: ReadTasksV1TasksGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<PaginationOutMyTask>(
      {url: `/v1/tasks`, method: 'get',
        params
    },
      options);
    }
  
/**
 * @summary 내 업무 생성
 */
export const createTaskV1TasksPost = (
    appApplicationTasksSchemasCreateTask: AppApplicationTasksSchemasCreateTask,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/tasks`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: appApplicationTasksSchemasCreateTask
    },
      options);
    }
  
/**
 * @summary 내 타임박스 조회
 */
export const readTimeboxesV1TimeboxesGet = (
    params?: ReadTimeboxesV1TimeboxesGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutTimeboxWithTasks[]>(
      {url: `/v1/timeboxes`, method: 'get',
        params
    },
      options);
    }
  
/**
 * @summary 타임박스를 생성
 */
export const createTimeboxesV1TimeboxesPost = (
    createTimebox: CreateTimebox,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutTimeboxWithTasks>(
      {url: `/v1/timeboxes`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createTimebox
    },
      options);
    }
  
/**
 * @summary 타임박스 제거
 */
export const removeTimeboxV1TimeboxesTimeboxIdDelete = (
    timeboxId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppApplicationCommonSchemasOutRemovedEntity>(
      {url: `/v1/timeboxes/${timeboxId}`, method: 'delete'
    },
      options);
    }
  
/**
 * @summary 타임박스를 수정합니다.
 */
export const updateTimeboxesV1TimeboxesTimeboxIdPatch = (
    timeboxId: string,
    updateTimebox: UpdateTimebox,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutTimebox>(
      {url: `/v1/timeboxes/${timeboxId}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: updateTimebox
    },
      options);
    }
  
/**
 * 타임박스 내에서만 존재하는 업무로, 내 업무 목록에는 노출되지 않습니다. <br>타임박스 업무 조회시 `'simple': true` 값을 갖습니다.
 * @summary 타임박스 내 업무를 생성
 */
export const createTimeboxTaskV1TimeboxesTimeboxIdTasksPost = (
    timeboxId: string,
    createTimeboxTask: CreateTimeboxTask,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/timeboxes/${timeboxId}/tasks`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createTimeboxTask
    },
      options);
    }
  
/**
 * @summary 타임박스 업무 할당
 */
export const assignTaskV1TimeboxesTimeboxIdTasksTaskIdPost = (
    timeboxId: string,
    taskId: string,
    assignTimeboxTask: AssignTimeboxTask,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/timeboxes/${timeboxId}/tasks/${taskId}`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: assignTimeboxTask
    },
      options);
    }
  
/**
 * @summary 타임박스 내 업무 제거
 */
export const detachTaskV1TimeboxesTimeboxIdTasksTaskIdDelete = (
    timeboxId: string,
    taskId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppApplicationCommonSchemasOutRemovedEntity>(
      {url: `/v1/timeboxes/${timeboxId}/tasks/${taskId}`, method: 'delete'
    },
      options);
    }
  
/**
 * @summary 메모 수정
 */
export const updateNoteV1MemosMemoIdPut = (
    memoId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/memos/${memoId}`, method: 'put'
    },
      options);
    }
  
/**
 * @summary 메모 삭제
 */
export const removeMemoV1MemosMemoIdDelete = (
    memoId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppModelsSchemasBaseOutRemovedEntity>(
      {url: `/v1/memos/${memoId}`, method: 'delete'
    },
      options);
    }
  
/**
 * @summary 메세지 목록을 조회합니다.
 */
export const listNotificationsV1NotificationsGet = (
    params?: ListNotificationsV1NotificationsGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<PaginationOutNotification>(
      {url: `/v1/notifications`, method: 'get',
        params
    },
      options);
    }
  
/**
 * API for testing
 * @deprecated
 * @summary 알림을 생성합니다.
 */
export const createNotificationsV1NotificationsPost = (
    createNotification: CreateNotification,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutBaseResponse>(
      {url: `/v1/notifications`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createNotification
    },
      options);
    }
  
/**
 * @summary 알림 메세지를 읽었음을 확인합니다.
 */
export const checkReadingNotificationV1NotificationsNotificationIdReadPut = (
    notificationId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutBaseResponse>(
      {url: `/v1/notifications/${notificationId}/read`, method: 'put'
    },
      options);
    }
  
/**
 * @summary Slack workspace 채널 및 사용자 조회
 */
export const listReceiversV1SlackReceiversGet = (
    params?: ListReceiversV1SlackReceiversGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<PaginationSlackReceiver>(
      {url: `/v1/slack/receivers`, method: 'get',
        params
    },
      options);
    }
  
/**
 * @summary Slack 연동
 */
export const integrateV1SlackAuthGet = (
    params: IntegrateV1SlackAuthGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/slack/auth`, method: 'get',
        params
    },
      options);
    }
  
/**
 * @summary Revoke
 */
export const revokeV1SlackRevokeDelete = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/slack/revoke`, method: 'delete'
    },
      options);
    }
  
/**
 * @summary 일일 업무 관리 메모 조회
 */
export const readDailyMemoV1DailyMemosGet = (
    params: ReadDailyMemoV1DailyMemosGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutDailyMemo>(
      {url: `/v1/daily-memos`, method: 'get',
        params
    },
      options);
    }
  
/**
 * @summary 일일 업무 관리 메모 생성
 */
export const createDailyMemoV1DailyMemosPost = (
    createDailyMemo: CreateDailyMemo,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutDailyMemo>(
      {url: `/v1/daily-memos`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createDailyMemo
    },
      options);
    }
  
/**
 * @summary 일일 업무 관리 메모 수정
 */
export const updateDailyMemoV1DailyMemosMemoIdPut = (
    memoId: string,
    updateDailyMemo: UpdateDailyMemo,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutDailyMemo>(
      {url: `/v1/daily-memos/${memoId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: updateDailyMemo
    },
      options);
    }
  
/**
 * [start_date, end_date] 범위의 태스크박스를 조회합니다. <br />start_date, end_date를 null로 설정하거나 입력하지 않은 경우 워크스테이션의 태스크박스를 조회합니다.
 * @summary 태스크박스 조회
 */
export const readTaskboxesV1TaskboxesGet = (
    params?: ReadTaskboxesV1TaskboxesGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutTaskboxDetailResponse[]>(
      {url: `/v1/taskboxes`, method: 'get',
        params
    },
      options);
    }
  
/**
 * 태스크박스를 생성합니다. <br />- 시간이 지정되지 않은 워크스테이션 내 태스크박스인 경우 start, end를 비워두거나 null을 설정합니다. <br />- 종일인 경우 [start.date, end.date]를 설정합니다. <br />- 시간이 지정된 경우 [start.datetime, end.datetime]를 설정합니다.
 * @summary 태스크박스 생성
 */
export const createTaskboxV1TaskboxesPost = (
    createTaskbox: CreateTaskbox,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutTaskboxDetail>(
      {url: `/v1/taskboxes`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createTaskbox
    },
      options);
    }
  
/**
 * @summary 태스크박스 수정
 */
export const updateTaskboxesV1TaskboxesTaskboxIdPut = (
    taskboxId: string,
    updateTaskbox: UpdateTaskbox,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutTaskboxDetail>(
      {url: `/v1/taskboxes/${taskboxId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: updateTaskbox
    },
      options);
    }
  
/**
 * @summary 태스크박스 제거
 */
export const removeTimeboxV1TaskboxesTaskboxIdDelete = (
    taskboxId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppApplicationCommonSchemasOutRemovedEntity>(
      {url: `/v1/taskboxes/${taskboxId}`, method: 'delete'
    },
      options);
    }
  
/**
 * 태스크박스 내에서만 존재하는 업무로, 내 업무 목록에는 노출되지 않습니다. <br>태스크박스 업무 조회시 `"simple": true` 값을 갖습니다.
 * @summary 태스크박스 내 업무 생성
 */
export const createTaskboxTaskV1TaskboxesTaskboxIdTasksPost = (
    taskboxId: string,
    appApplicationTaskboxesSchemasCreateTask: AppApplicationTaskboxesSchemasCreateTask,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppApplicationTaskboxesSchemasOutTask>(
      {url: `/v1/taskboxes/${taskboxId}/tasks`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: appApplicationTaskboxesSchemasCreateTask
    },
      options);
    }
  
/**
 * @summary 태스크박스 업무 할당
 */
export const assignTaskV1TaskboxesTaskboxIdTasksTaskIdPut = (
    taskboxId: string,
    taskId: string,
    assignTask: AssignTask,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppApplicationTaskboxesSchemasOutTask>(
      {url: `/v1/taskboxes/${taskboxId}/tasks/${taskId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: assignTask
    },
      options);
    }
  
/**
 * @summary 태스크박스 내 업무 제거
 */
export const detachTaskV1TaskboxesTaskboxIdTasksTaskIdDelete = (
    taskboxId: string,
    taskId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppApplicationCommonSchemasOutRemovedEntity>(
      {url: `/v1/taskboxes/${taskboxId}/tasks/${taskId}`, method: 'delete'
    },
      options);
    }
  
/**
 * @summary 태스크, 이슈 목록 조회
 */
export const listWorksV1WorksGet = (
    params: ListWorksV1WorksGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutWorks>(
      {url: `/v1/works`, method: 'get',
        params
    },
      options);
    }
  
/**
 * @summary 태스크, 이슈 생성
 */
export const createTaskV1WorksPost = (
    createWork: CreateWork,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutWork>(
      {url: `/v1/works`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createWork
    },
      options);
    }
  
/**
 * @summary 태스크, 이슈 수정
 */
export const updateTaskV1WorksWorkIdPut = (
    workId: string,
    updateWork: UpdateWork,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutWork>(
      {url: `/v1/works/${workId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: updateWork
    },
      options);
    }
  
/**
 * 태스크, 이슈, 그리고 이슈 내 태스크 제거에 사용됩니다.
 * @summary 태스크, 이슈 삭제
 */
export const removeTaskV1WorksWorkIdDelete = (
    workId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppApplicationCommonSchemasOutRemovedEntity>(
      {url: `/v1/works/${workId}`, method: 'delete'
    },
      options);
    }
  
/**
 * @summary 이슈 내 업무 할당
 */
export const assignTaskV1WorksIssuesIssueIdTasksPut = (
    issueId: string,
    subTask: SubTask[],
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutWork>(
      {url: `/v1/works/issues/${issueId}/tasks`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: subTask
    },
      options);
    }
  
/**
 * @summary 이슈 상세내역 조회
 */
export const readIssueV1WorksIssuesIssueIdGet = (
    issueId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutIssue>(
      {url: `/v1/works/issues/${issueId}`, method: 'get'
    },
      options);
    }
  
/**
 * @summary 이슈 상세내역 수정
 */
export const updateIssueV1WorksIssuesIssueIdPut = (
    issueId: string,
    updateIssueDetail: UpdateIssueDetail,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/works/issues/${issueId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: updateIssueDetail
    },
      options);
    }
  
/**
 * 태스크를 태스크박스로 이동합니다. 태스크박스에 서브태스크는 request body의 id 순서대로 생성됩니다.
 * @summary 태스크를 태스크박스로 이동
 */
export const moveWorkToTaskboxV1WorksMoveTaskboxIdPost = (
    taskboxId: string,
    moveWorkToTaskbox: MoveWorkToTaskbox,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/v1/works/move/${taskboxId}`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: moveWorkToTaskbox
    },
      options);
    }
  
/**
 * @summary 유저의 모든 플랜 섹션 조회
 */
export const listPlanSectionsV1PlansSectionsGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutPlanSection[]>(
      {url: `/v1/plans/sections`, method: 'get'
    },
      options);
    }
  
/**
 * @summary 플랜 섹션 생성
 */
export const createPlanSectionV1PlansSectionsPost = (
    createPlanSection: CreatePlanSection,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutPlanSection>(
      {url: `/v1/plans/sections`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createPlanSection
    },
      options);
    }
  
/**
 * @summary 플랜 섹션 변경
 */
export const updatePlanSectionV1PlansSectionsPlanSectionIdPut = (
    planSectionId: string,
    updatePlanSection: UpdatePlanSection,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutPlanSection>(
      {url: `/v1/plans/sections/${planSectionId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: updatePlanSection
    },
      options);
    }
  
/**
 * @summary 플랜 섹션 삭제
 */
export const deletePlanSectionV1PlansSectionsPlanSectionIdDelete = (
    planSectionId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppModelsSchemasBaseOutRemovedEntity>(
      {url: `/v1/plans/sections/${planSectionId}`, method: 'delete'
    },
      options);
    }
  
/**
 * @summary 유저의 주간 플랜 조회
 */
export const listPlanWeeklyV1PlansWeeklyGet = (
    params: ListPlanWeeklyV1PlansWeeklyGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutPlanWeekly[]>(
      {url: `/v1/plans/weekly`, method: 'get',
        params
    },
      options);
    }
  
/**
 * @summary 주간 플랜 생성
 */
export const createPlanWeeklyV1PlansWeeklyPost = (
    createPlanWeekly: CreatePlanWeekly,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutPlanWeekly>(
      {url: `/v1/plans/weekly`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createPlanWeekly
    },
      options);
    }
  
/**
 * @summary 주간 플랜 변경
 */
export const updatePlanWeeklyV1PlansWeeklyPlanWeeklyIdPut = (
    planWeeklyId: string,
    updatePlanWeekly: UpdatePlanWeekly,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutPlanWeekly>(
      {url: `/v1/plans/weekly/${planWeeklyId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: updatePlanWeekly
    },
      options);
    }
  
/**
 * @summary 주간 플랜 삭제
 */
export const deletePlanWeeklyV1PlansWeeklyPlanWeeklyIdDelete = (
    planWeeklyId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppModelsSchemasBaseOutRemovedEntity>(
      {url: `/v1/plans/weekly/${planWeeklyId}`, method: 'delete'
    },
      options);
    }
  
/**
 * @summary workbox내 work 생성
 */
export const createTaskboxTaskV1WorkboxesWorkBoxesIdWorkPost = (
    workBoxesId: string,
    createWorkInWorkBoxes: CreateWorkInWorkBoxes,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutWork>(
      {url: `/v1/workboxes/${workBoxesId}/work`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createWorkInWorkBoxes
    },
      options);
    }
  
/**
 * @summary workbox 제거
 */
export const removeWorkBoxesV1WorkboxesWorkBoxesIdDelete = (
    workBoxesId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppApplicationCommonSchemasOutRemovedEntity>(
      {url: `/v1/workboxes/${workBoxesId}`, method: 'delete'
    },
      options);
    }
  
/**
 * @summary workbox 내 work 제거
 */
export const removeWorkInWorkBoxesV1WorkboxesWorkBoxesIdWorksWorkIdDelete = (
    workBoxesId: string,
    workId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppApplicationCommonSchemasOutRemovedEntity>(
      {url: `/v1/workboxes/${workBoxesId}/works/${workId}`, method: 'delete'
    },
      options);
    }
  
/**
 * 자주 하는 업무를 조회합니다.
 * @summary 자주 하는 업무 조회
 */
export const readFrequentWorksV1FrequentworksGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutFrequentWork[]>(
      {url: `/v1/frequentworks`, method: 'get'
    },
      options);
    }
  
/**
 * @summary 자주 하는 업무 생성
 */
export const createFrequentWorkV1FrequentworksPost = (
    createFrequentWork: CreateFrequentWork,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CreateFrequentWork>(
      {url: `/v1/frequentworks`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createFrequentWork
    },
      options);
    }
  
/**
 * 자주 하는 업무를 수정합니다.
 * @summary 자주 하는 업무 수정
 */
export const updateFrequentWorkV1FrequentworksFrequentWorkIdPut = (
    frequentWorkId: string,
    updateFrequentWork: UpdateFrequentWork,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutFrequentWork>(
      {url: `/v1/frequentworks/${frequentWorkId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: updateFrequentWork
    },
      options);
    }
  
/**
 * 자주 하는 업무를 삭제합니다.
 * @summary 자주 하는 업무 삭제
 */
export const deleteFrequentWorkV1FrequentworksFrequentWorkIdDelete = (
    frequentWorkId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppApplicationCommonSchemasOutRemovedEntity>(
      {url: `/v1/frequentworks/${frequentWorkId}`, method: 'delete'
    },
      options);
    }
  
/**
 * 자주 하는 업무를 태스크박스로 생성합니다.
 * @summary 자주 하는 업무를 태스크박스로 생성
 */
export const createFrequentWorkToTaskboxV1FrequentworksFrequentWorkIdTaskboxPost = (
    frequentWorkId: string,
    createTaskbox: CreateTaskbox,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutTaskboxDetail>(
      {url: `/v1/frequentworks/${frequentWorkId}/taskbox`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createTaskbox
    },
      options);
    }
  
/**
 * @summary 자주 하는 업무 내 하위 업무 생성
 */
export const createTaskInFrequentWorkV1FrequentworksFreuqentWorkIdTasksPost = (
    freuqentWorkId: string,
    createFrequentWorkTask: CreateFrequentWorkTask,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CreateFrequentWorkTask>(
      {url: `/v1/frequentworks/${freuqentWorkId}/tasks`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createFrequentWorkTask
    },
      options);
    }
  
/**
 * @summary 자주 하는 업무 내 하위 업무 제거
 */
export const deleteTaskInFrequentWorkV1FrequentworksFrequentWorkIdTasksTaskIdDelete = (
    frequentWorkId: string,
    taskId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<AppApplicationCommonSchemasOutRemovedEntity>(
      {url: `/v1/frequentworks/${frequentWorkId}/tasks/${taskId}`, method: 'delete'
    },
      options);
    }
  
/**
 * title을 null로 설정하거나 입력하지 않으면 모든 태스크박스 업무와 자주 하는 업무를 가져옵니다.
 * @summary 업무 자동 완성
 */
export const readAutocompleteListV1AutocompleteGet = (
    params?: ReadAutocompleteListV1AutocompleteGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutAutoComplete[]>(
      {url: `/v1/autocomplete`, method: 'get',
        params
    },
      options);
    }
  
/**
 * @summary 태스크 목록 조회
 */
export const listWorksV2WorkboxesGet = (
    params: ListWorksV2WorkboxesGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutWorksAndWorkBoxes>(
      {url: `/v2/workboxes`, method: 'get',
        params
    },
      options);
    }
  
/**
 * 인박스/나중에 에서 사용할 박스를 생성합니다.
 * @summary work box 생성
 */
export const createWorkBoxesV2WorkboxesPost = (
    createWorkBoxes: CreateWorkBoxes,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutWorkBoxes>(
      {url: `/v2/workboxes`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createWorkBoxes
    },
      options);
    }
  
/**
 * @summary workbox 수정
 */
export const updateWorkBoxesV2V2WorkboxesWorkBoxesIdPut = (
    workBoxesId: string,
    updateWorkBoxesV2: UpdateWorkBoxesV2,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutWorkBoxes>(
      {url: `/v2/workboxes/${workBoxesId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: updateWorkBoxesV2
    },
      options);
    }
  
/**
 * @summary work 수정
 */
export const updateWorkV2V2WorksWorkIdPut = (
    workId: string,
    updateWorkV2: UpdateWorkV2,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutWork>(
      {url: `/v2/works/${workId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: updateWorkV2
    },
      options);
    }
  
/**
 * @summary work 혹은 workboxes를 병합합니다.
 */
export const mergeWorkOrWorkBoxesV2V2WorksMergePost = (
    mergeWorkOrWorkBoxes: MergeWorkOrWorkBoxes,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OutCreatedEntity>(
      {url: `/v2/works/merge`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: mergeWorkOrWorkBoxes
    },
      options);
    }
  
/**
 * @summary Health Check
 */
export const healthCheckGet = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/`, method: 'get'
    },
      options);
    }
  
export type CreateGoogleUserByAppV1AuthGoogleGetResult = NonNullable<Awaited<ReturnType<typeof createGoogleUserByAppV1AuthGoogleGet>>>
export type CreateGoogleUserV1AuthGooglePostResult = NonNullable<Awaited<ReturnType<typeof createGoogleUserV1AuthGooglePost>>>
export type CreateUserV1AuthEmailPostResult = NonNullable<Awaited<ReturnType<typeof createUserV1AuthEmailPost>>>
export type ListWorkspacesV1AuthWorkspacesGetResult = NonNullable<Awaited<ReturnType<typeof listWorkspacesV1AuthWorkspacesGet>>>
export type CreateWorkspaceV1AuthWorkspacesPostResult = NonNullable<Awaited<ReturnType<typeof createWorkspaceV1AuthWorkspacesPost>>>
export type JoinWorkspaceV1AuthWorkspacesWorkspaceIdJoinPostResult = NonNullable<Awaited<ReturnType<typeof joinWorkspaceV1AuthWorkspacesWorkspaceIdJoinPost>>>
export type UserMeV1AuthMeGetResult = NonNullable<Awaited<ReturnType<typeof userMeV1AuthMeGet>>>
export type GetUserMeV1UsersMeGetResult = NonNullable<Awaited<ReturnType<typeof getUserMeV1UsersMeGet>>>
export type RemoveV1UsersMeDeleteResult = NonNullable<Awaited<ReturnType<typeof removeV1UsersMeDelete>>>
export type UpdateMyProfileV1UsersMePatchResult = NonNullable<Awaited<ReturnType<typeof updateMyProfileV1UsersMePatch>>>
export type GetMembersV1UsersMembersGetResult = NonNullable<Awaited<ReturnType<typeof getMembersV1UsersMembersGet>>>
export type GrantPermissionV1UsersMembersPermissionPostResult = NonNullable<Awaited<ReturnType<typeof grantPermissionV1UsersMembersPermissionPost>>>
export type InviteMembersV1UsersInvitePostResult = NonNullable<Awaited<ReturnType<typeof inviteMembersV1UsersInvitePost>>>
export type UpdateUserProfileV1UsersUserIdPatchResult = NonNullable<Awaited<ReturnType<typeof updateUserProfileV1UsersUserIdPatch>>>
export type ReadDepartmentsV1UsersDepartmentsGetResult = NonNullable<Awaited<ReturnType<typeof readDepartmentsV1UsersDepartmentsGet>>>
export type ReadUserHistoryV1UsersHistoryGetResult = NonNullable<Awaited<ReturnType<typeof readUserHistoryV1UsersHistoryGet>>>
export type RefreshV1TokenRefreshPostResult = NonNullable<Awaited<ReturnType<typeof refreshV1TokenRefreshPost>>>
export type GetEventsV1EventsGetResult = NonNullable<Awaited<ReturnType<typeof getEventsV1EventsGet>>>
export type CreateEventV1EventsPostResult = NonNullable<Awaited<ReturnType<typeof createEventV1EventsPost>>>
export type UpdateEventsV1EventsEventIdPutResult = NonNullable<Awaited<ReturnType<typeof updateEventsV1EventsEventIdPut>>>
export type RemoveEventV1EventsEventIdDeleteResult = NonNullable<Awaited<ReturnType<typeof removeEventV1EventsEventIdDelete>>>
export type HandleEventNotificationV1EventsNotificationsPostResult = NonNullable<Awaited<ReturnType<typeof handleEventNotificationV1EventsNotificationsPost>>>
export type ReadMeetingNoteByEventIdV1MeetingNotesGetResult = NonNullable<Awaited<ReturnType<typeof readMeetingNoteByEventIdV1MeetingNotesGet>>>
export type ReadBlocksV1MeetingNotesMeetingNoteIdGetResult = NonNullable<Awaited<ReturnType<typeof readBlocksV1MeetingNotesMeetingNoteIdGet>>>
export type SaveTransactionV1MeetingNotesMeetingNoteIdSavePostResult = NonNullable<Awaited<ReturnType<typeof saveTransactionV1MeetingNotesMeetingNoteIdSavePost>>>
export type ListRecurringMeetingNotesV1MeetingNotesMeetingNoteIdRecurringGetResult = NonNullable<Awaited<ReturnType<typeof listRecurringMeetingNotesV1MeetingNotesMeetingNoteIdRecurringGet>>>
export type ListNotesDetailV1NotesSearchGetResult = NonNullable<Awaited<ReturnType<typeof listNotesDetailV1NotesSearchGet>>>
export type ListMeetingNotesV1NotesListGetResult = NonNullable<Awaited<ReturnType<typeof listMeetingNotesV1NotesListGet>>>
export type RemoveMeetingNoteV1NotesMeetingNoteIdDeleteResult = NonNullable<Awaited<ReturnType<typeof removeMeetingNoteV1NotesMeetingNoteIdDelete>>>
export type RetrieveUploadLinkV1NotesMeetingNoteIdAttachmentsFilePostResult = NonNullable<Awaited<ReturnType<typeof retrieveUploadLinkV1NotesMeetingNoteIdAttachmentsFilePost>>>
export type RetrieveDownloadLinkV1NotesMeetingNoteIdAttachmentsAttachmentIdFileGetResult = NonNullable<Awaited<ReturnType<typeof retrieveDownloadLinkV1NotesMeetingNoteIdAttachmentsAttachmentIdFileGet>>>
export type UpdateAttachmentContentV1NotesMeetingNoteIdAttachmentsAttachmentIdPutResult = NonNullable<Awaited<ReturnType<typeof updateAttachmentContentV1NotesMeetingNoteIdAttachmentsAttachmentIdPut>>>
export type DeleteAttachmentV1NotesMeetingNoteIdAttachmentsAttachmentIdDeleteResult = NonNullable<Awaited<ReturnType<typeof deleteAttachmentV1NotesMeetingNoteIdAttachmentsAttachmentIdDelete>>>
export type RetrieveAttachmentLinkV1NotesMeetingNoteIdAttachmentsLinksPostResult = NonNullable<Awaited<ReturnType<typeof retrieveAttachmentLinkV1NotesMeetingNoteIdAttachmentsLinksPost>>>
export type ShareMeetingNoteBySlackV1NotesMeetingNoteIdShareSlackPostResult = NonNullable<Awaited<ReturnType<typeof shareMeetingNoteBySlackV1NotesMeetingNoteIdShareSlackPost>>>
export type ShareMeetingNoteByEmailV1NotesMeetingNoteIdShareEmailPostResult = NonNullable<Awaited<ReturnType<typeof shareMeetingNoteByEmailV1NotesMeetingNoteIdShareEmailPost>>>
export type ReadMemoV1NotesMeetingNoteIdMemosGetResult = NonNullable<Awaited<ReturnType<typeof readMemoV1NotesMeetingNoteIdMemosGet>>>
export type CreateMemoV1NotesMeetingNoteIdMemosPostResult = NonNullable<Awaited<ReturnType<typeof createMemoV1NotesMeetingNoteIdMemosPost>>>
export type ReadInvitationV1NotesMeetingNoteIdInvitationGetResult = NonNullable<Awaited<ReturnType<typeof readInvitationV1NotesMeetingNoteIdInvitationGet>>>
export type UpdateInvitationV1NotesMeetingNoteIdInvitationPutResult = NonNullable<Awaited<ReturnType<typeof updateInvitationV1NotesMeetingNoteIdInvitationPut>>>
export type SendInvitationV1NotesMeetingNoteIdInvitationSendPostResult = NonNullable<Awaited<ReturnType<typeof sendInvitationV1NotesMeetingNoteIdInvitationSendPost>>>
export type ReadReminderV1NotesMeetingNoteIdInvitationReminderGetResult = NonNullable<Awaited<ReturnType<typeof readReminderV1NotesMeetingNoteIdInvitationReminderGet>>>
export type SetReminderV1NotesMeetingNoteIdInvitationReminderPostResult = NonNullable<Awaited<ReturnType<typeof setReminderV1NotesMeetingNoteIdInvitationReminderPost>>>
export type ReadTaskV1TasksTaskIdGetResult = NonNullable<Awaited<ReturnType<typeof readTaskV1TasksTaskIdGet>>>
export type RemoveTaskV1TasksTaskIdDeleteResult = NonNullable<Awaited<ReturnType<typeof removeTaskV1TasksTaskIdDelete>>>
export type UpdateTaskV1TasksTaskIdPatchResult = NonNullable<Awaited<ReturnType<typeof updateTaskV1TasksTaskIdPatch>>>
export type ReadTasksV1TasksGetResult = NonNullable<Awaited<ReturnType<typeof readTasksV1TasksGet>>>
export type CreateTaskV1TasksPostResult = NonNullable<Awaited<ReturnType<typeof createTaskV1TasksPost>>>
export type ReadTimeboxesV1TimeboxesGetResult = NonNullable<Awaited<ReturnType<typeof readTimeboxesV1TimeboxesGet>>>
export type CreateTimeboxesV1TimeboxesPostResult = NonNullable<Awaited<ReturnType<typeof createTimeboxesV1TimeboxesPost>>>
export type RemoveTimeboxV1TimeboxesTimeboxIdDeleteResult = NonNullable<Awaited<ReturnType<typeof removeTimeboxV1TimeboxesTimeboxIdDelete>>>
export type UpdateTimeboxesV1TimeboxesTimeboxIdPatchResult = NonNullable<Awaited<ReturnType<typeof updateTimeboxesV1TimeboxesTimeboxIdPatch>>>
export type CreateTimeboxTaskV1TimeboxesTimeboxIdTasksPostResult = NonNullable<Awaited<ReturnType<typeof createTimeboxTaskV1TimeboxesTimeboxIdTasksPost>>>
export type AssignTaskV1TimeboxesTimeboxIdTasksTaskIdPostResult = NonNullable<Awaited<ReturnType<typeof assignTaskV1TimeboxesTimeboxIdTasksTaskIdPost>>>
export type DetachTaskV1TimeboxesTimeboxIdTasksTaskIdDeleteResult = NonNullable<Awaited<ReturnType<typeof detachTaskV1TimeboxesTimeboxIdTasksTaskIdDelete>>>
export type UpdateNoteV1MemosMemoIdPutResult = NonNullable<Awaited<ReturnType<typeof updateNoteV1MemosMemoIdPut>>>
export type RemoveMemoV1MemosMemoIdDeleteResult = NonNullable<Awaited<ReturnType<typeof removeMemoV1MemosMemoIdDelete>>>
export type ListNotificationsV1NotificationsGetResult = NonNullable<Awaited<ReturnType<typeof listNotificationsV1NotificationsGet>>>
export type CreateNotificationsV1NotificationsPostResult = NonNullable<Awaited<ReturnType<typeof createNotificationsV1NotificationsPost>>>
export type CheckReadingNotificationV1NotificationsNotificationIdReadPutResult = NonNullable<Awaited<ReturnType<typeof checkReadingNotificationV1NotificationsNotificationIdReadPut>>>
export type ListReceiversV1SlackReceiversGetResult = NonNullable<Awaited<ReturnType<typeof listReceiversV1SlackReceiversGet>>>
export type IntegrateV1SlackAuthGetResult = NonNullable<Awaited<ReturnType<typeof integrateV1SlackAuthGet>>>
export type RevokeV1SlackRevokeDeleteResult = NonNullable<Awaited<ReturnType<typeof revokeV1SlackRevokeDelete>>>
export type ReadDailyMemoV1DailyMemosGetResult = NonNullable<Awaited<ReturnType<typeof readDailyMemoV1DailyMemosGet>>>
export type CreateDailyMemoV1DailyMemosPostResult = NonNullable<Awaited<ReturnType<typeof createDailyMemoV1DailyMemosPost>>>
export type UpdateDailyMemoV1DailyMemosMemoIdPutResult = NonNullable<Awaited<ReturnType<typeof updateDailyMemoV1DailyMemosMemoIdPut>>>
export type ReadTaskboxesV1TaskboxesGetResult = NonNullable<Awaited<ReturnType<typeof readTaskboxesV1TaskboxesGet>>>
export type CreateTaskboxV1TaskboxesPostResult = NonNullable<Awaited<ReturnType<typeof createTaskboxV1TaskboxesPost>>>
export type UpdateTaskboxesV1TaskboxesTaskboxIdPutResult = NonNullable<Awaited<ReturnType<typeof updateTaskboxesV1TaskboxesTaskboxIdPut>>>
export type RemoveTimeboxV1TaskboxesTaskboxIdDeleteResult = NonNullable<Awaited<ReturnType<typeof removeTimeboxV1TaskboxesTaskboxIdDelete>>>
export type CreateTaskboxTaskV1TaskboxesTaskboxIdTasksPostResult = NonNullable<Awaited<ReturnType<typeof createTaskboxTaskV1TaskboxesTaskboxIdTasksPost>>>
export type AssignTaskV1TaskboxesTaskboxIdTasksTaskIdPutResult = NonNullable<Awaited<ReturnType<typeof assignTaskV1TaskboxesTaskboxIdTasksTaskIdPut>>>
export type DetachTaskV1TaskboxesTaskboxIdTasksTaskIdDeleteResult = NonNullable<Awaited<ReturnType<typeof detachTaskV1TaskboxesTaskboxIdTasksTaskIdDelete>>>
export type ListWorksV1WorksGetResult = NonNullable<Awaited<ReturnType<typeof listWorksV1WorksGet>>>
export type CreateTaskV1WorksPostResult = NonNullable<Awaited<ReturnType<typeof createTaskV1WorksPost>>>
export type UpdateTaskV1WorksWorkIdPutResult = NonNullable<Awaited<ReturnType<typeof updateTaskV1WorksWorkIdPut>>>
export type RemoveTaskV1WorksWorkIdDeleteResult = NonNullable<Awaited<ReturnType<typeof removeTaskV1WorksWorkIdDelete>>>
export type AssignTaskV1WorksIssuesIssueIdTasksPutResult = NonNullable<Awaited<ReturnType<typeof assignTaskV1WorksIssuesIssueIdTasksPut>>>
export type ReadIssueV1WorksIssuesIssueIdGetResult = NonNullable<Awaited<ReturnType<typeof readIssueV1WorksIssuesIssueIdGet>>>
export type UpdateIssueV1WorksIssuesIssueIdPutResult = NonNullable<Awaited<ReturnType<typeof updateIssueV1WorksIssuesIssueIdPut>>>
export type MoveWorkToTaskboxV1WorksMoveTaskboxIdPostResult = NonNullable<Awaited<ReturnType<typeof moveWorkToTaskboxV1WorksMoveTaskboxIdPost>>>
export type ListPlanSectionsV1PlansSectionsGetResult = NonNullable<Awaited<ReturnType<typeof listPlanSectionsV1PlansSectionsGet>>>
export type CreatePlanSectionV1PlansSectionsPostResult = NonNullable<Awaited<ReturnType<typeof createPlanSectionV1PlansSectionsPost>>>
export type UpdatePlanSectionV1PlansSectionsPlanSectionIdPutResult = NonNullable<Awaited<ReturnType<typeof updatePlanSectionV1PlansSectionsPlanSectionIdPut>>>
export type DeletePlanSectionV1PlansSectionsPlanSectionIdDeleteResult = NonNullable<Awaited<ReturnType<typeof deletePlanSectionV1PlansSectionsPlanSectionIdDelete>>>
export type ListPlanWeeklyV1PlansWeeklyGetResult = NonNullable<Awaited<ReturnType<typeof listPlanWeeklyV1PlansWeeklyGet>>>
export type CreatePlanWeeklyV1PlansWeeklyPostResult = NonNullable<Awaited<ReturnType<typeof createPlanWeeklyV1PlansWeeklyPost>>>
export type UpdatePlanWeeklyV1PlansWeeklyPlanWeeklyIdPutResult = NonNullable<Awaited<ReturnType<typeof updatePlanWeeklyV1PlansWeeklyPlanWeeklyIdPut>>>
export type DeletePlanWeeklyV1PlansWeeklyPlanWeeklyIdDeleteResult = NonNullable<Awaited<ReturnType<typeof deletePlanWeeklyV1PlansWeeklyPlanWeeklyIdDelete>>>
export type CreateTaskboxTaskV1WorkboxesWorkBoxesIdWorkPostResult = NonNullable<Awaited<ReturnType<typeof createTaskboxTaskV1WorkboxesWorkBoxesIdWorkPost>>>
export type RemoveWorkBoxesV1WorkboxesWorkBoxesIdDeleteResult = NonNullable<Awaited<ReturnType<typeof removeWorkBoxesV1WorkboxesWorkBoxesIdDelete>>>
export type RemoveWorkInWorkBoxesV1WorkboxesWorkBoxesIdWorksWorkIdDeleteResult = NonNullable<Awaited<ReturnType<typeof removeWorkInWorkBoxesV1WorkboxesWorkBoxesIdWorksWorkIdDelete>>>
export type ReadFrequentWorksV1FrequentworksGetResult = NonNullable<Awaited<ReturnType<typeof readFrequentWorksV1FrequentworksGet>>>
export type CreateFrequentWorkV1FrequentworksPostResult = NonNullable<Awaited<ReturnType<typeof createFrequentWorkV1FrequentworksPost>>>
export type UpdateFrequentWorkV1FrequentworksFrequentWorkIdPutResult = NonNullable<Awaited<ReturnType<typeof updateFrequentWorkV1FrequentworksFrequentWorkIdPut>>>
export type DeleteFrequentWorkV1FrequentworksFrequentWorkIdDeleteResult = NonNullable<Awaited<ReturnType<typeof deleteFrequentWorkV1FrequentworksFrequentWorkIdDelete>>>
export type CreateFrequentWorkToTaskboxV1FrequentworksFrequentWorkIdTaskboxPostResult = NonNullable<Awaited<ReturnType<typeof createFrequentWorkToTaskboxV1FrequentworksFrequentWorkIdTaskboxPost>>>
export type CreateTaskInFrequentWorkV1FrequentworksFreuqentWorkIdTasksPostResult = NonNullable<Awaited<ReturnType<typeof createTaskInFrequentWorkV1FrequentworksFreuqentWorkIdTasksPost>>>
export type DeleteTaskInFrequentWorkV1FrequentworksFrequentWorkIdTasksTaskIdDeleteResult = NonNullable<Awaited<ReturnType<typeof deleteTaskInFrequentWorkV1FrequentworksFrequentWorkIdTasksTaskIdDelete>>>
export type ReadAutocompleteListV1AutocompleteGetResult = NonNullable<Awaited<ReturnType<typeof readAutocompleteListV1AutocompleteGet>>>
export type ListWorksV2WorkboxesGetResult = NonNullable<Awaited<ReturnType<typeof listWorksV2WorkboxesGet>>>
export type CreateWorkBoxesV2WorkboxesPostResult = NonNullable<Awaited<ReturnType<typeof createWorkBoxesV2WorkboxesPost>>>
export type UpdateWorkBoxesV2V2WorkboxesWorkBoxesIdPutResult = NonNullable<Awaited<ReturnType<typeof updateWorkBoxesV2V2WorkboxesWorkBoxesIdPut>>>
export type UpdateWorkV2V2WorksWorkIdPutResult = NonNullable<Awaited<ReturnType<typeof updateWorkV2V2WorksWorkIdPut>>>
export type MergeWorkOrWorkBoxesV2V2WorksMergePostResult = NonNullable<Awaited<ReturnType<typeof mergeWorkOrWorkBoxesV2V2WorksMergePost>>>
export type HealthCheckGetResult = NonNullable<Awaited<ReturnType<typeof healthCheckGet>>>
