import { SVGProps } from 'react';

export const Later = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9999 7.65685C14.5857 7.65685 14.2499 7.99264 14.2499 8.40685C14.2499 8.82107 14.5857 9.15685 14.9999 9.15685H20.6568C21.071 9.15685 21.4068 8.82107 21.4068 8.40685V2.75C21.4068 2.33579 21.071 2 20.6568 2C20.2426 2 19.9068 2.33579 19.9068 2.75V5.97552C19.7081 5.69919 19.4711 5.41164 19.1662 5.09124C17.4493 3.28686 15.1012 2.19194 12.5974 2.02364C10.0938 1.85535 7.61652 2.62582 5.66621 4.18259C3.71549 5.73969 2.43672 7.96823 2.09294 10.4197C1.74914 12.8712 2.36685 15.3581 3.81835 17.3772C5.26952 19.3958 7.44479 20.7952 9.90222 21.297C12.3595 21.7988 14.9199 21.3668 17.067 20.0863C19.2145 18.8056 20.7902 16.7702 21.4711 14.3884C21.585 13.9901 21.3544 13.575 20.9562 13.4611C20.5579 13.3472 20.1428 13.5778 20.0289 13.9761C19.4544 15.9857 18.1228 17.7102 16.2987 18.798C14.4742 19.8861 12.295 20.2547 10.2024 19.8273C8.10987 19.4 6.2643 18.2098 5.03628 16.5016C3.80859 14.7939 3.28857 12.6948 3.57841 10.628C3.86827 8.561 4.94728 6.67571 6.60198 5.35491C8.25708 4.03378 10.364 3.3769 12.4968 3.52027C14.6296 3.66363 16.6246 4.59615 18.0796 6.12524C18.6221 6.69539 18.89 7.11289 19.1791 7.65685H14.9999Z"
        fill="#1F2023"
      />
    </svg>
  );
};
