import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import updateLocale from 'dayjs/plugin/updateLocale';
import timezone from 'dayjs/plugin/timezone';
import weekend from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isYesterday from 'dayjs/plugin/isYesterday';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import 'dayjs/locale/ko';
import dayjsPlugin from './dayjsPlugin';

dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);
dayjs.extend(timezone);
dayjs.extend(weekend);
dayjs.extend(weekOfYear);
dayjs.extend(isYesterday);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);

dayjs.locale('ko');
dayjs.updateLocale('ko', { formats: { LL: 'YYYY-MM-DD', LLL: 'YYYY-MM-DD HH:mm' } });

dayjs.extend(dayjsPlugin);

export default dayjs;
