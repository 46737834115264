import { SVGProps } from 'react';

export const AddFrequent = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="icon_36">
        <path
          id="Vector"
          d="M9 10.7925C9 9.11232 9 8.27162 9.32698 7.62988C9.6146 7.0654 10.0732 6.60679 10.6377 6.31917C11.2794 5.99219 12.1201 5.99219 13.8003 5.99219H22.2003C23.8805 5.99219 24.7194 5.99219 25.3611 6.31917C25.9256 6.60679 26.3857 7.0654 26.6733 7.62988C27 8.27099 27 9.11068 27 10.7876V25.0202C27 27.0615 27 28.0825 26.6938 28.7071C26.1264 29.8649 24.8827 30.5301 23.6046 30.36C22.915 30.2683 22.0647 29.7025 20.366 28.5701C19.69 28.1194 19.3519 27.894 18.999 27.7693C18.3521 27.5406 17.6463 27.5406 16.9994 27.7693C16.6472 27.8938 16.3105 28.1182 15.6372 28.5671L15.6331 28.5698C13.9345 29.7023 13.0851 30.2685 12.3955 30.3603M4.5 20.2516H9M9 20.2516H13.5M9 20.2516V15.7516M9 20.2516V24.7516"
          stroke="#C8CDDB"
          strokeWidth="2.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
