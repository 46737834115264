import { Toaster } from 'react-hot-toast';

const Toast = () => (
  <Toaster
    toastOptions={{
      style: {
        fontWeight: 'bold',
        fontSize: '16px',
        padding: '8px 20px',
        boxSizing: 'border-box',
        boxShadow: '0px 16px 24px 0px #1a1e2729',
      },
      success: {
        style: {
          background: 'var(--positive2)',
        },
      },
      error: {
        style: {
          background: 'var(--negative2)',
        },
      },
    }}
  />
);
export default Toast;
