import styled from '@emotion/styled';
import Fonts from 'components/Fonts';
import { COLORS } from 'styles/constants';
import { Button, Popover, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from '@mui/material';
import { useState } from 'react';
import { OutTaskboxDetailResponse } from 'queries/model';
import { Icons } from 'components';

interface Props {
  taskId?: string | null;
  detail?: HTMLElement | null;
  onCloseDetail?: () => void;
  onClickRemoveSubtask?: (taskId: string) => void;
  onClickLaterSubtask?: (taskId: string) => void;
}

export const SubtaskDetail = ({ taskId, detail, onCloseDetail = () => {}, onClickRemoveSubtask = () => {}, onClickLaterSubtask = () => {} }: Props) => {
  return (
    <Popover
      open={Boolean(detail)}
      anchorEl={detail}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={onCloseDetail}
      sx={{ marginTop: '4px' }}
    >
      <DetailModal>
        <MenuList>
          <MenuItem className="item-text" onClick={() => onClickLaterSubtask(taskId!)}>
            <span style={{ marginRight: 8 }}>
              <Icons.Later width={16} height={16} fill="#1C1B1F" />
            </span>
            <span>나중에</span>
          </MenuItem>
          <Divider style={{ margin: '4px 0px' }}></Divider>
          <MenuItem className="item-text" onClick={() => onClickRemoveSubtask(taskId!)}>
            <Icons.Delete fill={COLORS.negative1}></Icons.Delete>
            <span style={{ marginLeft: '8px', color: `${COLORS.negative1}` }}>삭제</span>
          </MenuItem>
        </MenuList>
      </DetailModal>
    </Popover>
  );
};

const DetailModal = styled.div`
  width: fit-content;
  height: fit-content;
  min-width: 138px;
  background-color: ${COLORS.white};
  box-shadow: 0px 8px 16px 0px rgba(26, 30, 39, 0.16);
  border-radius: 8px;
  padding: 12px;
  z-index: 10;

  .item-text {
    display: flex;
    align-items: center;
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: ${COLORS.gray100};
    }
  }
`;

const MenuList = styled.ul``;

const MenuItem = styled.li`
  font-size: 12px;
`;
