import { SVGProps } from 'react';

export const PriorityIssueCheck = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="8" cy="8" r="7.5" fill={props.fill ? props.fill : '#0039A7'} stroke={props.stroke ? props.stroke : '#0039A7'} />
      <path d="M4 8.5L6.66667 11L12 6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
