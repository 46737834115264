import { Checkbox, CheckboxProps } from '@mui/material';
import { Icons } from 'components';

export interface IssueCheckboxProps extends CheckboxProps {}

const PriorityIssueCheckbox = (props: IssueCheckboxProps) => {
  const UncheckedIcon = <Icons.PriorityIssueUncheck />;
  const CheckedIcon = <Icons.PriorityIssueCheck />;
  return <Checkbox {...props} icon={UncheckedIcon} checkedIcon={CheckedIcon} sx={{ padding: 0, margin: 0, ...props.sx }} style={{ marginRight: 8 }} />;
};
export default PriorityIssueCheckbox;
