import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  linearProgressClasses,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Icons } from 'components';
import {
  createTaskboxTaskV1TaskboxesTaskboxIdTasksPost,
  detachTaskV1TaskboxesTaskboxIdTasksTaskIdDelete,
  assignTaskV1TaskboxesTaskboxIdTasksTaskIdPut,
  updateTaskV1TasksTaskIdPatch,
  updateTaskboxesV1TaskboxesTaskboxIdPut,
  createTaskboxV1TaskboxesPost,
  removeTaskV1WorksWorkIdDelete,
  readFrequentWorksV1FrequentworksGet,
  createFrequentWorkV1FrequentworksPost,
  deleteFrequentWorkV1FrequentworksFrequentWorkIdDelete,
  createFrequentWorkToTaskboxV1FrequentworksFrequentWorkIdTaskboxPost,
  updateFrequentWorkV1FrequentworksFrequentWorkIdPut,
  readAutocompleteListV1AutocompleteGet,
} from 'queries';
import {
  AppApplicationTaskboxesSchemasCreateTask,
  AppApplicationTaskboxesSchemasOutTask,
  OutFrequentWork,
  OutPlanWeeklyBrief,
  OutTaskboxDetailResponse,
  CreateFrequentWork,
  CreateTaskbox,
  UpdateTaskbox,
} from 'queries/model';
import React, { useRef, useEffect, useState, forwardRef, Ref, ForwardedRef, RefObject, useImperativeHandle, useMemo, useLayoutEffect } from 'react';
import { COLORS } from 'styles/constants';
import { TimelineTaskbox } from './TimelineTaskbox';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import Fonts from 'components/Fonts';
import { v4 as uuidv4 } from 'uuid';
import { useAtom } from 'jotai';
import { currentDateAtom, dragContextAtom, fetchTasksAtom } from 'atoms/works';
import dayjs from 'lib/dayjs';
import { DATE_FORMAT_4, DATE_FORMAT_5 } from 'utils/datetimeFormat';
import toast from 'react-hot-toast';
import { IssueCheckBox, SubTaskExpand } from 'components/Icons';
import { useClickOutside } from '@react-hookz/web';

import TaskBlockInputList, { TaskBlock, TaskBlockHandle } from './TaskBlockInputList';
import PropertySelectStories from 'stories/TextField/PropertySelect.stories';
import { debounce } from 'lodash';
import { TaskboxIncompleteDetail } from './TaskboxIncompleteDetail';

export interface Props {
  events?: OutTaskboxDetailResponse[];
  date?: Date;
  expanded?: boolean;
  selectedEvent?: OutTaskboxDetailResponse;
  stickyHeader?: boolean;
  editing?: boolean;
  focusedTaskBoard?: boolean;
  onChange?: (date: Date) => void;
  onClickTaskbox?: (taskbox: OutTaskboxDetailResponse) => void;
  onCreateTaskbox?: (value: string) => void;
  onDropTask?: (date: Date, tasks: { id: string; content: string }[]) => void;
  onClickFocusInput?: () => void;
  onClickExpand?: (date: Date) => void;
  onClickEditTaskBoard?: (date: Date) => void;
}

export const TimelineView = forwardRef(
  (
    {
      events = [],
      date,
      selectedEvent,
      expanded,
      stickyHeader,
      editing,
      focusedTaskBoard,
      onChange = () => {},
      onClickTaskbox = () => {},
      onCreateTaskbox = () => {},
      onDropTask = () => {},
      onClickFocusInput = () => {},
      onClickExpand = () => {},
      onClickEditTaskBoard = () => {},
    }: Props,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const [timelineItems, setTimelineItems] = useState<OutTaskboxDetailResponse[]>(events);
    const refTimelineScroll = useRef<HTMLDivElement[] | null[]>([]);
    const refTodoScroll = useRef<HTMLDivElement[] | null[]>([]);
    const [taskboxImage, setTaskboxImage] = useState<OutTaskboxDetailResponse>();
    const refTaskboard = useRef<HTMLDivElement>(null);
    const refInput = useRef<HTMLDivElement>(null);
    const [taskViewDragContext] = useAtom(dragContextAtom);
    const [isDragOver, setDragOver] = useState(false);
    const isDragging = useMemo(() => taskViewDragContext?.type === 'task', [taskViewDragContext]);
    const [incomplete, setIncomplete] = useState<HTMLElement | null>(null);

    useEffect(() => {
      setTimelineItems(events);
    }, [events]);

    useEffect(() => {
      const refTaskboardView = ref as RefObject<HTMLDivElement>;
      if (!refTaskboardView || !refTaskboardView.current) {
        return;
      }

      if (selectedEvent) {
        const targetTaskbox = events.find((event) => event.id === selectedEvent.id);
        if (targetTaskbox!.allDay) {
          const index = events.filter((event) => event.allDay).findIndex((event) => event.id === selectedEvent.id);
          setTimeout(() => {
            const el = refTodoScroll.current[index];
            if (el) refTaskboardView.current?.scrollTo({ top: Math.max(el.offsetTop - 200, 0), behavior: 'smooth' });
          }, 100);
        } else {
          const index = events.filter((event) => !event.allDay).findIndex((event) => event.id === selectedEvent.id);
          setTimeout(() => {
            const el = refTimelineScroll.current[index];
            if (el) refTaskboardView.current?.scrollTo({ top: Math.max(el.offsetTop - 200, 0), behavior: 'smooth' });
          }, 100);
        }
      }
    }, [selectedEvent]);

    const handleBeautifulDragEnd = async (result: DropResult) => {
      if (!result.destination) return;
      if (result.source.droppableId === result.destination.droppableId && result.source.index === result.destination.index) return;
      if (result.source.droppableId === 'all-taskbox') {
        const newTaskbox = timelineItems.filter((item) => item.id !== timelineItems[result.source.index].id);
        newTaskbox.splice(result.destination.index, 0, timelineItems[result.source.index]);
        setTimelineItems([...newTaskbox]);

        const updateTaskbox = timelineItems[result.source.index];
        if (result.source.index < result.destination.index) {
          const beforeId = timelineItems[result.destination.index].id;
          await updateTaskboxesV1TaskboxesTaskboxIdPut(updateTaskbox.id!, { beforeId });
        } else {
          const beforeId = result.destination.index - 1 < 0 ? null : timelineItems[result.destination.index - 1].id;
          await updateTaskboxesV1TaskboxesTaskboxIdPut(updateTaskbox.id!, { beforeId });
        }
        onChange(date!);
      }

      const startTaskboxIndex = timelineItems.findIndex((item) => item.id === result.source.droppableId);
      const startTaskBox = timelineItems.find((item) => item.id === result.source.droppableId);
      const endTaskboxIndex = timelineItems.findIndex((item) => item.id === result.destination!.droppableId);
      const endTaskBox = timelineItems.find((item) => item.id === result.destination!.droppableId);

      if (startTaskBox && endTaskBox) {
        if (startTaskBox === endTaskBox) {
          const srcTask = startTaskBox.tasks!.find((item) => item.id === result.draggableId);
          if (!srcTask) return;

          const dstTask = startTaskBox.tasks![result.destination.index];
          if (!dstTask) return;

          const srcTaskIndex = startTaskBox.tasks!.findIndex((item) => item.id === srcTask.id);
          if (srcTaskIndex === -1) return;
          const dstTaskIndex = startTaskBox.tasks!.findIndex((item) => item.id === dstTask.id);
          if (dstTaskIndex === -1) return;

          const newTasks = startTaskBox.tasks!.filter((item) => item.id !== srcTask.id);
          newTasks.splice(dstTaskIndex, 0, srcTask);

          startTaskBox.tasks = newTasks;
          timelineItems[startTaskboxIndex] = startTaskBox;
          setTimelineItems([...timelineItems]);

          if (srcTaskIndex < dstTaskIndex) {
            await assignTaskV1TaskboxesTaskboxIdTasksTaskIdPut(startTaskBox.id!, srcTask.id, { ...srcTask, beforeId: dstTask.id });
          } else {
            const id = dstTaskIndex - 1 >= 0 ? startTaskBox.tasks[dstTaskIndex - 1].id : undefined;
            await assignTaskV1TaskboxesTaskboxIdTasksTaskIdPut(startTaskBox.id!, srcTask.id, { ...srcTask, beforeId: id });
          }
        } else {
          const srcTask = startTaskBox.tasks!.find((item) => item.id === result.draggableId);
          if (!srcTask) return;

          const startNewTasks = startTaskBox.tasks!.filter((item) => item.id !== srcTask.id);
          startTaskBox.tasks = startNewTasks;
          events[startTaskboxIndex] = startTaskBox;

          const endNewTasks = endTaskBox.tasks;
          endNewTasks!.splice(result.destination.index, 0, srcTask);

          endTaskBox.tasks = endNewTasks;
          events[endTaskboxIndex] = endTaskBox;

          setTimelineItems([...timelineItems]);

          const id = result.destination.index - 1 < 0 ? undefined : endTaskBox.tasks![result.destination.index - 1].id;
          await detachTaskV1TaskboxesTaskboxIdTasksTaskIdDelete(startTaskBox.id!, srcTask.id);
          await createTaskboxTaskV1TaskboxesTaskboxIdTasksPost(endTaskBox.id!, { ...srcTask, beforeId: id });
          if (srcTask.done === true) {
            endTaskBox.tasks![result.destination.index].done = true;
            await updateTaskV1TasksTaskIdPatch(endTaskBox.tasks![result.destination.index].id, endTaskBox.tasks![result.destination.index]);
          }
        }
      }
    };

    // const handleBeautifulDragStart = (result: DropResult) => {
    //   const taskbox = events.find((event) => event.id === result.draggableId);
    //   if (taskbox) setTaskboxDrag({ id: taskbox.id, title: taskbox.title!, data: taskbox?.tasks });
    // };

    const handleAddTask = (index: number, tasks: AppApplicationTaskboxesSchemasOutTask[]) => {
      timelineItems[index].tasks = tasks;
      setTimelineItems([...timelineItems]);
    };

    const handleDrop = async () => {
      setDragOver(false);
      if (!taskViewDragContext || taskViewDragContext.type !== 'task') return;

      const { id, title, data } = taskViewDragContext;
      onDropTask?.(date!, Array.isArray(data) ? data : [{ id: id, content: title }]);
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      setDragOver(true);
    };

    const handleDragTaskboxImage = (event: OutTaskboxDetailResponse) => {
      setTaskboxImage(event);
    };

    const handleClickFocusPlusIcon = () => {
      onClickFocusInput();
      refInput.current?.focus();
    };

    const handleOpenIncompleteDialog = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      setIncomplete(event.currentTarget);
    };

    const handleCloseIncompleteDialog = () => {
      setIncomplete(null);
    };

    /** 미완료 태스크박스 오늘로 옮기기 */
    const handleClickTomorrow = async (incompleteTaskboxes: OutTaskboxDetailResponse[]) => {
      setIncomplete(null);
      const newDate = dayjs().format(DATE_FORMAT_4);
      const updateTaskbox: UpdateTaskbox = { start: { date: newDate }, end: { date: newDate } };

      incompleteTaskboxes.map(async (item) => {
        await updateTaskboxesV1TaskboxesTaskboxIdPut(item.id!, updateTaskbox);
      });

      toast.success('미완료 업무들을 오늘로 옮겼습니다.');
      setTimeout(() => {
        onChange(dayjs().toDate());
      }, 100);

      setTimeout(() => {
        onChange(date!);
      }, 100);
    };

    /** 미완료 태스크박스 다음주로 옮기기 */
    const handleClickNextWeek = async (incompleteTaskboxes: OutTaskboxDetailResponse[]) => {
      setIncomplete(null);
      const newDate = dayjs(date).add(1, 'week').day(1).format(DATE_FORMAT_4);
      const updateTaskbox: UpdateTaskbox = { start: { date: newDate }, end: { date: newDate } };
      incompleteTaskboxes.map(async (item) => {
        await updateTaskboxesV1TaskboxesTaskboxIdPut(item.id!, updateTaskbox);
      });

      toast.success('미완료 업무들을 다음주 월요일로 옮겼습니다.');

      setTimeout(() => {
        onChange(dayjs(date).add(1, 'week').day(1).toDate());
      }, 100);

      setTimeout(() => {
        onChange(date!);
      }, 100);
    };

    /** 미완료 태스크박스 나중에로 옮기기 */
    const handleClickLater = async (incompleteTaskboxes: OutTaskboxDetailResponse[]) => {
      toast.error('미완료 업무들을 나중에로 옮기기는\n현재 지원하지 않습니다.');
      // setIncomplete(null);
      // if (event && tasks.length === 0) {
      //   const createWork: CreateWork = {
      //     type: 'task',
      //     workSectionType: 'someday',
      //     id: event.id,
      //     content: event.title === '' ? '제목 없음' : event.title!,
      //   };
      //   const remove = await removeTimeboxV1TaskboxesTaskboxIdDelete(event.id!);
      //   const success = await createTaskV1WorksPost(createWork);
      //   if (remove && success) {
      //     toast.success('업무를 나중에로 옮겼습니다.');
      //     onChange(date!);
      //     fetchTasks();
      //   }
      // } else if (event && tasks.length > 0) {
      //   await removeTimeboxV1TaskboxesTaskboxIdDelete(event.id!);
      //   tasks.map(async (item) => {
      //     const createWork: CreateWork = {
      //       type: 'task',
      //       workSectionType: 'someday',
      //       id: item.id,
      //       content: item.content === '' ? '제목 없음' : item.content!,
      //       dueDate: item.dueDate,
      //     };
      //     await createTaskV1WorksPost(createWork);
      //   });
      //   toast.success('업무를 나중에로 옮겼습니다.');
      //   onChange(date!);
      //   fetchTasks();
      // }
    };

    /** 미완료 태스크박스 특정 일자로 미루는 로직 */
    const handleClickCalendar = async (value: dayjs.Dayjs, incompleteTaskboxes: OutTaskboxDetailResponse[]) => {
      if (!dayjs(date).isBefore(dayjs(value), 'day')) {
        toast.error('과거로는 옮길 수 없습니다.');
        return;
      }

      setIncomplete(null);
      const newDate = dayjs(value).format(DATE_FORMAT_4);
      const updateTaskbox: UpdateTaskbox = { start: { date: newDate }, end: { date: newDate } };
      incompleteTaskboxes.map(async (item) => {
        await updateTaskboxesV1TaskboxesTaskboxIdPut(item.id!, updateTaskbox);
      });

      toast.success(`미완료 업무들를 ${dayjs(value).format(DATE_FORMAT_5)}로 옮겼습니다.`);

      setTimeout(() => {
        onChange(dayjs(value).toDate());
      }, 100);
      setTimeout(() => {
        onChange(date!);
      }, 100);
    };

    return (
      <DailyTaskViewWrapper>
        <div>{stickyHeader ? '' : ''}</div>
        <div
          hidden={!stickyHeader}
          style={{
            zIndex: 1000,
            position: 'absolute',
            width: '630px',
            height: '48px',
            paddingTop: '12px',
            top: '0px',
            background: COLORS.gray200,
          }}
        >
          {isDragging ? (
            <TaskBoardHeaderDropzone onDrop={handleDrop} onDragOver={handleDragOver} onDragLeave={() => setDragOver(false)} dragOver={isDragOver} />
          ) : (
            <>
              <div
                style={{
                  position: 'absolute',
                  background: COLORS.gray200,
                  width: '690px',
                  height: '60px',
                  left: '-30px',
                  bottom: '-11px',
                  border: `1px solid ${COLORS.gray200}`,
                }}
              ></div>
              <DateHeader
                events={events}
                date={date!}
                expanded={expanded}
                isSticky={stickyHeader}
                focusedTaskBoard={focusedTaskBoard}
                incomplete={incomplete}
                onClickExpand={onClickExpand}
                onClickFocusIcon={stickyHeader ? handleClickFocusPlusIcon : undefined}
                onOpenIncomplete={handleOpenIncompleteDialog}
                onCloseIncomplete={handleCloseIncompleteDialog}
                onClickTomorrow={handleClickTomorrow}
                onClickNextWeek={handleClickNextWeek}
                onClickLater={handleClickLater}
                onClickCalendar={handleClickCalendar}
              />
            </>
          )}
        </div>
        <DateHeader
          events={events}
          date={date!}
          expanded={expanded}
          incomplete={incomplete}
          onClickExpand={onClickExpand}
          onOpenIncomplete={handleOpenIncompleteDialog}
          onCloseIncomplete={handleCloseIncompleteDialog}
          onClickTomorrow={handleClickTomorrow}
          onClickNextWeek={handleClickNextWeek}
          onClickLater={handleClickLater}
          onClickCalendar={handleClickCalendar}
        />

        {events?.length || editing ? (
          <Wrapper ref={refTaskboard}>
            <DragDropContext onDragEnd={handleBeautifulDragEnd}>
              {isDragging && !stickyHeader ? (
                <TaskBoardInputDropzone onDrop={handleDrop} onDragOver={handleDragOver} onDragLeave={() => setDragOver(false)} dragOver={isDragOver} />
              ) : (
                <FrequentWork ref={refInput} onCreate={onCreateTaskbox} onChange={onChange} date={date} isSticky={stickyHeader} />
              )}
              <TaskBoardToDoWrapper>
                <Typography fontSize={20} fontWeight={'bold'} sx={{ marginLeft: 1 }}>
                  할 일 목록
                </Typography>
                <Typography fontSize={20} fontWeight={'bold'} color={COLORS.gray400} style={{ marginLeft: 4 }}>
                  ({events.filter((item) => item.allDay).length})
                </Typography>
              </TaskBoardToDoWrapper>
              {timelineItems.filter((v) => v.allDay).length === 0 ? (
                <TimelineEmpty>
                  <TimelineEmptyContext>
                    <Icons.ListAdd stroke={COLORS.gray300} />
                    <Fonts.H5 style={{ color: COLORS.gray700, marginBottom: '4px', marginTop: '10px' }}>오늘 할 일은 무엇인가요?</Fonts.H5>
                    <Fonts.Blockquote style={{ color: COLORS.gray400 }}>할 일을 작성하거나 인박스에서 Drag&Drop 해주세요.</Fonts.Blockquote>
                  </TimelineEmptyContext>
                </TimelineEmpty>
              ) : (
                <Droppable droppableId="all-taskbox" type="taksbox">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {timelineItems
                        ?.filter((v) => v.allDay)
                        .map((event, index) => (
                          <div key={event.id} ref={(elem) => (refTodoScroll ? (refTodoScroll.current[index] = elem) : {})}>
                            <TimelineTaskbox
                              key={event.id}
                              event={event}
                              events={timelineItems}
                              date={date}
                              selectedEvent={selectedEvent}
                              index={index}
                              onChange={onChange}
                              onAddTask={handleAddTask}
                              onDragTaskboxImage={handleDragTaskboxImage}
                              onClickTaskbox={onClickTaskbox}
                              ref={refTaskboard}
                              draggingTask={taskViewDragContext}
                              expanded={expanded}
                            />
                          </div>
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              )}

              {timelineItems.filter((v) => v.allDay === false).length > 0 && (
                <div style={{ marginTop: 32 }}>
                  <TimelineHeaderTitle>
                    <Fonts.H2>타임라인</Fonts.H2>
                    <Typography fontSize={20} fontWeight={'bold'} color={COLORS.gray400} style={{ marginLeft: 4 }}>
                      ({timelineItems.filter((v) => v.allDay === false).length})
                    </Typography>
                  </TimelineHeaderTitle>
                  {timelineItems
                    ?.filter((v) => !v.allDay)
                    .map((event, index) => (
                      <div key={event.id} ref={(elem) => (refTimelineScroll ? (refTimelineScroll.current[index] = elem) : {})}>
                        <TimelineTaskbox
                          key={event.id}
                          event={event}
                          events={timelineItems}
                          date={date}
                          selectedEvent={selectedEvent}
                          onChange={onChange}
                          onAddTask={handleAddTask}
                          onClickTaskbox={onClickTaskbox}
                          draggingTask={taskViewDragContext}
                          expanded={expanded}
                        />
                      </div>
                    ))}
                </div>
              )}
            </DragDropContext>
            <TaskboxDragImage id="taskbox-drag-image">
              <TaskboxDragImageLine style={taskboxImage?.done ? { backgroundColor: `${COLORS.gray300}` } : {}} />
              <TaskboxDragImageTitle style={taskboxImage?.done ? { color: `${COLORS.gray500}`, textDecorationLine: 'line-through' } : {}}>
                {taskboxImage?.title ? taskboxImage.title : '제목 없음'}
              </TaskboxDragImageTitle>
            </TaskboxDragImage>
          </Wrapper>
        ) : (
          <NewTaskBoardWrapper>
            <Button disableElevation color="inherit" variant="text" sx={{ margin: 0 }} onClick={() => onClickEditTaskBoard?.(date!)}>
              <Icons.Plus width={16} height={16} strokeWidth={2} fill={COLORS.gray600} />
              <span style={{ color: COLORS.gray500, fontSize: 12, fontWeight: 'bold', marginLeft: 4, marginTop: 1 }}>업무 일지 작성하기</span>
            </Button>
          </NewTaskBoardWrapper>
        )}
      </DailyTaskViewWrapper>
    );
  },
);

const DailyTaskViewWrapper = styled.div`
  width: 100%;
  background-color: ${COLORS.gray100};
  border-radius: 8px;
  margin-top: 12px;
`;

const Wrapper = styled.div`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 24px;
`;

const TimelineHeaderTitle = styled.div`
  color: ${COLORS.gray900};
  margin-bottom: 8px;
  display: flex;
`;

const TimelineEmpty = styled.div`
  width: 100%;
  height: 108px;
  background-color: ${COLORS.gray100};
  border-radius: 8px;
  border: 1px solid ${COLORS.gray200};
  /* margin-bottom: 32px; */
`;

const TimelineEmptyContext = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 16px;
`;

const TaskboxDragImage = styled.div`
  width: 183px;
  height: 100px;
  cursor: pointer;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.gray300};
  border-radius: 8px;
  padding: 5px 6px 5px 8px;
  display: flex;
  position: absolute;
  left: -1000px;
  right: -1000px;
  z-index: 10000;
`;

const TaskboxDragImageLine = styled.div`
  width: 4px;
  height: 100%;
  background-color: ${COLORS.brand1};
  border-radius: 8px;
  margin-right: 8px;
`;

const TaskboxDragImageTitle = styled.div`
  font-size: 13px;
  font-weight: bold;
  color: #1f2023;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
`;

const TaskBoardHeaderDropzone = styled.div<{ dragOver?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 24px;
  height: 64px;
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: ${(props) => (props.dragOver ? COLORS.sub5 : COLORS.sub3)};
  border: 1px dashed ${(props) => (props.dragOver ? COLORS.sub4 : COLORS.brand1)};
  z-index: 1000;
`;

const TaskBoardInputDropzone = styled.div<{ dragOver?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 100%;
  border-radius: 8px;
  background-color: ${(props) => (props.dragOver ? COLORS.sub5 : COLORS.sub3)};
  border: 1px dashed ${(props) => (props.dragOver ? COLORS.sub4 : COLORS.brand1)};
`;

const TaskBoardToDoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 8px;
  border: 1px solid ${COLORS.gray100};
`;

const NewTaskBoardWrapper = styled.div`
  width: 100%;
  min-height: 82px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* border: 1px solid ${COLORS.gray100}; */
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  background-color: ${COLORS.gray100};
`;

/** DateHeader Component */
export interface DateHeaderProps {
  events?: OutTaskboxDetailResponse[];
  date: Date;
  expanded?: boolean;
  isSticky?: boolean;
  focusedTaskBoard?: boolean;
  incomplete?: HTMLElement | null;
  onClickExpand?: (date: Date) => void;
  onClickFocusIcon?: () => void;
  onOpenIncomplete?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onCloseIncomplete?: () => void;
  onClickTomorrow?: (taskboxes: OutTaskboxDetailResponse[]) => void;
  onClickNextWeek?: (taskboxes: OutTaskboxDetailResponse[]) => void;
  onClickLater?: (taskboxes: OutTaskboxDetailResponse[]) => void;
  onClickCalendar?: (value: dayjs.Dayjs, taskboxes: OutTaskboxDetailResponse[]) => void;
}

const DateHeader = ({
  events,
  date,
  expanded,
  isSticky,
  focusedTaskBoard,
  incomplete,
  onClickExpand = () => {},
  onClickFocusIcon = () => {},
  onOpenIncomplete = () => {},
  onCloseIncomplete = () => {},
  onClickTomorrow = () => {},
  onClickNextWeek = () => {},
  onClickLater = () => {},
  onClickCalendar = () => {},
}: DateHeaderProps) => {
  const weekday =
    [
      { condition: dayjs(date).isToday(), value: '오늘' },
      { condition: dayjs(date).isYesterday(), value: '어제' },
      { condition: dayjs(date).isTomorrow(), value: '내일' },
    ].find((item) => item.condition)?.value || dayjs(date).format('ddd요일');

  const [currentDate] = useAtom(currentDateAtom);
  const [doneTaskbox, setDoneTaskbox] = useState<OutTaskboxDetailResponse[]>(events!.filter((item) => item.done === true));
  const isBeforeDate = dayjs(date).isBefore(dayjs().startOf('day'));
  const isFocusedDate = currentDate === date;

  const handleClickExpand = () => {
    onClickExpand?.(date);
  };

  const handleClickIncomplete = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    onOpenIncomplete(e);
  };

  useEffect(() => {
    const filteredTaskbox = events!.filter((item) => item.done === true);
    setDoneTaskbox(filteredTaskbox);
  }, [events]);

  return (
    <DateHeaderWrapper isSticky={isSticky} focusedTaskBoard={focusedTaskBoard} events={events}>
      <DateHeaderTop>
        {isSticky ? (
          <div onClick={onClickFocusIcon}>
            <Tooltip title="새로운 할 일 입력하기">
              <IconButton
                sx={{
                  padding: '3px',
                  borderRadius: '6px',
                }}
              >
                <Icons.Plus width={16} height={16} strokeWidth={2} fill={COLORS.gray600} style={{ cursor: 'pointer' }} />
              </IconButton>
            </Tooltip>
          </div>
        ) : (
          <div style={{ width: '22px' }}></div>
        )}

        <span style={{ color: +dayjs(date) >= +dayjs().startOf('day') ? COLORS.gray900 : COLORS.gray500 }}>
          <b style={{ color: dayjs(date).isToday() ? COLORS.brand1 : '' }}>{weekday}</b> {dayjs(date).format('MM월 DD일')}
        </span>

        <div style={!events?.length ? { opacity: 0, right: 24 } : {}}>
          <Tooltip title={expanded ? '하위 업무 접기' : '하위 업무 펼치기'}>
            <IconButton
              sx={{
                'padding': '3px',
                'borderRadius': '6px',
                '&:hover': { background: expanded ? COLORS.sub2 : COLORS.sub3 },
                'background': expanded ? COLORS.sub2 : 'white',
              }}
              onClick={handleClickExpand}
            >
              <SubTaskExpand fill={expanded ? 'white' : ''} />
            </IconButton>
          </Tooltip>
        </div>
      </DateHeaderTop>

      {events!.length > 0 && (
        <DateHeaderProgress isFocusedDate={isFocusedDate} allTaskboxDone={events!.length === doneTaskbox.length} isBeforeDate={isBeforeDate}>
          <LinearProgress
            variant="determinate"
            value={(doneTaskbox.length / events!.length) * 100}
            sx={{ width: '100%', marginRight: '34px', height: '12px', borderRadius: '5px' }}
          ></LinearProgress>
          <DateHeaderProgressText
            onClick={(e) => {
              if (events?.length !== doneTaskbox.length && isBeforeDate) onOpenIncomplete(e);
            }}
            allTaskboxDone={events!.length === doneTaskbox.length}
            isBeforeDate={isBeforeDate}
          >
            {`(${doneTaskbox.length}/${events?.length})`}
            {events!.length !== doneTaskbox.length && isBeforeDate && <DateHeaderProgressTextDot />}
          </DateHeaderProgressText>
        </DateHeaderProgress>
      )}
      {incomplete && (
        <TaskboxIncompleteDetail
          events={events}
          incomplete={incomplete}
          onCloseIncomplete={onCloseIncomplete}
          onClickTomorrow={onClickTomorrow}
          onClickNextWeek={onClickNextWeek}
          onClickLater={onClickLater}
          onClickCalendar={onClickCalendar}
        ></TaskboxIncompleteDetail>
      )}
    </DateHeaderWrapper>
  );
};

const DateHeaderWrapper = styled.div<{ isSticky?: boolean; focusedTaskBoard?: boolean; events?: OutTaskboxDetailResponse[] }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 24px;
  height: 48px;
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: ${COLORS.gray100};
  border-bottom: 1px solid ${COLORS.gray200};
  ${(props) =>
    props.isSticky &&
    props.focusedTaskBoard &&
    css`
      box-shadow: 0px 0px 16px ${COLORS.shadow100};
    `}

  ${(props) =>
    props.events!.length > 0 &&
    css`
      height: 70px;
    `}
`;

const DateHeaderTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 4px;
`;

const DateHeaderProgress = styled.div<{ isFocusedDate?: boolean; allTaskboxDone?: boolean; isBeforeDate?: boolean }>`
  display: flex;
  align-items: center;

  ${(props) =>
    !props.isFocusedDate &&
    css`
      opacity: 60%;
    `}

  .MuiLinearProgress-colorPrimary {
    border: 1px solid ${COLORS.white};
    background-color: ${COLORS.gray200};
    ${(props) =>
      props.isBeforeDate &&
      css`
        background-color: #fcdfe6;
      `}
  }

  .MuiLinearProgress-bar {
    border-radius: 5px;
    backgroud-color: ${COLORS.brand1};
    ${(props) =>
      props.allTaskboxDone &&
      css`
        background-color: ${COLORS.sub4};
      `}/* ${(props) =>
      props.isBeforeDate &&
      !props.allTaskboxDone &&
      css`
        background-color: ${COLORS.negative1};
      `} */
  }
`;

const DateHeaderProgressText = styled.div<{ allTaskboxDone?: boolean; isBeforeDate?: boolean }>`
  font-size: 13px;
  font-weight: 700;
  color: ${COLORS.brand1};
  display: flex;
  padding: 2px;

  ${(props) =>
    !props.allTaskboxDone &&
    props.isBeforeDate &&
    css`
      cursor: pointer;
      color: ${COLORS.negative1};
      &:hover {
        background-color: ${COLORS.gray200};
      }
    `}

  ${(props) =>
    props.allTaskboxDone &&
    css`
      color: ${COLORS.sub4};
    `}
`;

const DateHeaderProgressTextDot = styled.div`
  width: 5px;
  height: 5px;
  background-color: ${COLORS.negative1};
  border-radius: 50%;
`;
/** DateHeader Component */

/** FrequentWork */
export interface FrequentWorkProps {
  date?: Date;
  isSticky?: boolean;
  onCreate?: (value: string) => void;
  onChange?: (date: Date) => void;
}

export interface FrequentWorkImperativeHandle {
  focus?: () => void;
}

const FrequentWork = forwardRef<FrequentWorkImperativeHandle, FrequentWorkProps>(
  ({ onCreate = () => {}, onChange = () => {}, date, isSticky = false }, ref: any) => {
    const [isFocus, setIsFocus] = useState(false);
    const refFrequentInput = useRef<HTMLInputElement>(null);
    const refCloseInput = useRef<HTMLInputElement>(null);
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [frequentWorkList, setFrequentWorkList] = useState<OutFrequentWork[]>([]);
    const [autoFrequentWorkList, setAutoFrequentWorkList] = useState<OutFrequentWork[]>([]);
    const [frequentWork, setFrequentWork] = useState<OutFrequentWork>();
    const [foldFrequentWork, setFoldFrequentWork] = useState(false);
    const [autoFoldFrequentWork, setAutoFoldFrequentWork] = useState(false);

    useLayoutEffect(() => {
      getFreqeuntWork();
    }, []);

    useEffect(() => {
      if (isSticky) {
        setIsFocus(false);
        setFoldFrequentWork(false);
      }
    }, [isSticky]);

    const getFreqeuntWork = async (title?: string) => {
      const param = title ? { title } : undefined;
      const data = await readAutocompleteListV1AutocompleteGet(param);
      const frequentWork = data.filter((item) => item.boxAttribute === 'FREQUENT_WORK_BOX');
      setFrequentWorkList(frequentWork);
    };

    useImperativeHandle(ref, () => ({ focus: () => refFrequentInput?.current?.focus() }));

    useClickOutside(refCloseInput, () => {
      if (!refFrequentInput.current) return;
      if (refFrequentInput.current.value) return;
      if (isOpenDialog) return;
      setIsFocus(false);
      setFoldFrequentWork(false);
    });

    const handleKeydownInput = async (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (!refFrequentInput.current) return;

      const value = refFrequentInput.current?.value;
      if (e.key === 'Escape') {
        if (value) {
          refFrequentInput.current.blur();
          return;
        }
        e.preventDefault();
        refFrequentInput.current.blur();
      }

      if (e.key === 'Enter') {
        if (e.nativeEvent.isComposing) return;
        if (e.repeat) {
          e.preventDefault();
          return;
        }

        refFrequentInput.current.value = '';
        onCreate?.(value);
      }
    };

    const handleFocus = () => {
      setIsFocus(true);
      refFrequentInput.current?.focus();
    };

    const handleCreateFrequentWork = async (frequentWork: OutFrequentWork) => {
      const newFrequentWork: CreateFrequentWork = {
        id: uuidv4(),
        type: 'FREQUENT_WORK_BOX',
        title: frequentWork?.title,
        tasks: frequentWork?.tasks,
      };
      const success = await createFrequentWorkV1FrequentworksPost(newFrequentWork);
      if (success) {
        getFreqeuntWork();
        toast.success('자주 하는 업무가 추가되었습니다.');
      }
    };

    const handlePutFreuqentWork = async (frequentWork: OutFrequentWork) => {
      const success = await updateFrequentWorkV1FrequentworksFrequentWorkIdPut(frequentWork.id!, frequentWork);

      if (success) {
        getFreqeuntWork();
      }
    };

    const handleDeleteFreuqentWork = async (frequentWork: OutFrequentWork) => {
      const success = await deleteFrequentWorkV1FrequentworksFrequentWorkIdDelete(frequentWork.id!);

      if (success) {
        await getFreqeuntWork();
        toast.success('자주 하는 업무가 삭제되었습니다.');
      }
    };

    const handleClickDialogDeleteFrequentWork = async (frequentWork: OutFrequentWork) => {
      setIsOpenDialog(false);
      setFrequentWork(undefined);
      handleDeleteFreuqentWork(frequentWork);
    };

    const handleCreateTaskbox = async (frequentWork: OutFrequentWork) => {
      const newTasks = frequentWork.tasks?.map((item) => ({
        id: uuidv4(),
        content: item.content,
      }));

      const taskbox: CreateTaskbox = {
        id: uuidv4(),
        title: frequentWork.title,
        tasks: newTasks,
        start: { date: dayjs(date).format(DATE_FORMAT_4) },
        end: { date: dayjs(date).format(DATE_FORMAT_4) },
      };

      const success = await createFrequentWorkToTaskboxV1FrequentworksFrequentWorkIdTaskboxPost(frequentWork.id!, taskbox);

      if (success) {
        toast.success('자주 하는 업무를 할 일 목록에 추가했습니다.');
        onChange(date!);
      }
    };

    const handleCloseDialog = async (frequentWork?: OutFrequentWork) => {
      setIsOpenDialog(false);
      setTimeout(() => {
        setFrequentWork(undefined);
      }, 200);

      if (!frequentWork) return;
      handlePutFreuqentWork(frequentWork!);
    };

    const handleSaveDialog = async (frequentWork: OutFrequentWork) => {
      setIsOpenDialog(false);

      handleCreateFrequentWork(frequentWork);

      setFrequentWork(undefined);
    };

    const handleClickDialogAddTaskboxButton = async (frequentWork: OutFrequentWork) => {
      setIsOpenDialog(false);
      setTimeout(() => {
        setFrequentWork(undefined);
      }, 200);
      handlePutFreuqentWork(frequentWork);
      handleCreateTaskbox(frequentWork);
      setFoldFrequentWork(false);
    };

    const handleSelectFrequentWork = (frequentWork: OutFrequentWork) => {
      setFrequentWork(frequentWork);
    };

    const handleChangeFrequentTitle = (title: string) => {
      setFrequentWork({ ...frequentWork, title: title });
    };

    const handleChangeTaskBlocks = (tasks: { id: string; content?: string; dueDate?: string }[] = []) => {
      setFrequentWork({ ...frequentWork, tasks: tasks });
    };

    const handleFilterTitle = debounce(async (e: React.ChangeEvent<HTMLInputElement>) => {
      const data = e.target.value ? await readAutocompleteListV1AutocompleteGet({ title: e.target.value }) : [];

      const autoFrequentWork = data.filter((item) => item.boxAttribute === 'FREQUENT_WORK_BOX');

      if (autoFrequentWork.length > 0 && e.target.value.length > 0) {
        setFoldFrequentWork(true);
        setFrequentWorkList(autoFrequentWork);
      } else if (autoFrequentWork.length === 0) {
        setFoldFrequentWork(false);
        const resp = await readFrequentWorksV1FrequentworksGet();
        setFrequentWorkList(resp);
      }
    }, 300);

    // useEffect(() => {
    //   if (filterTitle.length === 0) {
    //     setFoldFrequentWork(false);
    //   } else {
    //     const currentList = currentFrequentWorkList;
    //     const newMap: OutFrequentWork[] = currentList?.filter((item) => item.title?.includes(filterTitle));

    //     if (filterTitle.length !== 0 && newMap.length > 0) {
    //       setFoldFrequentWork(true);
    //       setCurrentFrequentWorkList(newMap);
    //     } else {
    //       setCurrentFrequentWorkList(currentList);
    //     }
    //   }
    // }, [filterTitle]);

    // useEffect(() => {
    //   console.log(currentFrequentWorkList);
    //   setFrequentWorkList(currentFrequentWorkList);
    // }, [currentFrequentWorkList]);
    // console.log(frequentWorkList);

    return (
      <>
        {/* <Autocomplete
        options={frequentWorkList!}
        open={false}
        getOptionLabel={(option) => option.title!}
        renderOption={(props, option) => (
          <li {...props}>
            <FrequentWorkListContent>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icons.Bookmark></Icons.Bookmark>
                <FrequentWorkListContentTitle>{option.title}</FrequentWorkListContentTitle>
                {option.tasks!.length > 0 && (
                  <FrequentWorkListSubTask>
                    <Icons.Subtask stroke={COLORS.gray300}></Icons.Subtask>
                    <FrequentWorkListSubTaskLength>{option.tasks?.length}</FrequentWorkListSubTaskLength>
                  </FrequentWorkListSubTask>
                )}
              </div>
              <FrequentWorkDetailBtn
                className="frequent-detail"
                onClick={() => {
                  setIsOpenDialog(true);
                  handleSelectFrequentWork(option);
                }}
              >
                상세보기
              </FrequentWorkDetailBtn>
            </FrequentWorkListContent>
          </li>
        )}
        renderInput={(params) => (
          <TaskboxInputWrapper>
            {isFocus ? <IssueCheckBox /> : <Icons.Plus width={18} height={18} fill={COLORS.gray600} />}
            <TextField
              inputRef={refFrequentInput}
              autoComplete="off"
              // fullWidth
              // variant="standard"
              placeholder={isFocus ? '할 일을 입력해주세요' : '새로운 할 일 입력하기'}
              onKeyDown={handleKeydownInput}
              onFocus={handleFocus}
              onClick={handleFocus}
              // InputProps={{ disableUnderline: true, style: { fontSize: 16, fontWeight: 'bold', color: COLORS.gray800 } }}
              style={{ margin: '0px 8px' }}
              {...params}
            />
             {foldFrequentWork ? (
              <IconButton style={{ width: '24px', height: '24px', padding: 0 }} onClick={() => setFoldFrequentWork(false)}>
                <Icons.ArrowUpSmall width={24} height={24} fill={COLORS.brand1}></Icons.ArrowUpSmall>
              </IconButton>
            ) : (
              <IconButton style={{ width: '24px', height: '24px', padding: 0 }} onClick={() => setFoldFrequentWork(true)}>
                <Icons.ArrowDownSmall width={24} height={24} fill={COLORS.brand1}></Icons.ArrowDownSmall>
              </IconButton>
            )}
          </TaskboxInputWrapper>
        )}
        sx={{ backgroundColor: COLORS.white, borderRadius: '8px', border: `1px solid ${COLORS.gray200}` }}
      ></Autocomplete>

      <Dialog open={isOpenDialog} onClose={handleCloseDialog}>
        <FrequentWorkInput
          date={date}
          frequentWork={frequentWork}
          onCloseDialog={handleCloseDialog}
          onSaveDialog={handleSaveDialog}
          onRemoveFrequentWork={handleDeleteFreuqentWork}
          onCreateTaskbox={handleCreateTaskbox}
          onChangeTaskBlocks={handleChangeTaskBlocks}
          onChangeFrequentTitle={handleChangeFrequentTitle}
        />
      </Dialog> */}

        <CreateFrequentWorkContainer ref={refCloseInput}>
          <FrequentWorkContainer foldFrequentWork={foldFrequentWork}>
            <TaskboxInputWrapper>
              {isFocus ? <IssueCheckBox /> : <Icons.Plus width={18} height={18} fill={COLORS.gray600} />}
              <TextField
                inputRef={refFrequentInput}
                autoComplete="off"
                fullWidth
                variant="standard"
                placeholder={isFocus ? '할 일을 입력해주세요' : '새로운 할 일 입력하기'}
                onKeyDown={handleKeydownInput}
                onFocus={handleFocus}
                onChange={handleFilterTitle}
                InputProps={{ disableUnderline: true, style: { fontSize: 16, fontWeight: 'bold', color: COLORS.gray800 } }}
                style={{ margin: '0px 8px' }}
              />
              {foldFrequentWork ? (
                <IconButton style={{ width: '24px', height: '24px', padding: 0 }} onClick={() => setFoldFrequentWork(false)}>
                  <Icons.ArrowUpSmall width={24} height={24} fill={COLORS.brand1}></Icons.ArrowUpSmall>
                </IconButton>
              ) : (
                <IconButton style={{ width: '24px', height: '24px', padding: 0 }} onClick={() => setFoldFrequentWork(true)}>
                  <Icons.ArrowDownSmall width={24} height={24} fill={COLORS.brand1}></Icons.ArrowDownSmall>
                </IconButton>
              )}
            </TaskboxInputWrapper>

            {foldFrequentWork && (
              <>
                <Divider style={{ width: '94%' }} />
                {frequentWorkList.length === 0 ? (
                  <FrequentWorkWrapper>
                    <Icons.AddFrequent></Icons.AddFrequent>
                    <FrequentWorkTitle>자주 하는 업무가 있나요?</FrequentWorkTitle>
                    <FrequentWorkContent>자주 하는 업무를 저장해두고 한번 클릭으로 추가할 수 있어요.</FrequentWorkContent>
                    <Button
                      onClick={() => setIsOpenDialog(true)}
                      variant="outlined"
                      sx={{
                        color: COLORS.gray900,
                        borderRadius: '8px',
                        border: `1px solid ${COLORS.gray200}`,
                        fontSize: '13px',
                        padding: '6px 12px',
                        fontWeight: 'bold',
                      }}
                    >
                      자주 하는 업무 만들기
                    </Button>
                  </FrequentWorkWrapper>
                ) : (
                  <FrequentWorkListContainer>
                    <FrequentWorkListTitle>자주 하는 업무</FrequentWorkListTitle>
                    <FrequentWorkListScroll>
                      {frequentWorkList.map((item) => (
                        <FrequentWorkListContent
                          key={item.id}
                          onClick={(e) => {
                            if (e.target === e.currentTarget) {
                              handleCreateTaskbox(item);
                              setFoldFrequentWork(false);
                            }
                          }}
                        >
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Icons.Bookmark stroke={COLORS.brand1}></Icons.Bookmark>
                            <FrequentWorkListContentTitle>{item.title}</FrequentWorkListContentTitle>
                            {item.tasks!.length > 0 && (
                              <FrequentWorkListSubTask>
                                <Icons.Subtask stroke={COLORS.gray300}></Icons.Subtask>
                                <FrequentWorkListSubTaskLength>{item.tasks?.length}</FrequentWorkListSubTaskLength>
                              </FrequentWorkListSubTask>
                            )}
                          </div>
                          <FrequentWorkDetailBtn
                            className="frequent-detail"
                            onClick={() => {
                              setIsOpenDialog(true);
                              handleSelectFrequentWork(item);
                            }}
                          >
                            상세보기
                          </FrequentWorkDetailBtn>
                        </FrequentWorkListContent>
                      ))}
                    </FrequentWorkListScroll>

                    <CreateFrequentWorkButtonWrapper>
                      <CreateFrequentWorkButton onClick={() => setIsOpenDialog(true)}>자주 하는 업무 만들기</CreateFrequentWorkButton>
                    </CreateFrequentWorkButtonWrapper>
                  </FrequentWorkListContainer>
                )}
              </>
            )}
          </FrequentWorkContainer>
          <Dialog
            open={isOpenDialog}
            onClose={() => {
              handleCloseDialog(frequentWork?.id ? frequentWork : undefined);
            }}
          >
            <FrequentWorkInput
              date={date}
              frequentWork={frequentWork}
              onCloseDialog={handleCloseDialog}
              onSaveDialog={handleSaveDialog}
              onClickDialogAddTaskboxButton={handleClickDialogAddTaskboxButton}
              onClickDialogDeleteFrequentWork={handleClickDialogDeleteFrequentWork}
              onChangeTaskBlocks={handleChangeTaskBlocks}
              onChangeFrequentTitle={handleChangeFrequentTitle}
            />
          </Dialog>
        </CreateFrequentWorkContainer>

        {/* {isFocus ? (
        <CreateFrequentWorkContainer ref={refCloseInput} style={{ border: 'none' }}>
          <FrequentWorkContainer>
            <TaskboxInputWrapper>
              <IssueCheckBox />
              <TextField
                inputRef={refFrequentInput}
                autoComplete="off"
                fullWidth
                variant="standard"
                placeholder={'할 일을 입력해주세요'}
                onKeyDown={handleKeydownInput}
                InputProps={{ disableUnderline: true, style: { fontSize: 16, fontWeight: 'bold', color: COLORS.gray800 } }}
                style={{ marginLeft: 8 }}
              />
            </TaskboxInputWrapper>
            <Divider style={{ width: '94%' }} />
            {frequentWorkList.length === 0 ? (
              <FrequentWorkWrapper>
                <Icons.AddFrequent></Icons.AddFrequent>
                <FrequentWorkTitle>자주 하는 업무가 있나요?</FrequentWorkTitle>
                <FrequentWorkContent>자주 하는 업무를 저장해두고 한번 클릭으로 추가할 수 있어요.</FrequentWorkContent>
                <Button
                  onClick={() => setIsOpenDialog(true)}
                  variant="outlined"
                  sx={{
                    color: COLORS.gray900,
                    borderRadius: '8px',
                    border: `1px solid ${COLORS.gray200}`,
                    fontSize: '13px',
                    padding: '6px 12px',
                    fontWeight: 'bold',
                  }}
                >
                  자주 하는 업무 만들기
                </Button>
              </FrequentWorkWrapper>
            ) : (
              <FrequentWorkListContainer>
                <FrequentWorkListTitle>자주 하는 업무</FrequentWorkListTitle>
                <FrequentWorkListScroll>
                  {frequentWorkList.map((item) => (
                    <FrequentWorkListContent>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Icons.Bookmark></Icons.Bookmark>
                        <FrequentWorkListContentTitle>{item.title}</FrequentWorkListContentTitle>
                        {item.tasks!.length > 0 && (
                          <FrequentWorkListSubTask>
                            <Icons.Subtask stroke={COLORS.gray300}></Icons.Subtask>
                            <FrequentWorkListSubTaskLength>{item.tasks?.length}</FrequentWorkListSubTaskLength>
                          </FrequentWorkListSubTask>
                        )}
                      </div>
                      <FrequentWorkDetailBtn
                        className="frequent-detail"
                        onClick={() => {
                          setIsOpenDialog(true);
                          handleSelectFrequentWork(item);
                        }}
                      >
                        상세보기
                      </FrequentWorkDetailBtn>
                    </FrequentWorkListContent>
                  ))}
                </FrequentWorkListScroll>

                <CreateFrequentWorkButtonWrapper>
                  <CreateFrequentWorkButton onClick={() => setIsOpenDialog(true)}>자주 하는 업무 만들기</CreateFrequentWorkButton>
                </CreateFrequentWorkButtonWrapper>
              </FrequentWorkListContainer>
            )}
          </FrequentWorkContainer>
          <Dialog open={isOpenDialog} onClose={handleCloseDialog}>
            <FrequentWorkInput
              date={date}
              frequentWork={frequentWork}
              onCloseDialog={handleCloseDialog}
              onSaveDialog={handleSaveDialog}
              onRemoveFrequentWork={handleDeleteFreuqentWork}
              onCreateTaskbox={handleCreateTaskbox}
              onChangeTaskBlocks={handleChangeTaskBlocks}
              onChangeFrequentTitle={handleChangeFrequentTitle}
            />
          </Dialog>
        </CreateFrequentWorkContainer>
      ) : (
        <CreateFrequentWorkContainer ref={refCloseInput}>
          <TaskboxInputWrapper onFocus={handleFocus}>
            <Icons.Plus width={18} height={18} fill={COLORS.gray600} />
            <TextField
              autoComplete="off"
              fullWidth
              variant="standard"
              placeholder={'새로운 할 일 입력하기'}
              InputProps={{ disableUnderline: true, style: { fontSize: 16, fontWeight: 'bold', color: COLORS.gray800 } }}
              style={{ marginLeft: 8 }}
            />
          </TaskboxInputWrapper>
        </CreateFrequentWorkContainer>
      )} */}
      </>
    );
  },
);

const CreateFrequentWorkContainer = styled.div`
  height: 50px;
  position: relative;
`;

const TaskboxInputWrapper = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 16px 20px;
`;

const FrequentWorkContainer = styled.div<{ foldFrequentWork: boolean }>`
  position: absolute;
  width: 100%;
  background-color: ${COLORS.white};
  z-index: 100;
  border-radius: 8px;
  border: 1px solid ${COLORS.gray200};
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(props) =>
    props.foldFrequentWork &&
    css`
      box-shadow: 0px 8px 16px rgba(26, 30, 39, 0.16);
    `}
`;

const FrequentWorkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  align-items: center;
`;

const FrequentWorkListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px 0px;
`;

const FrequentWorkListTitle = styled.div`
  color: ${COLORS.gray500};
  font-size: 12px;
  padding: 0px 12px 0px 12px;
`;

const FrequentWorkListScroll = styled.div`
  max-height: 400px;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

const CreateFrequentWorkButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const CreateFrequentWorkButton = styled.div`
  color: ${COLORS.gray400};
  font-size: 12px;
  font-weight: 400;
  margin-top: 12px;
  cursor: pointer;
  &:hover {
    color: ${COLORS.brand1};
  }
`;

const FrequentWorkListContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  padding: 4px 12px;
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.gray100};
    .frequent-detail {
      opacity: 1;
    }
  }
`;

const FrequentWorkListContentTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin: 0px 8px;
`;

const FrequentWorkListSubTask = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.gray300};
`;

const FrequentWorkListSubTaskLength = styled.div`
  margin-left: 4px;
  font-size: 13px;
`;

const FrequentWorkDetailBtn = styled.div`
  font-size: 10px;
  color: ${COLORS.gray400};
  cursor: pointer;
  opacity: 0;
  &:hover {
    color: ${COLORS.brand1};
  }
`;

const FrequentWorkTitle = styled.div`
  font-size: 12px;
  font-weight: 700;
  color: ${COLORS.gray700};
  margin-top: 8px;
`;

const FrequentWorkContent = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${COLORS.gray400};
  margin: 4px 0px 18px 0px;
`;
/** */

/** FrequentWorkInput */
interface FrequentWorkInputProps {
  date?: Date;
  frequentWork?: OutFrequentWork;
  onCloseDialog?: (frequentWork: OutFrequentWork) => void;
  onSaveDialog?: (frquentWork: OutFrequentWork) => void;
  onClickDialogAddTaskboxButton?: (frequentWork: OutFrequentWork) => void;
  onClickDialogDeleteFrequentWork?: (frequentWork: OutFrequentWork) => void;
  onChangeTaskBlocks?: (tasks: { id: string; content?: string; dueDate?: string }[]) => void;
  onChangeFrequentTitle?: (title: string) => void;
}

const FrequentWorkInput = ({
  date,
  frequentWork,
  onCloseDialog = () => {},
  onSaveDialog = () => {},
  onClickDialogAddTaskboxButton = () => {},
  onClickDialogDeleteFrequentWork = () => {},
  onChangeTaskBlocks = () => {},
  onChangeFrequentTitle = () => {},
}: FrequentWorkInputProps) => {
  const refInput = useRef<HTMLInputElement>(null);
  const refTaskBlockList = useRef<TaskBlockHandle>(null);
  const [frequentDetail, setFrequentDetail] = useState<HTMLElement | null>(null);
  const [removeFrequentWork, setRemoveFrequent] = useState(false);
  const [frequentWorkTitle, setFrequentWorkTitle] = useState('');

  const handleKeydownInput = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!refInput.current) return;

    const value = refInput.current?.value;

    if (e.key === 'Escape') {
      if (value) {
        refInput.current.blur();
        return;
      }
      e.preventDefault();
      refInput.current.blur();
    }

    if (e.key === 'Enter') {
      if (e.nativeEvent.isComposing) return;
      if (e.repeat) {
        e.preventDefault();
        return;
      }

      refTaskBlockList?.current?.add();
    }
  };

  const handleBlurInput = () => {
    if (!refInput.current) return;
    onChangeFrequentTitle(refInput.current.value);
  };

  const handleCloseFrequentPopover = () => {
    setFrequentDetail(null);
  };

  const handleFrequentWorkTitle = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFrequentWorkTitle(e.target.value);
  };

  return (
    <FrequentWorkDialogWrapper>
      <FrequentWorkToDo>
        <IssueCheckBox></IssueCheckBox>
        <TextField
          inputRef={refInput}
          autoComplete="off"
          fullWidth
          variant="standard"
          placeholder={'자주 할 일을 입력해주세요'}
          onKeyDown={handleKeydownInput}
          onBlur={handleBlurInput}
          onChange={(e) => {
            handleFrequentWorkTitle(e);
          }}
          defaultValue={frequentWork ? frequentWork?.title : ''}
          InputProps={{ disableUnderline: true, style: { fontSize: 16, fontWeight: 'bold', color: COLORS.gray800 } }}
          style={{ marginLeft: 8 }}
        />
        {frequentWork?.id && (
          <IconButton onClick={(e) => setFrequentDetail(e.currentTarget)} style={{ width: '24px', height: '24px', borderRadius: '6px', padding: '4px' }}>
            <Icons.More width={16} height={16} fill={COLORS.gray300}></Icons.More>
          </IconButton>
        )}
      </FrequentWorkToDo>

      <TaskBlockInputList ref={refTaskBlockList} tasks={frequentWork?.tasks || []} onChange={onChangeTaskBlocks} />

      {frequentWork?.id ? (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '24px' }}>
          <Button
            onClick={() => {
              onClickDialogAddTaskboxButton(frequentWork!);
            }}
          >
            할 일 목록에 추가하기
          </Button>
        </div>
      ) : frequentWorkTitle ? (
        <FrequentWorkDialogBtn>
          <Button
            variant="text"
            onClick={() => {
              onCloseDialog(frequentWork!);
            }}
            sx={{ padding: '10px 16px', borderRadius: '8px', fontSize: '13px' }}
          >
            취소하기
          </Button>
          <Button
            variant="contained"
            onClick={() => onSaveDialog(frequentWork!)}
            sx={{ padding: '10px 16px', borderRadius: '8px', fontSize: '13px', marginLeft: '8px' }}
          >
            저장하기
          </Button>
        </FrequentWorkDialogBtn>
      ) : (
        <FrequentWorkDialogBtn>
          <Button
            variant="contained"
            disabled
            onClick={() => onSaveDialog(frequentWork!)}
            sx={{ padding: '10px 16px', borderRadius: '8px', fontSize: '13px', marginLeft: '8px' }}
          >
            저장하기
          </Button>
        </FrequentWorkDialogBtn>
      )}

      <Popover
        open={Boolean(frequentDetail)}
        anchorEl={frequentDetail}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleCloseFrequentPopover}
        sx={{ marginTop: '4px' }}
      >
        <DetailModal>
          <Fonts.Blockquote
            className="detail-text"
            onClick={() => {
              setRemoveFrequent(true);
            }}
            style={{ color: `${COLORS.negative1}` }}
          >
            <Icons.Delete fill={COLORS.negative1}></Icons.Delete>
            <span style={{ marginLeft: '8px' }}>삭제</span>
          </Fonts.Blockquote>
        </DetailModal>
      </Popover>

      <Dialog
        open={removeFrequentWork}
        onClose={() => {
          setRemoveFrequent(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ color: `${COLORS.negative1}` }}>{`"${
          frequentWork?.title ? frequentWork?.title : 'Title'
        }" 자주 하는 업무를 삭제하시겠습니까?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ textAlign: 'center' }}>
            관련 데이터를 영구적으로 삭제하려고 합니다.<br></br> 확신할 수 없다면 취소를 눌러 삭제창을 닫아주세요.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setRemoveFrequent(false);
            }}
          >
            취소
          </Button>
          <Button
            onClick={() => {
              setRemoveFrequent(false);
              setFrequentDetail(null);
              setTimeout(() => {
                onClickDialogDeleteFrequentWork(frequentWork!);
              }, 200);
            }}
          >
            삭제
          </Button>
        </DialogActions>
      </Dialog>
    </FrequentWorkDialogWrapper>
  );
};

const FrequentWorkDialogWrapper = styled.div`
  padding: 24px 22px 24px 0px;
  width: 540px;
`;

const FrequentWorkToDo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding-left: 22px;
`;

const FrequentWorkSubtask = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

const AddFrequentWorkSubtask = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const FrequentWorkDialogBtn = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const DetailModal = styled.div`
  width: fit-content;
  height: fit-content;
  min-width: 138px;
  background-color: ${COLORS.white};
  box-shadow: 0px 8px 16px 0px rgba(26, 30, 39, 0.16);
  border-radius: 8px;
  padding: 12px;
  z-index: 10;

  .detail-text {
    display: flex;
    align-items: center;
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: ${COLORS.gray100};
    }
  }
`;

/** */
